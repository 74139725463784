define("elbe-ember-frond-end/templates/cookie", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 194,
            "column": 0
          }
        },
        "moduleName": "elbe-ember-frond-end/templates/cookie.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container body");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "main_container");
        var el3 = dom.createTextNode("\n      !-- top navigation -->\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "top_nav navbar-fixed-top");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "nav_menu");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "navbar-header");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6, "class", "navbar-brand");
        dom.setAttribute(el6, "href", "http://primo.design");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/images/3dprimo_logo_beta.png");
        dom.setAttribute(el7, "alt", "Visarity");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("nav");
        dom.setAttribute(el5, "role", "navigation");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" /nav-bar nav");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment(" /empty class");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" /nav-menu ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" /top navigation ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" Jumbotron ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "right_col");
        dom.setAttribute(el3, "role", "main");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "clearfix");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-md-12");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "x_panel");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "section");
        var el8 = dom.createTextNode("\n\n\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "header-tos");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("h1");
        dom.setAttribute(el9, "class", "text-black text-center");
        var el10 = dom.createTextNode("Cookie Policy");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "divider");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "row");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "col-md-6 left-panel-text");
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                      Visarity Technologies GmbH follows a strict antispam-policy and respects your privacy. To provide a personal experience for you as a visitor on our website, we use cookies.\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                      By using this website and agreeing to your Cookie settings, you consent to the processing of data about you by Visarity Technologies GmbH in accordance with the terms of this policy.\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                      About Cookies\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                      Cookies are small text-based data files that web servers store on web site visitors’ computers via the webbrowser. They can be used by web servers to identity visitors as they navigate through different pages on a website, and to identify returning visitors to a website.\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                      There are two main kinds of cookies: session cookies and persistent cookies. Session cookies are deleted from your computer when you close the browser, whereas persistent cookies remain stored on your computer until they get deleted, or until they reach their expiry date.\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                      How we use cookies\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                      We use cookies to improve your website experience and to gain insights into our websites’ user, traffic and traffic providers to make it even better. By using cookies we can improve the usability of our site and make some of the content more targeted. All in the mean to give you as a visitor a smooth experience as possible. None of these identify you personally. We may use the information we obtain from your use of our cookies for the following purposes:\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("ul");
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("to recognise your computer when you visit our website");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("to track you as you navigate our website, and to enable the use of login and webforms on our website");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("to improve the website’s usability");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("to analyse the use of our website");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("to personalise our website for you");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("to save your cookie settings");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                      ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                      Cookies on this website\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                      All cookies are associated with the “www.visarity.com” or \"www.primo.design\" domain\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                      Strictly necessary cookies\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                      Some cookies are strictly necessary in order to enable you to move around the website and use its features. Without these cookies,\n                      we will not be able to provide certain features, such as automatic sign into the Visarity Technologies GmbH services.\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                      Google Analytics (__utm*)\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                       ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("a");
        dom.setAttribute(el11, "href", "https://www.google.com/intl/sv/analytics/");
        dom.setAttribute(el11, "target", "_blank");
        var el12 = dom.createTextNode("Google Analytics");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" is a software solution for webmasters and help\n                        us to get access to e.g. user statistics and to adjust certain parts of our content regarding the user needs. Cookies also help third-party\n                        providers (e.g. Google) to recognize which content and advertisements across multiple\n                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment(" end of left panel");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "col-md-6 right-panel-text");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        websites were interesting for users.All cookies by Google can be identified and start with “__utma”, “__utmb”, “__utmc”, “__utmv”, “__utmz”. None of these identify you\n                        personally.This website uses Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses “cookies”, which are text files placed\n                        on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address)\n                        will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website,\n                        compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer\n                        this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate\n                        your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that\n                        if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in\n                        the manner and for the purposes set out above.\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        Data stored as cookies are for example:\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("ul");
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("Browsername/ -version");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("Operating system");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("Referrer URL");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("Hostname (IP Adress)n");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("Time of the server requestn");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        Third Party Cookies\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        When you use our website, you may also be sent third party cookies. Some of them might be intrusive.\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        Cookie preferences\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not\n                        be able to use the full functionality of this website.\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        Blocking or deleting cookies\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        Most browsers allow you to refuse to accept or to delete cookies. You can prevent that cookies are stored on your computer by modifying the settings in your web browser.\n                        Learn more about how to change the cookies settings.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                        You can block Google from accessing and storing cookies on your computer by using a browser plugin.\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        Browser-specific instructions to block and remove cookie\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("ul");
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("Internet Explorer: ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("a");
        dom.setAttribute(el13, "href", "http://support.microsoft.com/kb/196955");
        dom.setAttribute(el13, "target", "_blank");
        var el14 = dom.createTextNode("http://support.microsoft.com/kb/196955");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("FireFox: ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("a");
        dom.setAttribute(el13, "href", "http://support.mozilla.org/en-US/kb/Cookies");
        dom.setAttribute(el13, "target", "_blank");
        var el14 = dom.createTextNode("http://support.mozilla.org/en-US/kb/Cookies");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("Google Chrome: ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("a");
        dom.setAttribute(el13, "href", "http://support.google.com/chrome/bin/answer.py?hl=en&answer=95647");
        dom.setAttribute(el13, "target", "_blank");
        var el14 = dom.createTextNode("http://support.google.com/chrome/bin/answer.py?hl=en&answer=95647");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                          ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("li");
        var el13 = dom.createTextNode("Safari: ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("a");
        dom.setAttribute(el13, "href", "http://docs.info.apple.com/article.html?artnum=32467");
        dom.setAttribute(el13, "target", "_blank");
        var el14 = dom.createTextNode("http://docs.info.apple.com/article.html?artnum=32467");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode(" ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                        ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        Other\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        There might be also content on our website linking to other websites, which also use cookies.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                        Please contact us if you have questions about how we use cookies.\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n\n\n\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment(" x-panel ");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" col-md-12 ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" row ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" main container ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});