define("elbe-ember-frond-end/templates/components/co2-tc-form", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 2
              },
              "end": {
                "line": 65,
                "column": 2
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/co2-tc-form.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "x_panel");
            dom.setAttribute(el1, "style", "border-width: 2px; border-style:solid;border-color:black;");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "x_content");
            var el3 = dom.createTextNode("    \n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "id", "new-pub-form");
            dom.setAttribute(el3, "class", "form-horizontal form-label-left");
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "id", "tc-container");
            dom.setAttribute(el4, "style", "height: 50vh;overflow-y:scroll;position:relative;");
            var el5 = dom.createTextNode("    \n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("h1");
            dom.setAttribute(el5, "id", "terms-and-conditions-for-co2-reports");
            var el6 = dom.createTextNode("Terms and Conditions for CO2e reports");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("p");
            var el6 = dom.createTextNode("The Primo platform offers an external service to their customers to estimate the energy consumption and carbon emissions (“CO2e reports”) of advertising campaigns. This service is provided by PYURE GmbH (“PYURE”) and performed by software from IMPACT PLUS (“IMPACT PLUS”).");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("p");
            var el6 = dom.createTextNode("The Primo Vertriebs GmbH & Co KG (“PRIMO”) is acting as an intermediary between you as our client (“CLIENT”) and PYURE. ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("p");
            var el6 = dom.createTextNode("As far as General Data Protection Regulation (GDPR) is concerned, PYURE is not processing personal data of any kind related to Ad viewers. However, to enable this reporting the following data will be transferred:");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("ul");
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createTextNode("Your Company Name  ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createTextNode("Creative Name and ID (Primo given ID)  ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createTextNode("Weight of the creative  ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createTextNode("Number of Impressions  ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createTextNode("Per impression:  ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("ul");
            var el8 = dom.createTextNode("\n          ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("li");
            var el9 = dom.createTextNode("Domain   ");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n          ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("li");
            var el9 = dom.createTextNode("Country  ");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n          ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("li");
            var el9 = dom.createTextNode("Device");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n          ");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n          ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("p");
            var el6 = dom.createTextNode("The calculation of the CO2e reports comes with a Cost per Mille (CPM) fee. The exact amount of such CPM can be found under: ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("a");
            dom.setAttribute(el6, "href", "https://primo.visarity.com/company");
            var el7 = dom.createTextNode("https://primo.visarity.com/company");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(" look for CO2e pricing. The CO2e costs are separately listed on our invoices and the reporting overview page.");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("p");
            var el6 = dom.createTextNode("A CO2e report entails the following: ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("ul");
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createTextNode("Total GHG emissions (in kilograms of CO2e equivalent)  ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createTextNode("GHG emissions per 1000 impressions (in grams of CO2e equivalent)  ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createTextNode("Total electricity consumption (in kilowatt-hours)  ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("li");
            var el7 = dom.createTextNode("Electricity consumption per 1000 impressions (in watt-hours)");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n          ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("p");
            var el6 = dom.createTextNode("By agreeing to these terms and conditions you are entitled to enable CO2e reporting on a per creative basis or turn it on by default.");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("p");
            var el6 = dom.createElement("strong");
            var el7 = dom.createTextNode("About PYURE GmbH");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("br");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("PYURE is pioneer in CO2e-attribution, analysis and reduction of digital advertising campaigns in DACH. The company offers the first SaaS platform powered by IMPACT PLUS for the decarbonisation of online advertising for advertisers, media companies, media agencies and AdTech companies to automatically make online advertising climate-neutral and more efficient. PYURE GmbH is a privately owned company registered with the Companies Register HRB at number HRB 261720 B, with its registered address at Waldkiefernring 22, 12527 Berlin. ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("br");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("PYURE GmbH can be contacted at the following email address: ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("a");
            dom.setAttribute(el6, "href", "mailto:support@pyure.ai");
            var el7 = dom.createTextNode("support@pyure.ai");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(" .");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("p");
            var el6 = dom.createElement("strong");
            var el7 = dom.createTextNode("About IMPACT PLUS");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("br");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("The software provider IMPACT PLUS (“IMPACT PLUS”) is a simplified joint stock company registered with the Paris Trade and Companies Register under number 887 674 901, whose registered oﬃce is is located at 20, rue Etienne Dolet - 75020 Paris.");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("        ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "id", "button-agree");
            dom.setAttribute(el4, "style", "margin-left:auto;margin-right:auto;width:fit-content;margin-top:10px;");
            var el5 = dom.createTextNode("          \n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("button");
            dom.setAttribute(el5, "style", "margin-right: 100px;");
            dom.setAttribute(el5, "type", "submit");
            dom.setAttribute(el5, "class", "btn btn-lg btn-danger");
            var el6 = dom.createElement("i");
            dom.setAttribute(el6, "class", "fa fa-close");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(" Cancel");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("                            \n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("button");
            dom.setAttribute(el5, "type", "submit");
            dom.setAttribute(el5, "class", "btn btn-lg btn-success");
            var el6 = dom.createElement("i");
            dom.setAttribute(el6, "class", "fa fa-check");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("Agree");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("        \n        ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1, 1, 3]);
            var element1 = dom.childAt(element0, [1]);
            var element2 = dom.childAt(element0, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element1);
            morphs[1] = dom.createAttrMorph(element2, 'disabled');
            morphs[2] = dom.createElementMorph(element2);
            return morphs;
          },
          statements: [["element", "action", ["closeForm"], [], ["loc", [null, [59, 91], [59, 113]]]], ["attribute", "disabled", ["get", "blockAgreeButton", ["loc", [null, [60, 43], [60, 59]]]]], ["element", "action", ["confirmTC"], [], ["loc", [null, [60, 93], [60, 115]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 66,
              "column": 0
            }
          },
          "moduleName": "elbe-ember-frond-end/templates/components/co2-tc-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "modal-dialog", [], ["targetAttachment", "center", "translucentOverlay", true, "clickOutsideToClose", true, "close", ["subexpr", "action", ["closeForm"], [], ["loc", [null, [13, 10], [13, 30]]]], "overlay-class", "custom-styles-modal", "containerClass", "centered-scrolling-container", "containerClassNames", "custom-styles-modal-container-pricing", "wrapperClass", "centered-scrolling-wrapper"], 0, null, ["loc", [null, [9, 2], [65, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 66,
            "column": 7
          }
        },
        "moduleName": "elbe-ember-frond-end/templates/components/co2-tc-form.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-group");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "control-label col-md-1 col-sm-1 col-xs-12");
        var el3 = dom.createTextNode("CO2e Tracking");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-9 col-sm-6 col-xs-12");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  \n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 3]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "bs-switch", [], ["checked", ["subexpr", "@mut", [["get", "co2Tracking", ["loc", [null, [4, 24], [4, 35]]]]], [], []], "value", ["subexpr", "@mut", [["get", "co2Tracking", ["loc", [null, [4, 42], [4, 53]]]]], [], []], "on-switch-change", ["subexpr", "action", ["onSwitchChange"], [], ["loc", [null, [4, 71], [4, 96]]]]], ["loc", [null, [4, 4], [4, 98]]]], ["block", "if", [["get", "showForm", ["loc", [null, [8, 6], [8, 14]]]]], [], 0, null, ["loc", [null, [8, 0], [66, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});