define("elbe-ember-frond-end/components/file-drop", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    fileInput: null,
    validFormats: ".png,.jpg,.jpeg",

    checkFiles: function checkFiles(inputFiles) {
      function isFormat(path, formats) {
        var ret = false;
        var format = path.split(".");
        format = format[format.length - 1].toLowerCase();
        if (typeof formats === "string") {
          formats = formats.split(",");
        }

        formats.forEach(function (f) {
          ret = ret || f.replace(".", "") === format;
        });
        return ret;
      }

      var files = [];
      if (inputFiles && inputFiles.length) {
        for (var i = 0; i < inputFiles.length; i++) {
          var f = inputFiles[i];
          if (isFormat(f.name, this.validFormats)) {
            files.push(f);
          }
        }
        if (this.handleFiles && files.length > 0) {
          this.handleFiles(files);
        }
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.fileInput = document.getElementById("file_" + this.elementId);
      this.fileInput.onchange = this.files.bind(this);
    },

    preventDefaults: function preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    },

    dragStart: function dragStart(e) {
      this.preventDefaults(e);
      console.log("dragStart");
    },

    dragStop: function dragStop(e) {
      this.element.classList.remove("drop");
      this.preventDefaults(e);
      console.log("dragStop");
    },

    dragEnter: function dragEnter(e) {
      this.preventDefaults(e);
      this.element.classList.add("drop");
      console.log("dragEnter");
    },

    dragLeave: function dragLeave(e) {
      this.preventDefaults(e);
      this.element.classList.remove("drop");
      console.log("dragLeave");
    },

    dragOver: function dragOver(e) {
      this.preventDefaults(e);
      this.element.classList.add("drop");
      console.log("dragOver");
    },

    click: function click() {
      this.fileInput.value = null;
      this.fileInput.click();
      //console.log("click");
    },

    files: function files() {
      this.checkFiles(this.fileInput.files);
    },

    drop: function drop(e) {
      this.preventDefaults(e);
      this.element.classList.remove("drop");
      this.checkFiles(e.dataTransfer.files);
    },

    actions: {
      onClick: function onClick() {
        console.log("On Click");
      }
    }
  });
});