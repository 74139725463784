define('elbe-ember-frond-end/components/format-gallery-component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;
      function primoClone(c) {
        console.log(c);
        c.campaignID = c.creativeID;
        _this.parentController.set('campaign', c);
        _this.parentController.toggleProperty("isShowingCopyModal");
      }

      console.log(this.galleryID);

      window.primoGalleryConfig = {
        galleryID: "a721e146-cadb-426e-bf49-127a75dc8e5b",
        updateSearchParams: false,
        initialCreativeID: null,
        //sortBy: "size",
        //overridGalleryName: "Clone Gallery",
        primoClone: primoClone
      };

      var sc = document.createElement("script");

      if (_ember['default'].Environment === "development") {
        var head = document.getElementsByTagName('HEAD')[0];
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = 'https://local.visarity.com:8888/dist/gallery.css';
        head.appendChild(link);
        sc.src = "https://local.visarity.com:8888/dist/gallery.js";

        window.primoGalleryConfig.apiUrl = "https://local.visarity.com:8181/gallery/listcreatives";
        window.primoGalleryConfig.previewBaseUrl = "https://dps.visarity.com";
        window.primoGalleryConfig.previewHostUrl = "http://localhost:3000/preview";
        window.primoGalleryConfig.galleryID = "0b5bb867-f11e-4c95-b049-a1d6c9d59891";
      } else if (_ember['default'].Environment === "staging") {
        window.primoGalleryConfig.apiUrl = "https://dapi.visarity.com/gallery/listcreatives";
        sc.src = "https://dps.visarity.com/gallery/gallery.js?t=" + Date.now();
        window.primoGalleryConfig.previewBaseUrl = "https://ps.visarity.com";
        window.primoGalleryConfig.previewHostUrl = "https://ps.visarity.com/preview";
      } else {
        sc.src = "https://ps.visarity.com/gallery/gallery.js";
        window.primoGalleryConfig.previewBaseUrl = "https://ps.visarity.com";
        window.primoGalleryConfig.previewHostUrl = "https://ps.visarity.com/preview";
      }

      if (this.galleryID) {
        window.primoGalleryConfig.galleryID = this.galleryID;
      }

      document.getElementById("primoGallery").appendChild(sc);
      this._super.apply(this, arguments);
    }
  });
});