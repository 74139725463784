define('elbe-ember-frond-end/controllers/changepsswd', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        session: _ember['default'].inject.service('session'),
        current_password: null,
        new_password: null,
        confirm_password: null,
        isProcessing: false,
        init: function init() {},
        actions: {
            changePassword: function changePassword() {
                var currentPassword = this.get('current_password');
                var newPassword = this.get('new_password');
                var confirmPassword = this.get('confirm_password');
                var controller = this;
                var userID = this.get('session.data.authenticated.user_ID');
                if (currentPassword == "" || currentPassword == null) {
                    this.notifications.error('Current password cannot be blank!', {
                        autoClear: true,
                        clearDuration: 3400
                    });
                }
                var char8,
                    diffNewPasswd,
                    oneNumber,
                    lowerCase,
                    upperCase,
                    passwdMatch,
                    nullPasswd = false;
                if (newPassword !== null || confirmPassword !== null || currentPassword !== null) {
                    nullPasswd = true;
                    if (newPassword !== "" && newPassword === confirmPassword) {
                        passwdMatch = true;
                        if (newPassword.length < 8) {
                            this.notifications.error('Password must contain at least eight (8) characters!', {
                                autoClear: true,
                                clearDuration: 3400
                            });
                        } else {
                            char8 = true;
                        }
                        if (newPassword === currentPassword) {
                            this.notifications.error('New Password must be different from current password!', {
                                autoClear: true,
                                clearDuration: 3400
                            });
                        } else {
                            diffNewPasswd = true;
                        }
                        var re = /[0-9]/;
                        if (!re.test(newPassword)) {
                            this.notifications.error('password must contain at least one number (0-9)!', {
                                autoClear: true,
                                clearDuration: 3400
                            });
                        } else {
                            oneNumber = true;
                        }
                        re = /[a-z]/;
                        if (!re.test(newPassword)) {
                            this.notifications.error('password must contain at least one lowercase letter (a-z)!', {
                                autoClear: true,
                                clearDuration: 3400
                            });
                        } else {
                            lowerCase = true;
                        }
                        re = /[A-Z]/;
                        if (!re.test(newPassword)) {
                            this.notifications.error('password must contain at least one uppercase letter (A-Z)!', {
                                autoClear: true,
                                clearDuration: 3400
                            });
                        } else {
                            upperCase = true;
                        }
                    } else {
                        this.notifications.error('ReEnter your password to confirm it!', {
                            autoClear: true,
                            clearDuration: 3400
                        });
                    }
                }

                if (char8 && diffNewPasswd && oneNumber && lowerCase && upperCase && passwdMatch && nullPasswd) {
                    var params = {
                        current_password: currentPassword,
                        new_password: newPassword,
                        confirm_password: confirmPassword
                    };
                    _ember['default'].$.ajax({
                        url: _ember['default'].API_URL + '/updatepassword/' + userID,
                        type: 'POST',
                        data: params,
                        success: function success() /*response*/{
                            controller.set('isProcessing', false); //trigger splash animation
                            controller.notifications.success('Password has been successfully updated', {
                                autoClear: true,
                                clearDuration: 3200
                            });
                            controller.send('clearFields');
                        },
                        error: function error(_error) {
                            controller.set('isProcessing', false);
                            //console.log(JSON.parse(error.responseText).message);
                            controller.notifications.error(JSON.parse(_error.responseText).message, {
                                autoClear: true,
                                clearDuration: 3200
                            });
                        }
                    }); //ajax
                }
            }, //change password
            clearFields: function clearFields() {
                this.set('current_password', '');
                this.set('new_password', '');
                this.set('confirm_password', '');
            }
        }
    });
});