define("elbe-ember-frond-end/helpers/kformatter", ["exports", "ember"], function (exports, _ember) {
  exports.kformatter = kformatter;

  function kformatter(params) {
    var num = params[0];
    var digits = params[1];
    var d = params[2] || 1024;
    if (digits === undefined) {
      digits = 3;
    }
    if (Number.isNaN(num)) {
      return "n/a";
    } else if (!num) {
      return 0;
    }

    return (num / d).toFixed(digits);
  }

  exports["default"] = _ember["default"].Helper.helper(kformatter);
});