define('elbe-ember-frond-end/helpers/str-connect', ['exports', 'ember'], function (exports, _ember) {
  exports.strConnect = strConnect;

  function strConnect(params, hash) {
    var out = "";
    params.forEach(function (s) {
      out += s;
    });

    var len = hash.limit;

    var p = out.length > len;
    out = out.substr(0, len);

    if (p) {
      out += '..';
    }

    return out;
  }

  exports['default'] = _ember['default'].Helper.helper(strConnect);
});