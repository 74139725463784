define('elbe-ember-frond-end/controllers/list-publisher', ['exports', 'ember', 'elbe-ember-frond-end/utils/formatUtils', 'underscore'], function (exports, _ember, _elbeEmberFrondEndUtilsFormatUtils, _underscore) {
    exports['default'] = _ember['default'].Controller.extend({
        session: _ember['default'].inject.service('session'),
        init: function init() {},
        searchValueObserver: (function () /*searchStr*/{
            var searchString = this.get('searchTerm').toLowerCase();
            var publisherList = this.get('publisher_list_data_orig');
            var filteredList = _underscore['default'].filter(publisherList, function (obj) {
                return obj.name.toLowerCase().indexOf(searchString) >= 0;
            });
            this.set('publisher_list_data', filteredList);
        }).observes('searchTerm'),
        actions: {
            searchPublisherName: function searchPublisherName(searchStr) {
                var publisherList = this.get('publisher_list_data_orig');
                var filteredList = _underscore['default'].filter(publisherList, function (obj) {
                    return obj.name.toLowerCase().indexOf(searchStr.toLowerCase());
                });
                this.set('brand_list_data', filteredList);
            },
            clearSearch: function clearSearch() {
                var campaignList = this.get('brand_list_data_orig');
                this.set('brand_list_data', campaignList);
                this.set('searchTerm', '');
            },
            createNewPublisher: function createNewPublisher() {
                this.transitionToRoute('publishers');
            },
            fetchPublishers: function fetchPublishers() {
                var controller = this;
                _ember['default'].$.ajax({
                    url: _ember['default'].API_URL + '/publisher/' + this.get('session.data.authenticated.user_ID'),
                    type: 'GET',
                    dataType: 'json',
                    success: function success(response) {
                        controller.set('publisher_list_data', response);
                        //console.log(response);
                    },
                    error: function error(_error) {
                        console.log(_error);
                    }
                }); //ajax
            },
            detelePublisherFromDB: function detelePublisherFromDB(pub) {
                //var pubData = this.get('publisher_list_data');
                //console.log(pubData);
                var controller = this;
                $('#' + pub.publisherID).closest('tr').children('td').animate({
                    padding: 0
                }).wrapInner('<div />').children().slideUp(200, function () {
                    $(this).closest('tr').remove();
                });

                //delete does not care about return type JSON
                _ember['default'].$.ajax({
                    url: _ember['default'].API_URL + '/publisher/' + pub.publisherID,
                    type: 'DELETE',
                    contentType: 'application/json',
                    success: function success() /*response*/{
                        controller.send('fetchPublishers');
                    },
                    error: function error(_error2) {
                        console.log(_error2);
                    }
                }); //ajax
            }
        }
    });
});