define("elbe-ember-frond-end/components/cpm-pricing-info", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    headers: ["Name"],
    rowData: [],
    currency: "EUR",

    init: function init() {
      this._super();
      var c = this.currency = this.get('pricing.currency');
      var p = this.get('pricing.prices');
      var h = this.headers = ["Name"];
      var r = this.rowData = [];

      var toMio = function toMio(n) {
        return (n / 1000000 | 0).toFixed(1);
      };

      if (p.tiers) {
        h.push("< " + toMio(p.tiers[0].imp) + " Mio");

        p.tiers.forEach(function (e) {
          h.push("> " + toMio(e.imp) + " Mio");
        });

        p.categories.forEach(function (pp) {
          var row = [];
          row.push(pp.name);
          row.push(pp.cpm.toFixed(2) + " " + c);
          pp.tiers.forEach(function (t) {
            row.push(t.cpm.toFixed(2) + " " + c);
          });
          r.push(row);
        });
      } else {
        h.push("CPM");
        p.categories.forEach(function (pp) {
          var row = [];
          row.push(pp.name);
          row.push(pp.cpm.toFixed(2) + " " + c);
          r.push(row);
        });
      }
    }
  });
});