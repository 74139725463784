define('elbe-ember-frond-end/routes/verify-email', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
        title: 'Verify email',
        beforeModel: function beforeModel(transition) {
            //console.log(transition.queryParams.token);
        },
        model: function model(params, transition) {
            var username = transition.queryParams.username;

            var params = {
                token: transition.queryParams.token,
                username: username.replace(/( target)/gi, '')
            };
            //console.log(params);
            return _ember['default'].$.ajax({
                url: _ember['default'].API_URL + '/verifyemail',
                type: 'POST',
                data: params,
                success: function success(response, textStatus, xhr) {},
                error: function error(_error) {}
            });
        },
        setupController: function setupController(controller, model, transition) {
            console.log(model);
            switch (model.status) {
                case 200:
                    controller.set('emailVerified', true);

                    _ember['default'].run.later(function () {
                        controller.transitionToRoute('login');
                    }, 2800);

                    break;
                case 201:
                case 202:
                    controller.set('emailVerified', false);
                    controller.set('error_message', model.message);
                    break;
            }
        }

    });
});
//app/routes/login.js