define('elbe-ember-frond-end/routes/edit-org', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'elbe-ember-frond-end/utils/formatUtils', 'underscore'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _elbeEmberFrondEndUtilsFormatUtils, _underscore) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'Edit Organization',

    model: function model(params) {
      var orgData = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/org/' + params.id,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            resolve(response);
          },
          error: function error(_error) {}
        }); //ajax
      }); //promise

      /*
      var orgUsers = orgData.then(function(response) {
        var domain = response.billingEmail.lastIndexOf('@');
        return Ember.$.ajax({
          url: Ember.API_URL + '/org/users/' + encodeURIComponent(response.billingEmail.slice(domain+1)),
          type: 'GET',
          dataType: 'json',
        }); //ajax
      }, function(error) {
        reject(error);
      });
      */

      var user_stats = orgData.then(function (response) {
        var domain = response.billingEmail.lastIndexOf('@');
        return _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/org/userstats/' + encodeURIComponent(response.billingEmail.slice(domain + 1)),
          type: 'GET',
          dataType: 'json'
        }); //ajax
      }, function (error) {
        reject(error);
      });

      var orgAllUsers = orgData.then(function (response) {
        return _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/org/allusers/' + response.orgID,
          type: 'GET',
          dataType: 'json'
        }); //ajax
      }, function (error) {
        reject(error);
      });

      return new _ember['default'].RSVP.hash({
        org: orgData,
        users: orgAllUsers,
        userStats: user_stats
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('users_list_data', "");
      controller.set('selectedUsers', "");
      controller.set('orgID', model.org.orgID);
      controller.set('org-name', model.org.name);
      controller.set('org-city', model.org.city);
      controller.set('org-address', model.org.address);
      controller.set('org-pincode', model.org.pincode);
      controller.set('org-email', model.org.billingEmail);
      controller.set('countrySelected', model.org.country);
      controller.set('org-taxID', model.org.taxID);
      controller.set('org-taxID-verified', model.org.taxID_verified);
      controller.set('org-video-CPM', model.org.videoCPM);
      controller.set('org-richmedia-CPM', model.org.richmediaCPM);
      controller.set('orgBillingTypeSelected', model.org.billingType);
      controller.set('userList', model.users);

      if (model.org.userIDList !== null || !_underscore['default'].isEmpty(model.org.userIDList)) {
        if (model.userStats !== null && model.org.userIDList !== null && !_underscore['default'].isEmpty(model.org.userIDList)) {
          var filteredList = [];
          for (var i = 0; i < model.userStats.length; i++) {
            if (model.userStats[i].userID !== null) {
              for (var j = 0; j < model.org.userIDList.id.length; j++) {
                if (model.org.userIDList.id[j].email === model.userStats[i].email) {
                  filteredList.push(model.userStats[i]);
                }
              }
            }
          }
          controller.set('users_list_data', _underscore['default'].sortBy(_underscore['default'].filter(filteredList, function (obj) {
            return obj.email !== null;
          }), 'campaignCount').reverse());
        } //if
      }

      if (model.org.userIDList !== null) {
        controller.set('selectedUsers', model.org.userIDList.id);
      }
      if (model.org.taxID_verified) {
        controller.set('v-taxID', model.org.taxID);
        controller.set('v-countryID', model.org.country);
        controller.set('v-company-name', model.org.name);
        controller.set('v-company-address', model.org.address);
      }
    }
  });
});