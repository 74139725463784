define("elbe-ember-frond-end/templates/components/creative-gallery", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 14
            },
            "end": {
              "line": 43,
              "column": 14
            }
          },
          "moduleName": "elbe-ember-frond-end/templates/components/creative-gallery.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "control-label col-md-3 col-sm-3 col-xs-12");
          var el3 = dom.createTextNode("Allow Copy From");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-8");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                      \n                      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "style", "width:126px;margin-left:16px;margin-top:2px;");
          dom.setAttribute(el4, "type", "button");
          dom.setAttribute(el4, "data-original-title", "Preview");
          dom.setAttribute(el4, "class", "btn btn-dark btn-md btn btn-dark");
          var el5 = dom.createElement("i");
          dom.setAttribute(el5, "class", "fa fa-eye");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" Preview");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                       \n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                     \n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("              \n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1, 3, 1]);
          var element7 = dom.childAt(element6, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element6, 1, 1);
          morphs[1] = dom.createElementMorph(element7);
          return morphs;
        },
        statements: [["inline", "bs-switch", [], ["checked", ["subexpr", "@mut", [["get", "downloadable", ["loc", [null, [38, 42], [38, 54]]]]], [], []], "value", ["subexpr", "@mut", [["get", "downloadable", ["loc", [null, [38, 61], [38, 73]]]]], [], []]], ["loc", [null, [38, 22], [38, 75]]]], ["element", "action", ["openCopyFromGallery", ["get", "creative.creativeID", ["loc", [null, [39, 200], [39, 219]]]]], ["bubbles", false], ["loc", [null, [39, 168], [39, 236]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 71,
                "column": 1
              },
              "end": {
                "line": 86,
                "column": 0
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/creative-gallery.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1, "style", "cursor:grab;");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "span2 vertical_align_coln bold");
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3, "style", "width:96px;height:76px;object-fit:contain;");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      \n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "span2 vertical_align_coln bold");
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "span2 vertical_align_coln bold");
            var el3 = dom.createTextNode(" Brand: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("Creative Format: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "span2 vertical_align_coln column_width_small");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "style", "width:126px;");
            dom.setAttribute(el3, "type", "button");
            dom.setAttribute(el3, "data-original-title", "Preview");
            dom.setAttribute(el3, "class", "btn btn-dark btn-md btn btn-dark");
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "fa fa-eye");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" Preview");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" \n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                                \n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "span2 vertical_align_coln column_width_small");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "style", "cursor:pointer;");
            dom.setAttribute(el3, "title", "Open Settings");
            dom.setAttribute(el3, "data-placement", "top");
            dom.setAttribute(el3, "data-toggle", "tooltip");
            dom.setAttribute(el3, "type", "button");
            dom.setAttribute(el3, "data-original-title", "remove");
            dom.setAttribute(el3, "class", "btn btn-dark btn-md btn_dashboard_icon");
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "fa fa fa-wrench");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                                \n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "span2 vertical_align_coln column_width_small");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "style", "cursor:pointer;");
            dom.setAttribute(el3, "title", "Remove creative from Gallery");
            dom.setAttribute(el3, "data-placement", "top");
            dom.setAttribute(el3, "data-toggle", "tooltip");
            dom.setAttribute(el3, "type", "button");
            dom.setAttribute(el3, "data-original-title", "remove");
            dom.setAttribute(el3, "class", "btn btn-dark btn-md btn_dashboard_icon");
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "fa fa fa-trash");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                                \n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1, 1]);
            var element2 = dom.childAt(element0, [5]);
            var element3 = dom.childAt(element0, [7, 1]);
            var element4 = dom.childAt(element0, [9, 1]);
            var element5 = dom.childAt(element0, [11, 1]);
            var morphs = new Array(9);
            morphs[0] = dom.createAttrMorph(element0, 'id');
            morphs[1] = dom.createAttrMorph(element1, 'src');
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
            morphs[3] = dom.createMorphAt(element2, 1, 1);
            morphs[4] = dom.createMorphAt(element2, 4, 4);
            morphs[5] = dom.createMorphAt(element2, 6, 6);
            morphs[6] = dom.createElementMorph(element3);
            morphs[7] = dom.createElementMorph(element4);
            morphs[8] = dom.createElementMorph(element5);
            return morphs;
          },
          statements: [["attribute", "id", ["concat", [["get", "creative.creativeID", ["loc", [null, [72, 12], [72, 31]]]]]]], ["attribute", "src", ["get", "creative.splash_image", ["loc", [null, [73, 61], [73, 82]]]]], ["content", "creative.name", ["loc", [null, [74, 50], [74, 67]]]], ["inline", "lut", [["get", "creative.brandID", ["loc", [null, [75, 63], [75, 79]]]], ["get", "brandLUT", ["loc", [null, [75, 80], [75, 88]]]], "name", ""], [], ["loc", [null, [75, 57], [75, 100]]]], ["inline", "lut", [["get", "creative.formatID", ["loc", [null, [75, 127], [75, 144]]]], ["get", "formatLUT", ["loc", [null, [75, 145], [75, 154]]]], "name", ""], [], ["loc", [null, [75, 121], [75, 166]]]], ["content", "creative.creativeID", ["loc", [null, [75, 170], [75, 193]]]], ["element", "action", ["openPreview", ["get", "creative.creativeID", ["loc", [null, [77, 146], [77, 165]]]]], ["bubbles", false], ["loc", [null, [77, 122], [77, 182]]]], ["element", "action", ["openSettings", ["get", "creative.creativeID", ["loc", [null, [80, 219], [80, 238]]]]], [], ["loc", [null, [80, 195], [80, 240]]]], ["element", "action", ["removeCreative", ["get", "creative", ["loc", [null, [83, 236], [83, 244]]]]], [], ["loc", [null, [83, 210], [83, 246]]]]],
          locals: ["creative"],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 4
            },
            "end": {
              "line": 87,
              "column": 0
            }
          },
          "moduleName": "elbe-ember-frond-end/templates/components/creative-gallery.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ui-sortable", [], ["axis", "y", "placeholder", "placeholder76", "moved", ["subexpr", "action", ["movedCreative"], [], ["loc", [null, [71, 59], [71, 83]]]], "tagName", "tbody", "content", ["subexpr", "@mut", [["get", "creative_list_data", ["loc", [null, [71, 108], [71, 126]]]]], [], []]], 0, null, ["loc", [null, [71, 1], [86, 16]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 89,
            "column": 0
          }
        },
        "moduleName": "elbe-ember-frond-end/templates/components/creative-gallery.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "clearfix");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-12 col-sm-12 col-xs-12");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "x_panel");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "x_title");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h3");
        var el6 = dom.createTextNode("Edit Gallery - ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "clearfix");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "x_content");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("form");
        dom.setAttribute(el5, "id", "new-brand-form");
        dom.setAttribute(el5, "data-parsley-validate", "");
        dom.setAttribute(el5, "class", "form-horizontal form-label-left");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "row");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "col-md-6 col-sm-6 col-xs-6");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "form-group");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9, "class", "control-label col-md-3 col-sm-3 col-xs-12");
        var el10 = dom.createTextNode("galleryID");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "col-md-8");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "form-group");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9, "class", "control-label col-md-3 col-sm-3 col-xs-12");
        dom.setAttribute(el9, "for", "format-name");
        var el10 = dom.createTextNode("Name ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("span");
        dom.setAttribute(el10, "class", "required");
        var el11 = dom.createTextNode("*");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "col-md-8");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "form-group");
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("label");
        dom.setAttribute(el9, "class", "control-label col-md-3 col-sm-3 col-xs-12");
        var el10 = dom.createTextNode("Description");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "col-md-8");
        var el10 = dom.createTextNode("\n                  ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n              ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n");
        dom.appendChild(el7, el8);
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("            \n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("br");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("br");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "form-group");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "col-md-12 col-sm-12 col-xs-12 col-md-offset-5");
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("button");
        dom.setAttribute(el8, "type", "submit");
        dom.setAttribute(el8, "class", "btn btn-lg btn-primary btn-success");
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("              \n            ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" x-content ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" x-panel ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" col-md-12 ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" row ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("table");
        dom.setAttribute(el1, "id", "campaigns_dash");
        dom.setAttribute(el1, "class", "table table-striped jambo_table");
        dom.setAttribute(el1, "style", "max-width: 1200px");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("thead");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "vertical_align_coln");
        var el5 = dom.createTextNode("Image");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "vertical_align_coln");
        var el5 = dom.createTextNode("Name");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "vertical_align_coln");
        var el5 = dom.createTextNode("Info");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "vertical_align_coln");
        var el5 = dom.createTextNode("Preview");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "vertical_align_coln");
        var el5 = dom.createTextNode("Settings");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "vertical_align_coln");
        var el5 = dom.createTextNode("Remove");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("              \n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [2, 1, 1]);
        var element9 = dom.childAt(element8, [3, 3]);
        var element10 = dom.childAt(element9, [1, 1]);
        var element11 = dom.childAt(element9, [8, 1, 1]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(dom.childAt(element8, [1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element10, [1, 3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element10, [3, 3]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element10, [5, 3]), 1, 1);
        morphs[4] = dom.createMorphAt(element10, 7, 7);
        morphs[5] = dom.createMorphAt(element9, 3, 3);
        morphs[6] = dom.createAttrMorph(element11, 'disabled');
        morphs[7] = dom.createElementMorph(element11);
        morphs[8] = dom.createMorphAt(element11, 1, 1);
        morphs[9] = dom.createMorphAt(dom.childAt(fragment, [5]), 3, 3);
        return morphs;
      },
      statements: [["content", "gallery.name", ["loc", [null, [6, 27], [6, 43]]]], ["inline", "input", [], ["type", "text", "class", "form-control", "disabled", "disabled", "readonly", "true", "value", ["subexpr", "@mut", [["get", "gallery.galleryID", ["loc", [null, [17, 100], [17, 117]]]]], [], []]], ["loc", [null, [17, 18], [17, 119]]]], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "gallery.name", ["loc", [null, [24, 44], [24, 56]]]]], [], []], "placeholder", "", "required", "required", "class", "form-control col-md-6 col-xs-12"], ["loc", [null, [24, 18], [24, 134]]]], ["inline", "textarea", [], ["class", "form-control", "rows", "2", "value", ["subexpr", "@mut", [["get", "gallery.description", ["loc", [null, [30, 65], [30, 84]]]]], [], []]], ["loc", [null, [30, 18], [30, 86]]]], ["block", "if", [["get", "user_admin_role", ["loc", [null, [33, 20], [33, 35]]]]], [], 0, null, ["loc", [null, [33, 14], [43, 21]]]], ["inline", "notification-container", [], ["notifications", ["subexpr", "@mut", [["get", "notifications", ["loc", [null, [46, 49], [46, 62]]]]], [], []], "position", "top"], ["loc", [null, [46, 10], [46, 79]]]], ["attribute", "disabled", ["get", "isSaving", ["loc", [null, [50, 119], [50, 127]]]]], ["element", "action", ["save_newGallery"], [], ["loc", [null, [50, 79], [50, 107]]]], ["content", "gallery_btn_save", ["loc", [null, [50, 131], [50, 151]]]], ["block", "if", [["get", "showSortable", ["loc", [null, [70, 10], [70, 22]]]]], [], 1, null, ["loc", [null, [70, 4], [87, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});