define('elbe-ember-frond-end/controllers/zip-gen', ['exports', 'ember', 'elbe-ember-frond-end/config/environment'], function (exports, _ember, _elbeEmberFrondEndConfigEnvironment) {

  /********************************************************************/
  exports['default'] = _ember['default'].Controller.extend({
    options: null,
    adnamiID: "<Place-the-Adnami-specific-creative-code-here>",
    format: null,
    selFormat: null,
    selFormatWeborama: "Interscroller",
    weboramaFormats: ["Banner", "Interscroller", "Interstitial", "Portrait", "Vertical"],
    formatMap: {},
    formats: ["Google - IAB HTML5", "Google - Studio", "IAB HTML5 V2.0", "Sizmek - AdKit", "Flashtalking HTML5"],
    dco: false,
    fitIn: false,
    left: null,
    top: null,
    fitInMin: 0.1,
    fitInMax: 10,
    subLoad: false,
    enableAnalytics: false,
    fullScreen: false,
    htmlInjection: "",
    fixedAssetPath: "",
    oneFile: false,
    limits: false,
    selectConf: true,
    dynScript: false,
    bgColor: "#000",
    session: _ember['default'].inject.service('session'),
    info: "",
    dynItems: [],
    user_admin_role: JSON.parse(localStorage.getItem('ember_simple_auth:session')).user_admin_role,
    isResponsive: false,

    hostUrl: (function () {
      var model = this.get('model');
      if (_elbeEmberFrondEndConfigEnvironment['default'].environment === 'production') {
        if (model.campaign.status < 4) {
          return "https://ps.visarity.com/campaigns/" + model.campaign.campaignID + '/';
        } else {
          return "https://ls.visarity.com/campaigns/" + model.campaign.campaignID + '/';
        }
      } else {
        if (model.campaign.status < 4) {
          return "https://dps.visarity.com/campaigns/" + model.campaign.campaignID + '/';
        } else {
          return "https://dls.visarity.com/campaigns/" + model.campaign.campaignID + '/';
        }
      }
      return "https://ps.visarity.com/campaigns/" + model.campaign.campaignID + '/';
    }).property('model', 'model.campaign.campaignID'),

    init: function init() {
      this.get('session').authorize('authorizer:custom', { notDemo: true, subscription: true });

      var fm = {};
      var f = this.get('formats');

      fm[f[0]] = { id: "dcm", options: { fullScreen: true, fitIn: true, subLoad: true }, info: "Works on: DV360, Campaign Manager and Ad Manager", name: "Google" };
      fm[f[1]] = { id: "dcd", options: null, info: "Uses the Google Studio SDK. Please note!! Google might charge extra for this kind of creative.", name: "Studio" };
      fm[f[2]] = { id: "dcm", options: { fullScreen: true, fixedPath: true, htmlInjection: true, fitIn: true, subLoad: true, oneFile: true }, info: "Generic IAB-HTML5 version.", name: "IAB" };
      fm[f[3]] = { id: "adkit", options: { dco: true }, name: "sizmek" };
      fm[f[4]] = { id: "ft", options: { dco: true }, name: "flashtalking" };

      var orgID = this.get('session.data.authenticated.org_ID');

      if (this.user_admin_role || orgID === "ac5c82c3-da83-4218-9e52-3a58d12c809a") {
        f.pushObject("Remerge");
        fm[f[f.length - 1]] = { id: "remerge", options: { fullScreen: true, fitIn: true, fixedPath: true, enableAnalytics: true }, 'default': { fullScreen: true, oneFile: true }, name: "remerge" };
      }

      if (this.user_admin_role || orgID === "687f0d16-eb31-40b3-b83a-d40aeafbdf37") {
        f.pushObject("Weborama");
        fm[f[f.length - 1]] = { id: "weborama", options: { weborama: true }, 'default': {}, name: "weborama" };
      }

      if (this.user_admin_role) {
        f.pushObject("Adnami - Midscroll");
        fm[f[f.length - 1]] = { id: "adnami", options: { fullScreen: true, fitIn: true, oneFile: true, adnamiID: true }, 'default': { oneFile: true }, name: "adnami" };
      }

      var s = localStorage.getItem("zipFormat") || f[0];

      if (!fm[s]) {
        s = f[0];
      }

      this.set('selFormat', s);
      this.set('info', fm[s].info);
      this.set('options', fm[s].options);
      this.set('formatMap', fm);
      this.set('format', fm[s].id);
    },

    getDynItems: function getDynItems(project) {
      var ret = [];
      //var model = this.get('model');
      var hostUrl = this.get('hostUrl');
      var names = {};
      if (project.elements) {
        project.elements.forEach(function (i) {
          names[i.id] = i.name;
        });
      }
      if (project.assets) {
        project.assets.forEach(function (i) {
          names[i.id] = i.name;
        });
      }
      if (project.selectors) {
        project.selectors.forEach(function (i) {
          names[i.id] = i.name;
        });
      }
      var das = wceGlue.getDynamicAssets(project.elements, project.assets, project.selectors, names);

      /*
      var splash = {
          dynId: "ba3814f1.fileUrl",
          type: "texture",
          instanceName: "splash",
          instanceId: "splash",
          fileName: model.campaign.splash_image
      };
      das.push(splash);
      */
      var found = false;

      das.forEach(function (i) {
        if (i.type === "openwebsite") {
          _ember['default'].set(i, 'name', i.clickId.replace(/\W/g, '_'));
          _ember['default'].set(i, 'selected', i.override);
        } else {
          _ember['default'].set(i, 'name', i.instanceName.replace(/\W/g, '_'));
          _ember['default'].set(i, 'selected', false);
        }

        if (i.type === "texture" && i.instanceId !== "affeaffeaffeaffeaffeaffeaffeaffe") {
          if (i.instanceId === "splash") {
            _ember['default'].set(i, 'imageUrl', i.fileName);
          } else {
            _ember['default'].set(i, 'imageUrl', hostUrl + i.fileName);
          }
        } else {
          _ember['default'].set(i, 'imageUrl', null);
        }
        _ember['default'].set(i, 'visible', true);
        _ember['default'].set(i, 'id', i.dynId.split(".")[0]);
        ret.pushObject(i);
      });

      this.set('selectorsAvailable', project.selectors && project.selectors.length > 0);

      return ret;
    },

    dynItemsObserver: (function () {
      var _this = this;

      var project = JSON.parse(JSON.stringify(this.get('model.project')));

      if (project && project.elements) {
        project.elements.forEach(function (e) {
          if (e.type === "background") {
            _this.set("bgColor", e.designColor);
          }
        });
      }

      if (project.settings && project.settings.responsive && project.settings.responsive.enabled) {
        this.set("fitIn", true);
        this.set("fullScreen", true);
        this.set('isResponsive', true);

        if (project.settings.responsive.limits) {
          this.set("limits", true);
          this.set("fitInMin", project.settings.responsive.limitMin);
          this.set("fitInMax", project.settings.responsive.limitMax);
        }
      }
      console.log(project.settings.responsive);
      this.set('dynItems', this.getDynItems(project));
    }).observes('model.project'),

    genZip: function genZip(format, f) {
      var keepSelectors = !this.selectConf;
      var controller = this;
      var name = this.get('model.campaign.name').replace(/\W/g, '_');
      var campaignId = this.get('model.campaign.campaignID');
      var userId = this.get('session.data.authenticated.user_ID'); //this.get('model.campaign.userID');
      var url = _ember['default'].API_URL + '/package/' + campaignId + '/' + userId;
      var dynSelector = this.get('model.dynSelector');
      var dynItems = this.get('dynItems');
      var dyn = {};
      var exportDynIds;
      var dco = this.get('dco') && this.get('options.dco');

      console.log(userId);

      if (dco) {
        exportDynIds = ["ba3814f1.fileUrl"];
        dynItems.forEach(function (i) {
          if (i.visible && i.selected) {
            exportDynIds.push(i.dynId);
          }
        });
      }

      dynItems.forEach(function (i) {
        if (i.type === "selector") {
          if (!i.selected) {
            var v = i.active;
            if (dynSelector) {
              if (dynSelector[i.id]) {
                v = dynSelector[i.id].active;
              }
            }
            v = Math.max(0, Math.min(i.list.length, v));
            f = f + "_" + i.instanceName + '+' + i.list[v].name;

            if (keepSelectors) {
              dyn[i.id] = { keep: true };
            } else {
              dyn[i.id] = { active: v };
            }
          } else {
            dyn[i.id] = { keep: true };
          }
        }
      });

      var options = {
        createDynamicConfig: dco,
        exportDynIds: exportDynIds,
        dyn: dyn
      };

      if (format === "adkit") {
        options.defaultClickId = "click";
      }

      if (format === "adnami") {
        options.adnamiID = this.get('adnamiID');
      }

      if (format === "weborama") {
        var map = {
          "Banner": "banner",
          "Interscroller": "interscroller",
          "Interstitial": "interstitial",
          "Portrait": "portrait",
          "Vertical": "vertical"
        };
        f = f + ('_' + map[this.get('selFormatWeborama')]);
        options.placement = map[this.get('selFormatWeborama')];
      }

      var fixAssetPath = this.get('fixedAssetPath');
      if (fixAssetPath) {
        if (fixAssetPath[fixAssetPath.length - 1] !== "/") {
          fixAssetPath = fixAssetPath + "/";
        }
        options.fixAssetPath = fixAssetPath;
      }

      var fullScreen = this.get('fullScreen');
      if (fullScreen) {
        options.fullScreen = 1;
      }

      var subLoad = this.get('subLoad');
      if (subLoad) {
        options.subLoad = 1;
      }

      var htmlInjection = this.get('htmlInjection');
      if (htmlInjection) {
        options.htmlInjection = htmlInjection;
      }

      var left = this.get('left');
      if (left !== null) {
        options.left = left;
      }

      var top = this.get('top');
      if (top !== null) {
        options.top = top;
      }

      var fitIn = this.get('fitIn');
      if (fitIn) {
        options.fitIn = fitIn;
        if (this.limits) {
          options.fitInMin = this.fitInMin;
          options.fitInMax = this.fitInMax;
        }
      }

      if (this.get('oneFile')) {
        options.oneFile = true;
      }

      if (this.get('enableAnalytics')) {
        options.enableAnalytics = 1;
        if (format === "remerge") {
          options.partner = 1;
          options.publisher = 1;
        }
      }

      options.bgColor = this.bgColor;

      if (format === "remerge") {
        options.primoADSInHtml = 1;
        options.usePlayer = 1;
      }

      if (this.dynScript) {
        options.oneFile = false;
      }

      console.log("options: " + JSON.stringify(options) + "\nformat: " + format);

      if (this.get('dco')) {
        f = f + '_dco';
      }

      f = f.replace(/[^a-zA-Z0-9_+-]+/g, '_');
      //        console.log("f: " + f);

      var loadZipFile = function loadZipFile(r, fn) {
        url = r.zipURL.replace("http://", "https://");
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'arraybuffer';

        xhr.onload = function (e) {
          // response is unsigned 8 bit integer             
          var responseArray = new Uint8Array(this.response);
          download(responseArray, fn, "binary/octet-stream");
          controller.get('notifications').success('Zip ' + fn + ' Downloaded!', {
            autoClear: true,
            clearDuration: 5000
          });

          /*
          Ember.$.ajax({
            url: Ember.API_URL + '/package/regZip',
            type: 'POST',
            data: JSON.stringify(r),
            contentType: 'application/json',
            dataType: 'json',
            success: function (r) {
            },
            error: function (err) {
              console.log(err);
            }
          });
          */
        };

        xhr.onerror = function () {
          controller.notifications.error('Error generating Zip', {
            autoClear: true,
            clearDuration: 10000
          });
        };

        xhr.send();
      };

      _ember['default'].$.ajax({
        url: url,
        type: 'POST',
        data: JSON.stringify({ format: format, options: options }),
        contentType: 'application/json',
        dataType: 'json',
        success: function success(r) {
          loadZipFile(r, name + "_" + f + '.zip');
        },
        error: function error(err) {
          console.log(err);
          controller.notifications.error('Error generating Zip', {
            autoClear: true,
            clearDuration: 10000
          });
        }
      });
    },

    actions: {
      chooseFormat: function chooseFormat(param) {
        this.set('oneFile', false);
        this.set('limits', false);
        this.set('subload', false);
        this.set('enableAnalytics', false);
        this.set('fullScreen', false);

        this.set('selFormat', param);

        localStorage.setItem("zipFormat", param);

        var fm = this.get('formatMap');
        var f = fm[param];
        this.set('info', f.info);
        this.set('options', f.options);
        this.set('format', f.id);
        if (f['default']) {
          for (var a in f['default']) {
            if (typeof this[a] !== "undefined") {
              this.set(a, f['default'][a]);
            }
          }
        }
        if (f.id === "remerge") {
          this.set("fixedAssetPath", "https://assets.remerge.io/playables/");
        } else {
          this.set("fixedAssetPath", "");
        }
      },
      download: function download() {
        var f = this.get('selFormat');
        var fm = this.get('formatMap');
        var format = fm[f].id;
        var name = fm[f].name;
        this.genZip(format, name);
        console.log("download: " + format);
      }
    }
  });
});
/* globals wceGlue:false */