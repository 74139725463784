define('elbe-ember-frond-end/controllers/users', ['exports', 'ember'], function (exports, _ember) {

    function validEmail(v) {
        var r = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");
        return v.match(r) == null ? false : true;
    }

    exports['default'] = _ember['default'].Controller.extend({
        session: _ember['default'].inject.service('session'),
        userRole: ['demo', 'admin', 'designer', 'manager'],
        user_firstName: null,
        user_lastName: null,
        user_email: null,
        user_role: null,
        user_org: null,
        init: function init() {
            this.get('session').authorize('authorizer:custom', { admin: true });
        },
        actions: {
            chooseOrgParams: function chooseOrgParams(param) {
                this.set('orgSelected', param);
            },
            selectUserRole: function selectUserRole() {},
            clearFields: function clearFields() {
                var controller = this;
                controller.set('user_firstName', "");
                controller.set('user_lastName', "");
                controller.set('user_email', '');
                controller.set('user_org', '');
                controller.set('user_role', '');
                controller.set('isSavingUser', false);
                controller.set('orgSelected', '');
                controller.set('orgIDSelected', '');
            },
            saveUser: function saveUser() {
                var validatedEmail,
                    validatedFirstName,
                    validatedLastName,
                    validatedOrg,
                    validatedRole,
                    validDomain = false;
                var uRole = this.get('user_role');
                var email = this.get('user_email');
                var fname = this.get('user_firstName');
                var lname = this.get('user_lastName');
                var companyData = this.get('orgSelected');
                var company = companyData.name;
                var orgID = companyData.orgID;
                var domainL = companyData.billingEmail.lastIndexOf('@');

                //first name
                if (fname !== null) {
                    if (fname.length < 3) {
                        this.notifications.warning('First Name should be atleast 3 characters', {
                            autoClear: true,
                            clearDuration: 3200
                        });
                    } else {
                        validatedFirstName = true;
                    }
                } else {
                    this.notifications.warning('First Name should be atleast 3 characters', {
                        autoClear: true,
                        clearDuration: 3200
                    });
                }

                //last name
                if (lname !== null) {
                    if (lname.length < 3) {
                        this.notifications.warning('Last Name should be atleast 3 characters', {
                            autoClear: true,
                            clearDuration: 3200
                        });
                    } else {
                        validatedLastName = true;
                    }
                } else {
                    this.notifications.warning('Last Name should be atleast 3 characters', {
                        autoClear: true,
                        clearDuration: 3200
                    });
                }

                //email
                if (email !== null) {
                    if (!validEmail(email)) {
                        this.notifications.warning('email is not in a valid format', {
                            autoClear: true,
                            clearDuration: 3200
                        });
                    } else {
                        validatedEmail = true;
                    }
                } else {
                    this.notifications.warning('email is not in a valid format', {
                        autoClear: true,
                        clearDuration: 3200
                    });
                }

                //company
                if (company !== null) {
                    if (company.length < 3) {
                        this.notifications.warning('Enter the name of the company, for default enter betaUsers', {
                            autoClear: true,
                            clearDuration: 3200
                        });
                    } else {
                        validatedOrg = true;
                    }
                } else {
                    this.notifications.warning('Enter the name of the company, for default enter betaUsers', {
                        autoClear: true,
                        clearDuration: 3200
                    });
                }

                //user role
                if (this.get('user_role') === null) {
                    this.notifications.warning('select a user role', {
                        autoClear: true,
                        clearDuration: 3200
                    });
                } else {
                    validatedRole = true;
                }

                validDomain = true;
                /*
                if (validatedEmail && validatedOrg) {
                  var userEmailDomain = email.lastIndexOf('@');
                  if (companyData.billingEmail.slice(domainL+1) == email.slice(userEmailDomain+1)) {
                    validDomain = true;
                  }else{
                    this.notifications.warning('email domain and company domain do not match', {
                        autoClear: true,
                        clearDuration: 3200
                    });
                  }
                }
                */

                if (validatedRole && validatedEmail && validatedFirstName && validatedLastName && validatedOrg && validDomain) {
                    //console.log('all valid');
                    var controller = this;
                    var userParams = {
                        first_name: fname,
                        last_name: lname,
                        email: email,
                        role: uRole,
                        company: company,
                        orgID: orgID
                    };
                    this.set('isSavingUser', true);

                    _ember['default'].$.ajax({
                        url: _ember['default'].API_URL + '/createUser/' + controller.get('session.data.authenticated.user_ID'),
                        type: 'POST',
                        data: userParams,
                        success: function success(response) {
                            //console.log(response);
                            controller.set('isSavingUser', false);
                            controller.notifications.success('User created and activation mail sent', {
                                autoClear: true,
                                clearDuration: 3400
                            });
                            controller.send('clearFields');
                        },
                        error: function error(_error) {
                            controller.set('isSavingUser', false);
                            controller.notifications.error('Server error, retry after sometime', {
                                autoClear: true,
                                clearDuration: 2200
                            });
                        }
                    });
                }
            }
        }
    });
});