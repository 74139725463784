define('elbe-ember-frond-end/routes/zip-gen', ['exports', 'underscore', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _underscore, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'Generate Campaign Zips',
    actions: {},
    model: function model(params) {
      return new _ember['default'].RSVP.hash({
        campaign: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/find/' + params.id,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            //console.log(response);
          },
          error: function error(_error) {
            //console.log(error);
          }
        }), //ajax
        project: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/projects/' + params.id,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            //console.log(response);
          },
          error: function error(_error2) {
            //console.log(error);
          }
        }), //ajax
        sysVersion: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/version',
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            //console.log(response);
          },
          error: function error(_error3) {
            //console.log(error);
          }
        }), //ajax
        orgData: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/org/info/' + JSON.parse(localStorage.getItem('ember_simple_auth:session')).authenticated.user_ID,
          type: 'GET',
          contentType: 'application/json;charset=utf-8',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error4) {}
        }), //ajax
        info: new Promise(function (resolve, reject) {
          var _info = {};

          var pubStatus = _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/pubstatus/' + params.id,
            type: 'GET',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json',
            success: function success(response) {},
            error: function error(_error5) {}
          }); //pubStatus ajax     

          var genPreview = function genPreview() {
            _ember['default'].$.ajax({
              url: _ember['default'].API_URL + '/publish/' + params.id + '/preview',
              type: 'GET',
              contentType: 'application/json',
              dataType: 'json',
              success: function success(response) {
                resolve(_info);
              },
              error: function error(_error6) {}
            }); //genPreview ajax
          };

          pubStatus.then(function (value) {
            //resolve(_info);
            if (value.status.toLowerCase() === 'generatepreview') {
              genPreview();
            } else {
              resolve(_info);
            }
          }, function (reason) {
            reject(reason);
          });
        }) //info
      }); //rsvp
    },
    setupController: function setupController(controller, model) {
      this.set('model.update', Date.now().toPrecision());
      wceGlue.fixer(model.project);
      wceGlue.cutter(model.project);
      controller.set('selectConf', !model.campaign.dynScript);
      controller.set('dynScript', !!model.campaign.dynScript);
      controller.set('model', model);
    }
  });
});
/* globals wceGlue : false */