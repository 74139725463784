define('elbe-ember-frond-end/components/new-campaign-component', ['exports', 'ember', 'elbe-ember-frond-end/config/environment'], function (exports, _ember, _elbeEmberFrondEndConfigEnvironment) {

  function _zipObject(projectData) {
    function stringToArray(bufferString) {
      var uint8Array = new TextEncoder("utf-8").encode(bufferString);
      return uint8Array;
    }

    return new Promise(function (resolve, reject) {
      var file = new Blob([wceGlue.pako.gzip(stringToArray(JSON.stringify(projectData)))]);
      var fr = new FileReader();
      fr.onloadend = function () {
        resolve(fr.result);
      };
      fr.onerror = function () {
        reject();
      };
      fr.readAsDataURL(file);
    });
  }

  function _getDynItems(project, hostUrl) {
    var ret = [];
    //var model = this.get('model');
    hostUrl = hostUrl || "";
    var names = {};
    if (project.elements) {
      project.elements.forEach(function (i) {
        names[i.id] = i.name;
      });
    }
    if (project.assets) {
      project.assets.forEach(function (i) {
        names[i.id] = i.name;
      });
    }
    if (project.selectors) {
      project.selectors.forEach(function (i) {
        names[i.id] = i.name;
      });
    }
    var das = wceGlue.getDynamicAssets(project.elements, project.assets, project.selectors, names);

    /*
    var splash = {
        dynId: "ba3814f1.fileUrl",
        type: "texture",
        instanceName: "splash",
        instanceId: "splash",
        fileName: model.campaign.splash_image
    };
    das.push(splash);
    */
    var defaultClickUrl = "";

    das.forEach(function (i) {
      if (i.type === "openwebsite") {
        _ember['default'].set(i, 'name', i.clickId.replace(/\W/g, '_'));
        _ember['default'].set(i, 'selected', i.override);
      } else {
        _ember['default'].set(i, 'name', i.instanceName.replace(/\W/g, '_'));
        _ember['default'].set(i, 'selected', false);
      }

      if (i.type === "texture" && i.instanceId !== "affeaffeaffeaffeaffeaffeaffeaffe") {
        if (i.instanceId === "splash") {
          _ember['default'].set(i, 'imageUrl', i.fileName);
        } else {
          _ember['default'].set(i, 'imageUrl', hostUrl + i.fileName);
        }
      } else {
        _ember['default'].set(i, 'imageUrl', null);
      }
      _ember['default'].set(i, 'visible', true);
      _ember['default'].set(i, 'id', i.dynId.split(".")[0]);
      ret.pushObject(i);
    });
    return ret;
  }

  function _getTemplateData(templateID) {
    return new Promise(function (resolve, reject) {
      _ember['default'].$.ajax({
        url: _ember['default'].API_URL + '/templates/' + templateID + "?withData=1",
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        success: function success(response) {
          var projectData = response.templateData;
          resolve(projectData);
        },
        error: function error(_error) {
          reject();
        }
      }); //ajax
    });
  }

  function _getCampaign(campaignID) {
    return new Promise(function (resolve, reject) {
      _ember['default'].$.ajax({
        url: _ember['default'].API_URL + '/campaigns/find/' + campaignID,
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        success: function success(response) {
          resolve(response);
        },
        error: function error(_error2) {
          reject();
        }
      }); //ajax
    });
  }

  exports['default'] = _ember['default'].Component.extend({

    templateModel: null,
    campaignName: 'New campaign',
    campaignWidth: 320,
    campaignHeight: 480,
    sizeTypeSelected: '320x480',
    fromSelected: 'smart',
    selectedTemplate: '',
    selectedWizard: 'IMAGEFX',
    isBusy: true,
    templates: [],
    wizards: [],
    apiWait: false,

    sizeTypeList: [{ name: "Custom width / height", id: "custom" }, { name: "120x600 Skyscraper", id: "120x600" }, { name: "160x600 Wide Skyscraper", id: "160x600" }, { name: "300x150 Mobile-Banner 2:1", id: "300x150" }, { name: "300x250 Medium-Rectangle", id: "300x250" }, { name: "300x600 Half-Page", id: "300x600" }, { name: "320x50 Mobile-Leaderboard", id: "320x50" }, { name: "320x320 Medium-Square", id: "320x320" }, { name: "320x480 Mobile Interstitial", id: "320x480" }, { name: "336x280 Large-Rectangle", id: "336x280" }, { name: "728x90 Leaderboard", id: "728x90" }, { name: "970x250 Billboard", id: "970x250" }],

    /*
      init: function () {    
        this._super(...arguments);
      },
    */
    didInsertElement: function didInsertElement() {
      this.updateTemplates();
      this.observerCheck();
    },

    _campaignWidth: _ember['default'].computed({
      get: function get() /*key*/{
        return this.get('campaignWidth');
      },
      set: function set(key, value) {
        // jshint ignore:line
        if (isNaN(value) || (value | 0) > 2048) {
          this.set('campaignWidth', 0);
        } else {
          this.set('campaignWidth', value | 0);
        }
        return value;
      }
    }),

    _campaignHeight: _ember['default'].computed({
      get: function get() /*key*/{
        return this.get('campaignHeight');
      },
      set: function set(key, value) {
        // jshint ignore:line
        if (isNaN(value) || (value | 0) > 2048) {
          this.set('campaignHeight', 0);
        } else {
          this.set('campaignHeight', value | 0);
        }
        return value;
      }
    }),

    observerCheck: (function () {
      var isBusy = true;
      isBusy = isBusy && (this.get('campaignName') || "").length > 0;

      var st = this.get('sizeTypeSelected');

      if (st === 'custom') {
        var w = this.get('campaignWidth');
        var h = this.get('campaignHeight');

        w = isNaN(w) ? 0 : parseInt(w, 10);
        h = isNaN(h) ? 0 : parseInt(h, 10);

        isBusy = isBusy && w && h;
      } else {
        isBusy = isBusy && !!st;
      }

      var fromSelected = this.get('fromSelected');
      if (fromSelected === "template") {
        if (this.templates.length === 1) {
          this.set('selectedTemplate', this.templates[0].templateID);
        }
        isBusy = isBusy && this.get('selectedTemplate') !== "" && !!this.get('selectedTemplate');
        isBusy = isBusy && !this.noTemplates;
        //this.set('selectedWizard', null);
      } else if (fromSelected === "smart") {
          if (!this.selectedWizard) {
            this.set('selectedWizard', 'IMAGEFX');
          }
          isBusy = isBusy && this.get('selectedWizard') !== "" && !!this.get('selectedWizard');
          this.set('selectedTemplate', null);
          this.set('templateModel', null);
          isBusy = isBusy && !this.noWizards;
        } else {
          this.set('templateModel', null);
          this.set('selectedTemplate', null);
          // this.set('selectedWizard', null);
        }

      this.set('isBusy', !isBusy);
      console.log("isBusy: " + !isBusy);
    }).observes('sizeTypeSelected', 'campaignWidth', 'campaignHeight', 'campaignName', 'fromSelected', 'selectedTemplate', 'selectedWizard'),

    observerSelectedSize: (function () {
      var t = this.get('sizeTypeSelected');
      if (t !== "custom") {
        var s = t.split('x');
        this.set('campaignWidth', s[0] | 0);
        this.set('campaignHeight', s[1] | 0);
      }
      this.set('templateModel', null);
    }).observes('sizeTypeSelected'),

    updateTemplates: (function () {
      var format = this.get('sizeTypeSelected');
      var ta = this.get('model.template_data');

      if (format === "custom") {
        format = this.get('campaignWidth') + "x" + this.get('campaignHeight');
      }

      var templates = ta.filter(function (e) {
        return e.format === format && e.campaignID;
      });

      this.set('templates', templates);
      this.set('noTemplates', templates.length === 0);
      this.set('selectedTemplate', "");

      var tw = this.get('model.wizard_data');
      var wizards = tw.filter(function (e) {
        return e.allFormats || !!e.formats.filter(function (f) {
          return f === format || f === "custom";
        })[0];
      });
      this.set('noWizards', wizards.length === 0);

      if (wizards.length === 0) {
        this.set('selectedWizard', "");
      }

      //wizards.forEach( w => { Ember.set(w, 'image', w.thumbnails[format] || w.thumbnail); });
      wizards.forEach(function (w) {
        _ember['default'].set(w, 'image', w.thumbnail);
      });

      this.set('wizards', wizards);
    }).observes('sizeTypeSelected', 'campaignWidth', 'campaignHeight'),

    handleTemplate: (function () {
      var _this = this;
      var s = this.get('selectedTemplate');
      var e = this.get('templates').filter(function (t) {
        return t.templateID === s;
      })[0];

      if (e) {
        if (e.templateModel) {
          this.set("templateModel", e.templateModel);
        } else {
          var model = {};
          _this.set('apiWait', true);

          _getTemplateData(e.templateID).then(function (p) {
            wceGlue.fixer(p);
            model.copyProject = JSON.parse(JSON.stringify(p));
            model.project = p;
            model.dynItems = _getDynItems(p);
            _getCampaign(e.campaignID).then(function (c) {
              _this.set('apiWait', false);
              model.campaign = c;

              e.templateModel = model;
              _this.set('templateModel', model);
            })['catch'](function () {
              _this.set('apiWait', false);
              _this.notifications.warning('Server Error', { autoClear: true, clearDuration: 2600 });
            });
          })['catch'](function () {
            _this.set('apiWait', false);
            _this.notifications.warning('Server Error', { autoClear: true, clearDuration: 2600 });
          });
        }
      }
    }).observes('selectedTemplate'),

    createCampaign: function createCampaign(campaignParams) {
      var _this = this;
      _ember['default'].$.ajax({
        url: _ember['default'].API_URL + '/campaigns',
        type: 'POST',
        data: JSON.stringify(campaignParams),
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        success: function success(response) {
          _this.set('apiWait', false);
          _this.notifications.success('Ok', { autoClear: true, clearDuration: 2600 });

          if (_ember['default'].Environment === "development") {
            if (campaignParams.derivedWizardID) {
              window.open(_ember['default'].WIZARD_URL + campaignParams.derivedWizardID + "?campaignID=" + campaignParams.campaignID + "&localStorage=" + window.btoa(localStorage.getItem('ember_simple_auth:session')));
            } else {
              window.open(_ember['default'].EDITOR_URL + campaignParams.campaignID + '/normal/' + Date.now() + '/' + window.btoa(localStorage.getItem('ember_simple_auth:session')));
            }
          } else {
            if (campaignParams.derivedWizardID) {
              window.open(_ember['default'].WIZARD_URL + campaignParams.derivedWizardID + "?campaignID=" + campaignParams.campaignID);
            } else {
              window.open(_ember['default'].EDITOR_URL + campaignParams.campaignID + '/normal/' + Date.now());
            }
            //window.location.href = "/dashboard";
          }
          _ember['default'].getOwner(_this).lookup('router:main').transitionTo('dashboard');
        },
        error: function error(_error3) {
          _this.set('apiWait', false);
          console.log(_error3);
          _this.notifications.warning('Server Error', { autoClear: true, clearDuration: 2600 });
        }
      }); //ajax    
    },

    createFromScratch: function createFromScratch() {
      var format = this.campaignWidth + "x" + this.campaignHeight;
      var ta = this.get('model.template_data');
      var template = ta.filter(function (e) {
        return e.format === format && !e.campaignID;
      })[0];
      var base = ta.filter(function (e) {
        return e.templateID === "default";
      })[0];
      var _this = this;

      function createCampaign(projectData) {
        var campaignParams = {
          campaignID: _this.campaignID,
          name: _this.campaignName,
          status: 3,
          userID: _this.get('session.data.authenticated.user_ID'),
          format: format,
          splash_image: template ? template.splash : undefined,
          thumbnail: _elbeEmberFrondEndConfigEnvironment['default'].THUMBNAILURL,
          projectData: projectData
        };
        _this.createCampaign(campaignParams);
      }

      var templateID = template ? template.templateID : base.templateID;

      _getTemplateData(templateID).then(function (projectData) {
        projectData.resolutionValueX = _this.campaignWidth;
        projectData.resolutionValueY = _this.campaignHeight;
        _zipObject(projectData).then(function (r) {
          return createCampaign(r);
        })['catch'](function () {
          _this.set('apiWait', false);
          _this.notifications.warning('Server Error', { autoClear: true, clearDuration: 2600 });
        });
      })['catch'](function () {
        _this.set('apiWait', false);
        _this.notifications.warning('Server Error', { autoClear: true, clearDuration: 2600 });
      });
      this.set('apiWait', true);
    },

    createFromTemplate: function createFromTemplate() {

      var templateID = this.get('selectedTemplate');
      var ta = this.get('model.template_data');
      var template = ta.filter(function (e) {
        return e.templateID === templateID;
      })[0];
      var format = template.format;
      var _this = this;

      function createCampaign(projectData) {
        var campaignParams = {
          campaignID: _this.campaignID,
          name: _this.campaignName,
          status: 3,
          userID: _this.get('session.data.authenticated.user_ID'),
          format: format,
          splash_image: template ? template.splash : undefined,
          thumbnail: _elbeEmberFrondEndConfigEnvironment['default'].THUMBNAILURL,
          projectData: projectData
        };
        _this.createCampaign(campaignParams);
      }

      if (template.templateModel) {
        (function () {
          var p = template.templateModel.copyProject;
          var d = template.templateModel.dynSelector;
          if (p.selectors && d) {
            p.selectors.forEach(function (s) {
              var t = d[wceGlue.crc32(s.id)];
              if (t) {
                s.active = t.active;
              }
            });
          }
        })();
      }

      this.set('apiWait', true);
      this.set('templateModel', null);

      var projectData = template.templateModel.copyProject;
      wceGlue.selectorOptimiser(projectData);
      wceGlue.cutterRemoveUnusedItems(projectData);
      wceGlue.cutterRemoveDefaultAssets(projectData);

      _zipObject(projectData).then(function (r) {
        return createCampaign(r);
      })['catch'](function () {
        _this.set('apiWait', false);
        _this.notifications.warning('Server Error', { autoClear: true, clearDuration: 2600 });
      });
    },

    createFromSmart: function createFromSmart() {
      var format = this.campaignWidth + "x" + this.campaignHeight;
      var wizardID = this.get('selectedWizard');
      var tw = this.get('model.wizard_data');
      var wizard = tw.filter(function (e) {
        return e.wizardID === wizardID;
      })[0];
      var _this = this;

      function createCampaign() {
        var campaignParams = {
          campaignID: _this.campaignID,
          name: _this.campaignName,
          status: 3,
          userID: _this.get('session.data.authenticated.user_ID'),
          format: format,
          splash_image: wizard.splash,
          thumbnail: wizard.thumbnail,
          derivedWizardID: wizardID
        };
        _this.createCampaign(campaignParams);
      }

      if (wizard) {
        createCampaign();
      }
    },

    actions: {
      openCloneGallery: function openCloneGallery() {
        window.open('/from-gallery?size=' + this.sizeTypeSelected, "_self");
      },
      create: function create() {
        switch (this.get('fromSelected')) {
          case "scratch":
            this.createFromScratch();
            break;
          case "template":
            this.createFromTemplate();
            break;
          case "smart":
            this.createFromSmart();
            break;
        }
      }
    }

  });
});