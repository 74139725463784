define('elbe-ember-frond-end/routes/list-galleries', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'underscore'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _underscore) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'List Galleries',
    actions: {
      error: function error(_error, transition) {
        if (_error && _error.status === 400 || 401) {
          return this.transitionTo('serverError');
        }
      }
    },
    model: function model(params) {
      var orgID = this.get('session.data.authenticated.org_ID');
      return _ember['default'].$.ajax({
        url: _ember['default'].API_URL + '/galleries/' + orgID,
        type: 'GET',
        dataType: 'json',
        success: function success(response) {},
        error: function error(_error2) {}
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('gallery_list_data', model);
      controller.set('gallery_list_data_orig', model);
    }
  });
});