define('elbe-ember-frond-end/helpers/in-arr', ['exports', 'ember-inline-edit/helpers/in-arr'], function (exports, _emberInlineEditHelpersInArr) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberInlineEditHelpersInArr['default'];
    }
  });
  Object.defineProperty(exports, 'inArr', {
    enumerable: true,
    get: function get() {
      return _emberInlineEditHelpersInArr.inArr;
    }
  });
});