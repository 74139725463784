define("elbe-ember-frond-end/helpers/lut", ["exports", "ember"], function (exports, _ember) {
  exports.lut = lut;

  function lut(params) {
    var ret = params[3] || "";
    var id = params[0];
    var lut = params[1];
    var attr = params[2] || "name";

    var e = lut[id];
    if (id && e) {
      ret = e[attr];
    }

    return ret;
  }

  exports["default"] = _ember["default"].Helper.helper(lut);
});