define("elbe-ember-frond-end/utils/formatUtils", ["exports", "ember"], function (exports, _ember) {

    // internal URI spitter method - direct from RFC 3986
    var splitUri = function splitUri(uri) {
        var splitted = uri.match(/(?:([^:\/?#]+):)?(?:\/\/([^\/?#]*))?([^?#]*)(?:\?([^#]*))?(?:#(.*))?/);
        return splitted;
    };

    exports["default"] = {
        nFormatter: function nFormatter(num, digits) {
            if (Number.isNaN(num)) {
                return "n/a";
            } else if (!num) {
                return 0;
            }

            if (digits === undefined) {
                digits = 2;
            }

            var si = [{
                value: 1E18,
                symbol: "E"
            }, {
                value: 1E15,
                symbol: "P"
            }, {
                value: 1E12,
                symbol: "T"
            }, {
                value: 1E9,
                symbol: "G"
            }, {
                value: 1E6,
                symbol: "M"
            }, {
                value: 1E3,
                symbol: "K"
            }],
                i;
            for (i = 0; i < si.length; i++) {
                if (num >= si[i].value) {
                    return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].symbol;
                }
            }
            return num.toString();
        },

        dFormatter: function dFormatter(num, digits) {
            if (Number.isNaN(num)) {
                return "n/a";
            } else if (!num) {
                return "0s";
            }

            var si = [{
                value: 60 * 60 * 24 * 7,
                symbol: "w"
            }, {
                value: 60 * 60 * 24,
                symbol: "d"
            }, {
                value: 60 * 60,
                symbol: "h"
            }, {
                value: 60,
                symbol: "m"
            }],
                i;
            for (i = 0; i < si.length; i++) {
                if (num >= si[i].value) {
                    return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].symbol;
                }
            }
            return num.toFixed(digits) + 's';
        },

        //// Sort by price high to low
        //homes.sort(sort_by('price', true, parseInt));

        // Sort by city, case-insensitive, A-Z
        //homes.sort(sort_by('city', false, function(a){return a.toUpperCase()}));
        sortBy: function sortBy(field, reverse, primer) {

            var key = primer ? function (x) {
                return primer(x[field]);
            } : function (x) {
                return x[field];
            };

            reverse = !reverse ? 1 : -1;

            return function (a, b) {
                return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
            };
        },

        verifyEmail: function verifyEmail(emailAddress) {
            var sQtext = '[^\\x0d\\x22\\x5c\\x80-\\xff]';
            var sDtext = '[^\\x0d\\x5b-\\x5d\\x80-\\xff]';
            var sAtom = '[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+';
            var sQuotedPair = '\\x5c[\\x00-\\x7f]';
            var sDomainLiteral = '\\x5b(' + sDtext + '|' + sQuotedPair + ')*\\x5d';
            var sQuotedString = '\\x22(' + sQtext + '|' + sQuotedPair + ')*\\x22';
            var sDomain_ref = sAtom;
            var sSubDomain = '(' + sDomain_ref + '|' + sDomainLiteral + ')';
            var sWord = '(' + sAtom + '|' + sQuotedString + ')';
            var sDomain = sSubDomain + '(\\x2e' + sSubDomain + ')*';
            var sLocalPart = sWord + '(\\x2e' + sWord + ')*';
            var sAddrSpec = sLocalPart + '\\x40' + sDomain; // complete RFC822 email address spec
            var sValidEmail = '^' + sAddrSpec + '$'; // as whole string

            var reValidEmail = new RegExp(sValidEmail);

            return reValidEmail.test(emailAddress);
        },
        generateShortID: function generateShortID(length) {
            var charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var randomString = '';
            for (var i = 0; i < length; i++) {
                var randomPoz = Math.floor(Math.random() * charSet.length);
                randomString += charSet.substring(randomPoz, randomPoz + 1);
            }
            return randomString;
        },
        fileNameFromUrl: function fileNameFromUrl(url) {
            var matches = url.match(/\/([^\/?#]+)[^\/]*$/);
            if (matches.length > 1) {
                return matches[1];
            }
            return null;
        },
        detectBrowser: function detectBrowser() {
            var isChrome = false;
            var chrome = navigator.userAgent.match(/chrome/i);
            if (chrome != null) {
                isChrome = true;
            }
            return true; //isChrome;
        },
        is_iri: function is_iri(value) {
            if (!value) {
                return false;
            }

            // check for illegal characters
            if (/[^a-z0-9\:\/\?\#\[\]\@\!\$\&\'\(\)\*\+\,\;\=\.\-\_\~\%]/i.test(value)) return;

            // check for hex escapes that aren't complete
            if (/%[^0-9a-f]/i.test(value)) return;
            if (/%[0-9a-f](:?[^0-9a-f]|$)/i.test(value)) return;

            var splitted = [];
            var scheme = '';
            var authority = '';
            var path = '';
            var query = '';
            var fragment = '';
            var out = '';

            // from RFC 3986
            splitted = splitUri(value);
            scheme = splitted[1];
            authority = splitted[2];
            path = splitted[3];
            query = splitted[4];
            fragment = splitted[5];

            // scheme and path are required, though the path can be empty
            if (!(scheme && scheme.length && path.length >= 0)) return;

            // if authority is present, the path must be empty or begin with a /
            if (authority && authority.length) {
                if (!(path.length === 0 || /^\//.test(path))) return;
            } else {
                // if authority is not present, the path must not start with //
                if (/^\/\//.test(path)) return;
            }

            // scheme must begin with a letter, then consist of letters, digits, +, ., or -
            if (!/^[a-z][a-z0-9\+\-\.]*$/.test(scheme.toLowerCase())) return;

            // re-assemble the URL per section 5.3 in RFC 3986
            out += scheme + ':';
            if (authority && authority.length) {
                out += '//' + authority;
            }

            out += path;

            if (query && query.length) {
                out += '?' + query;
            }

            if (fragment && fragment.length) {
                out += '#' + fragment;
            }

            return out;
        },
        is_http_iri: function is_http_iri(value, allowHttps) {
            if (!this.is_iri(value)) {
                return;
            }
            var splitted = [];
            var scheme = '';
            var authority = '';
            var path = '';
            var port = '';
            var query = '';
            var fragment = '';
            var out = '';

            // from RFC 3986
            splitted = splitUri(value);
            scheme = splitted[1];
            authority = splitted[2];
            path = splitted[3];
            query = splitted[4];
            fragment = splitted[5];

            if (!scheme) return;

            if (allowHttps) {
                if (scheme.toLowerCase() != 'https') return;
            } else {
                if (scheme.toLowerCase() != 'http') return;
            }

            // fully-qualified URIs must have an authority section that is
            // a valid host
            if (!authority) {
                return;
            }

            // enable port component
            if (/:(\d+)$/.test(authority)) {
                port = authority.match(/:(\d+)$/)[0];
                authority = authority.replace(/:\d+$/, '');
            }

            out += scheme + ':';
            out += '//' + authority;

            if (port) {
                out += port;
            }

            out += path;

            if (query && query.length) {
                out += '?' + query;
            }

            if (fragment && fragment.length) {
                out += '#' + fragment;
            }

            return out;
        },
        formatBytes: function formatBytes(bytes, decimals) {
            if (bytes === 0) {
                return '0 Byte';
            }
            var k = 1000; // or 1024 for binary
            var dm = decimals + 1 || 3;
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            var i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
    };
});