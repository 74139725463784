define('elbe-ember-frond-end/authenticators/custom', ['exports', 'ember', 'ember-simple-auth/authenticators/base', 'elbe-ember-frond-end/config/environment'], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase, _elbeEmberFrondEndConfigEnvironment) {
    exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
        session: _ember['default'].inject.service('session'),
        tokenEndpoint: _ember['default'].API_URL + '/login',
        socialEndpoint: _ember['default'].API_URL + '/loginSocial',

        restore: function restore(data) {
            // console.log("restore: " + JSON.stringify(data));
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                if (!_ember['default'].isEmpty(data.token)) {
                    resolve(data);
                } else {
                    reject();
                }
            });
        },

        authenticate: function authenticate(options) {
            var _this2 = this;

            // console.log("authenticate: " + JSON.stringify(options));

            if (options.token) {
                return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                    _ember['default'].$.ajax({
                        url: _this2.socialEndpoint + '/' + options.token,
                        type: 'GET',
                        contentType: 'application/json;charset=utf-8',
                        dataType: 'json'
                    }).then(function (response) {
                        if (response.org_ID) {
                            _ember['default'].run(function () {
                                resolve({
                                    token: response.token,
                                    username: response.userName,
                                    user_ID: response.user_ID,
                                    org_ID: response.org_ID,
                                    user_role: response.userRole,
                                    org_name: response.org_name,
                                    billingType: response.billingType,
                                    exp: moment().add(1, 'days'),
                                    login_at: moment()
                                });
                            });
                        } else {
                            _ember['default'].run(function () {
                                reject(response.message);
                            });
                        }
                    }, function (xhr) {
                        //console.log(error);
                        _ember['default'].run(function () {
                            reject(xhr.responseJSON || xhr.responseText);
                        });
                    });
                });
            } else {
                return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                    _ember['default'].$.ajax({
                        url: _this2.tokenEndpoint,
                        type: 'POST',
                        data: JSON.stringify({
                            username: options.username,
                            password: options.password
                        }),
                        contentType: 'application/json;charset=utf-8',
                        dataType: 'json'
                    }).then(function (response) {
                        if (response.org_ID) {
                            _ember['default'].run(function () {
                                resolve({
                                    token: response.token,
                                    username: response.userName,
                                    user_ID: response.user_ID,
                                    org_ID: response.org_ID,
                                    user_role: response.userRole,
                                    org_name: response.org_name,
                                    billingType: response.billingType,
                                    exp: moment().add(1, 'days'),
                                    login_at: moment()
                                });
                            });
                        } else {
                            _ember['default'].run(function () {
                                reject(response.message);
                            });
                        }
                    }, function (xhr) {
                        //console.log(error);
                        _ember['default'].run(function () {
                            reject(xhr.responseJSON || xhr.responseText);
                        });
                    });
                });
            }
        },

        invalidate: function invalidate() {
            //console.log('invalidate...');
            // console.log("invalidate: ");
            var _this = this;
            this.get('session').set('data.user_admin_role', undefined);
            //this.get('session').set('data.currentPage', '');
            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                _ember['default'].$.ajax({
                    type: "POST",
                    data: JSON.stringify({
                        token: JSON.parse(localStorage.getItem('ember_simple_auth:session')).authenticated.token
                    }),
                    contentType: 'application/json',
                    dataType: 'json',
                    url: _ember['default'].API_URL + '/logout'
                }).then(function (response) {
                    _ember['default'].run(function () {
                        window.location.replace(_elbeEmberFrondEndConfigEnvironment['default']['ember-simple-auth'].authenticationRoute);
                        resolve(response);
                        _this.get('session').set('data.user_admin_role', undefined);
                    });
                }, function (xhr) {
                    _ember['default'].run(function () {
                        reject(xhr.responseJSON || xhr.responseText);
                    });
                });
            });
        }
    });
});
// app/authenticators/custom.js
//import Devise from 'ember-simple-auth/authenticators/devise';