define("elbe-ember-frond-end/components/org-info", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    headersCPM: ["Name"],
    rowDataCPM: [],
    headersCO2: ["Name"],
    rowDataCO2: [],
    pricingCO2: {
      currency: "EUR",
      prices: {
        "tiers": [{ "imp": 3000000 }, { "imp": 10000000 }, { "imp": 25000000 }],
        "categories": [{
          "name": "CO2e Attribution", "maxWeight": 768000, "flags": "", "cpm": 0.08, "tiers": [{ "cpm": 0.07 }, { "cpm": 0.06 }, { "cpm": 0.05 }]
        }]
      }
    },

    init: function init() {
      this._super();
      var i = undefined,
          j = undefined;
      var c = this.get('pricingCPM.currency');
      var p = this.get('pricingCPM.prices');
      var h = this.headersCPM;
      var r = this.rowDataCPM;

      var toMio = function toMio(n) {
        return (n / 1000000 | 0).toFixed(1);
      };

      if (p) {
        if (p.tiers) {
          h.push("< " + toMio(p.tiers[0].imp) + " Mio");

          p.tiers.forEach(function (e) {
            h.push("> " + toMio(e.imp) + " Mio");
          });

          p.categories.forEach(function (pp) {
            var row = [];
            row.push(pp.name);
            row.push(pp.cpm.toFixed(2) + " " + c);
            pp.tiers.forEach(function (t) {
              row.push(t.cpm.toFixed(2) + " " + c);
            });
            r.push(row);
          });
        } else {
          h.push("CPM");
          p.categories.forEach(function (pp) {
            var row = [];
            row.push(pp.name);
            row.push(pp.cpm.toFixed(2) + " " + c);
            r.push(row);
          });
        }
      }
    },

    actions: {
      enableCO2Tracking: function enableCO2Tracking() {}
    }
  });
});