define("elbe-ember-frond-end/templates/components/manage-ui", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 28,
              "column": 28
            },
            "end": {
              "line": 30,
              "column": 28
            }
          },
          "moduleName": "elbe-ember-frond-end/templates/components/manage-ui.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("th");
          dom.setAttribute(el1, "class", "vertical_align_coln");
          var el2 = dom.createTextNode("Show");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 34
              },
              "end": {
                "line": 41,
                "column": 34
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/manage-ui.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("  \n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "input", [], ["type", "text", "class", "form-control small", "placeholder", "Minimum 1 character.", "value", ["subexpr", "@mut", [["get", "brand.name", ["loc", [null, [40, 124], [40, 134]]]]], [], []], "change", ["subexpr", "action", ["saveName", ["get", "brand", ["loc", [null, [40, 161], [40, 166]]]]], [], ["loc", [null, [40, 142], [40, 167]]]], "id", ["subexpr", "getmember", [["get", "brand", ["loc", [null, [40, 182], [40, 187]]]], ["get", "pAttr", ["loc", [null, [40, 188], [40, 193]]]]], [], ["loc", [null, [40, 171], [40, 194]]]]], ["loc", [null, [40, 36], [40, 196]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 34
              },
              "end": {
                "line": 43,
                "column": 34
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/manage-ui.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("  \n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "input", [], ["readonly", true, "type", "text", "class", "form-control small", "placeholder", "Minimum 1 character.", "value", ["subexpr", "@mut", [["get", "brand.name", ["loc", [null, [42, 138], [42, 148]]]]], [], []], "change", ["subexpr", "action", ["saveName", ["get", "brand", ["loc", [null, [42, 175], [42, 180]]]]], [], ["loc", [null, [42, 156], [42, 181]]]], "id", ["subexpr", "@mut", [["get", "brand.brandID", ["loc", [null, [42, 185], [42, 198]]]]], [], []]], ["loc", [null, [42, 36], [42, 200]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 38
                },
                "end": {
                  "line": 59,
                  "column": 38
                }
              },
              "moduleName": "elbe-ember-frond-end/templates/components/manage-ui.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                      Copy Gallery URL to clipboard.\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 32
              },
              "end": {
                "line": 63,
                "column": 32
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/manage-ui.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1, "class", "span2 vertical_align_coln");
            var el2 = dom.createTextNode("\n                                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "style", "display:flex;");
            var el3 = dom.createTextNode("\n                                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n                                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("button");
            dom.setAttribute(el4, "data-placement", "top");
            dom.setAttribute(el4, "data-toggle", "tooltip");
            dom.setAttribute(el4, "type", "button");
            dom.setAttribute(el4, "data-original-title", "edit");
            dom.setAttribute(el4, "class", "btn  btn-md tooltips");
            var el5 = dom.createElement("i");
            dom.setAttribute(el5, "class", "fa fa fa-eye");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "style", "margin-bottom:auto;margin-top:auto;");
            dom.setAttribute(el3, "class", "copy-icon");
            var el4 = dom.createTextNode("\n                                      ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "fa fa-clipboard");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1, 1]);
            var element3 = dom.childAt(element2, [1, 1]);
            var element4 = dom.childAt(element2, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element3, 'title');
            morphs[1] = dom.createElementMorph(element3);
            morphs[2] = dom.createElementMorph(element4);
            morphs[3] = dom.createMorphAt(element4, 3, 3);
            return morphs;
          },
          statements: [["attribute", "title", ["concat", ["Show ", ["get", "paramType", ["loc", [null, [53, 58], [53, 67]]]]]]], ["element", "action", ["show", ["get", "brand", ["loc", [null, [53, 200], [53, 205]]]]], [], ["loc", [null, [53, 184], [53, 207]]]], ["element", "action", ["copyGalleryLink", ["get", "brand", ["loc", [null, [55, 130], [55, 135]]]]], ["bubbles", false], ["loc", [null, [55, 103], [55, 151]]]], ["block", "tooltip-on-element", [], ["side", "bottom"], 0, null, ["loc", [null, [57, 38], [59, 61]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 34
              },
              "end": {
                "line": 67,
                "column": 34
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/manage-ui.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "data-placement", "top");
            dom.setAttribute(el1, "data-toggle", "tooltip");
            dom.setAttribute(el1, "type", "button");
            dom.setAttribute(el1, "data-original-title", "edit");
            dom.setAttribute(el1, "class", "btn  btn-md tooltips");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa fa-pencil");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'title');
            morphs[1] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["attribute", "title", ["concat", ["Edit ", ["get", "paramType", ["loc", [null, [66, 58], [66, 67]]]]]]], ["element", "action", ["edit", ["get", "brand", ["loc", [null, [66, 200], [66, 205]]]]], [], ["loc", [null, [66, 184], [66, 207]]]]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 70,
                "column": 34
              },
              "end": {
                "line": 72,
                "column": 34
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/manage-ui.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "data-placement", "top");
            dom.setAttribute(el1, "data-toggle", "tooltip");
            dom.setAttribute(el1, "type", "button");
            dom.setAttribute(el1, "data-original-title", "delete");
            dom.setAttribute(el1, "class", "btn  btn-md tooltips");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa fa-trash");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element0, 'title');
            morphs[1] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["attribute", "title", ["concat", ["Delete ", ["get", "paramType", ["loc", [null, [71, 60], [71, 69]]]]]]], ["element", "action", ["deleteFromDB", ["get", "brand", ["loc", [null, [71, 212], [71, 217]]]]], [], ["loc", [null, [71, 188], [71, 219]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 36,
              "column": 24
            },
            "end": {
              "line": 75,
              "column": 24
            }
          },
          "moduleName": "elbe-ember-frond-end/templates/components/manage-ui.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n                                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln");
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "style", "min-width: 500px;overflow: hidden;text-overflow: ellipsis;background-color: #f0f0f0;padding-left:4px;");
          var el4 = dom.createTextNode(" \n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" \n");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                                \n                                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                                \n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln column_width_small");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln column_width_small");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                                \n                            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(element5, [1, 0]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(element5, [4]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(element5, [6]), 1, 1);
          morphs[3] = dom.createMorphAt(element5, 8, 8);
          morphs[4] = dom.createMorphAt(dom.childAt(element5, [10]), 1, 1);
          morphs[5] = dom.createMorphAt(dom.childAt(element5, [12]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "brand.orgID", ["loc", [null, [39, 40], [39, 51]]]]], [], 0, 1, ["loc", [null, [39, 34], [43, 41]]]], ["inline", "moment-format", [["get", "brand.updatedAt", ["loc", [null, [47, 87], [47, 102]]]], "MM/DD/YYYY"], [], ["loc", [null, [47, 71], [47, 117]]]], ["inline", "moment-format", [["get", "brand.createdAt", ["loc", [null, [48, 87], [48, 102]]]], "MM/DD/YYYY"], [], ["loc", [null, [48, 71], [48, 117]]]], ["block", "if", [["subexpr", "eq", [["get", "paramType", ["loc", [null, [49, 42], [49, 51]]]], "Gallery"], [], ["loc", [null, [49, 38], [49, 62]]]]], [], 2, null, ["loc", [null, [49, 32], [63, 39]]]], ["block", "if", [["get", "brand.orgID", ["loc", [null, [65, 40], [65, 51]]]]], [], 3, null, ["loc", [null, [65, 34], [67, 41]]]], ["block", "if", [["get", "brand.orgID", ["loc", [null, [70, 40], [70, 51]]]]], [], 4, null, ["loc", [null, [70, 34], [72, 41]]]]],
        locals: ["brand", "index"],
        templates: [child0, child1, child2, child3, child4]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 85,
            "column": 0
          }
        },
        "moduleName": "elbe-ember-frond-end/templates/components/manage-ui.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container body");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "main_container");
        var el3 = dom.createTextNode("\n   ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" page content ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "right_col");
        dom.setAttribute(el3, "role", "main");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "clearfix");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-md-12 col-sm-12 col-xs-12");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "x_panel");
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "x_title");
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("h3");
        var el9 = dom.createComment("");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "clearfix");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n              ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "x_content");
        var el8 = dom.createTextNode("\n                    ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "row tile_count");
        var el9 = dom.createTextNode("\n                        ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "col-md-4");
        var el10 = dom.createTextNode("\n                            ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("button");
        dom.setAttribute(el10, "data-placement", "top");
        dom.setAttribute(el10, "data-toggle", "tooltip");
        dom.setAttribute(el10, "type", "button");
        dom.setAttribute(el10, "data-original-title", "new brand");
        dom.setAttribute(el10, "class", "btn  btn-success btn-lg tooltips");
        var el11 = dom.createElement("i");
        dom.setAttribute(el11, "class", "fa fa-plus");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" Create New ");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("table");
        dom.setAttribute(el8, "id", "campaigns_dash");
        dom.setAttribute(el8, "class", "table table-striped jambo_table");
        dom.setAttribute(el8, "style", "max-width: 900px");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("thead");
        var el10 = dom.createTextNode("\n                        ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("tr");
        var el11 = dom.createTextNode("                            \n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode(" Name");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createTextNode("Last Update");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createTextNode("Created At");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n");
        dom.appendChild(el10, el11);
        var el11 = dom.createComment("");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createTextNode("Edit");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                            ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createTextNode("Delete");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("                            \n                        ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tbody");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createComment(" x-content ");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" x-panel ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createComment(" col-md-12 ");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" row ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" right-col ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" main container ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var element7 = dom.childAt(element6, [3]);
        var element8 = dom.childAt(element7, [5, 3, 1, 1]);
        var element9 = dom.childAt(element8, [3]);
        var element10 = dom.childAt(element9, [1, 1, 1]);
        var element11 = dom.childAt(element9, [3]);
        var element12 = dom.childAt(element11, [1, 1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(element6, 1, 1);
        morphs[1] = dom.createMorphAt(element7, 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element8, [1, 1]), 0, 0);
        morphs[3] = dom.createAttrMorph(element10, 'title');
        morphs[4] = dom.createElementMorph(element10);
        morphs[5] = dom.createMorphAt(element10, 2, 2);
        morphs[6] = dom.createMorphAt(dom.childAt(element12, [1]), 0, 0);
        morphs[7] = dom.createMorphAt(element12, 7, 7);
        morphs[8] = dom.createMorphAt(dom.childAt(element11, [3]), 1, 1);
        return morphs;
      },
      statements: [["inline", "notification-container", [], ["notifications", ["subexpr", "@mut", [["get", "notifications", ["loc", [null, [2, 41], [2, 54]]]]], [], []], "position", "top"], ["loc", [null, [2, 2], [2, 71]]]], ["content", "top-navbar", ["loc", [null, [4, 3], [4, 17]]]], ["content", "manageTitle", ["loc", [null, [12, 20], [12, 35]]]], ["attribute", "title", ["concat", ["Click to add new ", ["get", "paramType", ["loc", [null, [18, 62], [18, 71]]]]]]], ["element", "action", ["createNew"], [], ["loc", [null, [18, 205], [18, 227]]]], ["content", "paramType", ["loc", [null, [18, 266], [18, 279]]]], ["content", "paramType", ["loc", [null, [25, 60], [25, 73]]]], ["block", "if", [["subexpr", "eq", [["get", "paramType", ["loc", [null, [28, 38], [28, 47]]]], "Gallery"], [], ["loc", [null, [28, 34], [28, 58]]]]], [], 0, null, ["loc", [null, [28, 28], [30, 35]]]], ["block", "each", [["get", "list_data", ["loc", [null, [36, 32], [36, 41]]]]], [], 1, null, ["loc", [null, [36, 24], [75, 33]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});