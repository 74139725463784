define('elbe-ember-frond-end/helpers/truncate-text', ['exports', 'ember'], function (exports, _ember) {
  exports.truncateText = truncateText;

  function truncateText(params, hash) {
    var value = params[0];
    var len = typeof hash === "object" && hash.limit !== undefined ? hash.limit : params[1] | 0;
    var out = '';

    if (typeof value === "string") {
      out = value.substr(0, len);

      if (value.length > len) {
        out += '..';
      }
    } else {
      out = '';
    }

    return out;
  }

  exports['default'] = _ember['default'].Helper.helper(truncateText);
});