/* jshint ignore:start */

define('elbe-ember-frond-end/config/environment', ['ember'], function(Ember) {
  var prefix = 'elbe-ember-frond-end';
/* jshint ignore:start */

try {
  var metaName = prefix + '/config/environment';
  var rawConfig = Ember['default'].$('meta[name="' + metaName + '"]').attr('content');
  var config = JSON.parse(unescape(rawConfig));

  return { 'default': config };
}
catch(err) {
  throw new Error('Could not read config from meta tag with name "' + metaName + '".');
}

/* jshint ignore:end */

});

if (!runningTests) {
  require("elbe-ember-frond-end/app")["default"].create({"version":"2.22.0","name":"elbe-ember-frond-end","buildDate":"2025-03-16T18:29:43.916Z","buildInfo":{"semver":"2.22.0+4a978e9a","version":"2.22.0","major":"2","minor":"22","patch":"0","build":"4a978e9a","commit":"4a978e9a"}});
}

/* jshint ignore:end */
