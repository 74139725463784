define('elbe-ember-frond-end/controllers/forgotpassword', ['exports', 'ember', 'elbe-ember-frond-end/utils/formatUtils', 'underscore'], function (exports, _ember, _elbeEmberFrondEndUtilsFormatUtils, _underscore) {

    function validEmail(v) {
        var r = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");
        return v.match(r) == null ? false : true;
    }

    exports['default'] = _ember['default'].Controller.extend({
        init: function init() {
            this.set('username', '');
        },
        actions: {
            sendEmail: function sendEmail() {
                var u_name = this.get('username');
                if (u_name !== null || u_name !== undefined) {
                    if (validEmail(u_name)) {
                        var params = {
                            username: u_name
                        };
                        this.set('isProcessing', true);
                        var controller = this;
                        _ember['default'].$.ajax({
                            url: _ember['default'].API_URL + '/resetPassword',
                            type: 'POST',
                            data: params,
                            success: function success() /*response*/{
                                controller.set('isProcessing', false); //trigger splash animation
                                controller.notifications.success('An email has been sent to your registered mail', {
                                    autoClear: true,
                                    clearDuration: 3200
                                });
                            },
                            error: function error(_error) {
                                controller.set('isProcessing', false);
                                //console.log(JSON.parse(error.responseText).message);
                                controller.notifications.error(JSON.parse(_error.responseText).message, {
                                    autoClear: true,
                                    clearDuration: 2200
                                });
                            }
                        }); //ajax
                    } else {
                            this.notifications.error('Enter a valid email', {
                                autoClear: true,
                                clearDuration: 2200
                            });
                        }
                }
            }
        }
    });
});