define('elbe-ember-frond-end/routes/brands', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'elbe-ember-frond-end/mixins/manage-route'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _elbeEmberFrondEndMixinsManageRoute) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _elbeEmberFrondEndMixinsManageRoute['default'], {
    tAttr: "brand",
    pAttr: "brandID",
    api: "brand",
    title: 'Update Brand'
  });
});
/*  
title: 'Manage Brands',
  actions: {
      error(error, transition) {
          if (error && error.status === 400 || 401) {
              return this.transitionTo('serverError');
          }
      }
  },
  model: function(params) {
    var ret = null;      
    if (params.brandID) {
      ret = Ember.$.ajax({
        url: Ember.API_URL + '/brand/' + params.brandID,
        type: 'GET',          
        context: this,
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        success: function () {            
        },
        error: function () {            
        }
      });        
    }      
    return ret;
  },
  setupController: function(controller, model) {
    let brand;
    if (model) {
      this.set('title', "Update Brand - " + model.name);
      brand = model;
      controller.set('isNewBrand', false);
    } else {
      this.set('title', "New Brand");
      brand = {
        brandID: new UUID(4).format(),
        name: '',
        description: '',
        url: '',
        userID: this.get('session.data.authenticated.user_ID'),
        orgID: this.get('session.data.authenticated.org_ID')
      };  
      controller.set('isNewBrand', true);        
    }
    controller.set('brand', brand);       
  },
  */