define("elbe-ember-frond-end/templates/components/top-navbar", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 54
              },
              "end": {
                "line": 22,
                "column": 88
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Company Info");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 54
                },
                "end": {
                  "line": 24,
                  "column": 90
                }
              },
              "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Primo Admin");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 24
              },
              "end": {
                "line": 25,
                "column": 24
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                         ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "class", "chgPasswdMenu");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" \n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 0, 0);
            return morphs;
          },
          statements: [["block", "link-to", ["adminpanel"], [], 0, null, ["loc", [null, [24, 54], [24, 102]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 54
              },
              "end": {
                "line": 26,
                "column": 100
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Reporting Overview");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 54
                },
                "end": {
                  "line": 28,
                  "column": 93
                }
              },
              "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Zip Overview");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 24
              },
              "end": {
                "line": 29,
                "column": 24
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                         ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "class", "chgPasswdMenu");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 0, 0);
            return morphs;
          },
          statements: [["block", "link-to", ["overview-zip"], [], 0, null, ["loc", [null, [28, 54], [28, 105]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 53
              },
              "end": {
                "line": 30,
                "column": 94
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Change Password");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 24
              },
              "end": {
                "line": 37,
                "column": 24
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createElement("br");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("Version: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(", ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("br");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" built-on : ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element1, 1, 1);
            morphs[1] = dom.createMorphAt(element1, 5, 5);
            return morphs;
          },
          statements: [["content", "primo_app_version", ["loc", [null, [36, 42], [36, 63]]]], ["inline", "moment-format", [["get", "buildDate", ["loc", [null, [36, 103], [36, 112]]]], "MM/DD/YYYY - HH:mm:ss"], [], ["loc", [null, [36, 87], [36, 138]]]]],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 36
              },
              "end": {
                "line": 44,
                "column": 75
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Manage Brands");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child7 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 36
              },
              "end": {
                "line": 45,
                "column": 86
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Manage Creative Formats");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child8 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 36
              },
              "end": {
                "line": 46,
                "column": 81
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Manage Galleries");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child9 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 48,
                  "column": 35
                },
                "end": {
                  "line": 48,
                  "column": 80
                }
              },
              "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode(" Manage Templates");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 28
              },
              "end": {
                "line": 49,
                "column": 28
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createElement("a");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 0, 0);
            return morphs;
          },
          statements: [["block", "link-to", ["template-list"], [], 0, null, ["loc", [null, [48, 35], [48, 92]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child10 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 78,
                  "column": 36
                },
                "end": {
                  "line": 78,
                  "column": 64
                }
              },
              "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("New User");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 79,
                  "column": 35
                },
                "end": {
                  "line": 79,
                  "column": 72
                }
              },
              "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Manage Users");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 81,
                  "column": 36
                },
                "end": {
                  "line": 81,
                  "column": 61
                }
              },
              "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("New Org");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.6",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 35
                },
                "end": {
                  "line": 82,
                  "column": 68
                }
              },
              "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Manage Org");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 74,
                "column": 16
              },
              "end": {
                "line": 85,
                "column": 16
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "href", "javascript:;");
            dom.setAttribute(el2, "class", "main_menu_links dropdown-toggle");
            dom.setAttribute(el2, "data-toggle", "dropdown");
            var el3 = dom.createTextNode(" Users/Org ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", " fa fa-angle-down");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("ul");
            dom.setAttribute(el2, "class", "dropdown-menu pull-right");
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("li");
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("a");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("li");
            var el4 = dom.createElement("a");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("li");
            dom.setAttribute(el3, "class", "divider");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("li");
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("a");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("li");
            var el4 = dom.createElement("a");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 0]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [7, 1]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [9, 0]), 0, 0);
            return morphs;
          },
          statements: [["block", "link-to", ["users"], [], 0, null, ["loc", [null, [78, 36], [78, 76]]]], ["block", "link-to", ["list-users"], [], 1, null, ["loc", [null, [79, 35], [79, 84]]]], ["block", "link-to", ["org"], [], 2, null, ["loc", [null, [81, 36], [81, 73]]]], ["block", "link-to", ["list-org"], [], 3, null, ["loc", [null, [82, 35], [82, 80]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      var child11 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 88,
                "column": 47
              },
              "end": {
                "line": 88,
                "column": 140
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-home");
            dom.setAttribute(el2, "aria-hidden", "true");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Dashboard");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 12
            },
            "end": {
              "line": 92,
              "column": 10
            }
          },
          "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "nav navbar-nav navbar-right");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "javascript:;");
          dom.setAttribute(el3, "class", "dropdown-toggle main_menu_links");
          dom.setAttribute(el3, "data-toggle", "dropdown");
          var el4 = dom.createElement("i");
          dom.setAttribute(el4, "class", "fa fa-user");
          dom.setAttribute(el4, "aria-hidden", "true");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" (");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(") ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", " fa fa-angle-down");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          dom.setAttribute(el3, "class", "dropdown-menu dropdown-usermenu pull-right");
          var el4 = dom.createTextNode("\n                         ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "class", "chgPasswdMenu");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" \n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                         ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "class", "chgPasswdMenu");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "class", "chgPasswdMenu");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment(" <li><a href=\"https://www.primo.design/primodocs/primodocs-dashboard\" target=\"_blank\">Documentation</a></li> ");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          var el6 = dom.createElement("i");
          dom.setAttribute(el6, "class", "fa fa-sign-out pull-right");
          dom.setAttribute(el6, "aria-hidden", "true");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" Log Out");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" \n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("                \n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "javascript:;");
          dom.setAttribute(el3, "class", "main_menu_links dropdown-toggle");
          dom.setAttribute(el3, "data-toggle", "dropdown");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", " fa fa-bars");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Manage");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          dom.setAttribute(el3, "class", "dropdown-menu pull-right");
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                            \n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("a");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("         \n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          var el3 = dom.createTextNode("\n                       ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "javascript:;");
          dom.setAttribute(el3, "class", "main_menu_links dropdown-toggle");
          dom.setAttribute(el3, "data-toggle", "dropdown");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", " fa fa-info");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Documentation");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          dom.setAttribute(el3, "class", "dropdown-menu pull-right");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "href", "https://docs.google.com/document/d/1yEOrW7tH6UY82A06izWfrh46n3q5YZ1V-4Ej3KAqywQ");
          dom.setAttribute(el5, "target", "_blank");
          dom.setAttribute(el5, "class", "main_menu_links");
          var el6 = dom.createElement("span");
          var el7 = dom.createElement("i");
          dom.setAttribute(el7, "class", "fa fa-file-text-o");
          dom.setAttribute(el7, "aria-hidden", "true");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("  CO2e Attribution");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                               \n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "href", "https://docs.google.com/document/d/1YcpLVytZPS0OtXDuOMs-P8wVk2CReUb672pqAaSIK24");
          dom.setAttribute(el5, "target", "_blank");
          dom.setAttribute(el5, "class", "main_menu_links");
          var el6 = dom.createElement("span");
          var el7 = dom.createElement("i");
          dom.setAttribute(el7, "class", "fa fa-file-text-o");
          dom.setAttribute(el7, "aria-hidden", "true");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("  On Boarding Info");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("       \n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "href", "https://www.primo.design/primodocs/primodocs-dashboard");
          dom.setAttribute(el5, "target", "_blank");
          dom.setAttribute(el5, "class", "main_menu_links");
          var el6 = dom.createElement("span");
          var el7 = dom.createElement("i");
          dom.setAttribute(el7, "class", "fa fa-file-text-o");
          dom.setAttribute(el7, "aria-hidden", "true");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("  Dashboard");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("       \n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "href", "https://www.youtube.com/@primo-the-better-bannerbuilder");
          dom.setAttribute(el5, "target", "_blank");
          dom.setAttribute(el5, "class", "main_menu_links");
          var el6 = dom.createElement("span");
          var el7 = dom.createElement("i");
          dom.setAttribute(el7, "class", "fa fa-youtube");
          dom.setAttribute(el7, "aria-hidden", "true");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("  Video Channel");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("       \n                      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "href", "javascript:;");
          dom.setAttribute(el3, "class", "main_menu_links dropdown-toggle");
          dom.setAttribute(el3, "data-toggle", "dropdown");
          var el4 = dom.createElement("span");
          dom.setAttribute(el4, "class", " fa fa-image");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Galleries");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          dom.setAttribute(el3, "class", "dropdown-menu pull-right");
          var el4 = dom.createTextNode("\n                           ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          dom.setAttribute(el4, "class", "divider2");
          var el5 = dom.createTextNode("- Public -");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                           ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "href", "https://ps.visarity.com/gallery/?galleryID=9e83778e-a091-49ac-ada6-ea34ceba0cd8&creativeID=v3ad-c693-8889-103a-a2511");
          dom.setAttribute(el5, "target", "_blank");
          var el6 = dom.createTextNode("Primo Gallery");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                           ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "href", "https://ps.visarity.com/gallery/?galleryID=5c58b5e9-1329-4935-97aa-26d5b215f9ee&creativeID=v3ad-e1da-9c26-151d-a52a4");
          dom.setAttribute(el5, "target", "_blank");
          var el6 = dom.createTextNode("Best of Gallery");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                                                      \n                           ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("br");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                           ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          dom.setAttribute(el4, "class", "divider2");
          var el5 = dom.createTextNode("- Downloadable (login required) -");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                           ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "href", "https://primo.visarity.com/from-gallery?galleryID=62699155-eb85-4333-921b-36560d262ea4");
          dom.setAttribute(el5, "target", "_blank");
          var el6 = dom.createTextNode("Download Gallery");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                           ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createElement("a");
          dom.setAttribute(el5, "href", "https://primo.visarity.com/from-gallery?galleryID=a721e146-cadb-426e-bf49-127a75dc8e5b");
          dom.setAttribute(el5, "target", "_blank");
          var el6 = dom.createTextNode("Studio Template Gallery");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                           \n                        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                \n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "class", "main_menu_links");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment(" /profile");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element3, [3]);
          var element6 = dom.childAt(element5, [13, 0]);
          var element7 = dom.childAt(element2, [3, 3]);
          var morphs = new Array(15);
          morphs[0] = dom.createMorphAt(element4, 2, 2);
          morphs[1] = dom.createMorphAt(element4, 4, 4);
          morphs[2] = dom.createMorphAt(dom.childAt(element5, [1, 0]), 0, 0);
          morphs[3] = dom.createMorphAt(element5, 3, 3);
          morphs[4] = dom.createMorphAt(dom.childAt(element5, [5, 0]), 0, 0);
          morphs[5] = dom.createMorphAt(element5, 7, 7);
          morphs[6] = dom.createMorphAt(dom.childAt(element5, [9, 0]), 0, 0);
          morphs[7] = dom.createElementMorph(element6);
          morphs[8] = dom.createMorphAt(element5, 15, 15);
          morphs[9] = dom.createMorphAt(dom.childAt(element7, [1, 1]), 0, 0);
          morphs[10] = dom.createMorphAt(dom.childAt(element7, [3, 1]), 0, 0);
          morphs[11] = dom.createMorphAt(dom.childAt(element7, [5, 1]), 0, 0);
          morphs[12] = dom.createMorphAt(element7, 7, 7);
          morphs[13] = dom.createMorphAt(element2, 9, 9);
          morphs[14] = dom.createMorphAt(dom.childAt(element2, [11, 0]), 0, 0);
          return morphs;
        },
        statements: [["content", "userName", ["loc", [null, [20, 157], [20, 169]]]], ["content", "role", ["loc", [null, [20, 176], [20, 184]]]], ["block", "link-to", ["company"], [], 0, null, ["loc", [null, [22, 54], [22, 100]]]], ["block", "if", [["get", "user_admin_role", ["loc", [null, [23, 30], [23, 45]]]]], [], 1, null, ["loc", [null, [23, 24], [25, 31]]]], ["block", "link-to", ["overview-live"], [], 2, null, ["loc", [null, [26, 54], [26, 112]]]], ["block", "if", [["subexpr", "or", [["get", "isSubscription", ["loc", [null, [27, 34], [27, 48]]]], ["get", "user_admin_role", ["loc", [null, [27, 49], [27, 64]]]]], [], ["loc", [null, [27, 30], [27, 65]]]]], [], 3, null, ["loc", [null, [27, 24], [29, 31]]]], ["block", "link-to", ["changepsswd"], [], 4, null, ["loc", [null, [30, 53], [30, 106]]]], ["element", "action", ["invalidateSession"], [], ["loc", [null, [32, 31], [32, 62]]]], ["block", "if", [["get", "user_admin_role", ["loc", [null, [34, 30], [34, 45]]]]], [], 5, null, ["loc", [null, [34, 24], [37, 31]]]], ["block", "link-to", ["list-brands"], [], 6, null, ["loc", [null, [44, 36], [44, 87]]]], ["block", "link-to", ["list-formats"], [], 7, null, ["loc", [null, [45, 36], [45, 98]]]], ["block", "link-to", ["list-galleries"], [], 8, null, ["loc", [null, [46, 36], [46, 93]]]], ["block", "if", [["get", "user_admin_role", ["loc", [null, [47, 34], [47, 49]]]]], [], 9, null, ["loc", [null, [47, 28], [49, 35]]]], ["block", "if", [["get", "user_admin_role", ["loc", [null, [74, 22], [74, 37]]]]], [], 10, null, ["loc", [null, [74, 16], [85, 23]]]], ["block", "link-to", ["dashboard"], [], 11, null, ["loc", [null, [88, 47], [88, 152]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 102,
                "column": 4
              },
              "end": {
                "line": 106,
                "column": 4
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("strong");
            var el2 = dom.createTextNode("\n        Session has expired. ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("br");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("Please login again.        \n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 101,
              "column": 0
            },
            "end": {
              "line": 107,
              "column": 0
            }
          },
          "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "modal-dialog", [], ["close", "invalidateSession", "targetAttachment", "center", "translucentOverlay", true], 0, null, ["loc", [null, [102, 4], [106, 21]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 108,
            "column": 0
          }
        },
        "moduleName": "elbe-ember-frond-end/templates/components/top-navbar.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment(" top navigation ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "top_nav navbar-fixed-top");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "nav_menu");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "navbar-header");
        dom.setAttribute(el3, "style", "display: flex;");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "navbar-brand");
        var el5 = dom.createElement("img");
        dom.setAttribute(el5, "src", "/images/3dprimo_logo_beta.png");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "style", "margin-top:auto; margin-bottom:auto;display:flex;padding-top: 16px;");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6, "href", "https://www.linkedin.com/company/primo-adbuilder/");
        dom.setAttribute(el6, "target", "_blank");
        dom.setAttribute(el6, "class", "main_menu_links");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "class", "navbar-icon");
        dom.setAttribute(el7, "src", "/images/In-White-96.png");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("nav");
        dom.setAttribute(el3, "role", "navigation");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" profile");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" /nav-bar nav");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" /empty class");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" /nav-menu ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" /top navigation ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2, 1, 3]), 3, 3);
        morphs[1] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "enableAuthorizedLinks", ["loc", [null, [17, 18], [17, 39]]]]], [], 0, null, ["loc", [null, [17, 12], [92, 17]]]], ["block", "if", [["get", "isSessionExpired", ["loc", [null, [101, 6], [101, 22]]]]], [], 1, null, ["loc", [null, [101, 0], [107, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});