define('elbe-ember-frond-end/routes/list-users', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'elbe-ember-frond-end/utils/formatUtils', 'underscore'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _elbeEmberFrondEndUtilsFormatUtils, _underscore) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        title: 'List Users',
        actions: {
            error: function error(_error, transition) {
                if (_error && _error.status === 400 || 401) {
                    return this.transitionTo('serverError');
                }
            }
        },
        model: function model(params) {
            var controller = this;

            return new _ember['default'].RSVP.hash({
                campaignUsers: _ember['default'].$.ajax({
                    url: _ember['default'].API_URL + '/usersWithCampaign/' + controller.get('session.data.authenticated.user_ID'),
                    type: 'GET',
                    dataType: 'json',
                    success: function success(response) {
                        //console.log(response);
                    },
                    error: function error(_error2) {
                        reject(_error2);
                    }
                }), //ajax
                newUsers: _ember['default'].$.ajax({
                    url: _ember['default'].API_URL + '/getnewusers/' + controller.get('session.data.authenticated.user_ID'),
                    type: 'GET',
                    dataType: 'json',
                    success: function success(response) {
                        //console.log(response);
                    },
                    error: function error(_error3) {
                        reject(_error3);
                    }
                }) //ajax
            });
        },
        setupController: function setupController(controller, model) {

            controller.set('users_list_data', model.campaignUsers);
            controller.set('user_list_data_orig', model.campaignUsers);
            controller.set('new_users_list_data', model.newUsers);
        }
    });
});