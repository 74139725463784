define("elbe-ember-frond-end/mixins/manage-route", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Mixin.create({
    tAttr: "brand",
    pAttr: "brandID",
    api: "brand",
    title: 'Manage',
    actions: {
      error: function error(_error, transition) {
        if (_error && _error.status === 400 || 401) {
          return this.transitionTo('serverError');
        }
      }
    },
    model: function model(params) {
      return _ember["default"].$.ajax({
        url: _ember["default"].API_URL + "/" + this.api + "/" + params[this.pAttr],
        type: 'GET',
        context: this,
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        success: function success() {},
        error: function error() {}
      });
      return ret;
    },
    setupController: function setupController(controller, model) {
      controller.set(this.tAttr, model);
    }
  });
});