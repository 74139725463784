define('elbe-ember-frond-end/routes/template', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'Primo Templates',
    model: function model(params) {
      return new _ember['default'].RSVP.hash({
        template_data: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/templates?templatedata=1',
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error) {
            console.log(_error);
          }
        }), //ajax
        campaign_data: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/find/' + params.id,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            //console.log(response);
          },
          error: function error(_error2) {
            console.log(_error2);
          }
        }) });
    },
    //ajax
    setupController: function setupController(controller, model, queryParams) {
      model.template_data.forEach(function (e) {
        if (e.name === "Canvas" || e.name === "Custom Canvas") {
          e.name = "Empty (Create from Scratch)";
        }
      });

      controller.set('template_data_orig', model.template_data);
      controller.set('template_data', model.template_data);
      controller.set('campaignData', model.campaign_data);
      //console.log(model.template_data);               
      var data = model.template_data;
      var format = _.uniq(data, function (obj) {
        return obj.format;
      });
      var formats_1 = _.pluck(format, 'format');
      //var formats_clean= _.reject(formats_1, _.isNull);

      //  model.template_data.forEach( e =>  { if (e.name === "Canvas") e.name ="Empty (Create from Scratch)"; } );

      controller.set('template_format_list', _.reject(formats_1, _.isNull).reverse());
    }
  });
});