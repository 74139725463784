define('elbe-ember-frond-end/routes/format-gallery', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'Clone from Gallery',

    model: function model() {
      var controller = this;

      var userMembership = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/membership/' + controller.get('session.data.authenticated.user_ID'),
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            //console.log(response);
            resolve(response);
          },
          error: function error(reason) {
            reject(reason);
          }
        }); //ajax
      }); //promise

      var userList = userMembership.then(function (response) {
        var url = _ember['default'].API_URL + '/org/allUsers/' + controller.get('session.data.authenticated.org_ID');
        if (response.permission === 'admin') {
          url = _ember['default'].API_URL + '/allUsers/' + controller.get('session.data.authenticated.user_ID');
        }

        return _ember['default'].$.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error) {}
        }); //ajax
      });

      var brandList = userMembership.then(function (response) {
        var url = _ember['default'].API_URL + ('/brands/' + controller.get('session.data.authenticated.org_ID'));
        return _ember['default'].$.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error2) {}
        }); //ajax
      });

      var formatList = userMembership.then(function (response) {
        var url = _ember['default'].API_URL + ('/formats/' + controller.get('session.data.authenticated.org_ID'));
        return _ember['default'].$.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error3) {}
        }); //ajax
      });

      return new _ember['default'].RSVP.hash({
        user_role: userMembership,
        user_List: userList,
        brand_List: brandList,
        format_List: formatList
      }); //rsvp
    },

    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    }
  });
});