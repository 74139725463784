define('elbe-ember-frond-end/controllers/template', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    remodal: _ember['default'].inject.service(),
    queryParams: 'campaignID',
    isShowingCreateCampaignModal: false,
    template_alert_visible: false,
    isCreatingCampaignProcess: false,
    showPreview: false,
    selFormat: 'all',
    selTemplateSize: 'all',

    init: function init() {
      _ember['default'].run.schedule("afterRender", this, function () {
        $(".template_description").dotdotdot({
          ellipsis: '... ',
          wrap: 'word',
          height: 50
        });
      });
      //  console.log($("#template_description"));
    },

    observer: (function () {
      var _this = this;
      setTimeout(function () {
        _this.send('selectTemplateSize', "300x250");
        $("#300x250").addClass("active");
      }, 100);
      //this.send('selectTemplateSize',"300x250");
    }).observes("template_data_orig"),

    actions: {
      selectAllFormats: function selectAllFormats() {
        $(".btn-group > .btn").click(function () {
          $(this).addClass("active").siblings().removeClass("active");
        });
        this.set('selFormat', 'all');
        this.set('selTemplateSize', 'all');
        var data = this.get('template_data_orig');
        this.set('template_data', data);
      },
      selectInterstitial: function selectInterstitial() {
        $(".btn-group > .btn").click(function () {
          $(this).addClass("active").siblings().removeClass("active");
        });
        this.set('selFormat', 'interstitial');
        var data = '';
        if (this.get('selTemplateSize') === 'all') {
          data = this.get('template_data_orig');
        } else {
          data = this.get('template_data');
        }
        var formatArray = _.filter(data, function (obj) {
          return obj.type === 'interstitial';
        });
        this.set('template_data', formatArray);
      },
      selectBannerFormat: function selectBannerFormat() {
        $(".btn-group > .btn").click(function () {
          $(this).addClass("active").siblings().removeClass("active");
        });
        this.set('selFormat', 'banner');
        var data = '';
        if (this.get('selTemplateSize') === 'all') {
          data = this.get('template_data_orig');
        } else {
          data = this.get('template_data');
        }
        var formatArray = _.filter(data, function (obj) {
          return obj.type === 'banner';
        });
        this.set('template_data', formatArray);
      },
      showAllSizes: function showAllSizes() {
        var sizeArray = '';
        this.set('selTemplateSize', 'all');
        $(".btn-group > .btn").click(function () {
          $(this).addClass("active").siblings().removeClass("active");
        });
        if (this.get('selFormat') === 'banner') {
          data = this.get('template_data_orig');
          sizeArray = _.filter(data, function (item) {
            return item.type === 'banner';
          });
        }
        if (this.get('selFormat') === 'interstitial') {
          data = this.get('template_data_orig');
          sizeArray = _.filter(data, function (item) {
            return item.type === 'interstitial';
          });
        }
        if (this.get('selFormat') === 'all' && this.get('selTemplateSize') === 'all') {
          sizeArray = this.get('template_data_orig');
          //sizeArray =  _.filter(data, function(item){ return item.type === 'interstitial'})
        }
        this.set('template_data', sizeArray);
      },
      selectTemplateSize: function selectTemplateSize(templateSize) {
        //hack to main selection

        $(".btn-group > .btn").click(function () {
          $(this).addClass("active").siblings().removeClass("active");
        });

        this.set('selTemplateSize', templateSize);
        var data = '';
        var sizeArray = '';
        if (this.get('selFormat') === 'all') {
          data = this.get('template_data_orig');
          sizeArray = _.filter(data, function (obj) {
            return obj.format === templateSize;
          });
        } else {
          if (this.get('selTemplateSize') !== 'all' && this.get('selFormat') === 'banner') {
            data = this.get('template_data_orig');
            sizeArray = _.filter(data, function (item) {
              return item.type === 'banner' && item.format === templateSize;
            });
          }
          if (this.get('selTemplateSize') !== 'all' && this.get('selFormat') === 'interstitial') {
            data = this.get('template_data_orig');
            sizeArray = _.filter(data, function (item) {
              return item.type === 'interstitial' && item.format === templateSize;
            });
          }
        }
        this.set('template_data', sizeArray);
      },
      notifyCanceled: function notifyCanceled() {
        this.set('embedPreview', '');
        this.set('showPreview', false);
      },
      previewTemplate: function previewTemplate(templateModel) {
        var controller = this;
        var timeStamp = new Date().getTime();
        controller.set('showPreview', true);
        var modal = this.get('remodal.preview-campaign-modal');
        _ember['default'].run.later(function () {
          var iframe = '<iframe src="' + templateModel.demo_previewURL + '?timestamp=' + timeStamp + '" style="width:' + templateModel.format.split('x')[0] + 'px; height:' + templateModel.format.split('x')[1] + 'px" name="preview" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" vspace="0" hspace="0"></iframe>';
          controller.set('embedPreview', iframe);
          modal.setProperties({
            title: templateModel.name,
            closeOnOutsideClick: false,
            closeOnEscape: true,
            disableNativeClose: false,
            onCancel: 'notifyModalCancelled',
            modalClasses: "remodal-header"
          });
          modal.open();
        }, 300);
      },
      createCampaign: function createCampaign(campaignData, templateData, controller, isCustomTemplate) {
        var campaignParams = {
          brandID: campaignData.brandID,
          campaignID: campaignData.campaignID,
          format: templateData.format,
          userID: controller.get('session.data.authenticated.user_ID'),
          splash_image: templateData.splash,
          derivedTemplateID: templateData.templateID
        };

        var splashParams = {
          format: templateData.format,
          isCustomTemplate: isCustomTemplate,
          campaignID: campaignData.campaignID
        };

        var projParams = {};
        if (isCustomTemplate) {
          projParams.customSize = true;
          projParams.height = templateData.height;
          projParams.width = templateData.width;
        } else {
          projParams.customSize = false;
        }

        console.log(campaignParams);
        console.log(projParams);
        console.log(splashParams);

        var postProjectData = new _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/createproject/' + campaignData.campaignID + "/" + templateData.templateID,
          type: 'POST',
          data: projParams,
          success: function success(response) {
            //console.log(response);
          },
          error: function error(_error) {
            //return error;
            //console.log(error);
          }
        }); //ajax

        var postCampaignData = new _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/update/' + campaignData.campaignID,
          type: 'POST',
          data: campaignParams,
          success: function success(response) {
            //console.log(response);
            //return response;
          },
          error: function error(_error2) {
            //return error;

          }
        });

        var genCopySplash = new _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/gensplash/' + templateData.templateID,
          type: 'POST',
          data: splashParams,
          success: function success(response) {},
          error: function error(_error3) {}
        });
        Promise.all([postProjectData, postCampaignData, genCopySplash]).then(function (values) {
          controller.set('isShowingCreateCampaignModal', false);
          controller.set('currentTemplateData', '');
          if (_ember['default'].Environment === "development") {
            window.open(_ember['default'].EDITOR_URL + campaignData.campaignID + '/normal/' + Date.now() + '/' + window.btoa(localStorage.getItem('ember_simple_auth:session')));
            window.location.href = "/dashboard";
          } else {
            window.open(_ember['default'].EDITOR_URL + campaignData.campaignID + '/normal/' + Date.now());
            window.location.href = "/dashboard";
          }
        });
      },
      closeCreateCampaignModal: function closeCreateCampaignModal() {
        this.set('isShowingCreateCampaignModal', false);
        this.set('campaignHeight', '');
        this.set('campaignWidth', '');
        this.set('currentTemplateData', '');
      },
      startCreatingCampaign: function startCreatingCampaign(templateData) {
        var width = this.get('campaignWidth');
        var height = this.get('campaignHeight');
        var campaignData = this.get('campaignData');
        if ($.isNumeric(width) && $.isNumeric(width)) {
          this.set('template_alert_visible', false);
          this.set('templateAlertType', 'success');
          this.set('isCreatingCampaignProcess', true);
          var newFormat = parseInt(width) + 'x' + parseInt(height);
          var newTemplateData = {
            height: parseInt(height),
            width: parseInt(width),
            format: newFormat,
            templateID: templateData.templateID,
            splash: templateData.splash
          };
          this.send('createCampaign', campaignData, newTemplateData, this, true);
        } else {
          this.set('isCreatingCampaignProcess', false);
          this.set('template_alert_visible', true);
          this.set('templateAlertType', 'error');
          this.set('create_campaign_warning_msg', 'Enter a numeric value between 1 and 1920');
        }
      },
      selectAndEdit: function selectAndEdit(model) {
        //  /projects/:campaignID/:userID/:brandID': 'ProjectController.uploadJSON
        var user_ID = this.get('session.data.authenticated.user_ID');
        var brandID = 'hello';
        var campaignData = this.get('campaignData');
        var template_data = this.get('template_data');
        //console.log(template_data);
        if (campaignData.brandID != null || campaignData.brandID != '') {
          brandID = campaignData.brandID;
        }

        //console.log(template_data.format);
        if (model.format === 'custom') {
          this.set('currentTemplateData', model);
          this.set('isShowingCreateCampaignModal', true);
        } else {
          this.set('isShowingCreateCampaignModal', false);
          this.send('createCampaign', campaignData, model, this, false);
        }

        /**/
      } } });
});
//action select&Edit
//action