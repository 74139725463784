define("elbe-ember-frond-end/components/feed-container", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    headers: [],
    rowData: [],
    count: 200,
    from: 0,
    showSpinner: 2,
    info: null,

    updateTypes: [{ name: "Manual", id: "manual" }, { name: "Period (every x hours)", id: "period" }],
    updateTypeSelected: null,

    updatePeriods: [{ name: "Every 3 hours", hs: 3 }, { name: "Every 6 hours", hs: 6 }, { name: "Every 8 hours", hs: 8 }, { name: "Every 12 hours", hs: 12 }, { name: "Every 16 hours", hs: 16 }, { name: "Every 24 hours", hs: 24 }],
    updatePeriodSelected: null,

    showUploads: (function () {
      if (this.info) {
        return !this.info.url || this.info.url && this.info.updateType === "manual";
      } else {
        return false;
      }
    }).property("info.url", "info.updateType"),

    updateData: function updateData(data) {
      var headers = [];
      var rowData = [];
      var lines = [];
      var i = 1;

      for (var a in data[0]) {
        headers.push(a);
      }

      data.forEach(function (d) {
        var r = [];
        headers.forEach(function (a) {
          r.push(d[a]);
        });
        r.pos = i++;
        rowData.push(r);
      });

      this.set("headers", headers);
      this.set("rowData", rowData);
    },

    loadData: function loadData() {
      var _this = this;
      _ember["default"].$.ajax({
        url: _ember["default"].API_URL + '/feeds/data/get/' + this.model.campaignData.feedID + "/" + this.count + "/" + this.from,
        type: 'GET',
        dataType: 'json',
        success: function success(response) {
          _this.updateData(response);
          _this.set('showSpinner', _this.get('showSpinner') - 1);
        },
        error: function error(_error) {
          _this.set('showSpinner', _this.get('showSpinner') - 1);
        }
      }); //ajax      
    },

    loadInfo: function loadInfo() {
      var _this = this;
      return _ember["default"].$.ajax({
        url: _ember["default"].API_URL + '/feeds/get/' + this.model.campaignData.feedID,
        type: 'GET',
        dataType: 'json',
        success: function success(response) {
          _this.set('info', response);

          _this.updateTypes.forEach(function (e) {
            if (e.id === _this.info.updateType) {
              _this.set('updateTypeSelected', e);
            }
          });

          if (_this.info.updatePeriod === null) {
            _this.set('info.updatePeriod', 24);
          }

          _this.updatePeriods.forEach(function (e) {
            if (e.hs === _this.info.updatePeriod) {
              _this.set('updatePeriodSelected', e);
            }
          });

          _this.set('showSpinner', _this.get('showSpinner') - 1);
        },
        error: function error(_error2) {
          _this.set('showSpinner', _this.get('showSpinner') - 1);
        }
      }); //ajax   
    },

    waitForBusy: function waitForBusy() {
      var feedID = this.model.campaignData.feedID;

      function checkBusy() {
        return new Promise(function (resolve, reject) {
          var xhr = new XMLHttpRequest();
          var url = _ember["default"].API_URL + "/feeds/get/" + feedID;
          xhr.addEventListener("load", function () {
            if (xhr.status === 200) {
              resolve(xhr.response);
            } else {
              reject();
            }
          });
          xhr.addEventListener('error', function () {
            reject();
          });
          xhr.responseType = "json";
          xhr.open("GET", url);
          xhr.setRequestHeader("authorization", "primo");
          xhr.send();
        });
      }

      function check(resolve, reject) {
        checkBusy().then(function (info) {
          if (!info.busy) {
            resolve(info);
          } else {
            console.log("Check next");
            setTimeout(function () {
              return check(resolve, reject);
            }, 10000);
          }
        })["catch"](function () {
          reject();
        });
      }

      return new Promise(function (resolve, reject) {
        check(resolve, reject);
      });
    },

    uploadData: function uploadData(data) {
      var _this = this;

      return _ember["default"].$.ajax({
        url: _ember["default"].API_URL + '/feeds/data/update/' + this.model.campaignData.feedID,
        type: data ? 'POST' : 'GET',
        data: data ? JSON.stringify(data) : undefined,
        contentType: data ? 'application/json;charset=utf-8' : undefined,
        dataType: 'json',
        success: function success(response) {
          _this.waitForBusy().then(function (info) {
            _this.set('info.error', info.error);
            _this.loadData();
          })["catch"](function (err) {
            _this.controller.notifications.warning('Feed Data Updating Busy ', { autoClear: true, clearDuration: 5000 });
            _this.set('showSpinner', false);
          });
        },
        error: function error(_error3) {
          _this.controller.notifications.error('Feed Data Not Updated. Please try it later.', { autoClear: true, clearDuration: 5000 });
          _this.set('showSpinner', false);
        }
      }); //ajax   
    },

    didInsertElement: function didInsertElement() {
      this.loadData();
      this.loadInfo();
    },

    actions: {
      updateFeedConf: function updateFeedConf() {
        var _this = this;
        var info = this.info;

        if (!info.url) {
          this.set('info.updateType', "manual");
          this.set('updateTypeSelected', { name: "Manual", id: "manual" });
        }

        var params = {
          name: info.name,
          updateType: info.updateType,
          updatePeriod: info.updatePeriod,
          format: info.format,
          url: info.url
        };
        console.log(params);
        _ember["default"].$.ajax({
          url: _ember["default"].API_URL + '/feeds/set/' + this.model.campaignData.feedID,
          type: 'POST',
          data: JSON.stringify(params),
          contentType: 'application/json;charset=utf-8',
          dataType: 'json',
          success: function success(response) {
            _this.controller.notifications.success('Feed Config Updated', { autoClear: true, clearDuration: 2600 });
          },
          error: function error(_error4) {
            _this.controller.notifications.error('Feed Config Not Updated. Please try it later.', { autoClear: true, clearDuration: 2600 });
          }
        }); //ajax  
      },
      uploadFile: function uploadFile(files) {
        console.log("uploadFile");
        var _this = this;
        var file = files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
          console.log(reader.result);
          _this.set('showSpinner', 1);
          _this.uploadData({ data: reader.result });
        };
        reader.onerror = function () {};
        reader.readAsText(file);
      },
      updateFromUrl: function updateFromUrl() {
        this.set('showSpinner', 1);
        this.uploadData();
      },
      updateType: function updateType(ut) {
        this.set('updateTypeSelected', ut);
        this.set('info.updateType', ut.id);
        this.send('updateFeedConf');
      },
      updatePeriod: function updatePeriod(ut) {
        this.set('updatePeriodSelected', ut);
        this.set('info.updatePeriod', ut.hs);
        this.send('updateFeedConf');
      }
    }

  });
});