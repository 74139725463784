define('elbe-ember-frond-end/authorizers/custom', ['exports', 'ember', 'ember-simple-auth/authorizers/base'], function (exports, _ember, _emberSimpleAuthAuthorizersBase) {
  exports['default'] = _emberSimpleAuthAuthorizersBase['default'].extend({
    session: _ember['default'].inject.service('session'),
    authorize: function authorize(data, permissions) {
      var accessToken = this.get('session.data.authenticated.token');
      var isAuthenticated = this.get('session.isAuthenticated');

      var pass = isAuthenticated || !_ember['default'].isEmpty(accessToken);
      var admin = data.user_role === "admin";

      if (admin) {
        return;
      }

      var bt = data.billingType ? data.billingType.toLowerCase() : "";
      var demo = data.user_role === "demo";
      var subscription = bt === "subscription";

      if (permissions.admin === true && !admin) {
        pass = false;
      }

      if (permissions.notDemo === true && demo) {
        pass = false;
      }

      if (permissions.subscription === true && !subscription) {
        pass = false;
      }

      if (!pass) {
        this.get('session').invalidate();
        this.get('router').transitionTo('login');
      }
    }
  });
});
// app/authorizers/devise.js
//import Devise from 'ember-simple-auth/authorizers/devise';

//export default Devise.extend({});