define('elbe-ember-frond-end/routes/org', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'New Organization',
    setupController: function setupController(controller, model) {
      var orgUUID = new UUID(4).format();
      controller.set('orgID', orgUUID);
      controller.set('orgName', '');
      controller.set('orgAddress', '');
    }
  });
});