define('elbe-ember-frond-end/components/creative-gallery', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service('session'),
    gallery_btn_save: "Save",
    isSaving: false,
    creative_list_data: [],
    orderList: [],
    showSortable: true,

    downloadable: _ember['default'].computed({
      get: function get() /*key*/{
        return !!(this.get('gallery.flags') & 1);
      },
      set: function set(key, value) {
        // jshint ignore:line
        this.set('gallery.flags', value ? 1 : 0);
        return value;
      }
    }),

    init: function init() {
      var lut = {};
      this.brands.forEach(function (e) {
        lut[e.brandID] = e;
      });
      this.set('brandLUT', lut);

      lut = {};
      this.formats.forEach(function (e) {
        lut[e.formatID] = e;
      });
      this.set('formatLUT', lut);

      lut = {};
      this.creative_list_data.forEach(function (e) {
        lut[e.creativeID] = e;
      });
      this.set('creativeLUT', lut);

      this.set('downloadable', this.gallery.flags & 1);

      this.set('user_admin_role', this.get('session.data.user_admin_role'));

      this._super();
    },

    didInsertElement: function didInsertElement() {
      var ol = [];
      this.get('creative_list_data').forEach(function (c) {
        ol.push(c.creativeID);
      });
      this.ol = ol;
    },

    didRender: function didRender() {
      this.set('showSortable', true);
    },

    actions: {
      openCopyFromGallery: function openCopyFromGallery(creativeID) {
        window.open('/from-gallery?galleryID=' + this.get('gallery.galleryID'), "");
      },
      openSettings: function openSettings(creativeID) {
        window.open('/proj_settings/' + creativeID, "");
      },
      openPreview: function openPreview(creativeID) {
        window.open(_ember['default'].PREVIEW_URL + "/" + ('?creativeID=' + creativeID), "");
      },
      movedCreative: function movedCreative(creative, oldIndex, newIndex) {
        if (oldIndex === newIndex) {
          return;
        }

        var e = this.ol.splice(oldIndex, 1)[0];
        this.ol.splice(newIndex, 0, e);

        var m = "";
        this.ol.forEach(function (id, i) {
          if (m) {
            m += ' ';
          }
          m += id;
        });

        var controller = this;
        var data = {
          galleryID: this.get('gallery.galleryID'),
          creatives: m
        };

        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/galleries',
          type: 'POST',
          data: JSON.stringify(data),
          context: this,
          contentType: 'application/json;charset=utf-8',
          dataType: 'json',
          success: function success() {
            //console.log(response);
            controller.notifications.success('Gallery ' + controller.get('gallery.name') + ' Saved', {
              autoClear: true,
              clearDuration: 3200
            });
          },
          error: function error() {
            controller.notifications.success('Error saving... retry again', {
              autoClear: true,
              clearDuration: 3200
            });
          }
        });
      },

      removeCreative: function removeCreative(creative) {
        var _this = this;

        var controller = this;
        var idx = -1;

        this.ol.forEach(function (e, i) {
          if (e === creative.creativeID) {
            idx = i;
          }
        });

        if (idx >= 0) {
          var e = this.ol.splice(idx, 1)[0];
          console.log(e);
        }

        var ca = [];
        this.ol.forEach(function (id) {
          ca.push(_this.creativeLUT[id]);
        });

        this.set('creative_list_data', ca);
        this.set('showSortable', false);

        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + ('/gallery/remove/' + this.gallery.galleryID + '/' + creative.creativeID),
          type: 'DELETE',
          success: function success(response) {
            controller.notifications.success(creative.name + ' removed', { autoClear: true, clearDuration: 1500 });
          },
          error: function error(_error) {
            console.log(_error);
          }
        });
      },
      save_newGallery: function save_newGallery() {
        if (this.get('gallery.name') === '' || this.get('gallery.name') == null) {
          this.notifications.warning('Require a creative gallery name', {
            autoClear: true,
            clearDuration: 3400
          });
        } else {
          this.set('gallery_btn_save', 'Saving...');
          this.set('isSaving', true);
          var gallery = this.get('gallery');
          var controller = this;
          var data = {
            galleryID: this.get('gallery.galleryID'),
            name: this.get('gallery.name'),
            description: this.get('gallery.description'),
            userID: this.get('gallery.userID'),
            orgID: this.get('gallery.orgID'),
            flags: this.get('gallery.flags') || null
          };
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/galleries',
            type: 'POST',
            data: JSON.stringify(data),
            context: this,
            contentType: 'application/json;charset=utf-8',
            dataType: 'json',
            success: function success() {
              //console.log(response);
              controller.notifications.success('Gallery ' + controller.get('gallery.name') + ' Saved', {
                autoClear: true,
                clearDuration: 3200
              });
              controller.set('gallery_btn_save', 'Save Again');
              controller.set('isSaving', false);
            },
            error: function error() {
              controller.notifications.success('Error saving... retry again', {
                autoClear: true,
                clearDuration: 3200
              });
              controller.set('gallery_btn_save', 'Retry');
              controller.set('isSaving', false);
            }
          });
        }
      }
    }
  });
});