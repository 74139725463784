define('elbe-ember-frond-end/routes/overview-live', ['exports', 'elbe-ember-frond-end/config/environment', 'ember', 'ember-cli-pagination/remote/route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _elbeEmberFrondEndConfigEnvironment, _ember, _emberCliPaginationRemoteRouteMixin, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberCliPaginationRemoteRouteMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'Overview of Live Creatives',
    model: function model() {
      var prices = _ember['default'].$.ajax({
        url: _ember['default'].API_URL + '/prices/all',
        type: 'GET',
        dataType: 'json',
        success: function success(response) {},
        error: function error(_error) {}
      });
      return new _ember['default'].RSVP.hash({ prices: prices });
    },
    setupController: function setupController(controller, model) {
      var lut = {};
      model.prices.forEach(function (e) {
        lut[e.priceID] = e;
      });
      controller.set('pricesLUT', lut);
    }
  });
});