define('elbe-ember-frond-end/controllers/login', ['exports', 'ember', 'elbe-ember-frond-end/utils/formatUtils', 'npm:jwt-decode'], function (exports, _ember, _elbeEmberFrondEndUtilsFormatUtils, _npmJwtDecode) {
  exports['default'] = _ember['default'].Controller.extend({

    queryParams: { 'token': {} },
    token: null,
    showLoginGoogle: !!_ember['default'].LoginGoogleUrl,
    showSlackGoogle: !!_ember['default'].LoginSlackUrl,

    observeGeneral: (function () {
      var _this = this;

      try {

        console.log("token:" + this.get('token'));
        var token = this.get('token');
        var decoded = (0, _npmJwtDecode['default'])(token);
        if (token && decoded) {
          this.get('session').authenticate('authenticator:custom', { token: token })['catch'](function (message) {
            console.log(message);
            _this.set('loginAlertVisible', true);
            _this.set('error_message_text', 'login failed.');
            _this.set('password', '');
          });
        }
      } catch (error) {}
    }).observes('token'),

    session: _ember['default'].inject.service('session'),
    loginAlertVisible: false,
    username: '',
    password: '',
    actions: {

      google: function google() {
        window.open(_ember['default'].LoginGoogleUrl, '_self');
      }, //google   

      slack: function slack() {
        window.open(_ember['default'].LoginSlackUrl, '_self');
      }, //slack

      authenticate: function authenticate() {
        var _this2 = this;

        var isUserNameValid,
            isPasswordValid = false;
        var username = this.get('username');
        var password = this.get('password');
        if (username == null || username == '') {
          this.set('loginAlertVisible', true);
          this.set('error_message_text', 'username required');
        } else {
          if (_elbeEmberFrondEndUtilsFormatUtils['default'].verifyEmail(username)) {
            isUserNameValid = true;
          } else {
            this.set('loginAlertVisible', true);
            this.set('error_message_text', 'username is of the format xxxx@xxxx.xxx');
          }
        }
        if (!password) {
          if (isUserNameValid) {
            this.set('loginAlertVisible', true);
            this.set('error_message_text', 'password required');
          }
        } else {
          isPasswordValid = true;
        }
        var credentials = this.getProperties('username', 'password');
        if (isUserNameValid && isPasswordValid) {
          this.get('session').authenticate('authenticator:custom', credentials)['catch'](function (message) {
            console.log(message);
            _this2.set('loginAlertVisible', true);
            _this2.set('error_message_text', 'incorrect password or username');
            _this2.set('password', '');
          });
        }
      } } });
});
//app/controller/login.js

//authenticate
//actions