define('elbe-ember-frond-end/components/duplicate-creative', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isForkingCampaign: false,
    copy_campaign_warning_msg: "",

    showBrands: (function () {
      return this.get('user_selected.orgID') === this.get('session.data.authenticated.org_ID');
    }).property('user_selected'),

    didInsertElement: function didInsertElement() {
      var campaign = this.campaign;
      var width = campaign.format.split('x')[0] | 0;
      var height = campaign.format.split('x')[1] | 0;
      var user = null;
      var brand = this.brand_List.filter(function (b) {
        return b.brandID === campaign.brandID;
      })[0] || null;
      var cu = this.get('session.data.authenticated.user_ID');
      this.get('allUserList').forEach(function (u) {
        if (u.userID === cu) {
          user = u;
        }
      });

      this.set('user_admin_role', this.get('session.data.user_admin_role'));
      this.set('cforkedCampaignName', campaign.name + " - Copy");
      this.set('origCampaignID', campaign.campaignID);
      this.set('user_selected', user);
      this.set('brand_selected', brand);
      this.set('isForkingCampaign', false);
      this.set('copyTemplateVisible', false);
      this.set('copyCampAlertType', 'warning');
      this.set('copy_campaign_warning_msg', '');
      this.set('campaignHeight', height);
      this.set('campaignWidth', width);
      this.set('campaignConvert', false);
      this.set('campaignKeepSelectors', false);

      if (campaign.derivedWizardID) {
        this.set('isSmartbuilder', true);
        this.set('campaignNewFormat', campaign.format);
      } else {
        this.set('isSmartbuilder', false);
      }
    },

    actions: {
      startForkingCampaign: function startForkingCampaign(campaign) {
        console.log(campaign);

        var _this = this;
        var controller = this.parentController;
        var campaignName = this.get('cforkedCampaignName');
        var campaignID = this.get('origCampaignID');
        var transferUserID = this.get('user_selected.userID');
        var brandID = this.get('brand_selected.brandID');
        var campaignFormat = this.get('campaignFormat');
        var width = this.get('campaignWidth');
        var height = this.get('campaignHeight');
        campaignFormat = width + 'x' + height;

        if ($.isNumeric(width) && $.isNumeric(height)) {
          var data = {
            campaignID: campaignID,
            userID: transferUserID,
            brandID: brandID,
            campaignName: campaignName,
            format: campaignFormat,
            isCustomRes: true,
            convertToStudio: this.get('campaignConvert'),
            keepSelectors: this.get('campaignKeepSelectors')
          };

          this.set('isForkingCampaign', true);
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/forkCampaign',
            timeout: 60000,
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json',
            dataType: 'json',
            success: function success(response) {
              controller.notifications.success('Campaign Successfully duplicated', {
                autoClear: true,
                clearDuration: 3000
              });
              _this.set('isForkingCampaign', false);
              _this.doneCloning();
            },
            error: function error(_error) {
              console.log(_error);
              // controller.send('closeCopyCampaign');           
              controller.notifications.error('Error in duplicating campaign, try again later', {
                autoClear: true,
                clearDuration: 3000
              });
              _this.set('isForkingCampaign', false);
              _this.doneCloning();
            }
          });
        } else {
          this.set('copyTemplateVisible', true);
          this.set('copyCampAlertType', 'warning');
          this.set('copy_campaign_warning_msg', 'Not a numeric value in width or height of size');
        }
        //      this.parentController.toggleProperty(this.toggleProperty);
      }
    }
  });
});