define('elbe-ember-frond-end/controllers/overview-live', ['exports', 'ember', 'elbe-ember-frond-end/config/environment', 'elbe-ember-frond-end/utils/timeZone', 'npm:XLSX'], function (exports, _ember, _elbeEmberFrondEndConfigEnvironment, _elbeEmberFrondEndUtilsTimeZone, _npmXLSX) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    remodal: _ember['default'].inject.service(),

    isUpdatingData: false,
    currentDataCount: 0,
    totalDataCount: 0,

    searchTerm: "",

    allCreatives: "on",

    totalCampaigns: 0,
    totalImpressions: 0,
    totalUsers: 0,
    totalOrgs: 0,
    totalCosts: { EUR: 0, USD: 0 },

    realmList: ['live', 'preview', 'zip'],
    realm: "live",

    orgList: [{ name: "All", id: null }],
    org: { name: "All", id: null },

    brandList: [{ name: "All", id: null }],
    brand: { name: "All", id: null },
    formatList: [{ name: "All", id: null }],
    format: { name: "All", id: null },
    userList: [{ name: "All", id: null, email: "" }],
    user: { name: "All", id: null, email: "" },

    startDate: null,
    endDate: null,
    timeZoneOffset: "Z",
    timeZone: "UTC",

    minDate: moment().subtract(480, 'days').startOf('month').toISOString(),

    baseCampaignList: [],
    baseInfo: {
      brands: [],
      formats: []
    },
    campaignList: [],

    queue: [],
    queueCnt: 0,

    pricingCO2: {
      currency: "EUR",
      prices: {
        "tiers": [{ "imp": 3000000 }, { "imp": 10000000 }, { "imp": 25000000 }],
        "categories": [{
          "name": "CO2e Attribution", "maxWeight": 768000, "flags": "", "cpm": 0.08, "tiers": [{ "cpm": 0.07 }, { "cpm": 0.06 }, { "cpm": 0.05 }]
        }]
      }
    },

    reports: [],
    reportQueue: [],
    reportQueueCnt: 0,

    timeZoneSelected: {
      "value": "UTC",
      "abbr": "UTC",
      "offset": 0,
      "isdst": false,
      "text": "(UTC) Coordinated Universal Time",
      "utc": ["America/Danmarkshavn", "Etc/GMT"]
    },
    timeZoneList: _elbeEmberFrondEndUtilsTimeZone['default'].timeZoneList,

    generateReport: function generateReport() {
      var _this = this;
      wceGlue.genReport(this.reports, _npmXLSX['default'], moment, this.startDate, this.endDate, this.timeZoneOffset).then(function (wb) {
        var bookType = "xlsx";
        var ms = moment(_this.startDate).format('YYYY-MM-DD');
        var me = moment(_this.endDate).format('YYYY-MM-DD');
        var ws_name = 'PrimoReport-' + _this.org.name.replace(/ /g, '') + '-' + ms + '--' + me;
        var write_opts = { bookType: bookType };
        _npmXLSX['default'].writeFile(wb, ws_name + "." + bookType, write_opts);
      })['catch'](function (e) {
        console.error(e);
      });
    },

    getSingleFullReport: function getSingleFullReport(creativeID, info) {
      var _this = this;
      var userID = this.get('session.data.authenticated.user_ID');
      var startTS = this.startDate + this.timeZoneOffset;
      var endTS = this.endDate + this.timeZoneOffset;
      var timeZone = this.timeZone;
      var token = this.get('session.data.authenticated.token');
      var baseUrl = _ember['default'].API_URL + "/extern/v3/reporting";
      var env = "production/";

      if (_elbeEmberFrondEndConfigEnvironment['default'].environment === 'development') {
        env = "development/";
      }

      var url = baseUrl + '/' + userID + '/' + creativeID;
      var data = {
        userID: userID,
        creativeID: creativeID,
        startTS: startTS,
        endTS: endTS,
        timeZone: timeZone,
        primoCheck: true
      };

      $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        success: function success(response) {
          if (response && response.ok) {
            _this.reports.push({ id: creativeID, info: info, result: response.result });
          }
          _this.incrementProperty('currentDataCount');
          _this.nextReportQueue();
        },
        error: function error(response) {
          _this.nextReportQueue();
        }
      });
    },

    handleReportQueue: function handleReportQueue() {
      var _this = this;
      if (this.reportQueueCnt < 4) {
        var o = this.reportQueue.shift();
        if (o !== undefined) {
          this.reportQueueCnt++;
          this.getSingleFullReport(o.id, o.info);
        } else {
          this.set('isUpdatingData', false);
          this.generateReport();
        }
      }
    },

    nextReportQueue: function nextReportQueue() {
      this.reportQueueCnt--;
      this.handleReportQueue();
    },

    handleQueue: function handleQueue() {
      var _this2 = this;

      var _this = this;
      if (this.queueCnt < 8) {
        var baseUrl;
        var env;
        var url;

        (function () {
          var o = _this2.queue.shift();
          if (o !== undefined) {
            (function () {
              _this2.queueCnt++;

              var query = {
                "queryType": "groupBy",
                "aggregations": [{
                  "type": "count",
                  "name": "count",
                  "fieldName": "impression"
                }],

                "intervals": [o.startTS + _this2.timeZoneOffset + '/' + o.endTS + _this2.timeZoneOffset],
                "granularity": { "type": "period", "period": "P1D", "timeZone": _this2.timeZone },
                // "granularity": "all",
                "dimensions": ["impression", "openwebsite", "videoplay"]
              };

              baseUrl = "https://druid.visarity.com/report/";
              env = "production/";

              if (_elbeEmberFrondEndConfigEnvironment['default'].environment === 'development') {
                baseUrl = "https://ddruid.visarity.com/report/";
                env = "development/";
              }

              var minImpressions = 1000;
              if (_elbeEmberFrondEndConfigEnvironment['default'].environment === 'development') {
                minImpressions = 10;
              }

              url = baseUrl + env + o.realm + "/" + o.campaignID + "/overview";

              $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: JSON.stringify(query),
                headers: { "Content-Type": "application/json" },
                success: function success(response) {

                  if (response && response[0]) {
                    var i = 0;
                    var c = 0;
                    var v = 0;

                    response.forEach(function (d) {
                      i += d.event.count * (d.event.impression | 0);
                      c += d.event.count * (d.event.openwebsite | 0);
                      v += d.event.videoplay | 0;
                    });

                    _ember['default'].set(o, "low", o.realm === "live" && i < minImpressions);
                    _ember['default'].set(o, "impressions", i);
                    _ember['default'].set(o, "clicks", c);
                    _ember['default'].set(o, "ctr", (c / i * 100).toFixed(2) + "%");
                    _ember['default'].set(o, "hasVideo", !!v);
                  } else {
                    _ember['default'].set(o, "low", true);
                    _ember['default'].set(o, "error", true);
                    _ember['default'].set(o, "impressions", 0);
                    _ember['default'].set(o, "clicks", 0);
                    _ember['default'].set(o, "ctr", "N/A");
                    _ember['default'].set(o, "hasVideo", false);
                  }

                  _ember['default'].set(o, "ready", true);
                  _ember['default'].set(o, "busy", false);
                  _this.nextQueue();
                },
                error: function error(response) {
                  _this.nextQueue();
                }
              });
            })();
          }
        })();
      }
    },

    nextQueue: function nextQueue() {
      this.queueCnt--;
      this.queueOverall--;
      this.handleQueue();

      this.set('fetchCnt', this.queueOverall);

      if (this.queueOverall === 0) {
        this.set('fetchingData', false);
        this.updateCampaignList();
      } else {
        this.set('fetchingData', true);
      }
    },

    sortCampaignList: function sortCampaignList() {
      this.campaignList.sort(function (a, b) {
        return (a.impressions | 0) < b.impressions ? 1 : -1;
      });
    },

    calcTotalImpressions: function calcTotalImpressions() {
      var _this3 = this;

      var tc = 0;
      var i = 0;
      var tcost = { "EUR": 0, "USD": 0 };
      var orgsLUT = {};
      var _this = this;
      var live = this.get('realm') === "live";
      var orgs = [];
      var co2Data = null;

      this.campaignList.forEach(function (e) {
        if (!orgsLUT[e.orgID]) {
          var r = { impressions: 0, impressionsCO2: 0, tier: 0, prices: e.priceID ? _this3.pricesLUT[e.priceID] : null };
          orgsLUT[e.orgID] = r;
          orgs.push(r);
        }
        tc += e.low ? 0 : 1;
        if (e.ready && !e.error) {
          i += e.impressions;
        }
        if (!e.low) {
          orgsLUT[e.orgID].impressions += e.impressions;
        }
        orgsLUT[e.orgID].impressionsCO2 += e.co2 ? e.co2.impressions | 0 : 0;
      });

      orgs.forEach(function (o) {
        if (o.prices) {
          var t = -1;
          if (o.prices.prices.tiers) {
            for (var _i = 0; _i < o.prices.prices.tiers.length; _i++) {
              if (o.impressions < o.prices.prices.tiers[_i].imp) {
                break;
              }
              t = _i;
            }
          }
          o.tier = t;

          if (o.impressionsCO2) {
            t = -1;
            for (var _i2 = 0; _i2 < _this3.pricingCO2.prices.tiers.length; _i2++) {
              if (o.impressionsCO2 < _this3.pricingCO2.prices.tiers[_i2].imp) {
                break;
              }
              t = _i2;
            }
            if (t === -1) {
              o.cpmCO2 = _this3.pricingCO2.prices.categories[0].cpm;
            } else {
              o.cpmCO2 = _this3.pricingCO2.prices.categories[0].tiers[t].cpm;
            }
          }
        }
      });

      if (live) {
        this.campaignList.forEach(function (e) {
          if (e.ready && !e.error && !e.low && e.priceID) {
            try {
              (function () {
                var p = _this.get('pricesLUT')[e.priceID];
                var cat = e.priceCat;

                if (!cat) {
                  p.prices.categories.forEach(function (c) {
                    var ok = e.liveAssetSize < c.maxWeight;
                    if (e.hasVideo) {
                      ok = ok && c.flags.indexOf('video') !== -1;
                    }
                    if (ok && !cat) {
                      cat = c;
                    }
                  });
                  e.priceCat = cat;
                }

                if (cat) {
                  var cpm = cat.cpm;
                  var costs = undefined;
                  var org = orgsLUT[e.orgID];

                  if (org.tier >= 0) {
                    cpm = cat.tiers[org.tier].cpm;
                  }
                  costs = cpm * e.impressions / 1000;
                  tcost[p.currency] += costs;
                  _ember['default'].set(e, "costs", costs.toFixed(2));
                  _ember['default'].set(e, "cpm", cpm);
                  _ember['default'].set(e, "currency", p.currency);
                } else {
                  _ember['default'].set(e, "costs", 0);
                  _ember['default'].set(e, "cpm", 0);
                  _ember['default'].set(e, "currency", 0);
                }
              })();
            } catch (error) {
              console.error(error);
            }
          }

          if (e.co2) {
            var org = orgsLUT[e.orgID];
            co2Data = co2Data || { impressions: 0, kgco2eq: 0, kwh: 0, costs: 0 };
            _ember['default'].set(e.co2, 'cpm', org.cpmCO2);
            _ember['default'].set(e.co2, 'costs', e.co2.impressions * org.cpmCO2 / 1000);
            co2Data.impressions += e.co2.impressions;
            co2Data.kgco2eq += e.co2.kgco2eq;
            co2Data.kwh += e.co2.kwh;
            co2Data.costs += e.co2.costs;
          }
        });

        this.set('totalCosts', tcost);
      }
      this.set('totalCampaigns', tc);
      this.set('totalImpressions', i);

      if (co2Data) {
        this.set('baseInfo.co2Data', co2Data);
      } else {
        this.set('baseInfo.co2Data', null);
      }
    },

    init: function init() {
      var s = moment().startOf('month').toDate();
      var e = moment().endOf('day').toDate();

      var startDate = _elbeEmberFrondEndUtilsTimeZone['default'].toISOString(s);
      var endDate = _elbeEmberFrondEndUtilsTimeZone['default'].toISOString(e);

      this.set('startDate', startDate);
      this.set('endDate', endDate);

      this.fetchReporting();
    },

    druidQueryAllCampaign: function druidQueryAllCampaign() {
      var controller = this;

      var params = {
        startTS: this.startDate + this.timeZoneOffset,
        endTS: this.endDate + this.timeZoneOffset,
        timeZone: this.timeZone
      };

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + "/extern/v3/reporting/overview/" + controller.get('session.data.authenticated.user_ID'),
          type: 'POST',
          dataType: 'json',
          data: JSON.stringify(params),
          headers: {
            "Content-Type": "application/json",
            "Authorization": controller.get('session.data.authenticated.token')
          },
          success: function success(response) {
            resolve(response);
          },
          error: function error(response) {
            reject(response);
          }
        });
      });
    },

    updateCampaignList: (function () {
      var _this4 = this;

      var r = [];
      var l = this.get('baseCampaignList');
      var info = this.get('baseInfo');
      var realm = this.get('realm');
      var st = this.get('searchTerm');

      if (st) {
        st = st.split(" ");
        for (var i = 0; i < st.length; i++) {
          st[i] = st[i].toLowerCase();
        }
      } else {
        st = null;
      }

      var bl = [{ name: "All", id: null }];
      var fl = [{ name: "All", id: null }];
      var ul = [{ name: "All", id: null, email: "" }];
      var ol = [{ name: "All", id: null }];
      var uc = 0;
      var oc = 0;
      var mapUser = {};
      var mapOrg = {};

      var bid = this.get('brand').id;
      var fid = this.get('format').id;

      l.forEach(function (e) {
        if (e.realm === realm && !mapOrg[e.orgID]) {
          ol.push({ name: e.orgName, id: e.orgID });
          mapOrg[e.orgID] = true;
          oc++;
        }
      });

      if (this.org && this.org.id && !mapOrg[this.org.id]) {
        this.set('org', { name: "All", id: null });
      }

      var oid = this.get('org').id;

      info.brands.forEach(function (e) {
        if (oid === null || e.orgID === null || e.orgID === oid) {
          bl.push({ name: e.name, id: e.brandID });
        }
      });

      info.formats.forEach(function (e) {
        if (oid === null || e.orgID === null || e.orgID === oid) {
          fl.push({ name: e.name, id: e.formatID });
        }
      });

      var found = false;
      bl.forEach(function (e) {
        found = found || e.id === bid;
      });
      if (!found) {
        bid = null;
        this.set('brand', { name: "All", id: null });
      }

      found = false;
      fl.forEach(function (e) {
        found = found || e.id === fid;
      });
      if (!found) {
        fid = null;
        this.set('format', { name: "All", id: null });
      }

      l.forEach(function (e) {
        if (e.realm === realm) {
          if (!mapUser[e.userID] && (oid === null || oid === e.orgID)) {
            ul.push({ name: e.userName, id: e.userID, email: e.userEmail });
            mapUser[e.userID] = true;
            uc++;
          }
        }
      });
      if (this.user && this.user.id && !mapUser[this.user.id]) {
        this.set('user', { name: "All", id: null, email: "" });
      }

      var uid = this.get('user').id;

      function checkST(s) {
        var ret = true;
        if (!st) {
          return true;
        }
        s = s.toLowerCase();
        st.forEach(function (t) {
          ret = ret && s.indexOf(t) !== -1;
        });
        return ret;
      }

      this.queueOverall = 0;
      l.forEach(function (e) {
        if (e.realm === realm && (checkST(e.name) || checkST(e.campaignID) || checkST(e.wizardID || "")) && (fid === null || fid === e.formatID) && (bid === null || bid === e.brandID) && (uid === null || uid === e.userID) && (oid === null || oid === e.orgID)) {
          if (!e.ready && !e.busy) {
            e.busy = true;
            e.ready = false;
            e.startTS = _this4.startDate;
            e.endTS = _this4.endDate;
            _this4.queue.push(e);
            _this4.queueOverall++;
            _this4.handleQueue();
          }
          r.push(e);
        }
      });

      var live = realm === "live";

      if (this.allCreatives !== "on" && live) {
        r = r.filter(function (e) {
          return !e.low;
        });
      }

      this.set("totalUsers", uc);
      this.set("totalOrgs", oc);

      this.set("userList", ul);
      this.set("orgList", ol);

      this.set("brandList", bl);
      this.set("formatList", fl);

      this.set('campaignList', r);
      this.sortCampaignList();
      this.calcTotalImpressions();
    }).observes("baseCampaignList", "realm", "searchTerm", "allCreatives"),

    fetchReporting: function fetchReporting() {
      var _this5 = this;

      this.set('isFetchingList', true);
      this.druidQueryAllCampaign().then(function (result) {
        _this5.set('isFetchingList', false);
        _this5.queue = [];
        _this5.set("baseInfo", result.info);
        _this5.set("baseCampaignList", result.result);
      })['catch'](function () {
        _this5.set('isFetchingList', false);
      });
    },

    actions: {
      applyDates: function applyDates(startDate, endDate) {
        startDate = startDate + "T00:00:00.000"; // moment(startDate).startOf('day').toISOString(true);
        endDate = endDate + "T23:59:59.999"; //moment(endDate).endOf('day').toISOString(true);           
        this.set('startDate', startDate);
        this.set('endDate', endDate);
        this.fetchReporting();
      },
      cancelDates: function cancelDates() {},
      openWindow: function openWindow(campaignID, what) {
        switch (what) {
          case "studio":
            window.open(_ember['default'].EDITOR_URL + campaignID + "/" + Date.now(), "");
            break;
          case "smart":
            window.open(_ember['default'].WIZARD_URL + campaignID.wizardID + "?campaignID=" + campaignID.campaignID, "");
            break;
          case "preview":
            window.open(_ember['default'].PREVIEW_URL + "/" + ('?creativeID=' + campaignID), "");
            break;
          case "settings":
            window.open('/proj_settings/' + campaignID, "");
            break;
          default:
            window.open(_ember['default'].ANALYTICS_URL + campaignID + "/" + Date.now(), "");
            break;
        }
      },
      changeRealm: function changeRealm(r) {
        this.set("realm", r);
      },
      changeOrg: function changeOrg(o) {
        this.set("org", o);
        this.set('user', { name: "All", id: null, email: "" });
        this.updateCampaignList();
      },
      changeUser: function changeUser(u) {
        this.set("user", u);
        this.updateCampaignList();
      },
      changeBrand: function changeBrand(b) {
        this.set("brand", b);
        this.updateCampaignList();
      },
      changeFormat: function changeFormat(f) {
        this.set("format", f);
        this.updateCampaignList();
      },
      searchFilterEnter: function searchFilterEnter(searchStr) {
        this.set("searchTerm", searchStr);
      },
      changeTimeZone: function changeTimeZone(v) {
        this.set("timeZoneSelected", v);
        var t = _elbeEmberFrondEndUtilsTimeZone['default'].getTimeZoneAndOffset(v.offset);
        this.set("timeZoneOffset", t.timeZoneOffset);
        this.set("timeZone", t.timeZone);
        this.fetchReporting();
      },
      getFullReport: function getFullReport() {
        var _this6 = this;

        var cl = this.get('campaignList');
        if (cl && cl.length > 0) {
          (function () {
            var q = [];
            cl.forEach(function (c) {
              if (c.cpm === undefined) {
                c.cpm = 0;
              }
              q.push({ id: c.campaignID, info: c });
            });
            _this6.reportQueue = q;
            _this6.reportQueueCnt = 0;
            _this6.reports = [];
            _this6.set('currentDataCount', 0);
            _this6.set('totalDataCount', cl.length);
            _this6.set('isUpdatingData', true);
            _this6.handleReportQueue();
          })();
        }
      }
    }
  });
});