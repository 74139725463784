define('elbe-ember-frond-end/routes/list-publisher', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'elbe-ember-frond-end/utils/formatUtils', 'underscore'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _elbeEmberFrondEndUtilsFormatUtils, _underscore) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'List Publishers',
    actions: {
      error: function error(_error, transition) {
        if (_error && _error.status === 400 || 401) {
          return this.transitionTo('serverError');
        }
      }
    },
    model: function model(params) {
      var controller = this;
      return _ember['default'].$.ajax({
        url: _ember['default'].API_URL + '/publisher/' + controller.get('session.data.authenticated.user_ID'),
        type: 'GET',
        dataType: 'json',
        success: function success(response) {
          //console.log(response);
        },
        error: function error(_error2) {
          reject(_error2);
        }
      }); //ajax
    },
    setupController: function setupController(controller, model) {

      controller.set('publisher_list_data', model);
      controller.set('publisher_list_data_orig', model);
    }
  });
});