define('elbe-ember-frond-end/routes/new-campaign', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    //queryParams: ['campaignID', 'campaignName'],
    campaignID: null,
    campaignName: null,

    title: 'New Creative',

    model: function model(params, transition) {
      this.set('campaignID', transition.queryParams.campaignID);
      this.set('campaignName', transition.queryParams.campaignName);
      return new _ember['default'].RSVP.hash({
        template_data: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/templates?templatedata=1',
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error) {
            console.log(_error);
          }
        }), //ajax
        wizard_data: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/wizard/list',
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error2) {
            console.log(_error2);
          }
        }) //ajax
      });
    },

    setupController: function setupController(controller, model) {
      function genCampaignID() {
        var d = new Date().getTime();
        var uuid = 'v3ad-xxxx-yxxx-xxxx-xxxxx'.replace(/[xy]/g, function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == 'x' ? r : r & 0x3 | 0x8).toString(16);
        });
        return uuid;
      }

      var campaignID = this.campaignID || genCampaignID();
      var name = this.campaignName ? decodeURIComponent(this.campaignName) : "";

      controller.set('title', "New Campaign");
      controller.set('branded_name', "");
      controller.set('campaignName', name || "New Campaign");
      controller.set('campaignID', campaignID);
      controller.set('model', model);
    }
  });
});