define('elbe-ember-frond-end/components/dynamic-container', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        dynSelectors: null,

        didReceiveAttrs: function didReceiveAttrs() {
            this.dynSelectorsUpdate();
            this.dynUpdateVisible();
        },

        dynImages: (function () {
            var ret = this.get('dynItems').filter(function (i) {
                return i.type === "texture" && i.instanceId !== "affeaffeaffeaffeaffeaffeaffeaffe";
            });
            if (ret.length === 0) {
                ret = null;
            }
            return ret;
        }).property('dynItems'),

        dynVideos: (function () {
            var ret = this.get('dynItems').filter(function (i) {
                return i.type === "video";
            });
            if (ret.length === 0) {
                ret = null;
            }
            return ret;
        }).property('dynItems'),

        dynText: (function () {
            var ret = this.get('dynItems').filter(function (i) {
                return i.type === "text";
            });
            if (ret.length === 0) {
                ret = null;
            }
            return ret;
        }).property('dynItems'),

        dynWebsite: (function () {
            var ret = this.get('dynItems').filter(function (i) {
                return i.type === "openwebsite";
            });
            if (ret.length === 0) {
                ret = null;
            }
            return ret;
        }).property('dynItems'),

        dyItemsUpdate: (function () {
            var dyn = this.get('model.dynSelector');
            if (dyn) {
                this.get('dynItems').forEach(function (item) {
                    var id = item.id;
                    if (dyn[id]) {
                        _ember['default'].set(item, 'active', dyn[id].active);
                    }
                });
            }
        }).observes("model.dynSelector"),

        dynSelectorsUpdate: (function () {
            var ret = this.get('dynItems').filter(function (i) {
                return i.type === "selector";
            });
            if (ret.length === 0) {
                ret = null;
            }
            this.set('dynSelectors', ret);
        }).observes('dynItems'),

        dynUpdateVisible: (function () {
            var items = this.get('dynItems');
            var selectors = this.get('dynSelectors');
            var project = JSON.parse(JSON.stringify(this.get('model.project')));
            var dyn = this.get('model.dynSelector');
            if (dyn) {
                dyn = JSON.parse(JSON.stringify(dyn));
            } else {
                dyn = {};
            }

            if (selectors) {
                selectors.forEach(function (sel) {
                    if (sel.selected) {
                        dyn[sel.id] = { keep: true };
                    }
                });
            }
            wceGlue.dynamic(project, dyn);
            wceGlue.selectorOptimiser(project);
            var names = {};
            if (project.elements) {
                project.elements.forEach(function (i) {
                    names[i.id] = i.name;
                });
            }
            if (project.assets) {
                project.assets.forEach(function (i) {
                    names[i.id] = i.name;
                });
            }
            if (project.selectors) {
                project.selectors.forEach(function (i) {
                    names[i.id] = i.name;
                });
            }
            var das = wceGlue.getDynamicAssets(project.elements, project.assets, project.selectors, names);
            items.forEach(function (item) {
                if (item.type !== "selector") {
                    var b = das.filter(function (e) {
                        return e.instanceId === item.instanceId;
                    }).length > 0;
                    _ember['default'].set(item, "visible", b);
                }
            });
        }).observes('dynSelectors.@each.selected', 'model.dynSelector', 'dynItems'),

        actions: {
            selectAll: function selectAll() {
                this.get('dynItems').forEach(function (i) {
                    _ember['default'].set(i, 'selected', true);
                });
            },
            unSelectAll: function unSelectAll() {
                this.get('dynItems').forEach(function (i) {
                    _ember['default'].set(i, 'selected', false);
                });
            }
        }
    });
});