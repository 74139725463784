define("elbe-ember-frond-end/components/code-editor", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    value: null,
    codeMirror: null,
    ta: null,

    didInsertElement: function didInsertElement() {
      this.ta = document.getElementById("code-text");
      this.ta.value = this.value || "";
      this.codeMirror = CodeMirror.fromTextArea(this.ta, {
        lineNumbers: true,
        mode: "application/json",
        gutters: ["CodeMirror-lint-markers"],
        lint: { options: { esversion: 2021 } }
      });

      if (this.ta.value) {
        this.ta.parentElement.style.visibility = "visible";
        this.ta.parentElement.style.height = null;
      }
    },

    showCode: (function () {
      var r = this.get('value');
      console.log(r);
      var ret = !!r;
      if (ret && this.ta) {
        this.ta.parentElement.style.visibility = "visible";
        this.ta.parentElement.style.height = null;
      }
      return ret;
    }).property('value'),

    actions: {
      save: function save() {
        if (this.save) {
          this.save.call(this.base, this.codeMirror.getValue());
        }
      },
      create: function create() {
        if (this.save) {
          var code = '(function () {\n' + '  var dyn = primoADS.dyn = primoADS.dyn || {};\n' + '\n' + ' if (primoADS.cbStartPrimo) {\n' + '   //Primo has already finished init phase\n' + '   primoADS.cbStartPrimo();\n' + ' } else {\n' + '   //Primo still in init phase\n' + '   primoADS.cbStartPrimo = function (start) { start(); };\n' + '}\n' + '})();\n';

          this.set('value', code);
          this.codeMirror.setValue(code);
        }
      }

    }
  });
});