define('elbe-ember-frond-end/controllers/template-list', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        session: _ember['default'].inject.service('session'),
        remodal: _ember['default'].inject.service(),
        init: function init() {},
        actions: {
            updateTemplateJSON: function updateTemplateJSON(templateData) {
                //console.log(templateData);
                //console.log(templateData.campaignID);
                /*
                if ((templateData.campaignID != undefined) || (templateData.campaignID != null)) {
                  if (Ember.Environment === "development") {
                      window.location.href = Ember.EDITOR_URL + templateData.campaignID + '/' + Date.now() + '/' + window.btoa(localStorage.getItem('ember_simple_auth:session'));
                  } else {
                      window.location.href = Ember.EDITOR_URL + templateData.campaignID + '/' + Date.now();
                  }
                }else{
                  this.notifications.success('This is a pre-defined template and cannot be edited', {
                      autoClear: true,
                      clearDuration: 3800
                  });
                }
                */
                var controller = this;
                _ember['default'].$.ajax({
                    url: _ember['default'].API_URL + '/updateTemplateJSON/' + templateData.templateID,
                    type: 'GET',
                    dataType: 'json',
                    success: function success(response) {
                        controller.notifications.success('Template Updated', {
                            autoClear: true,
                            clearDuration: 3400
                        });
                        _ember['default'].$.ajax({
                            url: _ember['default'].API_URL + '/templates?templatedata=0',
                            type: 'GET',
                            dataType: 'json',
                            success: function success(response) {
                                controller.set('template_list_data', response);
                            },
                            error: function error(_error2) {}
                        });
                    },
                    error: function error(_error) {
                        controller.notifications.error('COuld not update, try later', {
                            autoClear: true,
                            clearDuration: 2600
                        });
                    }
                }); //ajax
            },
            fetchTemplates: function fetchTemplates() {
                var controller = this;
                _ember['default'].$.ajax({
                    url: _ember['default'].API_URL + '/templates?templatedata=0',
                    type: 'GET',
                    dataType: 'json',
                    success: function success(response) {
                        controller.set('template_list_data', response);
                    },
                    error: function error(_error3) {}
                }); //ajax
            },
            deleteTemplateFromDB: function deleteTemplateFromDB(template) {
                //console.log(template);
                var controller = this;
                var t_data = this.get('template_list_data');
                $('#' + template.templateID).closest('tr').children('td').animate({
                    padding: 0
                }).wrapInner('<div />').children().slideUp(200, function () {
                    $(this).closest('tr').remove();
                });
                _ember['default'].run.later(function () {
                    _ember['default'].$.ajax({
                        url: _ember['default'].API_URL + '/template/' + template.templateID,
                        type: 'DELETE',
                        contentType: 'application/json;charset=utf-8',
                        dataType: 'json',
                        success: function success(response) {
                            controller.notifications.success('Template Deleted', {
                                autoClear: true,
                                clearDuration: 2200
                            });
                            controller.send('fetchTemplates');
                            //console.log(data.length);
                            //console.log(response);
                        },
                        error: function error(_error4) {
                            console.log(_error4);
                        }
                    }); //ajax
                }, 200);
            },
            showPreview: function showPreview(template) {
                //console.log(template);
                var controller = this;
                this.set('getPreview', true);
                this.set('showPreview', false);
                var modal = this.get('remodal.preview-template-modal');
                modal.setProperties({
                    title: 'Template preview for : ' + template.name + ' ...',
                    closeOnOutsideClick: false,
                    closeOnEscape: true,
                    disableNativeClose: false,
                    onCancel: 'notifyPreviewClosed',
                    modalClasses: "remodal-header"
                });
                var timeStamp = new Date().getTime();
                var iframe = '<iframe src="' + template.demo_previewURL + '?timestamp=' + timeStamp + '" style="width:' + template.format.split('x')[0] + 'px; height:' + template.format.split('x')[1] + 'px" name="preview" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" vspace="0" hspace="0"></iframe>';
                controller.set('embedPreview', iframe);
                modal.open();

                _ember['default'].run.later(function () {
                    controller.set('showPreview', true);
                    controller.set('getPreview', false);
                }, 1500);
            },
            notifyPreviewClosed: function notifyPreviewClosed() {
                this.set('embedPreview', '');
                this.set('showPreview', false);
            },
            editTemplateSettings: function editTemplateSettings(templateData) {
                this.transitionToRoute('template-settings', templateData.templateID);
            }
        }
    });
});