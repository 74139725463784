define('elbe-ember-frond-end/routes/login', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    title: 'Primo Login',
    actions: {
      error: function error(_error, transition) {
        if (_error && _error.status === 400 || 401) {
          return this.transitionTo('serverError');
        }
      }
    }
  });
});
//app/routes/login.js