define('elbe-ember-frond-end/controllers/edit-proj-settings', ['exports', 'elbe-ember-frond-end/config/environment', 'ember', 'elbe-ember-frond-end/utils/formatUtils', 'elbe-ember-frond-end/utils/shared'], function (exports, _elbeEmberFrondEndConfigEnvironment, _ember, _elbeEmberFrondEndUtilsFormatUtils, _elbeEmberFrondEndUtilsShared) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  /********************************************************************/
  function publishCampaignLiveToS3(campaignID, controller) {
    _ember['default'].$.ajax({
      url: _ember['default'].API_URL + '/publish/' + campaignID + "/live",
      type: 'GET',
      success: function success(response) {
        controller.set('isPublishingLive', false);
        controller.set("campaignStatus", 4);
        controller.notifications.success('Campaign is now live, create Ad TAGs ', {
          autoClear: true,
          clearDuration: 3200
        });
        controller.send("purgeCDN");
      },
      error: function error(_error) {
        controller.set('isCampaignPublishing', false);
        controller.set('isSavingImage', false);
        controller.set('isPublishingLive', false);
        controller.set('isPublishBtnDisabled', false);
        //            upDateStatus(campaignID, 3);
        controller.notifications.error('Server error, retry after sometime', {
          autoClear: true,
          clearDuration: 2200
        });
      }
    });
    _ember['default'].$.ajax({
      url: _ember['default'].API_URL + '/publish/' + campaignID + "/updatepreview",
      type: 'GET',
      success: function success(response) {},
      error: function error(_error2) {
        // upDateStatus(campaignID, 3);
        controller.notifications.error('Server error, retry after sometime', {
          autoClear: true,
          clearDuration: 2200
        });
      }
    });
  }

  /********************************************************************/
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    user_admin_role: null,
    needs: ['campaignParams'],
    isSavingImage: false,
    isCampaignPublishing: false,
    isBusy: false,
    hasb64Image: false,
    dummySplash: false,
    brandList: [],

    placements: _elbeEmberFrondEndUtilsShared['default'].placements,

    distributionRegion: ['World-Wide', 'Europe', 'NorthAmerica', 'Asia'],
    fileListStatusOptions: ['preview', 'live'],
    db_brandID: '',
    splashName: null,
    splashPreviewUrl: null,
    campaignUserID: null,

    shareCategories: null,

    other_value0: null,
    other_value1: null,
    other_value2: null,
    other_value3: null,
    other_value4: null,
    other_value5: null,

    iabformat: null,

    serviceType_value: null,

    set_name: "",

    serviceTypeCategories: [{ name: "Standard", id: "standard" }, { name: "Richmedia", id: "richmedia" }, { name: "Custom", id: "custom" }],

    typeCategoriesBanner: [{ name: "Medium-Rectangle", id: "mr" }, { name: "Billboard", id: "bb" }, { name: "Half-Page", id: "hp" }, { name: "Skyscraper", id: "ss" }, { name: "Leaderboard", id: "lp" }],

    pricingCO2: {
      currency: "EUR",
      prices: {
        "tiers": [{ "imp": 3000000 }, { "imp": 10000000 }, { "imp": 25000000 }],
        "categories": [{
          "name": "CO2e Attribution", "maxWeight": 768000, "flags": "", "cpm": 0.08, "tiers": [{ "cpm": 0.07 }, { "cpm": 0.06 }, { "cpm": 0.05 }]
        }]
      }
    },

    showCO2Pricing: false,

    showCO2Tracking: (function () {
      return this.get('model.campaignData.status') >= 4;
    }).property('model.campaignData'),

    autoGenFallback: _ember['default'].computed('model.campaignData.splash_image_custom', {
      get: function get() /*key*/{
        return this.get('model.campaignData.splash_image_custom') ? "off" : "on";
      },
      set: function set(key, value) {
        // jshint ignore:line
        var controller = this;
        var v = value !== 'on' ? 1 : null;
        this.set('model.campaignData.splash_image_custom', v);

        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/update/' + controller.get('campaignID'),
          type: 'POST',
          data: {
            splash_image_custom: v
          },
          success: function success() /*response*/{
            controller.notifications.success('fallback auto gen saved', { autoClear: true, clearDuration: 2200 });
          },
          error: function error(_error3) {
            controller.notifications.error('Server error, retry after sometime', {
              autoClear: true,
              clearDuration: 2200
            });
          }
        });

        return value;
      }
    }),

    firstDeviceGet: true,
    devices: _ember['default'].computed('model.campaignData.devices', {
      get: function get() /*key*/{
        var r = [];
        var d = this.get('model.campaignData.devices');
        if (d) {
          r = d.split(' ');
        }
        return r;
      },
      set: function set(key, value) {
        // jshint ignore:line
        var v = value.join(' ');
        if (this.firstDeviceGet) {
          this.firstDeviceGet = false;
        } else {
          this.set('model.campaignData.devices', v);
          this.send('saveCampaign');
        }
        return value;
      }
    }),

    placement: _ember['default'].computed({
      get: function get() /*key*/{
        var p = this.get('model.campaignData.placement');
        var ret = this.placementsLUT[p];
        return ret;
      },
      set: function set(key, value) {
        // jshint ignore:line
        this.set('model.campaignData.placement', value.id);
        return value;
      }
    }),

    showDevices: _ember['default'].computed('model.campaignData.placement', {
      get: function get() /*key*/{
        var ret = false;
        var d = this.get('model.campaignData.placement');

        console.log(d);

        if (d === 'INFEED') {
          ret = true;
        } else if (d) {
          this.set('model.campaignData.devices', 'PHONE');
        } else {
          this.set('model.campaignData.devices', null);
        }
        return ret;
      },
      set: function set(key, value) {
        // jshint ignore:line
        return value;
      }
    }),

    init: function init() {
      var user_role = JSON.parse(localStorage.getItem('ember_simple_auth:session')).authenticated.user_role;
      this.set('user_admin_role', this.get('session.data.user_admin_role'));
      this.set('user_role', user_role);
      this.set('isBusy', false);
      this.set('isSavingImage', false);
      this.set('isPublishingLive', true);

      this.observerCampaignStatus();

      /*
      if (this.user_admin_role) {
        this.placements.push(
          { name: 'Prime Time', id: "PRIMETIME" }
        );          
      }
        */

      var lut = [];
      this.placements.forEach(function (e) {
        lut[e.id] = e;
      });
      this.set('placementsLUT', lut);

      //prevent  return keystroke
      $(function () {
        var keyStop = {
          8: ":not(input:text, textarea, input:file, input:password)", // stop backspace = back
          13: "input:text, input:password", // stop enter = submit

          end: null
        };
        $(document).bind("keydown", function (event) {
          var selector = keyStop[event.which];

          if (selector !== undefined && $(event.target).is(selector)) {
            event.preventDefault(); //stop event
          }
          return true;
        });
      });
    },

    saveDynScriptCode: function saveDynScriptCode(code) {
      var controller = this;

      // console.log("observesDynScriptCode");
      // console.log(this.get('model.campaignData.dynScript'));

      var campaignParams = {
        dynScript: code
      };

      //console.log('dummy splash present');
      //just update campaign params
      _ember['default'].$.ajax({
        url: _ember['default'].API_URL + '/campaigns/update/' + controller.get('campaignID'),
        type: 'POST',
        data: campaignParams,
        success: function success() /*response*/{
          controller.set('isBusy', false); // disable save button
          //controller.notifications.clearAll();
          controller.notifications.success('dynamic script saved', { autoClear: true, clearDuration: 2200 });
        },
        error: function error(_error4) {
          console.log(_error4);
          controller.set('isSavingImage', false);
          controller.notifications.error('Server error, retry after sometime', {
            autoClear: true,
            clearDuration: 2200
          });
        }
      });
    },

    observerCampaignStatus: (function () {
      var liveUrl = 'https://live.primoadserver.com/campaigns/' + this.campaignID + '/index.html';

      if (_ember['default'].Environment !== "production") {
        liveUrl = 'https://devlive.visarity.com/campaigns/' + this.campaignID + '/index.html';
      }
      switch (this.get('campaignStatus')) {
        case 0:
        case 1:
          this.set('label_class', "label label-primary");
          this.set('label_fa_class', "fa fa-star");
          this.set('label_name', "   NEW  ");
          this.set('isEditorDiabled', false);
          this.set('showLiveURL', false);
          break;
        case 2:
        case 3:
          this.set('label_class', "label label-info");
          this.set('label_fa_class', "fa fa-pencil");
          this.set('label_name', "    IN EDITING  ");
          this.set('splash_isDisabled', false);
          this.set('startDateDisabled', false);
          this.set('endDateDisabled', false);
          this.set('volumeDisabled', false);
          this.set('formatDisabled', false);
          this.set('splashDisabled', false);
          this.set('isPublishBtnDisabled', false);
          this.set('isEditorDiabled', false);
          this.set('isADTagDisabled', true);
          this.set('isStopButtonDisabled', true);
          this.set('showLiveURL', false);
          break;
        case 4:
          this.set('label_class', "label label-success");
          this.set('label_fa_class', "fa fa-calendar");
          this.set('label_name', "   LIVE  ");
          this.set('splash_isDisabled', true);
          this.set('startDateDisabled', true);
          this.set('endDateDisabled', true);
          this.set('volumeDisabled', true);
          this.set('formatDisabled', true);
          this.set('splashDisabled', true);
          this.set('isPublishBtnDisabled', true);
          this.set('isEditorDiabled', true);
          this.set('isADTagDisabled', false);
          this.set('isStopButtonDisabled', false);
          this.set('showLiveURL', liveUrl);
          break;
        case 5:
          this.set('label_class', "label label-default");
          this.set('label_fa_class', "fa fa-calendar");
          this.set('label_name', "   SCHEDULED  ");
          this.set('splash_isDisabled', true);
          this.set('startDateDisabled', true);
          this.set('endDateDisabled', true);
          this.set('volumeDisabled', true);
          this.set('formatDisabled', true);
          this.set('splashDisabled', true);
          this.set('isPublishBtnDisabled', true);
          this.set('isEditorDiabled', true);
          this.set('isADTagDisabled', false);
          this.set('isStopButtonDisabled', false);
          this.set('showLiveURL', liveUrl);
          break;
        case 6:
          this.set('label_class', "label label-danger");
          this.set('label_fa_class', "fa fa-stop");
          this.set('label_name', "   STOPPED  ");
          this.set('splash_isDisabled', true);
          this.set('startDateDisabled', true);
          this.set('endDateDisabled', true);
          this.set('volumeDisabled', true);
          this.set('formatDisabled', true);
          this.set('splashDisabled', true);
          this.set('isPublishBtnDisabled', true);
          this.set('isEditorDiabled', true);
          this.set('isADTagDisabled', true);
          this.set('isStopButtonDisabled', true);
          this.set('showLiveURL', false);
          break;
        default:
      }
    }).observes('campaignStatus'),

    observeIndustry: (function () {
      var _this = this;

      function get2(p, s) {
        var ret = _this.get(p);
        if (ret) {
          ret = ret.id;
        }
        return ret ? s ? " " + ret : ret : "";
      }

      var industry = get2('industry_value0') + get2('industry_value1', true) + get2('industry_value2', true);

      industry = industry.trim().replace(/ +(?= )/g, '');

      if (this.get('shareCategories')) {
        this.set('shareCategories.industry', industry);
        this.saveShareCategories();
      }

      // console.log(industry);
    }).observes('industry_value0', 'industry_value1', 'industry_value2'),

    observeOther: (function () {
      var _this = this;

      function get2(p, s) {
        var ret = _this.get(p);
        if (ret) {
          ret = ret.id;
        }
        return ret ? s ? " " + ret : ret : "";
      }

      var other = get2('other_value0') + get2('other_value1', true) + get2('other_value2', true) + get2('other_value3', true) + get2('other_value4', true) + get2('other_value5', true);

      other = other.trim().replace(/ +(?= )/g, '');

      if (this.get('shareCategories')) {
        this.set('shareCategories.other', other);
        this.saveShareCategories();
      }

      console.log(other);
    }).observes('other_value0', 'other_value1', 'other_value2', 'other_value3', 'other_value4', 'other_value5'),

    saveShareCategories: function saveShareCategories() {
      var shareCategories = this.get('shareCategories');

      var _this = this;

      function replacer(key, value) {
        if (key.indexOf("_") === 0) {
          return undefined;
        }
        return value;
      }

      function get(p, s) {
        var ret = _this.get(p);
        return ret ? s ? " " + ret : ret : "";
      }

      function get2(p, s) {
        var ret = _this.get(p).id;
        return ret ? s ? " " + ret : ret : "";
      }

      if (shareCategories) {
        var iabformat = get('iabformat');
        var galleryName = get('galleryName');

        if (galleryName === this.get('camapaignName')) {
          galleryName = null;
        }

        _ember['default'].set(shareCategories, "galleryName", galleryName);
        _ember['default'].set(shareCategories, "iabformat", iabformat);
        _ember['default'].set(shareCategories, "set", this.get('set_name'));
        _ember['default'].set(shareCategories, "assets", this.get('assets'));
        _ember['default'].set(shareCategories, "serviceType", this.get('serviceType_value'));

        console.log("shareCategories: " + JSON.stringify(shareCategories, replacer));

        var controller = this;

        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/update/' + controller.get('campaignID'),
          type: 'POST',
          data: JSON.stringify({ shareCategories: shareCategories }),
          contentType: 'application/json',
          dataType: 'json',
          success: function success() /*response*/{
            controller.notifications.success('campaign data saved', { autoClear: true, clearDuration: 2200 });
          },
          error: function error(_error5) {
            console.log(_error5);
            //this.set('checkedGalleryShare', state);
          }
        });
        //this.set('checkedReadOnlyState', state);
      }
    },

    observesshareCategories: (function () {
      this.saveShareCategories();
    }).observes("iabformat", "triggerSave", "set_name", "serviceType_value", "assets.@each.src"),

    sendToDruid: function sendToDruid(api, realm) {
      var baseUrl = "https://druid.visarity.com/";

      if (_elbeEmberFrondEndConfigEnvironment['default'].environment !== 'production') {
        baseUrl = "https://ddruid.visarity.com/";
      }

      var env = _ember['default'].Environment === "production" ? "production" : "development";
      var campaignID = this.get('campaignID');
      var auth = this.get('session.data.authenticated.user_ID');

      var url = baseUrl + api + "/" + env + "/" + realm + "/" + campaignID;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        $.ajax({
          url: url,
          type: 'GET',
          //dataType: 'json',
          //data: null,

          headers: {
            "Authorization": auth
          },

          success: function success(response) {
            resolve(response);
          },
          error: function error(response, status) {
            reject(response);
          }
        });
      });
    },

    actions: {
      togglePricing: function togglePricing() {
        this.toggleProperty('showCO2Pricing');
      },
      saveCategories: function saveCategories() {
        this.saveShareCategories();
      },
      addAllCreativeImages: function addAllCreativeImages() {
        var a = this.get('assets');
        this.get('dynamicAssets').forEach(function (image) {
          a.pushObject({
            id: new UUID(4).format(),
            type: "image",
            src: image.url,
            fileName: image.fileName,
            name: image.name
          });
        });
      },
      addCreativeImage: function addCreativeImage(image) {
        this.get('assets').pushObject({
          id: new UUID(4).format(),
          type: "image",
          src: image.url,
          fileName: image.fileName,
          name: image.name
        });
      },
      uploadFile: function uploadFile(files, index) {
        var controller = this;
        var formData = new FormData();
        formData.append('campaignID', this.get('campaignID'));
        formData.append('userID', this.get('campaignUserID'));
        formData.append('file', files[0]);

        console.log(files);
        console.log('index: ' + index);

        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/uploadFile',
          data: formData,
          type: 'POST',
          context: this,
          contentType: false,
          processData: false,
          mimeType: "multipart/form-data",
          success: function success(response) {
            console.log(response);
            response = JSON.parse(response);
            var a = controller.get('assets').objectAt(index);
            _ember['default'].set(a, 'src', response.assetURL);
            _ember['default'].set(a, 'fileName', response.name);
            _ember['default'].set(a, 'fileType', response.fileType);
            _ember['default'].set(a, 'sizeInBytes', response.size);
            controller.notifications.success('Image uploaded', {
              autoClear: true,
              clearDuration: 3200
            });
          },
          error: function error() {
            controller.notifications.error('Could not upload image', {
              autoClear: true,
              clearDuration: 3200
            });
          }
        });
      },
      upAssetsImage: function upAssetsImage(index) {
        var a = this.get('assets');
        var b = [].concat(_toConsumableArray(a));

        var e1 = b[index - 1];
        var e2 = b[index];

        b[index - 1] = e2;
        b[index] = e1;
        this.set('assets', b);
      },
      downAssetsImage: function downAssetsImage(index) {
        var a = this.get('assets');
        var b = [].concat(_toConsumableArray(a));

        var e1 = b[index + 1];
        var e2 = b[index];

        b[index + 1] = e2;
        b[index] = e1;
        this.set('assets', b);
      },
      removeAssetsImage: function removeAssetsImage(index) {
        this.get('assets').removeAt(index);
      },
      changeImageName: function changeImageName() {
        this.toggleProperty('triggerSave');
      },
      addAssetsImage: function addAssetsImage() {
        this.get('assets').pushObject({
          id: new UUID(4).format(),
          type: "image",
          src: "https://ms.visarity.com/templates/CampaignManager_Image_Placeholder.jpg",
          name: "Image"
        });
      },
      changeFormat: function changeFormat(format) {
        this.set('format', format);
        this.send('saveCampaign');
      },
      changeBrand: function changeBrand(brand) {
        this.set('brand', brand);
        this.send('saveCampaign');
      },
      reportingUnuse: function reportingUnuse() {
        var controller = this;
        this.sendToDruid('unuse', 'live').then(function () {
          controller.notifications.success('Live Reporting Dropped', {
            autoClear: true,
            clearDuration: 3200
          });
        }, function () {
          controller.notifications.error('Could not drop live reporting', {
            autoClear: true,
            clearDuration: 3200
          });
        });
        this.sendToDruid('unuse', 'zip').then(function () {
          controller.notifications.success('Zip Reporting Dropped', {
            autoClear: true,
            clearDuration: 3200
          });
        }, function () {
          controller.notifications.error('Could not drop zip reporting', {
            autoClear: true,
            clearDuration: 3200
          });
        });
        this.sendToDruid('unuse', 'preview').then(function () {
          controller.notifications.success('Preview Reporting Dropped', {
            autoClear: true,
            clearDuration: 3200
          });
        }, function () {
          controller.notifications.error('Could not drop Preview reporting', {
            autoClear: true,
            clearDuration: 3200
          });
        });
      },
      reportingUse: function reportingUse() {
        var controller = this;
        this.sendToDruid('use', 'live').then(function () {
          controller.notifications.success('Live Reporting Reloaded', {
            autoClear: true,
            clearDuration: 3200
          });
        }, function () {
          controller.notifications.error('Could not reload live reporting', {
            autoClear: true,
            clearDuration: 3200
          });
        });
        this.sendToDruid('use', 'zip').then(function () {
          controller.notifications.success('Zip Reporting Reloaded', {
            autoClear: true,
            clearDuration: 3200
          });
        }, function () {
          controller.notifications.error('Could not reload zip reporting', {
            autoClear: true,
            clearDuration: 3200
          });
        });
        this.sendToDruid('use', 'preview').then(function () {
          controller.notifications.success('Preview Reporting Reloaded', {
            autoClear: true,
            clearDuration: 3200
          });
        }, function () {
          controller.notifications.error('Could not reload Preview reporting', {
            autoClear: true,
            clearDuration: 3200
          });
        });
      },
      reportingKill: function reportingKill() {
        var controller = this;

        this.sendToDruid('kill', 'live').then(function () {
          controller.notifications.success('Live Reporting Completely Deleted', {
            autoClear: true,
            clearDuration: 3200
          });
        }, function () {
          controller.notifications.error('Could not delete live reporting', {
            autoClear: true,
            clearDuration: 3200
          });
        });

        this.sendToDruid('kill', 'zip').then(function () {
          controller.notifications.success('Zip Reporting Completely Deleted', {
            autoClear: true,
            clearDuration: 3200
          });
        }, function () {
          controller.notifications.error('Could not delete zip reporting', {
            autoClear: true,
            clearDuration: 3200
          });
        });

        this.sendToDruid('kill', 'preview').then(function () {
          controller.notifications.success('Preview Reporting Completely Deleted', {
            autoClear: true,
            clearDuration: 3200
          });
        }, function () {
          controller.notifications.error('Could not delete preview reporting', {
            autoClear: true,
            clearDuration: 3200
          });
        });
      },
      createAdTag: function createAdTag() {
        this.transitionToRoute('tagGen', this.get('campaignID')).then(function () {});
      },
      editCampaign: function editCampaign() {
        //console.log('browser detected  : ' + detectBrowser());
        var campaignID = this.get('campaignID');
        var campaignStatus = this.get('campaignStatus');
        if (!_elbeEmberFrondEndUtilsFormatUtils['default'].detectBrowser()) {
          this.set('isShowingBrowserModal', true);
        } else {
          if (campaignStatus === 1) {
            this.transitionToRoute('template', campaignID);
          } else {
            if (_ember['default'].Environment === "development") {
              window.location.href = _ember['default'].EDITOR_URL + campaignID + '/' + Date.now() + '/' + window.btoa(localStorage.getItem('ember_simple_auth:session'));
            } else {
              window.location.href = _ember['default'].EDITOR_URL + campaignID + '/' + Date.now();
            }
          }
        }
      },
      changeCampaignStatusEdit: function changeCampaignStatusEdit() {
        var controller = this;
        var campaignParams = {
          campaignID: this.get('campaignID'),
          status: 3
        };

        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/update/' + controller.get('campaignID'),
          type: 'POST',
          data: campaignParams,
          success: function success(response) {
            controller.set('label_class', "label label-primary");
            controller.set('label_fa_class', "fa fa-edit");
            controller.set('label_name', "   IN EDITING ");
            controller.set('splash_isDisabled', false);
            controller.set('startDateDisabled', false);
            controller.set('endDateDisabled', false);
            controller.set('volumeDisabled', false);
            controller.set('formatDisabled', true);
            controller.set('splashDisabled', false);
            controller.set('isPublishBtnDisabled', false);
            controller.notifications.success('Campaign Status changed to Edit, you need to purge and republish', {
              autoClear: true,
              clearDuration: 3200
            });
          },
          error: function error(_error6) {
            controller.notifications.error('Could not change status, contact admin', {
              autoClear: true,
              clearDuration: 3200
            });
          }
        });
      },
      updateCampaignData: function updateCampaignData(campaignID) {
        var controller = this;
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/find/' + campaignID,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            controller.set('campaignID', response.campaignID);
            controller.set('camapaignName', response.name);
            controller.set('brandID', response.brandID);
            controller.set('campaignFormat', response.format);
            controller.set('campaignParams', response);
            controller.set('campaign_model', response);
            controller.set('campaignStatus', response.status);
            controller.set('derivedTemplateID', response.derivedTemplateID);
            controller.set('checkedReadOnlyState', response.readonly);
            if (response.readonly != null) {
              controller.set('readOnlyCampaignValue', response.readonly);
            } else {
              controller.set('readOnlyCampaignValue', false);
              controller.set('checkedReadOnlyState', false);
            }
            controller.notifications.success('campaign data saved', { autoClear: true, clearDuration: 2200 });
          },
          error: function error(_error7) {
            //console.log(error);
          }
        }); //ajax
      },
      purgeCDN: function purgeCDN() {
        var controller = this;
        _ember['default'].run.later(function () {
          controller.notifications.success('CDN Purge initiated. It will take 15 min to clear cache', {
            autoClear: true,
            clearDuration: 3200
          });
        }, 800);

        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/purge/' + controller.get('campaignID') + '/World-Wide',
          type: 'GET',
          success: function success(response) {},
          error: function error(_error8) {
            controller.notifications.error('Could not pruge CDN , try again later', {
              autoClear: true,
              clearDuration: 3200
            });
          }
        });
      },
      stopCampaign: function stopCampaign() {
        var controller = this;
        var campaignParams = {
          campaignID: this.get('campaignID'),
          status: 6
        };

        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/stop/' + controller.get('campaignID'),
          type: 'GET',
          data: campaignParams,
          success: function success(response) {
            _ember['default'].$.ajax({
              url: _ember['default'].API_URL + '/campaigns/update/' + controller.get('campaignID'),
              type: 'POST',
              data: campaignParams,
              success: function success(response) {
                controller.set("campaignStatus", 6);
                controller.send("purgeCDN");
              },
              error: function error(_error10) {}
            });
          },
          error: function error(_error9) {
            controller.notifications.error('Could not STOP campaign , try again later', {
              autoClear: true,
              clearDuration: 3200
            });
          }
        });
      },
      preloadCDN: function preloadCDN() {
        var controller = this;
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/preloadContent/' + controller.get('campaignID') + '/World-Wide',
          type: 'GET',
          success: function success() /*response*/{
            controller.notifications.success('Pre-Load initiated, wait for 10 min', {
              autoClear: true,
              clearDuration: 3200
            });
          },
          error: function error(_error11) {
            console.log(_error11);
            controller.notifications.error('Could not pre-load CDN , try again later', {
              autoClear: true,
              clearDuration: 3200
            });
          }
        });
      },
      rePublish: function rePublish() {
        var controller = this;
        var campaignParams = {
          status: 4
        };
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/purge/' + controller.get('campaignID') + '/World-Wide',
          type: 'GET',
          success: function success() /*response*/{
            controller.notifications.success('RePublish Initiated, wait for 5 min', {
              autoClear: true,
              clearDuration: 3200
            });
          },
          error: function error(_error12) {
            console.log(_error12);
            controller.notifications.error('Could not pruge CDN , try again later', {
              autoClear: true,
              clearDuration: 3200
            });
          }
        });

        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/update/' + controller.get('campaignID'),
          type: 'POST',
          data: campaignParams,
          success: function success() /*response*/{
            //controller.set('isSavingImage', false); //trigger splash animation
            publishCampaignLiveToS3(controller.get('campaignID'), controller);
          },
          error: function error(_error13) {
            console.log(_error13);
            controller.notifications.error('Could not republish campaign , try again later', {
              autoClear: true,
              clearDuration: 3200
            });
          }
        });
      },

      publishLiveCampaign: function publishLiveCampaign() /*campaignData*/{
        var controller = this;
        var campaignName_Valid = false,
            startDate_Valid = true,
            endDate_Valid = true,
            impression_Valid = true,
            brand_Valid = true,
            splash_Valid = false,
            campaign_status = false,
            campaignFormat_Valid = false,
            template_splash_Valid = false;

        var campaignParams = {
          startDate: moment(this.get('campaign_startDate')).format("YYYY-MM-DD"),
          endDate: moment(this.get('campaign_endDate')).format("YYYY-MM-DD"),
          booked_imp: this.get('impression_volume'),
          comments: this.get('campaign_description'),
          campaignID: this.get('campaignID'),
          format: this.get('campaignFormat'),
          name: this.get('camapaignName'),
          userID: this.get('campaignUserID'),
          derivedTemplateID: this.get('derivedTemplateID'),
          splashPreviewUrl: this.get('splashPreviewUrl')
        };

        var campaignStatus = this.get('campaign_model.status');
        if (campaignStatus > 1) {
          campaign_status = true;
        } else {
          this.notifications.warning('Creative data not present, choose a template', {
            autoClear: true,
            clearDuration: 3200
          });
        }

        if (campaignParams.name.length < 3) {
          this.notifications.warning('Campaign Name should be atleast 3 characters long', {
            autoClear: true,
            clearDuration: 3200
          });
        } else {
          campaignName_Valid = true;
        }

        if (campaignParams.format === undefined || campaignParams.format === null) {
          this.notifications.warning('Select one of the canvas sizes for the creative', {
            autoClear: true,
            clearDuration: 3200
          });
        } else {
          campaignFormat_Valid = true;
        }

        var orgData = this.get('orgData');
        //console.log(orgData);

        //console.log('dplash present      : ' + this.get('dummySplash'));
        if (this.get('dummySplash')) {
          this.notifications.warning('The campaign uses a default splash,kindly upload a new one ', {
            autoClear: true,
            clearDuration: 3800
          });
        } else {
          splash_Valid = true;
        }

        saveAndPublishLive(controller);

        function saveAndPublishLive(controller) {
          if (startDate_Valid && endDate_Valid && impression_Valid && campaignFormat_Valid && campaignName_Valid && brand_Valid && splash_Valid && campaign_status) {
            //just update campaign params
            //console.log('campaignparams ' + campaignParams);
            controller.set('isPublishingLive', true);
            controller.set('isPublishBtnDisabled', true);
            controller.set('isEditorDisabled', true);
            controller.set('isADTagDisabled', true);
            controller.set('isStopButtonDisabled', true);
            _ember['default'].$.ajax({
              url: _ember['default'].API_URL + '/campaigns/update/' + controller.get('campaignID'),
              type: 'POST',
              data: campaignParams,
              success: function success(response) {
                //controller.set('isSavingImage', false); //trigger splash animation
                publishCampaignLiveToS3(campaignParams.campaignID, controller);
              },
              error: function error(_error14) {
                console.log(_error14);
                controller.set('isCampaignPublishing', false);
                controller.set('isSavingImage', false);
                controller.set('isPublishingLive', false);
                controller.set('isPublishBtnDisabled', false);
                controller.set('isEditorDisabled', false);
                controller.set('isADTagDisabled', true);
                //upDateStatus(controller.get('campaignID'), 3);
                controller.notifications.error('Server error, retry after sometime', {
                  autoClear: true,
                  clearDuration: 2200
                });
              }
            });
          }
        }

        //there was a b64 image but user changed the splash to new file
      },
      didSelectFiles: function didSelectFiles(fileList) {
        var _this2 = this;

        var re = new RegExp('image/*');
        var controller = this;
        if (re.test(fileList[0].type)) {
          var fd;

          (function () {
            controller.set('splashName', fileList[0].name);
            var reader = new FileReader();
            reader.onloadend = function () {
              controller.set('splashPreviewUrl', reader.result);
            };
            reader.readAsDataURL(fileList[0]);
            //console.log(fileList[0])
            controller.set('isSavingImage', true);
            fd = new FormData();

            fd.append('campaignID', _this2.get('campaignID'));
            fd.append('userID', _this2.get('campaignUserID'));
            fd.append('image', fileList[0]);
            _ember['default'].$.ajax({
              url: _ember['default'].API_URL + '/campaigns/updatesplash',
              type: 'POST',
              data: fd,
              contentType: false,
              processData: false,
              mimeType: "multipart/form-data",
              success: function success() /*response*/{
                controller.set('model.campaignData.splash_image_custom', 1);
                controller.set('isSavingImage', false);
                controller.set('dummySplash', false);
                controller.notifications.success('Splash image successfully uploaded', {
                  autoClear: true,
                  clearDuration: 3200
                });
                // controller.send('updateCampaignData', controller.get('campaignID'));
              },
              error: function error(_error15) {
                console.log(_error15);
                controller.set('isSavingImage', false); //trigger splash animation
                controller.set('dummySplash', true);
                controller.notifications.error('Server Error retry again', {
                  autoClear: true,
                  clearDuration: 2200
                });
                //console.log(error);
              }
            }); //ajax
          })();
        } else {
            controller.notifications.error('File must be a JPG or PNG image', {
              autoClear: true,
              clearDuration: 3800
            });
          }
      },
      saveCampaign: function saveCampaign() /*campaignData*/{
        var controller = this;

        var campaignParams = {
          comments: this.get('campaign_description'),
          brandID: this.get('brand.brandID') || null,
          formatID: this.get('format.formatID') || null,
          campaignID: this.get('campaignID'),
          format: this.get('campaignFormat'),
          name: this.get('camapaignName'),
          userID: this.get('campaignUserID'),
          placement: this.get('model.campaignData.placement') || null,
          devices: this.get('model.campaignData.devices') || null
        };

        console.log(campaignParams);
        if (campaignParams.startDate === null || campaignParams.startDate === undefined) {
          campaignParams.startDate = moment().format("YYYY-MM-DD");
        }
        if (campaignParams.endDate === null || campaignParams.endDate === undefined) {
          campaignParams.endDate = moment().add(30, 'days').format("YYYY-MM-DD");
        }
        if (campaignParams.booked_imp === null || campaignParams.booked_imp === undefined) {
          campaignParams.booked_imp = 1000;
        }

        if (campaignParams.format === null || campaignParams.format === undefined) {
          campaignParams.format = "300x600";
        }
        if (campaignParams.name === null || campaignParams.name === undefined) {
          campaignParams.name = "My name is Hello World";
        }

        //console.log('dummy splash present');
        //just update campaign params
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/update/' + controller.get('campaignID'),
          type: 'POST',
          data: campaignParams,
          success: function success() /*response*/{
            controller.set('isBusy', false); // disable save button
            //controller.notifications.clearAll();
            controller.notifications.success('campaign data saved', { autoClear: true, clearDuration: 2200 });
          },
          error: function error(_error16) {
            console.log(_error16);
            controller.set('isSavingImage', false);
            controller.notifications.error('Server error, retry after sometime', {
              autoClear: true,
              clearDuration: 2200
            });
          }
        });
      },
      makeCampaignReadOnly: function makeCampaignReadOnly(state) {
        var controller = this;

        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/update/' + controller.get('campaignID'),
          type: 'POST',
          data: JSON.stringify({
            campaignID: controller.get('campaignID'),
            readonly: state
          }),
          contentType: 'application/json',
          dataType: 'json',
          success: function success() /*response*/{
            controller.set('checkedReadOnlyState', state);
            controller.notifications.success('campaign data saved', { autoClear: true, clearDuration: 2200 });
          },
          error: function error(_error17) {
            //this.set('checkedGalleryShare', state);
          }
        });
        //this.set('checkedReadOnlyState', state);
      },
      setPlacement: function setPlacement(p) {
        this.set('placement', p);
        this.send('saveCampaign');
      }
    } //actions
  });
});
//controller/edit-proj-settings.js