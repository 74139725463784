define('elbe-ember-frond-end/controllers/dashboard', ['exports', 'ember', 'underscore', 'elbe-ember-frond-end/config/environment', 'elbe-ember-frond-end/utils/formatUtils', 'ember-cli-pagination/computed/paged-array', 'elbe-ember-frond-end/utils/timeZone'], function (exports, _ember, _underscore, _elbeEmberFrondEndConfigEnvironment, _elbeEmberFrondEndUtilsFormatUtils, _emberCliPaginationComputedPagedArray, _elbeEmberFrondEndUtilsTimeZone) {

  function filterCombo(controller) {
    var brandLUT = controller.get('model.brand_lut');
    var formatLUT = controller.get('model.format_lut');
    var data_orig = controller.get('model.campaign_data');
    var searchBlock = controller.get('filterComboParams');
    var filteredList;
    var otherCategories = [{ name: "150KB", id: "skb" }, { name: "2.5D", id: "3db" }, { name: "Animation", id: "anim" }, { name: "Best Of", id: "boc" }, { name: "Branding", id: "bnd" }, { name: "Compare", id: "cmp" }, { name: "Countdown", id: "cnt" }, { name: "Custom", id: "cust" }, { name: "Desktop", id: "des" }, { name: "DOOH", id: "ooh" }, { name: "Dynamic", id: "dyn" }, { name: "eCommerce", id: "ecom" }, { name: "Gamification", id: "game" }, { name: "Hero", id: "hero" }, { name: "Gyro", id: "gyr" }, { name: "Mobile", id: "mob" }, { name: "Multivariant", id: "mul" }, { name: "Performance", id: "perf" },
    // { name: "PrimoStudio", id: "std" },
    { name: "Real 3D", id: "msh" }, { name: "Retargeting", id: "rtg" }, { name: "Responsive", id: "resp" },
    //  { name: "SmartBuilder", id: "smt" },
    { name: "Special FX", id: "sfx" }, { name: "Special placement", id: "spec" }, { name: "Video", id: "vid" }, { name: "Xmas", id: "xmas" }];

    var industryCategories = [{ name: "Automotive", id: "cars" }, { name: "Beauty", id: "beauty" }, { name: "Books & Magazines", id: "book" }, { name: "Commodities", id: "com" }, { name: "DIY", id: "diy" }, { name: "eCommerce", id: "ecom" }, { name: "Electronics", id: "edev" }, { name: "Entertainment", id: "enter" }, { name: "Fashion", id: "fash" }, { name: "Finance", id: "fin" }, { name: "FMCG", id: "fmcg" }, { name: "Food", id: "food" }, { name: "Furniture", id: "furn" }, { name: "Games", id: "games" }, { name: "Luxury Goods", id: "luxury" }, { name: "Movies & TV", id: "mtv" }, { name: "QSR", id: "qsr" }, { name: "Rental", id: "rent" }, { name: "Retail", id: "ret" }, { name: "Sports", id: "sport" }, { name: "Sweets & Candies", id: "candy" }, { name: "Telecom", id: "tele" }, { name: "Theme Parks", id: "tp" }, { name: "Toys", id: "toys" }, { name: "Travel", id: "travel" }];

    var otherLUT = {};
    var industryLUT = {};

    otherCategories.forEach(function (e) {
      return otherLUT[e.id] = e.name;
    });
    industryCategories.forEach(function (e) {
      return industryLUT[e.id] = e.name;
    });

    function getNames(s, l) {
      var ret = "";
      if (s && typeof s === "string") {
        var a = s.split(' ');
        a.forEach(function (b) {
          ret += l[b] ? " " + l[b] : "";
        });
      }
      return ret;
    }

    if (searchBlock.searchTerm) {
      var st = searchBlock.searchTerm.split(" ");
      var searchFilteredList = [];

      for (var j = 0; j < data_orig.length; j++) {
        var o = data_orig[j];
        var s = o.name + " " + o.campaignID + " " + o.format;
        var found = true;

        if (o.formatID) {
          s += formatLUT[o.formatID] ? formatLUT[o.formatID].name : "";
        } else if (o.derivedWizardID) {
          s += formatLUT[o.derivedWizardID] ? formatLUT[o.derivedWizardID].name : "";
        }

        if (o.brandID) {
          s += brandLUT[o.brandID] ? brandLUT[o.brandID].name : "";
        }

        if (o.shareCategories) {
          s += getNames(o.shareCategories.industry, industryLUT);
          s += getNames(o.shareCategories.other, otherLUT);
        }

        s = s.toLowerCase();
        for (var i = 0; i < st.length; i++) {
          var t = st[i].toLowerCase();
          found = found && s.indexOf(t) !== -1;
        }
        if (found) {
          searchFilteredList.push(o);
        }
      }
      filteredList = searchFilteredList;
    } else {
      filteredList = data_orig;
    }

    if (searchBlock.format) {
      filteredList = _underscore['default'].filter(filteredList, function (obj) {
        return obj.format === searchBlock.format;
      });
    }

    if (searchBlock.creativeFormat) {
      filteredList = _underscore['default'].filter(filteredList, function (obj) {
        return obj.formatID === searchBlock.creativeFormat || obj.derivedWizardID === searchBlock.creativeFormat;
      });
    }

    if (searchBlock.brand) {
      filteredList = _underscore['default'].filter(filteredList, function (obj) {
        return obj.brandID === searchBlock.brand;
      });
    }

    if (searchBlock.status) {
      if (searchBlock.status < 100) {
        filteredList = _underscore['default'].filter(filteredList, function (obj) {
          return obj.status === searchBlock.status;
        });
      } else {
        filteredList = _underscore['default'].filter(filteredList, function (obj) {
          if (searchBlock.status === 100) {
            return !!obj.readonly;
          }
          return !!obj.shareOnGallery;
        });
      }
    } else {
      filteredList = _underscore['default'].filter(filteredList, function (obj) {
        return obj.status <= 4;
      });
    }

    if (searchBlock.user) {
      filteredList = _underscore['default'].filter(filteredList, function (obj) {
        return obj.userID === searchBlock.user;
      });
    }

    if (searchBlock.org) {
      filteredList = _underscore['default'].filter(filteredList, function (obj) {
        return obj.orgID === searchBlock.org;
      });
    }

    controller.set('campaign_data', filteredList);
  }

  function updateCampaignData(controller, model) {
    var userID = model.userID;

    var data = model.campaign_data;
    var usernames = _underscore['default'].pluck(data, 'userID');
    model.user_List.forEach(function (e) {
      usernames.push(e.userID);
    });
    usernames = _underscore['default'].uniq(usernames);

    var username_1 = [];

    var x;
    var mapUsers = {};
    usernames.forEach(function (u) {
      var x = model.user_List.filter(function (e) {
        return e.userID === u && e.userID !== userID;
      })[0];
      if (x) {
        username_1.push(x);
        mapUsers[x.userID] = x;
      }
    });

    username_1.sort(function (a, b) {
      if (a.fullName < b.fullName) {
        return -1;
      }
      if (a.fullName > b.fullName) {
        return 1;
      }
      return 0;
    });

    x = model.user_List.filter(function (e) {
      return e.userID === userID;
    })[0];
    if (x) {
      username_1.unshift(x);
      mapUsers[x.userID] = x;
    }

    username_1.push({ fullName: "All User", orgID: null });
    _ember['default'].set(model, "user_list_sorted", username_1);

    var format = _underscore['default'].uniq(data, function (obj) {
      return obj.format;
    });
    var formats_1 = _underscore['default'].pluck(format, 'format');

    data.forEach(function (c) {
      c.userRef = mapUsers[c.userID];
    });

    formats_1 = _underscore['default'].reject(formats_1, _underscore['default'].isNull);

    formats_1.sort(function (a, b) {
      var ret = 1;
      if (a && b) {
        var aw = a.split("x")[0] | 0;
        var ah = a.split("x")[1] | 0;
        var bw = b.split("x")[0] | 0;
        var bh = b.split("x")[1] | 0;
        if (aw < bw) {
          ret = -1;
        } else if (aw === bw) {
          if (ah < bh) {
            ret = -1;
          } else {
            ret = 1;
          }
        } else {
          ret = 1;
        }
      }
      return ret;
    });
    formats_1.unshift("Any");

    controller.set('filterFormatArray', formats_1);

    if (model.user_role.permission === 'admin') {
      controller.set('user_admin_role', true);
      controller.get('session').set('data.user_admin_role', true);
    }

    model.user_List.sort(function (a, b) {
      if (a.fullName < b.fullName) {
        return -1;
      }
      if (a.fullName > b.fullName) {
        return 1;
      }
      return 0;
    });
  }

  function refreshCampaignData(admin, controller) {
    if (admin) {
      _ember['default'].$.ajax({
        url: _ember['default'].API_URL + ('/campaigns/detailsAdmin/' + encodeURIComponent(controller.get('startDate')) + '/' + encodeURIComponent(controller.get('endDate'))),
        type: 'GET',
        dataType: 'json',
        success: function success(response) {
          controller.set('model.campaign_data', response);
          updateCampaignData(controller, controller.model);
          filterCombo(controller);
        },
        error: function error(_error) {
          console.log(_error);
        }
      });
    } else {
      _ember['default'].$.ajax({
        url: _ember['default'].API_URL + ('/campaigns/details/' + controller.get('session.data.authenticated.user_ID') + '/' + encodeURIComponent(controller.get('startDate')) + '/' + encodeURIComponent(controller.get('endDate'))),
        type: 'GET',
        dataType: 'json',
        success: function success(response) {
          controller.set('model.campaign_data', response);
          updateCampaignData(controller, controller.model);
          filterCombo(controller);
        },
        error: function error(_error2) {
          console.log(_error2);
        }
      });
    }
  }

  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    remodal: _ember['default'].inject.service(),
    queryParams: ["page"],
    model: null,
    page: 1,
    perPage: 15,
    current_campaignData: null,
    campaign_data: [],
    clipboard: null,
    user_role: null,
    sortByCol: null,
    user_admin_role: false,
    isShowingCopyModal: false,
    isShowingShareModal: false,
    isShowingTemplateErrorModal: false,
    campaignTypeOptions: ['banner', 'interstitial', '3D Video', '360 Video', 'VR Interactive'],
    filterStatusArray: ['Any', 'New', 'In Editing', 'Live', 'Stopped', 'Protected', 'Archived'],
    template_alert_visible: false,
    allUserList: [],
    filterParamsArray: ['Campaign Name', 'Creative Size', 'Campaign Status', 'Shared Campaigns', 'Any'],
    selectedFilterParam: 'Campaign Name',
    pagedContent: (0, _emberCliPaginationComputedPagedArray['default'])('campaign_data', { perPage: 25 }),
    filterUserOptsArray: null,
    filterOrgOptsArray: null,
    filterFormatArray: null,
    sortCampaignName: 1,
    sortByUser: 1,
    sortByStartDate: 1,
    sortStatus: 1,
    sortCampaignFormat: 1,
    sortCampaignLastEdit: 0,
    sortCampaignLastPreviewGen: 0,
    sortGalleryEnabled: 1,
    sortTemplateEnabled: 1,
    searchTerm: "",
    selFormatParam: null,
    selStatusParam: null,
    selFilterUser: null,
    selFilterOrg: null,
    selFilterCreativeFormat: null,
    selFilterBrand: null,
    activeUserList: [],
    wizardFormatArrays: { "IMAGEFX": ["300x250", "300x600", "320x480", "120x600", "160x600", "300x150", "320x320", "336x280", "970x250"] },
    wizardFormatArray: ["300x250", "300x600"],

    pageBinding: 'pagedContent.page',
    perPageBinding: 'pagedContent.perPage',
    totalPagesBinding: 'pagedContent.totalPages',
    filterComboParams: {
      'searchTerm': null,
      'format': null,
      'status': null,
      'user': null,
      'org': null,
      'creativeFormat': null,
      'brand': null
    },
    fKeyPressed: false,
    _KeyHandlerDown: function _KeyHandlerDown(e) {
      if (e.keyCode === 70) {
        this.fKeyPressed = true;
      }
      this.altKey = e.altKey;
      return true;
    },
    _KeyHandlerUp: function _KeyHandlerUp(e) {
      if (e.keyCode === 70) {
        this.fKeyPressed = false;
      }
      this.altKey = e.altKey;
      return true;
    },

    init: function init() {
      var controller = this;
      this.clipboard = new Clipboard('.btn-copy-url');

      document.addEventListener('keydown', this._KeyHandlerDown.bind(this));
      document.addEventListener('keyup', this._KeyHandlerUp.bind(this));

      var exp = moment(this.get('session.data.authenticated.exp'));
      //    var login_at = moment(this.get('session.data.authenticated.login_at'));
      if (this.get('session.data.user_admin_role')) {
        this.set('filterParamsArray', ['Campaign Name', 'User Name', 'Creative Size', 'Campaign Status', 'Shared Campaigns', 'Template Campaigns', 'Any']);
        this.set('filterStatusArray', ['Any', 'New', 'In Editing', 'Live', 'Stopped', 'Protected', 'From Desktop', 'Shared', 'Archived']);
      }

      var currentTime = moment();

      _ember['default'].run.later(function () {
        //controller.set('selectedFilterParam', 'Campaign Name');
        //console.log('browser detected  : ' + Utils.detectBrowser());
        if (!_elbeEmberFrondEndUtilsFormatUtils['default'].detectBrowser()) {
          controller.set('isShowingBrowserModal', true);
        }
      }, 800);
      if (moment(exp).isBefore(currentTime)) {}
      //show the session has expired and reload
      //this.get('session').invalidate();

      //this.set('start-date', moment().subtract(30, 'days'));
      //this.set('end-date', moment());
    },

    campaignDataObserver: (function () {
      var p = this.get('page');
      var l = this.get('campaign_data');
      l = l.length / 20;
      if (p > l) p = 1;

      //console.log("campaignDataObserver: " + p);
      this.set('pagedContent', (0, _emberCliPaginationComputedPagedArray['default'])('campaign_data', { perPage: 20, page: p }));
    }).observes('campaign_data'),

    show_tag_gen: (function () {
      return this.get('session.data.user_admin_role') || this.get('model.org_data.billingType').toLowerCase() !== "subscription";
    }).property(),

    show_download: (function () {
      return this.get('session.data.user_admin_role') || this.get('model.org_data.billingType').toLowerCase() === "subscription";
    }).property(),

    readonly_download: (function () {
      var user_role = JSON.parse(localStorage.getItem('ember_simple_auth:session')).authenticated.user_role;
      var ur = this.get('user_role');
      return this.get('user_role.permission') === 'demo';
    }).property('show_download'),

    initModel: function initModel(model) {
      var _this = this;

      this.set('model', model);

      updateCampaignData(this, model);

      this.set('allUserList', model.user_List);

      if (model.org_list) {
        model.org_list.unshift({ name: "All" });
        this.set('filterOrgOptsArray', model.org_list);
      }

      if (model.wizard_List) {
        (function () {
          var a = {};
          model.wizard_List.forEach(function (w) {
            a[w.wizardID] = w.formats;
          });
          _this.set('wizardFormatArrays', a);
        })();
      }
      this.updateUserList();
      filterCombo(this);
    },

    updateUserList: function updateUserList() {
      var _this2 = this;

      if (!this.selFilterOrg) {
        this.set('filterUserOptsArray', this.model.user_list_sorted);
      } else {
        var a = this.model.user_list_sorted.filter(function (e) {
          return e.orgID === null || e.orgID === _this2.selFilterOrg.orgID;
        });
        this.set('filterUserOptsArray', a);
      }

      var t = this.get('filterUserOptsArray').filter(function () {
        return true;
      });
      t.pop();
      this.set('activeUserList', t);
    },

    actions: {
      toNewGallery: function toNewGallery() {
        window.open("/galleries", "_self");
      },
      showGallery: function showGallery(gallery) {
        window.open(_ember['default'].GALLERY_URL + '/?galleryID=' + gallery.galleryID);
      },
      editGallery: function editGallery(gallery) {
        console.log(gallery);
        window.open('update_gallery/' + gallery.galleryID);
      },
      chooseNewFormat: function chooseNewFormat(e) {
        this.set('campaignNewFormat', e);
      },
      chooseFormatfilterParams: function chooseFormatfilterParams(param) {
        var searchBlock = this.get('filterComboParams');
        this.set('selFormatParam', param);
        if (param === "Any") {
          param = null;
          this.set('selFormatParam', null);
        }
        _ember['default'].set(searchBlock, 'format', param);
        filterCombo(this);
      },
      chooseStatusfilterParams: function chooseStatusfilterParams(param) {
        var searchBlock = this.get('filterComboParams');
        if (!param) {
          _ember['default'].set(searchBlock, 'status', null);
          param = null;
        } else if (param.toLowerCase() === "new") {
          _ember['default'].set(searchBlock, 'status', 1);
        } else if (param.toLowerCase() === "in editing") {
          _ember['default'].set(searchBlock, 'status', 3);
        } else if (param.toLowerCase() === "live") {
          _ember['default'].set(searchBlock, 'status', 4);
        } else if (param.toLowerCase() === "stopped") {
          _ember['default'].set(searchBlock, 'status', 6);
        } else if (param.toLowerCase() === "archived") {
          _ember['default'].set(searchBlock, 'status', 7);
        } else if (param.toLowerCase() === "from desktop") {
          _ember['default'].set(searchBlock, 'status', 10);
        } else if (param.toLowerCase() === 'protected') {
          _ember['default'].set(searchBlock, 'status', 100);
        } else if (param.toLowerCase() === 'shared') {
          _ember['default'].set(searchBlock, 'status', 101);
        } else {
          _ember['default'].set(searchBlock, 'status', null);
          param = null;
        }
        this.set('selStatusParam', param);
        filterCombo(this);
      },
      chooseUserfilterParams: function chooseUserfilterParams(param) {
        var searchBlock = this.get('filterComboParams');
        if (param === null || param === undefined || param.fullName === "All User") {
          _ember['default'].set(searchBlock, 'user', null);
          this.set('selFilterUser', null);
        } else {
          _ember['default'].set(searchBlock, 'user', param.userID);
          this.set('selFilterUser', param);
        }
        filterCombo(this);
      },
      chooseCreativeFormatfilterParams: function chooseCreativeFormatfilterParams(param) {
        var searchBlock = this.get('filterComboParams');
        if (param === null || param === undefined || !param.formatID) {
          _ember['default'].set(searchBlock, 'creativeFormat', null);
          this.set('selFilterCreativeFormat', null);
        } else {
          _ember['default'].set(searchBlock, 'creativeFormat', param.formatID);
          this.set('selFilterCreativeFormat', param);
        }
        filterCombo(this);
      },
      chooseBrandfilterParams: function chooseBrandfilterParams(param) {
        var searchBlock = this.get('filterComboParams');
        if (param === null || param === undefined || !param.brandID) {
          _ember['default'].set(searchBlock, 'brand', null);
          this.set('selFilterBrand', null);
        } else {
          _ember['default'].set(searchBlock, 'brand', param.brandID);
          this.set('selFilterBrand', param);
        }
        filterCombo(this);
      },
      chooseOrgfilterParams: function chooseOrgfilterParams(param) {
        var searchBlock = this.get('filterComboParams');
        _ember['default'].set(searchBlock, 'user', null);
        this.set('selFilterUser', null);

        if (param === null || param === undefined || param.name === "All") {
          _ember['default'].set(searchBlock, 'org', null);
          this.set('selFilterOrg', null);
        } else {
          _ember['default'].set(searchBlock, 'org', param.orgID);
          this.set('selFilterOrg', param);
        }
        this.updateUserList();
        filterCombo(this);
      },
      sortCampaignNameAction: function sortCampaignNameAction() {
        var filteredList = '';
        var campaignList = this.get('campaign_data');
        filteredList = _underscore['default'].sortBy(campaignList, function (obj) {
          return obj.name;
        });
        if (this.get('sortCampaignName')) {
          this.set('campaign_data', filteredList);
        } else {
          this.set('campaign_data', filteredList.reverse());
        }
        this.set('sortByCol', 'sortCampaignName');
        this.toggleProperty('sortCampaignName');
      },
      sortByStartDateAction: function sortByStartDateAction() {
        var filteredList = '';
        var campaignList = this.get('campaign_data');
        filteredList = _underscore['default'].sortBy(campaignList, function (obj) {
          return obj.startDate;
        });
        if (this.get('sortByStartDate')) {
          this.set('campaign_data', filteredList);
        } else {
          this.set('campaign_data', filteredList.reverse());
        }
        this.set('sortByCol', 'sortByStartDate');
        this.toggleProperty('sortByStartDate');
      },
      sortLastPreviewGenAction: function sortLastPreviewGenAction() {
        var filteredList = '';
        var campaignList = this.get('campaign_data');
        // filteredList = _.chain(campaignList).sortBy('preview_gen_date').partition('preview_gen_date').flatten().value();
        filteredList = _underscore['default'].sortBy(campaignList, function (obj) {
          return obj.preview_gen_date ? obj.preview_gen_date : "2010";
        });

        if (this.get('sortCampaignLastPreviewGen')) {
          this.set('campaign_data', filteredList);
        } else {
          this.set('campaign_data', filteredList.reverse());
        }
        this.set('sortByCol', 'previewGenDate');
        this.toggleProperty('sortCampaignLastPreviewGen');
      },
      sortCampaignLastEditAction: function sortCampaignLastEditAction() {
        var filteredList = '';
        var campaignList = this.get('campaign_data');
        filteredList = _underscore['default'].sortBy(campaignList, function (obj) {
          return obj.editedCampaignAt;
        });
        if (this.get('sortCampaignLastEdit')) {
          this.set('campaign_data', filteredList);
        } else {
          this.set('campaign_data', filteredList.reverse());
        }
        this.set('sortByCol', 'lastEdit');
        this.toggleProperty('sortCampaignLastEdit');
      },
      sortCampaignFormatAction: function sortCampaignFormatAction() {
        var filteredList = this.get('campaign_data').map(function (e) {
          return e;
        });

        filteredList.sort(function (_a, _b) {
          var ret = 0;
          var a = _a.format;
          var b = _b.format;
          if (a && b) {
            var aw = a.split("x")[0] | 0;
            var ah = a.split("x")[1] | 0;
            var bw = b.split("x")[0] | 0;
            var bh = b.split("x")[1] | 0;
            if (aw < bw) {
              ret = -1;
            } else if (aw === bw) {
              if (ah < bh) {
                ret = -1;
              } else {
                ret = 1;
              }
            } else {
              ret = 1;
            }
          }
          return ret;
        });

        /*
        filteredList = _.sortBy(campaignList, function (obj) {
          return obj.format;
        });
        */
        if (this.get('sortCampaignFormat')) {
          this.set('campaign_data', filteredList);
        } else {
          this.set('campaign_data', filteredList.reverse());
        }
        this.set('sortByCol', 'format');
        this.toggleProperty('sortCampaignFormat');
      },
      copyQRCode: function copyQRCode() {
        var qrdiv = document.getElementById("contains-qr-code").children[0].children[1];

        var link = document.createElement("a");
        link.download = "QRCode " + this.current_preview_campaign.name + "-" + this.current_preview_campaign.campaignID;
        link.href = qrdiv.src;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        //      delete link;

        console.log(qrdiv);
      },
      copyShortURL: function copyShortURL(shareURL) {
        var controller = this;
        //console.log(shareURL);
        var url = shareURL;
        var clipboard = new Clipboard('.btn-copy-url');
        clipboard.on('success', function (url) {
          controller.notifications.clearAll();
        });
        clipboard.on('error', function (shareURL) {});
      },
      clearFilterFields: function clearFilterFields() {
        this.set('searchTerm', '');
        this.set('selFormatParam', null);
        this.set('selStatusParam', null);
        this.set('selFilterUser', null);
        this.set('selFilterOrg', null);
        this.set('selFilterCreativeFormat', null);
        this.set('selFilterBrand', null);

        var searchBlock = this.get('filterComboParams');
        _ember['default'].set(searchBlock, 'searchTerm', null);
        _ember['default'].set(searchBlock, 'format', null);
        _ember['default'].set(searchBlock, 'status', null);
        _ember['default'].set(searchBlock, 'user', null);
        _ember['default'].set(searchBlock, 'org', null);
        _ember['default'].set(searchBlock, 'creativeFormat', null);

        filterCombo(this);
        document.getElementById("clearFilters").blur();
      },
      /*
          loadAll: function () {
            var _this = this;
            Ember.$.ajax({
              url: Ember.API_URL + '/campaigns/detailsAdmin?all=yes',
              type: 'GET',
              dataType: 'json',
              success: function (response) { },
              error: function (error) {
                console.log(error);
              }
            }).then(r => {
              console.log(r);
              _this.set('model.campaign_data', r);
              filterCombo(_this);
            });
          },
      */
      searchTermChange: function searchTermChange(searchTerm) {
        this.set('filterComboParams.searchTerm', searchTerm ? searchTerm.toLowerCase() : null);
        filterCombo(this);
      },

      /*
      searchFilterEnter: function (searchStr) {
        searchStr = searchStr.toLowerCase();
        var campaignList = this.get('campaign_data_orig');
        var searchCriteriaSelected = this.get('selectedFilterParam');
        var filteredList = '';
        if (searchCriteriaSelected === 'Campaign Name') {
          filteredList = _.filter(campaignList, function (obj) {
            return obj.name.toLowerCase().indexOf(searchStr) >= 0;
          });
        }
        if (searchCriteriaSelected === 'User Name') {
          filteredList = _.filter(campaignList, function (obj) {
            return obj.userName.toLowerCase().indexOf(searchStr) >= 0;
          });
        }
        if (searchCriteriaSelected === 'Creative Size') {
          filteredList = _.filter(campaignList, function (obj) {
            return obj.format.toLowerCase().indexOf(searchStr) >= 0;
          });
        }
        if (searchCriteriaSelected === 'Campaign Status') {
          if (searchStr === 'live') {
            filteredList = _.filter(campaignList, function (obj) {
              return obj.status === 4;
            });
          }
           if (searchStr === 'in editing') {
            filteredList = _.filter(campaignList, function (obj) {
              return ((obj.status >= 2) && (obj.status <= 3));
            });
          }
        }
        this.set('campaign_data', filteredList);
         if (searchStr === '') {
          this.set('campaign_data', campaignList);
          this.set('searchTerm', '');
        }
       },
      */

      getAnalytics: function getAnalytics(campaignsData) {
        //this.transitionToRoute('camapign-analytics',campaignsData);
        var shortID = _elbeEmberFrondEndUtilsFormatUtils['default'].generateShortID(6);
        window.open("analytics/" + campaignsData.campaignID + '/' + shortID);
        /*
        this.transitionToRoute('camapign-analytics', campaignsData.campaignID, shortID).then(function (analytics) {
          //controller.get('session').set('data.analytics', campaignsData);
          analytics.set('campaignID', campaignsData.campaignID);
          analytics.set('gesture_count', '0');
          analytics.set('click_through_rate', '0');
          analytics.set('impression_count', '0');
          analytics.set('hr_24_impression_count', '0');
        });
        */
      },
      genPreview: function genPreview(campaignData) {
        var controller = this;

        var purge = function purge() {
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/purge/' + campaignData.campaignID + '/preview',
            type: 'GET',
            success: function success(response) {
              console.log("purge done.");
            },
            error: function error(_error3) {
              console.log(_error3);
            }
          });
        };

        _ember['default'].set(campaignData, "_genBusy", true);
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/publish/' + campaignData.campaignID + '/preview?oneFile=1',
          type: 'GET',
          contentType: 'application/json',
          dataType: 'json',
          success: function success(response) {
            _ember['default'].set(campaignData, "_genBusy", false);
            _ember['default'].set(campaignData, "preview_gen_date", response.preview_gen_date);
            _ember['default'].set(campaignData, "previewAssetSize", response.previewAssetSize);
            _ember['default'].set(campaignData, "demo_previewURL", response.demo_previewURL);
            _ember['default'].set(campaignData, "shortURL", response.shortURL);

            _ember['default'].set(campaignData, "splash_image", response.splash_image);
            _ember['default'].set(campaignData, "thumbnail", response.thumbnail);

            purge();
            controller.notifications.success('Creative-Preview produced successfully.', {
              autoClear: true,
              clearDuration: 3000
            });
          },
          error: function error(_error4) {
            _ember['default'].set(campaignData, "_genBusy", false);
            controller.notifications.error('Error! Creative-Preview not produced. Try it later.', {
              autoClear: true,
              clearDuration: 3000
            });
            console.log(_error4);
          }
        }); //ajax    
      },

      applyDates: function applyDates(startDate, endDate) {
        //this.set('campaign_startDate', startDate);
        //this.set('campaign_endDate', endDate);
        var s = moment(startDate).startOf('day').toDate();
        var e = moment(endDate).endOf('day').toDate();

        startDate = _elbeEmberFrondEndUtilsTimeZone['default'].toISOString(s);
        endDate = _elbeEmberFrondEndUtilsTimeZone['default'].toISOString(e);

        this.set('startDate', startDate);
        this.set('endDate', endDate);
        refreshCampaignData(this.get('session.data.user_admin_role'), this);
      },
      cancelDates: function cancelDates() {},
      copyPreviewLink: function copyPreviewLink(campaignData) {
        var url = campaignData.demo_previewURL;
        if (campaignData.shortURL) {
          url = "http://" + campaignData.shortURL;
        }
        if (this.model.org_data.previewHost) {
          url = this.model.org_data.previewHost + "/campaigns/" + campaignData.campaignID + "/index.html";
        }
        navigator.clipboard.writeText(url);
        this.notifications.success(url + ' - successfully copied to clipboard.', {
          autoClear: true,
          clearDuration: 3000
        });
      },
      showPreview: function showPreview(campaignData) {
        var url = campaignData.demo_previewURL;
        /*
        if (campaignData.shortURL) {
          url = "http://" + campaignData.shortURL;
        } 
        */

        url = _ember['default'].PREVIEW_URL + ('?creativeID=' + campaignData.campaignID);

        if (campaignData.preview_gen_date) {
          if (moment(campaignData.preview_gen_date).isBefore(moment("2020-03-13T11:27:52.000Z"))) {
            url = campaignData.demo_previewURL;
          }
        }

        if (this.model.org_data.previewHost) {
          // url = this.model.org_data.previewHost + "/campaigns/" + campaignData.campaignID + "/index.html";
          url = this.model.org_data.previewHost + ('/preview?creativeID=' + campaignData.campaignID);
        }
        window.open(url);
      },
      /*
      showPreview: function (campaignData) {
        var controller = this;
         this.set('showPreview', true);
        this.set('current_preview_campaign', campaignData);
        const modal = this.get('remodal.preview-campaign-modal');
         modal.setProperties({
          title: 'Preview for ' + campaignData.name,
          closeOnOutsideClick: false,
          closeOnEscape: false,
          disableNativeClose: false,
          onCancel: 'notifyModalCancelled',
          modalClasses: "remodal-header"
        });
        modal.open();
         var timeStamp = new Date().getTime();
        controller.set('showPreview', true);
        var iframe = '<iframe src="' + campaignData.demo_previewURL + '?analytics=0&timestamp=' + timeStamp + '" style="width:' + campaignData.format.split('x')[0] + 'px; height:' + campaignData.format.split('x')[1] + 'px" name="preview" scrolling="no" marginwidth="0" marginheight="0" frameborder="0" vspace="0" hspace="0"></iframe>';
        controller.set('campaignFileSize', Utils.formatBytes(campaignData.previewAssetSize, 2));
        controller.set('embedPreview', iframe);
        if (this.model.org_data.previewHost) {
          controller.set('shareURL', this.model.org_data.previewHost + "/campaigns/" + campaignData.campaignID + "/index.html");
        } else if (campaignData.shortURL) {
           if (campaignData.shortURL.indexOf("s.visarity") !== 0) {
            controller.set('shareURL', campaignData.demo_previewURL);
            console.log("shareURL - demo_previewURL ");
          } else {
            controller.set('shareURL', campaignData.shortURL);
          }
        } else {
          if (config.environment === 'production') {
            if (this.model.org_data.previewHost) {
              controller.set('shareURL', this.model.org_data.previewHost + "/campaigns/" + campaignData.campaignID);
            } else {
              controller.set('shareURL', '');
              Ember.run.later((function () {
                Ember.$.ajax({
                  url: Ember.API_URL + '/shorturl/' + campaignData.campaignID,
                  type: 'GET',
                  contentType: 'application/json',
                  dataType: 'json',
                  success: function (response) {
                    controller.set('shareURL', response.shorturl);
                    console.log("shorten: " + response.shorturl);
                  },
                  error: function (error) {
                    console.log(error);
                  }
                });
              }), 1500);
            }
          } else {
            if (campaignData.shortURL) {
              controller.set('shareURL', campaignData.shortURL);
            } else {
              controller.set('shareURL', campaignData.demo_previewURL);
            }
            
          }
        }
      },
      */
      startCreatingTemplate: function startCreatingTemplate(campaignID) {
        //console.log(campaignID);
        var controller = this;
        var campType,
            descriptionTxt,
            nameValue = false;
        this.set('template_alert_visible', false);
        var campaignType = this.get('campaignTypeSelected');
        var description = this.get('templateDescription');
        var templatename = this.get('templatename');

        if (templatename.length < 3 || templatename == undefined) {
          this.set('templateAlertType', 'error');
          this.set('template_alert_visible', true);
          this.set('create_template_warning_msg', 'Enter a name of atleast 3 characters');
        } else {
          nameValue = true;
        }

        if (campaignType === '' || campaignType === undefined) {
          this.set('templateAlertType', 'error');
          this.set('template_alert_visible', true);
          this.set('create_template_warning_msg', 'Select a campaign Type');
        } else {
          campType = true;
        }

        if (description.length < 10 || description == undefined) {
          this.set('templateAlertType', 'error');
          this.set('template_alert_visible', true);
          this.set('create_template_warning_msg', 'Enter a description of atleast 10 characters');
        } else {
          descriptionTxt = true;
        }

        if (descriptionTxt && campType && nameValue) {
          this.set('isCreatingTemplateProcess', true);
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/copytotemplate/' + campaignID,
            type: 'POST',
            data: JSON.stringify({
              campaignType: campaignType,
              description: description,
              name: templatename
            }),
            contentType: 'application/json',
            dataType: 'json',
            success: function success() /*response*/{
              //console.log(response);
              controller.set('templateAlertType', 'success');
              controller.set('template_alert_visible', true);
              controller.set('create_template_warning_msg', 'Template successfully created');
              controller.set('isCreatingTemplateProcess', false);
              refreshCampaignData(controller.get('session.data.user_admin_role'), controller);
              _ember['default'].run.later(function () {
                controller.send('closeCreateTemplateModal');
              }, 800);
            },
            error: function error(_error5) {
              console.log(_error5);
              controller.set('templateAlertType', 'error');
              controller.set('template_alert_visible', true);
              controller.set('create_template_warning_msg', 'server error, unable to create template');
              controller.set('isCreatingTemplateProcess', false);
            }
          });
        }
      },
      createTemplate: function createTemplate(campaignData) {
        this.set('isShowingTemplateErrorModal', false);
        this.set('create_template_warning_msg', '');
        this.set('campaignTypeSelected', '');
        this.set('templatename', '');
        this.set('templateDescription', '');
        this.set('isCreatingTemplateProcess', false);
        this.set('template_alert_visible', false);
        this.set('create_template_warning_msg', '');
        if (campaignData.demo_previewURL != null) {
          this.toggleProperty('isShowingCreateTemplateModal');
          this.set('currentCampaignName', campaignData.name);
          this.set('origCampaignID', campaignData.campaignID);
        } else {
          this.set('isShowingTemplateErrorModal', true);
        }
      },
      closeBrowserModal: function closeBrowserModal() {
        this.toggleProperty('isShowingBrowserModal');
        this.set('isShowingBrowserModal', false);
      },
      closeCreateTemplateModal: function closeCreateTemplateModal() {
        this.toggleProperty('isShowingCreateTemplateModal');
      },
      closeTemplateErrorCampaignModal: function closeTemplateErrorCampaignModal() {
        this.toggleProperty('isShowingTemplateErrorModal');
      },
      makeCampaignVisibleOnGallery: function makeCampaignVisibleOnGallery(gallery, state) {
        var controller = this;

        console.log(gallery);
        console.log(state);

        if (state) {
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/gallery/add',
            type: 'POST',
            data: JSON.stringify({
              creativeID: gallery.creativeID,
              galleryID: gallery.galleryID
            }),
            contentType: 'application/json',
            dataType: 'json',
            success: function success() /*response*/{
              controller.notifications.success(' ' + gallery.name + ' updated', { autoClear: true, clearDuration: 1500 });
            },
            error: function error(_error6) {
              console.log(_error6);
            }
          });
        } else {
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + ('/gallery/remove/' + gallery.galleryID + '/' + gallery.creativeID),
            type: 'DELETE',
            success: function success() /*response*/{
              controller.notifications.success(' ' + gallery.name + ' updated', { autoClear: true, clearDuration: 1500 });
            },
            error: function error(_error7) {
              console.log(_error7);
            }
          });
        }

        var shared = false;
        gallery.galleries.forEach(function (c) {
          shared = shared || !!c.shared;
        });
        _ember['default'].set(this.get('currentCampaign'), "shareOnGallery", shared);
      },
      shareCampaign: function shareCampaign(campaignData) {
        var controller = this;
        var isAdmin = this.get('session.data.user_admin_role');

        this.set('currentCampaign', campaignData);
        this.set('currentCampaignName', campaignData.name);
        this.set('shareCampaignID', campaignData.campaignID);
        var data = [];

        data.push(_ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/galleries/listgalleries/' + campaignData.campaignID,
          type: 'GET',
          contentType: 'application/json',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error8) {
            console.log(_error8);
          }
        }));

        var orgID = this.get('orgID');
        console.log(orgID);
        if (isAdmin && campaignData.orgID !== orgID) {
          data.push(_ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/galleries/' + campaignData.orgID,
            type: 'GET',
            contentType: 'application/json',
            dataType: 'json',
            success: function success(response) {},
            error: function error(_error9) {
              console.log(_error9);
            }
          }));
        }

        Promise.all(data).then(function (values) {
          var galleries = []; //;

          var lut = {};
          values[0].forEach(function (g) {
            return lut[g.galleryID] = true;
          });

          if (values.length > 1) {
            values[1].forEach(function (g) {
              galleries.push({
                shared: !!lut[g.galleryID],
                name: g.name + (' (' + campaignData.orgName + ')'),
                galleryID: g.galleryID,
                galleries: galleries,
                creativeID: campaignData.campaignID
              });
            });
          }

          controller.gallery_List.forEach(function (g) {
            galleries.push({
              shared: !!lut[g.galleryID],
              name: g.name,
              galleryID: g.galleryID,
              galleries: galleries,
              creativeID: campaignData.campaignID
            });
          });
          controller.set('galleries', galleries);
          controller.toggleProperty('isShowingShareModal');
        });
      },
      closeShareErrorCampaignModal: function closeShareErrorCampaignModal() {
        this.toggleProperty('isShowingShareErrorModal');
      },
      closeShareCampaignModal: function closeShareCampaignModal() {
        this.set('checkedGalleryShare', false);
        this.set('shortURLLink', '');
        this.toggleProperty('isShowingShareModal');
      },
      copyCampaign: function copyCampaign(campaignData) {
        this.set("currentCampaignData", campaignData);
        this.set("controllerObject", this);
        this.toggleProperty('isShowingCopyModal');
      },
      closeCopyCampaign: function closeCopyCampaign() /*campaignData*/{
        var controller = this;
        this.toggleProperty('isShowingCopyModal');
      },
      closeCopyCampaign2: function closeCopyCampaign2() /*campaignData*/{
        var controller = this;
        this.toggleProperty('isShowingCopyModal');
        _ember['default'].run.later(function () {
          refreshCampaignData(controller.get('session.data.user_admin_role'), controller);
        }, 2000);
      },
      generateTags: function generateTags(campaignData) {
        window.open('tagGen/' + campaignData.campaignID);
      },
      downloadZip: function downloadZip(campaignData) {
        window.open('zipGen/' + campaignData.campaignID);
      },
      editProjectSettings: function editProjectSettings(campaignData) {
        window.open('proj_settings/' + campaignData.campaignID, "_blank");
      },
      createNewProject: function createNewProject() {
        this.transitionToRoute('new-campaign').then(function (campaignData) {});
      },
      createFromGallery: function createFromGallery() {
        this.transitionToRoute('format-gallery').then(function (campaignData) {});
      },
      saveCampaignName: function saveCampaignName(campaignData) {
        var controller = this;
        var campaignParams = {
          name: campaignData.name
        };
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/update/' + campaignData.campaignID,
          type: 'POST',
          data: campaignParams,
          success: function success() /*response*/{
            //controller.notifications.clearAll();
            controller.notifications.success('campaign ' + campaignData.name + ' saved', {
              autoClear: true,
              clearDuration: 2200
            });
          },
          error: function error(_error10) {
            console.log(_error10);
            controller.notifications.error('Server error, retry after sometime', {
              autoClear: true,
              clearDuration: 2200
            });
          }
        });
      },
      editCreative: function editCreative(campaignData) {
        var uid = this.get('user_role.user_ID');
        var mode = uid === campaignData.userID ? "normal" : "readonly";
        mode = this.fKeyPressed ? "normal" : mode;

        //console.log('browser detected  : ' + detectBrowser());
        if (!_elbeEmberFrondEndUtilsFormatUtils['default'].detectBrowser()) {
          this.set('isShowingBrowserModal', true);
        } else {
          if (campaignData.status === 1) {
            //this.transitionToRoute('template', campaignData.campaignID);
            this.transitionToRoute('new-campaign', {
              queryParams: {
                campaignName: encodeURIComponent(campaignData.name),
                campaignID: campaignData.campaignID
              }
            });
          } else if (campaignData.derivedWizardID) {
            if (_ember['default'].Environment === "development") {
              window.open(_ember['default'].WIZARD_URL + campaignData.derivedWizardID + "?campaignID=" + campaignData.campaignID + "&localStorage=" + window.btoa(localStorage.getItem('ember_simple_auth:session')));
            } else {
              window.open(_ember['default'].WIZARD_URL + campaignData.derivedWizardID + "?campaignID=" + campaignData.campaignID + "&t=" + Date.now());
            }
          } else {
            if (_ember['default'].Environment === "development") {
              window.open(_ember['default'].EDITOR_URL + campaignData.campaignID + '/' + mode + '/' + Date.now() + '/' + window.btoa(localStorage.getItem('ember_simple_auth:session')));
            } else {
              window.open(_ember['default'].EDITOR_URL + campaignData.campaignID + '/' + mode + '/' + Date.now());
            }
          }
        }
      },
      deleteProjectFromDB: function deleteProjectFromDB(campaignData) {
        this.set('current_campaignData', campaignData);
        this.get('remodal').open('delete-campaign-modal', {
          title: 'Do you want to delete creative ' + campaignData.name + ' ?',
          onConfirm: 'notifyDeleteConfirmed',
          onCancel: 'notifyDBDeleteModalCanceled',
          innerButtonClasses: 'btn-delete-campaign'
        });
      }, //deleteProject
      archiveCreative: function archiveCreative(campaignData) {
        this.set('current_campaignData', campaignData);
        if (this.altKey) {
          this.send('notifyArchiveConfirmed');
        } else {
          this.get('remodal').open('archive-campaign-modal', {
            title: 'Do you want to archive creative ' + campaignData.name + ' ?',
            onConfirm: 'notifyArchiveConfirmed',
            onCancel: 'notifyDBDeleteModalCanceled',
            innerButtonClasses: 'btn-archive-campaign'
          });
        }
      }, //archiveCreative
      unarchiveCreative: function unarchiveCreative(campaignData) {
        var controller = this;
        var campaignParams = {
          status: 3
        };
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/update/' + campaignData.campaignID,
          type: 'POST',
          data: campaignParams,
          success: function success() /*response*/{
            //controller.notifications.clearAll();
            _ember['default'].set(campaignData, "status", 3);
            controller.notifications.success('creative ' + campaignData.name + '  unarchived', {
              autoClear: true,
              clearDuration: 2200
            });
          },
          error: function error(_error11) {
            console.log(_error11);
            controller.notifications.error('Server error, retry after sometime', {
              autoClear: true,
              clearDuration: 2200
            });
          }
        });
      }, //unarchiveCreative

      notifyArchiveConfirmed: function notifyArchiveConfirmed() {
        var controller = this;
        var campaignData = this.get('current_campaignData');

        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/archive/' + campaignData.campaignID,
          type: 'GET',
          success: function success() /*response*/{
            _ember['default'].set(campaignData, "status", 7);
            _ember['default'].set(campaignData, "preview_gen_date", null);

            //controller.notifications.clearAll();
            controller.notifications.success('campaign ' + campaignData.name + ' has been archived', {
              autoClear: true,
              clearDuration: 2200
            });
          },
          error: function error(_error12) {
            console.log(_error12);
            controller.notifications.error('Server error, retry after sometime', {
              autoClear: true,
              clearDuration: 2200
            });
          }
        });
      }, //notifyArchiveConfirmed

      notifyDeleteConfirmed: function notifyDeleteConfirmed() {
        var controller = this;
        var c_data = this.get('current_campaignData');
        $('#' + c_data.campaignID).closest('tr').children('td').animate({
          padding: 0
        }).wrapInner('<div />').children().slideUp(200, function () {
          $(this).closest('tr').remove();
        });
        _ember['default'].run.later(function () {
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/campaigns/' + c_data.campaignID,
            type: 'DELETE',
            //contentType: 'application/json;charset=utf-8',
            //dataType: 'json',
            success: function success() /*response*/{
              controller.set('current_campaignData', '');
              //console.log(data.length);
              //console.log(response);
            },
            error: function error(_error13) {
              console.log(_error13);
            }
          }); //ajax
        }, 200);
      }, //confirm deleteProject
      notifyPreviewCanceled: function notifyPreviewCanceled() {
        this.set('current_campaignData', '');
      },
      notifyPreviewClosed: function notifyPreviewClosed() {
        refreshCampaignData(this.get('session.data.user_admin_role'), this);
      },
      notifyDBDeleteModalCanceled: function notifyDBDeleteModalCanceled() {
        this.set('current_campaignData', '');
      }
    }
  });
});
//app/controller/dashboard.js