define('elbe-ember-frond-end/components/selector-item', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'tr',
        selected: "",
        updated: null,

        options: (function () {
            var ret = [];
            var active = this.get('item.active');
            var list = this.get('item.list');

            if (this.get('item.id') === "bd2404af" || this.get('item.id') === "e958cd28") {
                ret.push("auto detect");
                this.set('selected', "auto detect");
                /*
                setTimeout( () => {
                    this.set('item.active', -1);
                    this.set('updated', Date.now().toString());
                }, 1000);                        
                */
            } else {
                    this.set('selected', list[active].name);
                }

            list.forEach(function (i) {
                ret.push(i.name);
            });
            return ret;
        }).property('item'),

        actions: {
            active: function active(e) {
                var list = this.get('item.list');
                var i;

                if (e === "auto detect" && (this.get('item.id') === "bd2404af" || this.get('item.id') === "e958cd28")) {
                    this.set('item.active', -1);
                } else {
                    for (i = 0; i < list.length; i++) {
                        var o = list[i];
                        if (o.name === e) {
                            this.set('item.active', i);
                            break;
                        }
                    }
                }
                this.set('selected', e);
                this.set('updated', Date.now().toString());
            }
        }
    });
});