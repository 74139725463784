define('elbe-ember-frond-end/routes/camapign-analytics', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'underscore'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _underscore) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        title: 'Primo Analytics',
        actions: {
            error: function error(_error, transition) {
                if (_error.status === 400 || _error.status === 401) {
                    return this.transitionTo('analyticsError');
                } else if (_error.status === 403) {
                    return this.transitionTo('forbiddenError');
                }
            }
        },
        beforeModel: function beforeModel(transition) {
            var controller = this.controllerFor("camapign-analytics");
            var route = this;
            controller.set('gesture_count', '0');
            controller.set('click_through_rate', '0');
            controller.set('impression_count', '0');
            controller.set('hr_24_impression_count', '0');
            controller.set('browser_pie_model', '0');

            controller.set('viewtime_data: null', null);
            controller.set('iatime_data', null);

            var userName = JSON.parse(localStorage.getItem('ember_simple_auth:session')).authenticated.username;
            if (userName === undefined) {
                //console.log(transition);
                var path = transition.intent.url.split("/");
                controller.set('enableAuthorizedLinks', false);
                _ember['default'].$.ajax({
                    url: _ember['default'].API_URL + '/analytics/verify/' + path[2] + '/' + path[3],
                    type: 'GET',
                    dataType: 'json',
                    success: function success(response) {},
                    error: function error(reason) {
                        route.transitionTo('analyticsError');
                    }
                }); //ajax
            } else if (userName.length > 0) {
                    controller.set('enableAuthorizedLinks', true);
                } else {
                    controller.set('enableAuthorizedLinks', false);
                }
        },
        render: function render(controller, model) {
            this._super();
            window.scrollTo(0, 0);
        },
        model: function model(params) {
            var controller = this;

            var campaignDates = {};

            var campaignData = new _ember['default'].RSVP.Promise(function (resolve, reject) {
                _ember['default'].$.ajax({
                    url: _ember['default'].API_URL + '/campaigns/find/' + params.id,
                    type: 'GET',
                    dataType: 'json',
                    success: function success(response) {
                        var live = response.status === 4 || response.status === 6 || response.status === 11;

                        response.startDate = moment(response.createdAt).subtract(1, 'days').toISOString();
                        response.endDate = moment().toISOString();
                        campaignDates.endDate = encodeURI(response.endDate);
                        campaignDates.startDate = encodeURI(response.startDate);

                        if (!response.booked_imp) {
                            response.booked_imp = 1;
                        }
                        response.live = live;
                        campaignDates = response;
                        resolve(response);
                    },
                    error: function error(reason) {
                        reject(reason);
                    }
                }); //ajax
            }); //promise

            var project = new _ember['default'].RSVP.Promise(function (resolve, reject) {
                _ember['default'].$.ajax({
                    url: _ember['default'].API_URL + '/projects/' + params.id,
                    type: 'GET',
                    dataType: 'json',
                    success: function success(response) {
                        resolve(response);
                        //console.log(response);
                    },
                    error: function error(_error2) {
                        reject(_error2);
                        //console.log(error);
                    }
                }); //ajax
            }); //promise

            return new _ember['default'].RSVP.hash({
                campaignData: campaignData,
                project: project
            }); //rsvp
        },
        setupController: function setupController(controller, model) {
            controller.set('model', model);
            controller.set("realm", model.campaignData.live ? "live" : "preview");
        }

    });
});
//app/routes/campaign-analytics.js

//import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';