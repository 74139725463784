define('elbe-ember-frond-end/components/edit-brand', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isSaving: false,

    actions: {
      save_newBrand: function save_newBrand() {
        if (this.get('brand.name') === '' || this.get('brand.name') == null) {
          this.notifications.warning('Require a brand name', {
            autoClear: true,
            clearDuration: 3400
          });
        } else {
          this.set('isSaving', true);
          var brand = this.get('brand');
          var controller = this;
          var data = {
            brandID: this.get('brand.brandID'),
            name: this.get('brand.name'),
            description: this.get('brand.description'),
            userID: this.get('brand.userID'),
            orgID: this.get('brand.orgID')
          };
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/brands',
            type: 'POST',
            data: JSON.stringify(data),
            context: this,
            contentType: 'application/json;charset=utf-8',
            dataType: 'json',
            success: function success() {
              //console.log(response);
              controller.set('isSaving', false);
              if (controller.get('isNewBrand')) {
                controller.notifications.success('Brand ' + controller.get('brand.name') + ' Created', {
                  autoClear: true,
                  clearDuration: 3200
                });
                setTimeout(function () {
                  window.open('brands');
                }, 1000);
              } else {
                controller.notifications.success('Brand ' + controller.get('brand.name') + ' Updated', {
                  autoClear: true,
                  clearDuration: 3200
                });
              }
            },
            error: function error() {
              controller.notifications.success('Error saving... retry again', {
                autoClear: true,
                clearDuration: 3200
              });
              controller.set('isSaving', false);
            }
          });
        }
      }
    }
  });
});