define('elbe-ember-frond-end/controllers/new-campaign', ['exports', 'ember', 'elbe-ember-frond-end/config/environment'], function (exports, _ember, _elbeEmberFrondEndConfigEnvironment) {

  function generatePromise() {
    var brandArray = [];
    return new _ember['default'].RSVP.Promise(function (resolve) {
      _ember['default'].$.ajax({
        url: _ember['default'].API_URL + '/brands',
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        success: function success(response) {
          for (var i = 0; i < response.length; i++) {
            brandArray.push({
              name: response[i].name,
              brandID: response[i].brandID
            });
            //console.log(response[i].name);
          }
          setTimeout(function () {
            return resolve(brandArray);
          }, 200);
        },
        error: function error(_error) {
          console.log(_error);
        }
      }); //ajax
    });
  }

  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    campaignID: null,
    brandNames: [],
    new_campaign: true,
    isBusy: false,
    splash_file: null,
    splash_image: null,
    disable_openEditor: true,
    create_campaign_btn: "Create Campaign",
    init: function init() {
      this.set('isBusy', false);
      this.set('user_admin_role', this.get('session.data.user_admin_role'));
      this._super();
      this.set('campaign-header', 'New Campaign');
    },
    hasError: "",
    actions: {
      brandNameChoosen: function brandNameChoosen() {},
      searchLogo: function searchLogo(searchTerm) {
        if (_ember['default'].isBlank(searchTerm)) {
          return [];
        }
        return _ember['default'].$.ajax({
          url: 'https://autocomplete.clearbit.com/v1/companies/suggest?query=' + searchTerm,
          type: 'GET',
          contentType: 'application/json;charset=utf-8',
          dataType: 'json',
          success: function success() /*response*/{},
          error: function error(_error2) {
            console.log(_error2);
          }
        }); //ajax
      },
      cancel_newCampaigns: function cancel_newCampaigns() {
        this.set('campaignID', '');
        //this.transitionToRoute('dashboard', Date.now());
        history.back();
      },
      create_newCampaigns: function create_newCampaigns() {
        var tCampaignID = this.get('campaignID');
        var tcampaignName = this.get('campaign_name');
        var tbrandID = this.get('brand-name.brandID');
        //var tbrandName = this.get('branded_name');
        if (this.get('campaign_name') === '') {
          this.notifications.warning('Campaign name required', { autoClear: true, clearDuration: 1200 });
        } else {
          if (this.get('campaign_name').length < 3) {
            this.notifications.warning('Campaign Name should be atleast 3 characters long', {
              autoClear: true,
              clearDuration: 3400
            });
          } else {
            var controller = this;
            var thumbnailURL = _elbeEmberFrondEndConfigEnvironment['default'].THUMBNAILURL;
            this.set('isBusy', true);
            _ember['default'].$.ajax({
              url: _ember['default'].API_URL + '/campaigns',
              type: 'POST',
              data: JSON.stringify({
                campaignID: tCampaignID,
                name: tcampaignName,
                brandID: tbrandID,
                status: 1,
                userID: controller.get('session.data.authenticated.user_ID'),
                thumbnail: thumbnailURL
              }),
              contentType: 'application/json;charset=utf-8',
              dataType: 'json',
              success: function success() /*response*/{
                //console.log(response);
                controller.set('isBusy', false);
                _ember['default'].run.later(function () {
                  controller.transitionToRoute('template', tCampaignID);
                }, 1000);
                controller.notifications.success('Campaign Details Saved - Open Designer from Dastboard', { autoClear: true, clearDuration: 1200 });
                controller.set('create_campaign_btn', "Save Campaign");
              },
              error: function error(_error3) {
                console.log(_error3);
                controller.set('isBusy', false);
                controller.notifications.warning('Server Error', { autoClear: true, clearDuration: 2600 });
              }
            }); //ajax
          }
        } //else
      },
      selectedBrand: function selectedBrand(select) {
        this.set('branded_name', select);
        console.log(select.brandID);
      },
      handleBrandList: function handleBrandList() /*select, e*/{
        this.set('promise', generatePromise());
      }, //handleBrandList
      cancel_campaignsDetails: function cancel_campaignsDetails() {
        this.set('campaign-header', 'Edit Campaign');
        this.set('new_campaign', true);
        this.set('isBusy', false);
      }
    } //actions
  });
});
//app/controllers/new-campaign.js