define('elbe-ember-frond-end/routes/edit-proj-settings', ['exports', 'ember', 'elbe-ember-frond-end/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _elbeEmberFrondEndConfigEnvironment, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {

  var escapeURIparam = function escapeURIparam(url) {
    if (encodeURIComponent) url = encodeURIComponent(url);else if (encodeURI) url = encodeURI(url);else url = escape(url);
    url = url.replace(/\+/g, '%2B'); // Force the replacement of "+"
    return url;
  };

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'Edit Campaign',
    model: function model(params) {
      var controller = this;
      var user_admin_role = JSON.parse(localStorage.getItem('ember_simple_auth:session')).user_admin_role;
      var brandOrg = controller.get('session.data.authenticated.org_ID');
      /*
      if (user_admin_role) {
        brandOrg = "all";
      }
      */
      var basePreviewUrl = 'https://dps.visarity.com/campaigns/' + params.id + '/' + params.id + '.json';
      if (_elbeEmberFrondEndConfigEnvironment['default'].environment === 'production') {
        basePreviewUrl = 'https://ps.visarity.com/campaigns/' + params.id + '/' + params.id + '.json';
      }

      var hash = {
        campaignData: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/find/' + params.id,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            //console.log(response);
          },
          error: function error(_error) {}
        }), //ajax
        project: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/projects/' + params.id,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            //console.log(response);
          },
          error: function error(_error2) {
            //console.log(error);
          }
        }), //ajax

        orgData: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/org/info/' + controller.get('session.data.authenticated.user_ID'),
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            //console.log(response);
          },
          error: function error(_error3) {}
        }), //ajax

        brandList: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/brands/' + brandOrg,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error4) {}
        }), //ajax

        formatList: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/formats/' + brandOrg,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error5) {}
        }), //ajax     

        previewJSON: new Promise(function (resolve, reject) {
          _ember['default'].$.ajax({
            url: basePreviewUrl,
            type: 'GET',
            dataType: 'json',
            success: function success(response) {
              resolve(response);
            },
            error: function error(_error6) {
              resolve(null);
            }
          });
        })
      };

      return new _ember['default'].RSVP.hash(hash);
    },
    setupController: function setupController(controller, model) {
      controller.set('isSavingData', false);
      controller.set('isBusy', false);
      controller.set('hasb64Image', false);
      controller.set('splash_image', "");
      controller.set('splash_file', "");
      controller.set('campaignID', model.campaignData.campaignID);
      controller.set('camapaignName', model.campaignData.name);
      controller.set('campaignUserID', model.campaignData.userID);
      controller.set('brandID', model.campaignData.brandID);
      controller.set('campaignFormat', model.campaignData.format);
      controller.set('campaignParams', model.campaignData);
      controller.set('editInitial', true);
      controller.set('campaign_model', model.campaignData);
      controller.set('isPublishingLive', false);
      controller.set('campaignStatus', model.campaignData.status);
      controller.set('derivedTemplateID', model.campaignData.derivedTemplateID);
      controller.set('checkedReadOnlyState', model.campaignData.readonly);
      controller.set('isStopButtonDisabled', true);
      //controller.set('openWebsite', false);
      controller.set('isADdTagDisabled', true);
      //controller.set('openWebsite_len', 0);
      controller.set('orgData', model.orgData);

      model.brandList.unshift({ brandID: null, name: "None" });
      controller.set('brandList', model.brandList);
      if (model.brandList) {
        controller.set("brand", model.brandList[0]);
        model.brandList.forEach(function (b) {
          if (b.brandID === model.campaignData.brandID) {
            controller.set("brand", b);
          }
        });
      }

      model.formatList.unshift({ formatID: null, name: "None" });
      controller.set('formatList', model.formatList);
      if (model.formatList) {
        controller.set("format", model.formatList[0]);
        if (!model.campaignData.formatID && model.campaignData.derivedWizardID) {
          model.campaignData.formatID = model.campaignData.derivedWizardID;
        }
        model.formatList.forEach(function (b) {
          if (b.formatID === model.campaignData.formatID) {
            controller.set("format", b);
          }
        });
      }

      var shareCategories = model.campaignData.shareCategories;

      if (!shareCategories) {
        shareCategories = {
          galleryName: "",
          other: "",
          industry: "",
          iabformat: "",
          set: ""
        };
      }

      if (!shareCategories.galleryName) {
        shareCategories.galleryName = model.campaignData.name;
      }
      if (!shareCategories.iabformat) {
        shareCategories.iabformat = "";
      }

      if (!shareCategories.assets) {
        shareCategories.assets = [];
      }

      if (!shareCategories.industry) {
        shareCategories.industry = "";
      }

      if (!shareCategories.other) {
        shareCategories.other = "";
      }

      function setParam(name, value) {
        value = value === undefined ? null : value;
        controller.set(name, value);
      }

      var other = shareCategories.other.split(" ");
      var iabformat = shareCategories.iabformat;
      var industry = shareCategories.industry.split(" ");

      controller.set('shareCategories', null);

      setParam("assets", shareCategories.assets);
      setParam("galleryName", shareCategories.galleryName);

      var otherCategories = [{ name: "None", id: null }, { name: "150KB", id: "skb" }, { name: "2.5D", id: "3db" }, { name: "Animation", id: "anim" }, { name: "Best Of", id: "boc" }, { name: "Branding", id: "bnd" }, { name: "Compare", id: "cmp" }, { name: "Countdown", id: "cnt" }, { name: "Custom", id: "cust" }, { name: "Desktop", id: "des" }, { name: "DOOH", id: "ooh" }, { name: "Dynamic", id: "dyn" }, { name: "eCommerce", id: "ecom" }, { name: "Gamification", id: "game" }, { name: "Hero", id: "hero" }, { name: "Gyro", id: "gyr" }, { name: "Mobile", id: "mob" }, { name: "Multivariant", id: "mul" }, { name: "Performance", id: "perf" },
      // { name: "PrimoStudio", id: "std" },
      { name: "Real 3D", id: "msh" }, { name: "Retargeting", id: "rtg" }, { name: "Responsive", id: "resp" },
      //  { name: "SmartBuilder", id: "smt" },
      { name: "Special FX", id: "sfx" }, { name: "Special placement", id: "spec" }, { name: "Video", id: "vid" }, { name: "Xmas", id: "xmas" }];

      var industryCategories = [{ name: "None", id: null }, { name: "Automotive", id: "cars" }, { name: "Beauty", id: "beauty" }, { name: "Books & Magazines", id: "book" }, { name: "Commodities", id: "com" }, { name: "DIY", id: "diy" }, { name: "eCommerce", id: "ecom" }, { name: "Electronics", id: "edev" }, { name: "Entertainment", id: "enter" }, { name: "Fashion", id: "fash" }, { name: "Finance", id: "fin" }, { name: "FMCG", id: "fmcg" }, { name: "Food", id: "food" }, { name: "Furniture", id: "furn" }, { name: "Games", id: "games" }, { name: "Luxury Goods", id: "luxury" }, { name: "Movies & TV", id: "mtv" }, { name: "QSR", id: "qsr" }, { name: "Rental", id: "rent" }, { name: "Retail", id: "ret" }, { name: "Sports", id: "sport" }, { name: "Sweets & Candies", id: "candy" }, { name: "Telecom", id: "tele" }, { name: "Theme Parks", id: "tp" }, { name: "Toys", id: "toys" }, { name: "Travel", id: "travel" }];

      var lut = [];
      industryCategories.forEach(function (e) {
        lut[e.id] = e;
      });
      controller.set('industryCategoriesLUT', lut);
      controller.set('industryCategories', industryCategories);

      setParam("industry_value0", lut[industry[0]]);
      setParam("industry_value1", lut[industry[1]]);
      setParam("industry_value2", lut[industry[2]]);

      controller.set('otherCategories', otherCategories);
      lut = [];
      otherCategories.forEach(function (e) {
        lut[e.id] = e;
      });
      controller.set('otherCategoriesLUT', lut);
      setParam("other_value0", lut[other[0]]);
      setParam("other_value1", lut[other[1]]);
      setParam("other_value2", lut[other[2]]);
      setParam("other_value3", lut[other[3]]);
      setParam("other_value4", lut[other[4]]);
      setParam("other_value5", lut[other[5]]);

      setParam("serviceType_value", shareCategories.serviceType ? shareCategories.serviceType : "");

      setParam("set_name", shareCategories.set === null ? "" : shareCategories.set);

      if (!iabformat) {
        switch (model.campaignData.format) {
          case "300x600":
            iabformat = "hp";
            break;
          case "300x250":
            iabformat = "mr";
            break;
        }
      }

      setParam("iabformat", iabformat);

      controller.set('shareCategories', shareCategories);

      var project = model.project;
      wceGlue.fixer(project);
      wceGlue.cutter(project);
      var names = {};
      if (project.elements) {
        project.elements.forEach(function (i) {
          names[i.id] = i.name;
        });
      }
      if (project.assets) {
        project.assets.forEach(function (i) {
          names[i.id] = i.name;
          names[wceGlue.crc32(i.id)] = i.name;
          if (i.url) {
            names[wceGlue.crc32(i.url)] = i.name;
          }
        });
      }
      if (project.selectors) {
        project.selectors.forEach(function (i) {
          names[i.id] = i.name;
        });
      }
      var das = wceGlue.getDynamicAssets(project.elements, project.assets, project.selectors, names);
      var openWebsite_len = 0;
      das.forEach(function (e) {
        if (e.type === "openwebsite") {
          openWebsite_len++;
        }
      });
      controller.set('openWebsite', openWebsite_len > 0);
      controller.set('openWebsite_len', openWebsite_len);

      //console.log(model.project.assets);
      //console.log(das);
      //console.log("preview JSON");
      //console.log(model.previewJSON);
      //console.log("CRC32: " + wceGlue.crc32("18aaeab910a1a8c871b"));

      das = [];
      if (model.previewJSON) {
        (function () {
          var basePreviewUrl = 'https://dps.visarity.com/campaigns/' + model.campaignData.campaignID + '/';
          if (_elbeEmberFrondEndConfigEnvironment['default'].environment === 'production') {
            basePreviewUrl = 'https://ps.visarity.com/campaigns/' + model.campaignData.campaignID + '/';
          }
          model.previewJSON.assets.forEach(function (a) {
            if (a.type === "texture" && a.mediaType === "image") {
              var suffix = a.fileName.split(".");
              suffix = suffix[suffix.length - 1];
              a.url = a.url || basePreviewUrl + a.fileName;
              das.push({
                type: "texture",
                name: (names[a.id] || names[wceGlue.crc32(a.url)] || "Unknown") + "." + suffix,
                instanceName: names[a.id] || names[wceGlue.crc32(a.url)] || "Unknown",
                fileName: a.fileName,
                url: basePreviewUrl + a.fileName
              });
            }
          });
        })();
      }
      controller.set('dynamicAssets', das);

      var user_admin_role = JSON.parse(localStorage.getItem('ember_simple_auth:session')).user_admin_role;
      if (user_admin_role) {
        controller.set('user_admin_role', true);
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/listfiles/' + model.campaignData.campaignID,
          type: 'GET',
          success: function success(response) {
            controller.set('campaignFileData', response);
          },
          error: function error(_error7) {
            console.log(_error7);
          }
        }); //ajax
      }

      if (model.campaignData.startDate === null || model.campaignData.startDate === undefined || model.campaignData.startDate === 'null') {
        controller.set('campaign_startDate', new Date(moment().format()));
      } else {
        controller.set('campaign_startDate', new Date(moment(model.campaignData.startDate).format()));
        //console.log(controller.get('campaign_startDate'));
      }

      if (model.campaignData.endDate === null || model.campaignData.endDate === undefined || model.campaignData.endDate === 'null') {
        controller.set('campaign_endDate', new Date(moment().add(30, 'days').format()));
      } else {
        controller.set('campaign_endDate', new Date(moment(model.campaignData.endDate).format()));
      }

      if (model.campaignData.comments != null) {
        controller.set('campaign_description', model.campaignData.comments);
      }
      if (model.campaignData.readonly != null) {
        controller.set('readOnlyCampaignValue', model.campaignData.readonly);
      } else {
        controller.set('readOnlyCampaignValue', false);
        controller.set('checkedReadOnlyState', false);
      }
      if (model.campaignData.status != null) {
        controller.set('campaignStatus', model.status);
      }
      if (model.campaignData.comments != null) {
        controller.set('campaign_description', model.campaignData.comments);
      }
      if (model.campaignData.booked_imp != null) {
        controller.set('impression_volume', model.campaignData.booked_imp);
      } else {
        controller.set('impression_volume', 50000);
      }

      if (model.orgData.billingType !== null || model.orgData.billingType !== undefined) {
        if (model.orgData.billingType === 'Subscription') {
          controller.set('regionDisabled', true);
        }
      }

      if (model.campaignData.splash_image != null) {
        controller.set('isSavingData', true);
        controller.set('isBusy', true);
        var splash_image_name = 'Splash_Campaign_' + model.campaignData.format;
        var dummy_splash_image = new RegExp(splash_image_name, "g");
        //console.log(splash_image_name);
        controller.set('splashPreviewUrl', model.campaignData.splash_image);
        var m;
        //check if there is a default splash, if so before publishing set an alert
        if ((m = dummy_splash_image.exec(model.campaignData.splash_image)) !== null) {
          if (m.index === dummy_splash_image.lastIndex) {
            dummy_splash_image.lastIndex++;
          }
          if (m[0] === splash_image_name) {
            controller.set('dummySplash', true);
            controller.set('dummySplashIcon', true);
          }
        }
      }

      //console.log(model.derivedTemplateID);
      if (model.campaignData.derivedTemplateID === null || model.campaignData.derivedTemplateID === undefined || model.derivedTemplateID == '') {
        controller.set('derivedTemplateID', null);
      } else {
        controller.set('derivedTemplateID', model.campaignData.derivedTemplateID);
      }

      //console.log(model.openwebsite);

      controller.set('model', model);

      //console.log('after setup controller');
      controller.set('campaignStatus', model.campaignData.status);

      switch (model.campaignData.status) {
        case 0:
        case 1:
          {
            controller.set('label_class', "label label-primary");
            controller.set('label_fa_class', "fa fa-star");
            controller.set('label_name', "   NEW  ");
            controller.set('BrandDisabled', false);
            controller.set('startDateDisabled', false);
            controller.set('endDateDisabled', false);
            controller.set('volumeDisabled', false);
            controller.set('formatDisabled', false);
            controller.set('splashDisabled', false);
            controller.set('isBusy', false);
            controller.set('isPublishBtnDisabled', false);
            if (model.orgData.billingType === 'Subscription') {
              controller.set('regionDisabled', true);
            } else {
              controller.set('regionDisabled', false);
            }
            controller.set('openWebsite', false);
            controller.set('isEditorDisabled', false);
            controller.set('isADdTagDisabled', true);
          }
          break;
        case 2:
        case 3:
          {
            controller.set('label_class', "label label-info");
            controller.set('label_fa_class', "fa fa-pencil");
            controller.set('label_name', "    IN EDITING  ");
            controller.set('splash_isDisabled', false);
            controller.set('BrandDisabled', false);
            controller.set('startDateDisabled', false);
            controller.set('endDateDisabled', false);
            controller.set('volumeDisabled', false);
            controller.set('formatDisabled', false);
            controller.set('splashDisabled', false);
            controller.set('isBusy', false);
            controller.set('isPublishBtnDisabled', false);
            if (model.orgData.billingType === 'Subscription') {
              controller.set('regionDisabled', true);
            } else {
              controller.set('regionDisabled', false);
            }
            controller.set('isEditorDisabled', false);
            controller.set('isADdTagDisabled', true);
          }
          break;
        case 4:
        case 11:
          controller.set('label_class', "label label-success");
          controller.set('label_fa_class', "fa fa-calendar");
          controller.set('label_name', "   LIVE  ");
          controller.set('splash_isDisabled', true);
          controller.set('BrandDisabled', true);
          controller.set('startDateDisabled', true);
          controller.set('endDateDisabled', true);
          controller.set('volumeDisabled', true);
          controller.set('formatDisabled', true);
          controller.set('splashDisabled', true);
          controller.set('isPublishBtnDisabled', true);
          controller.set('regionDisabled', true);
          controller.set('isEditorDisabled', true);
          controller.set('isADdTagDisabled', false);
          break;
        case 5:
          controller.set('label_class', "label label-default");
          controller.set('label_fa_class', "fa fa-calendar");
          controller.set('label_name', "   SCHEDULED  ");
          controller.set('splash_isDisabled', true);
          controller.set('BrandDisabled', true);
          controller.set('startDateDisabled', true);
          controller.set('endDateDisabled', true);
          controller.set('volumeDisabled', true);
          controller.set('formatDisabled', true);
          controller.set('splashDisabled', true);
          controller.set('isPublishBtnDisabled', true);
          controller.set('regionDisabled', true);
          controller.set('isEditorDisabled', true);
          controller.set('isADdTagDisabled', false);
          break;
        case 6:
          controller.set('label_class', "label label-danger");
          controller.set('label_fa_class', "fa fa-stop");
          controller.set('label_name', "   STOPPED  ");
          controller.set('splash_isDisabled', true);
          controller.set('BrandDisabled', true);
          controller.set('startDateDisabled', true);
          controller.set('endDateDisabled', true);
          controller.set('volumeDisabled', true);
          controller.set('formatDisabled', true);
          controller.set('splashDisabled', true);
          controller.set('isPublishBtnDisabled', true);
          controller.set('regionDisabled', true);
          controller.set('isEditorDisabled', true);
          controller.set('isADdTagDisabled', false);
          break;
        case 7:
          controller.set('label_class', "label label-default");
          controller.set('label_fa_class', "fa fa-archive");
          controller.set('label_name', "   ARCHIVED  ");
          controller.set('splash_isDisabled', true);
          controller.set('BrandDisabled', true);
          controller.set('startDateDisabled', true);
          controller.set('endDateDisabled', true);
          controller.set('volumeDisabled', true);
          controller.set('formatDisabled', true);
          controller.set('splashDisabled', true);
          controller.set('isPublishBtnDisabled', true);
          controller.set('regionDisabled', true);
          controller.set('isEditorDisabled', true);
          controller.set('isADdTagDisabled', false);
          break;
        case 10:
          controller.set('label_class', "label label-warning");
          controller.set('label_fa_class', "fa fa-pencil");
          controller.set('label_name', "   FROM DESKTOP  ");
          controller.set('splash_isDisabled', true);
          controller.set('BrandDisabled', false);
          controller.set('startDateDisabled', false);
          controller.set('endDateDisabled', false);
          controller.set('volumeDisabled', false);
          controller.set('formatDisabled', true);
          controller.set('splashDisabled', true);
          controller.set('isPublishBtnDisabled', true);
          controller.set('regionDisabled', false);
          controller.set('isEditorDisabled', true);
          controller.set('isADdTagDisabled', false);
          break;
        default:

      }
    }
  });
});