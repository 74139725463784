define('elbe-ember-frond-end/routes/publishers', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'New Publisher',
    setupController: function setupController(controller, model) {
      var pubUUID = new UUID(4).format();
      controller.set('pubID', pubUUID);
      controller.set('pub-name', '');
      controller.set('pub-url', '');
      controller.set('pub_description', '');
    }
  });
});