define("elbe-ember-frond-end/models/campaign", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Model.extend({
    campaignID: _emberData["default"].attr("string"),
    name: _emberData["default"].attr("string"),
    startDate: _emberData["default"].attr("date", { defaultValue: function defaultValue() {
        return new Date();
      } }),
    endDate: _emberData["default"].attr("date", { defaultValue: function defaultValue() {
        return new Date();
      } }),
    booked_imp: _emberData["default"].attr("number", { defaultValue: 1 }),
    format: _emberData["default"].attr("string", { defaultValue: "300x600" }),
    comments: _emberData["default"].attr("string"),
    splash_image: _emberData["default"].attr("string"),
    brandID: _emberData["default"].attr("string"),
    status: _emberData["default"].attr("number", { defaultValue: 1 })
  });
});