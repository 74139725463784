define('elbe-ember-frond-end/routes/tag-gen', ['exports', 'underscore', 'ember', 'elbe-ember-frond-end/config/environment', 'ember-simple-auth/mixins/authenticated-route-mixin', 'jquery'], function (exports, _underscore, _ember, _elbeEmberFrondEndConfigEnvironment, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _jquery) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'Generate Campaign Tag',
    actions: {},
    model: function model(params) {
      var campaign = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/find/' + params.id,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            resolve(response);
          },
          error: function error(reason) {
            reject(reason);
          }
        }); //ajax
      }); //promise

      var project = campaign.then(function (c) {
        var projectUrl = (c.status == 4 ? _ember['default'].LIVE_BASE_URL : _ember['default'].PREVIEW_BASE_URL) + '/campaigns/' + params.id + '/' + params.id + '.json?t=' + Date.now();

        return _ember['default'].$.ajax({
          url: projectUrl,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error) {
            //console.log(error);
          }
        }); //ajax
      });

      var editorProject = _ember['default'].$.ajax({
        url: _ember['default'].API_URL + '/projects/' + params.id,
        type: 'GET',
        dataType: 'json',
        success: function success(response) {},
        error: function error(_error2) {}
      }); //ajax

      var orgData = _ember['default'].$.ajax({
        url: _ember['default'].API_URL + '/org/info/' + JSON.parse(localStorage.getItem('ember_simple_auth:session')).authenticated.user_ID,
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        success: function success(response) {},
        error: function error(_error3) {}
      }); //ajax

      return new _ember['default'].RSVP.hash({
        campaign: campaign,
        project: project,
        orgData: orgData,
        editorProject: editorProject
      }); //rsvp
    },
    setupController: function setupController(controller, model) {
      function loadFeedInfo(feedID) {
        return new Promise(function (resolve, reject) {
          var xhr = new XMLHttpRequest();
          var url = _ember['default'].API_URL + "/feeds/get/" + feedID;
          xhr.addEventListener("load", function () {
            if (xhr.status === 200) {
              resolve(xhr.response);
            } else {
              reject();
            }
          });
          xhr.addEventListener('error', function () {
            reject();
          });
          xhr.responseType = "json";
          xhr.open("GET", url);
          xhr.setRequestHeader("authorization", "primo");
          xhr.send();
        });
      }
      if (model.campaign.feedID) {
        loadFeedInfo(model.campaign.feedID).then(function (info) {
          console.log("test");
          controller.set('collapse.adserver', false);
          _ember['default'].set(model, "feedInfo", info);
        })['catch'](function () {});
      }
      controller.set('model', model);
    }
  });
});