define('elbe-ember-frond-end/routes/dashboard', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-cli-pagination/remote/route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _emberCliPaginationRemoteRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberCliPaginationRemoteRouteMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'Primo Dashboard',
    model: function model() {
      var controller = this;
      var startDate = moment().subtract(365 * 4, 'days').toISOString();
      var endDate = moment().toISOString();

      var userMembership = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/membership/' + controller.get('session.data.authenticated.user_ID'),
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            //console.log(response);
            resolve(response);
          },
          error: function error(reason) {
            reject(reason);
          }
        }); //ajax
      }); //promise

      var campaign_data = userMembership.then(function (response) {
        if (response.permission === 'admin') {
          return _ember['default'].$.ajax({
            url: _ember['default'].API_URL + ('/campaigns/detailsAdmin/' + encodeURIComponent(startDate) + '/' + encodeURIComponent(endDate)),
            type: 'GET',
            dataType: 'json',
            success: function success(response) {},
            error: function error(_error) {
              console.log(_error);
            }
          });
        } else {
          return _ember['default'].$.ajax({
            url: _ember['default'].API_URL + ('/campaigns/details/' + controller.get('session.data.authenticated.user_ID') + '/' + encodeURIComponent(startDate) + '/' + encodeURIComponent(endDate)),
            type: 'GET',
            dataType: 'json',
            success: function success(response) {},
            error: function error(_error2) {
              console.log(_error2);
            }
          });
        }
      });

      var orgData = userMembership.then(function (response) {
        return _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/org/info/' + controller.get('session.data.authenticated.user_ID'),
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error3) {}
        }); //ajax
      });

      var userList = userMembership.then(function (response) {
        var url = _ember['default'].API_URL + '/org/allUsers/' + controller.get('session.data.authenticated.org_ID');
        if (response.permission === 'admin') {
          url = _ember['default'].API_URL + '/allUsers/' + controller.get('session.data.authenticated.user_ID');
        }

        return _ember['default'].$.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error4) {}
        }); //ajax
      });

      var wizardList = userMembership.then(function (response) {
        var url = _ember['default'].API_URL + '/wizard/list';
        return _ember['default'].$.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error5) {}
        }); //ajax
      });

      var brandList = userMembership.then(function (response) {
        var url = _ember['default'].API_URL + ('/brands/' + controller.get('session.data.authenticated.org_ID'));
        return _ember['default'].$.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error6) {}
        }); //ajax
      });

      var formatList = userMembership.then(function (response) {
        var url = _ember['default'].API_URL + ('/formats/' + controller.get('session.data.authenticated.org_ID'));
        return _ember['default'].$.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error7) {}
        }); //ajax
      });

      var orgList = userMembership.then(function (response) {
        if (response.permission === 'admin') {
          var url = _ember['default'].API_URL + '/orglistshort';

          return _ember['default'].$.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            success: function success(response) {},
            error: function error(_error8) {}
          }); //ajax
        } else {
            return null;
          }
      });

      var galleryList = userMembership.then(function (response) {
        var url = _ember['default'].API_URL + ('/galleries/' + controller.get('session.data.authenticated.org_ID'));

        return _ember['default'].$.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function success(response) {},
          error: function error(_error9) {}
        }); //ajax
      });

      return new _ember['default'].RSVP.hash({
        startDate: startDate,
        endDate: endDate,
        campaign_data: campaign_data,
        user_role: userMembership,
        org_data: orgData,
        user_List: userList,
        org_list: orgList,
        wizard_List: wizardList,
        brand_List: brandList,
        format_List: formatList,
        gallery_List: galleryList
      }); //rsvp
    },

    setupController: function setupController(controller, model) {
      var userID = this.get('session.data.authenticated.user_ID');
      var orgID = this.get('session.data.authenticated.org_ID');

      model.format_List.push({
        "formatID": "AIRTIME",
        "name": "Airtime (BigAds)",
        "tags": "",
        "description": "",
        "orgID": null,
        "userID": null
      });

      //Put user org first
      if (model.org_list) {
        var ownOrg = model.org_list.filter(function (e) {
          return e.orgID === orgID;
        })[0];
        var t = model.org_list.filter(function (e) {
          return e.orgID !== orgID;
        });
        t.unshift(ownOrg);
        _ember['default'].set(model, "org_list", t);
      }

      model.format_List.unshift({
        name: "All",
        formatID: null,
        orgID: "x"
      });

      model.format_lut = {};
      model.format_List.forEach(function (e) {
        model.format_lut[e.formatID] = e;
      });

      model.brand_List.unshift({
        name: "All",
        brandID: null,
        orgID: "x"
      });

      model.brand_lut = {};
      model.brand_List.forEach(function (e) {
        model.brand_lut[e.brandID] = e;
      });

      controller.set('user_role', model.user_role);

      model.userID = userID;
      model.orgID = orgID;
      controller.setProperties(model);

      controller.initModel(model);
    }
  });
});
//app/routes/dashboard.js