define('elbe-ember-frond-end/controllers/edit-user', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    userRoleList: ['demo', 'admin', 'designer', 'manager'],
    init: function init() {
      this.get('session').authorize('authorizer:custom', { admin: true });
    },
    actions: {
      backToPrevious: function backToPrevious() {
        history.back();
      },
      saveUser: function saveUser() {
        var userParams = {
          fullName: this.get('user_fullName'),
          userRole: this.get('user_role'),
          admin_userID: this.get('session.data.authenticated.user_ID'),
          userID: this.get('userID')
        };
        var controller = this;
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/users/updaterole/',
          type: 'POST',
          data: userParams,
          success: function success(response) {
            controller.notifications.success('User Details have been updated', {
              autoClear: true,
              clearDuration: 3200
            });
          },
          error: function error(_error) {
            controller.notifications.error('Could not change status, contact admin', {
              autoClear: true,
              clearDuration: 3200
            });
          }
        });
      }
    }

  });
});