define('elbe-ember-frond-end/controllers/template-settings', ['exports', 'ember', 'elbe-ember-frond-end/utils/formatUtils'], function (exports, _ember, _elbeEmberFrondEndUtilsFormatUtils) {
    exports['default'] = _ember['default'].Controller.extend({
        session: _ember['default'].inject.service('session'),
        thumbnailURL: null,
        campaignTypeOptions: ['banner', 'interstitial', '3D Video', '360 Video', 'VR Interactive'],
        thumbnailURL: null,
        splashURL: null,
        thumbnailBg: _ember['default'].computed('thumbnailURL', function () {
            var url = this.get('thumbnailURL');
            return _ember['default'].String.htmlSafe('background-image: url("' + url + '")');
        }),
        splashBg: _ember['default'].computed('splashURL', function () {
            var url = this.get('splashURL');
            return _ember['default'].String.htmlSafe('background-image: url("' + url + '")');
        }),
        init: function init() {
            this.get('session').authorize('authorizer:custom', { admin: true });
        },
        actions: {
            backToTemplates: function backToTemplates() {
                this.transitionToRoute('template-list');
            },
            saveTemplate: function saveTemplate() {
                var controller = this;
                var templateParams = {
                    name: this.get('templateName'),
                    type: this.get('templateTypeSelected'),
                    description: this.get('description')
                };
                _ember['default'].$.ajax({
                    url: _ember['default'].API_URL + '/templates/update/' + controller.get('templateID'),
                    type: 'POST',
                    data: templateParams,
                    success: function success(response) {
                        controller.notifications.success('Template settings saved', {
                            autoClear: true,
                            clearDuration: 3200
                        });
                    },
                    error: function error(_error) {
                        controller.notifications.error('Error saving settings', {
                            autoClear: true,
                            clearDuration: 3200
                        });
                    }
                }); //ajax
            },
            didSelectSplashFile: function didSelectSplashFile(fileList) {
                var _this = this;

                var re = new RegExp('image/*');
                var controller = this;
                if (re.test(fileList[0].type)) {
                    var fd;

                    (function () {
                        var reader = new FileReader();
                        reader.onloadend = function () {
                            controller.set('splashURL', reader.result);
                        };
                        reader.readAsDataURL(fileList[0]);
                        //console.log(this.get('templateID'));
                        controller.set('isSavingSplash', true);
                        fd = new FormData();

                        fd.append('templateID', _this.get('templateID'));
                        fd.append('displayImageType', 'splash');
                        fd.append('image', fileList[0]);
                        _ember['default'].$.ajax({
                            url: _ember['default'].API_URL + '/templates/updateimage',
                            type: 'POST',
                            data: fd,
                            contentType: false,
                            processData: false,
                            mimeType: "multipart/form-data",
                            success: function success(response) {
                                controller.set('isSavingSplash', false);
                                controller.notifications.success('Splash image uploaded', {
                                    autoClear: true,
                                    clearDuration: 3200
                                });
                            },
                            error: function error(_error2) {
                                controller.set('isSavingSplash', false);
                                controller.notifications.error('error uploading splash', {
                                    autoClear: true,
                                    clearDuration: 3200
                                });
                            }
                        }); //ajax
                    })();
                }
            },
            didSelectThumbnailFile: function didSelectThumbnailFile(fileList) {
                var _this2 = this;

                var re = new RegExp('image/*');
                var controller = this;
                if (re.test(fileList[0].type)) {
                    var fd;

                    (function () {
                        var reader = new FileReader();
                        reader.onloadend = function () {
                            controller.set('thumbnailURL', reader.result);
                        };
                        reader.readAsDataURL(fileList[0]);
                        //console.log(this.get('templateID'));
                        //console.log(fileList[0])
                        controller.set('isSavingThumb', true);
                        fd = new FormData();

                        fd.append('templateID', _this2.get('templateID'));
                        fd.append('displayImageType', 'thumbnail');
                        fd.append('image', fileList[0]);
                        _ember['default'].$.ajax({
                            url: _ember['default'].API_URL + '/templates/updateimage',
                            type: 'POST',
                            data: fd,
                            contentType: false,
                            processData: false,
                            mimeType: "multipart/form-data",
                            success: function success(response) {
                                controller.set('isSavingThumb', false);
                                controller.notifications.success('Thumbnail image uploaded', {
                                    autoClear: true,
                                    clearDuration: 3200
                                });
                            },
                            error: function error(_error3) {
                                controller.set('isSavingThumb', false);
                                controller.notifications.error('error uploading thumbnail', {
                                    autoClear: true,
                                    clearDuration: 3200
                                });
                            }
                        }); //ajax
                    })();
                }
            }
        }
    });
});