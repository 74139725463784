define('elbe-ember-frond-end/controllers/list-org', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    init: function init() {
      this.get('session').authorize('authorizer:custom', { admin: true });
    },
    actions: {
      createNewOrg: function createNewOrg() {
        this.transitionToRoute('org');
      },
      editOrg: function editOrg(orgData) {
        //console.log(orgData);
        this.transitionToRoute('editOrg', orgData.orgID).then(function () /*orgData*/{});
      }
    }
  });
});