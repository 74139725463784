define('elbe-ember-frond-end/routes/company', ['exports', 'ember', 'ember-cli-pagination/remote/route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberCliPaginationRemoteRouteMixin, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberCliPaginationRemoteRouteMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'Company Info',

    /*
    actions: {
        error(error, transition) {
            if (error && error.status === 400 || 401) {
                return this.transitionTo('serverError');
            }
        }
    },
    */

    model: function model(params) {
      var controller = this;

      var orgInfo = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/org/' + controller.get('session.data.authenticated.org_ID'),
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            resolve(response);
          },
          error: function error(_error) {
            reject(_error);
          }
        });
      });

      var pricingCPM = orgInfo.then(function (orgInfo) {
        if (orgInfo.priceCPMID) {
          return _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/prices/' + orgInfo.priceCPMID,
            type: 'GET',
            dataType: 'json',
            success: function success(response) {},
            error: function error(_error2) {}
          });
        } else {
          return null;
        }
      });

      var pricingZIP = orgInfo.then(function (orgInfo) {
        if (orgInfo.priceZIPID) {
          return _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/prices/' + orgInfo.priceZIPID,
            type: 'GET',
            dataType: 'json',
            success: function success(response) {},
            error: function error(_error3) {}
          });
        } else {
          return null;
        }
      });

      return new _ember['default'].RSVP.hash({
        orgInfo: orgInfo,
        pricingCPM: pricingCPM,
        pricingZIP: pricingZIP
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      console.log(model);
    }
  });
});