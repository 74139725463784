define('elbe-ember-frond-end/controllers/adminpanel', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    jsDistregion: null,
    distRegionsOptions: ['World-Wide', 'Europe', 'NorthAmerica', 'Asia', 'Gallery'],
    distRegionSelected: 'World-Wide',
    init: function init() {
      this.get('session').authorize('authorizer:custom', { admin: true });
    },
    actions: {
      purgeJSFIle: function purgeJSFIle(jsfileName) {
        var controller = this;
        var distregion = controller.get('distRegionSelected');
        if (distregion === undefined || distregion === undefined) {
          controller.notifications.warning('Kindly select a region', {
            autoClear: true,
            clearDuration: 3200
          });
        } else {
          var fileParams = {
            fileName: jsfileName,
            distRegion: controller.get('distRegionSelected')
          };
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/purgeJSFIle',
            type: 'POST',
            data: fileParams,
            success: function success() {
              controller.notifications.success('Purge has started kindly wait for 10min', {
                autoClear: true,
                clearDuration: 3200
              });
            },
            error: function error() {
              controller.notifications.error('There was an error purging, try after sometime', {
                autoClear: true,
                clearDuration: 3200
              });
            } //error
          }); //ajax
        } //else
      }
    }
  });
});