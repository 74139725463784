define('elbe-ember-frond-end/initializers/debug-logger', ['exports', 'ember-debug-logger/initializers/debug-logger'], function (exports, _emberDebugLoggerInitializersDebugLogger) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberDebugLoggerInitializersDebugLogger['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberDebugLoggerInitializersDebugLogger.initialize;
    }
  });
});