define('elbe-ember-frond-end/helpers/getmember', ['exports', 'ember'], function (exports, _ember) {
  exports.getmember = getmember;

  function getmember(params) {
    var o = params[0];
    var p = params[1];
    return o[p];
  }

  exports['default'] = _ember['default'].Helper.helper(getmember);
});