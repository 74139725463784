define("elbe-ember-frond-end/templates/privacy", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 174,
            "column": 0
          }
        },
        "moduleName": "elbe-ember-frond-end/templates/privacy.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container body");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "main_container");
        var el3 = dom.createTextNode("\n      !-- top navigation -->\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "top_nav navbar-fixed-top");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "nav_menu");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "navbar-header");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6, "class", "navbar-brand");
        dom.setAttribute(el6, "href", "#");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/images/3dprimo_logo_beta.png");
        dom.setAttribute(el7, "alt", "primo");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("nav");
        dom.setAttribute(el5, "role", "navigation");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" /nav-bar nav");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment(" /empty class");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" /nav-menu ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" /top navigation ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" Jumbotron ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "right_col");
        dom.setAttribute(el3, "role", "main");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "clearfix");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-md-12");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "x_panel");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "section");
        var el8 = dom.createTextNode("\n\n\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "header-tos");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("h1");
        dom.setAttribute(el9, "class", "text-black text-center");
        var el10 = dom.createTextNode("Privacy Policy");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("h4");
        dom.setAttribute(el9, "class", "text-center text-normal");
        var el10 = dom.createTextNode("Your privacy is of important to us, this is how we protect your privacy.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "divider");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "row");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "col-md-6 left-panel-text");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        Through the usage of Visarity's Primo Composer both we and our customers contact a\n                        large amount of people. It is vital that our Ad production platform users show respect towards the ad viewers and their integrity.\n                        To maintain the highest level of quality in Primo Composer and security for our customers,\n                        we have strict rules and policies that our users must follow.\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        How To Contact Us\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        Visarity Technologies GmbH ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                        Lerchenstraße 28 a ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                        22767 Hamburg");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                        Germany");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        Business Registration Number : HRB 137473");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        Security in Primo Composer\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        Security is a highly prioritized area for us and we continuously work to maintain the highest\n                        level of security. We are using third party redundant Microsoft/Windows Azure & Amazon Web Services\n                        for our sites and services, which guarantee us to scale our services with your needs. We are making\n                        backups to increase your data protection, but we cannot be held liable for any faults, failures,\n                        errors, or issues, including permanent data loss due to third party server issues. All data is\n                        currently backed up and stored in multiple locations. They provide systems that discover any attempts\n                        to intrude and immediately notify us if that situation should occur. Access to the service is\n                        blocked while the investigation takes place.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        Data protection");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        Visarity saves and processes customers personal data, where applicable, to the extent this is necessary\n                        to complete the contract and for Visarity's contractual obligations. We make sure that your payment details\n                        data are protected. ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        By creating an account on our sites, you may subscribe to our newsletters. You can opt-out from your account\n                        at any time by unchecking the newsletter checkbox or from any email message that you receive by clicking the\n                        Unsubscribe link at the bottom. We will not send you any spam or off-topic emails. Most of our emails\n                        consist of product updates, new product info, notifications and special offers.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        Anti-spam policy\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        Our general terms and conditions regulate the usage of Primo Composer. We care about our customers and the ad viewers\n\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("br");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "col-md-6 right-panel-text");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        that view 3D Ads through Visarity. Therefore we have a strict policy against spam, click here to read the policy.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        Privacy and information about cookies");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        To be able to give you as a visitor a smooth and personal website experience, we use cookies. Learn more about cookies\n                        and how we respect and protect your privacy.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        Information about Google AdWords");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        We use the Google AdWords remarketing service to advertise on third party websites (including Google) to previous\n                        visitors to our site. That means that we advertise to previous visitors who haven’t completed a task on our site,\n                        for example registering for a free trial. This could be in the form of an advertisement on the Google search results\n                        page, or a website in the Google Display Network. Third-party vendors, including Google, use cookies and usage data.\n                        Google claims to process the data in the USA. Learn more about cookies and how to opt-out.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        General disclaimer");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        The information contained in this website is for general information purposes only. The information\n                        is provided by Visarity Technologies GmbH in Germany and while we endeavour to keep the information up to date and correct, we make no\n                        representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability\n                        or availability with respect to the website or the information, products, services, or related graphics contained on the website\n                        for any purpose. Any reliance you place on such information is therefore strictly at your risk. ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage,\n                        or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        Through this website, you might be able to link to other websites that are not under the control of Visarity Technologies GmbH, Germany. We have no\n                        control over nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation\n                        or endorse the views expressed within them.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        Every effort is made to keep the website up and running smoothly. However, Visarity Technologies GmbH in Germany takes no\n                        responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment(" x-panel ");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" col-md-12 ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" row ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n\n\n\n\n\n\n\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" main container ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});