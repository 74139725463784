define('elbe-ember-frond-end/routes/users', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        title: 'New User',
        actions: {
            error: function error(_error, transition) {
                if (_error && _error.status === 400 || 401) {
                    return this.transitionTo('serverError');
                }
            }
        },
        model: function model(params) {
            var controller = this;

            return _ember['default'].$.ajax({
                url: _ember['default'].API_URL + '/orglistshort',
                type: 'GET',
                dataType: 'json',
                success: function success(response) {
                    //console.log(response);
                },
                error: function error(_error2) {
                    //reject(error);
                }
            }); //ajax
        },
        setupController: function setupController(controller, model) {
            controller.set('user_firstName', "");
            controller.set('user_lastName', "");
            controller.set('user_email', '');
            controller.set('orgListArray', model);
            controller.set('user_role', '');
        }
    });
});