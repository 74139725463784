define("elbe-ember-frond-end/templates/terms-service", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 280,
            "column": 0
          }
        },
        "moduleName": "elbe-ember-frond-end/templates/terms-service.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container body");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "main_container");
        var el3 = dom.createTextNode("\n      !-- top navigation -->\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "top_nav navbar-fixed-top");
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "nav_menu");
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "navbar-header");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6, "class", "navbar-brand");
        dom.setAttribute(el6, "href", "#");
        var el7 = dom.createElement("img");
        dom.setAttribute(el7, "src", "/images/3dprimo_logo_beta.png");
        dom.setAttribute(el7, "alt", "Visarity");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("nav");
        dom.setAttribute(el5, "role", "navigation");
        var el6 = dom.createTextNode("\n                  ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" /nav-bar nav");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment(" /empty class");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" /nav-menu ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" /top navigation ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" Jumbotron ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "right_col");
        dom.setAttribute(el3, "role", "main");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "clearfix");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-md-12");
        var el6 = dom.createTextNode("\n              ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "x_panel");
        var el7 = dom.createTextNode("\n                ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "section");
        var el8 = dom.createTextNode("\n\n\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "header-tos");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("h1");
        dom.setAttribute(el9, "class", "text-black text-center");
        var el10 = dom.createTextNode("Terms Of Service");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode(" ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("h4");
        dom.setAttribute(el9, "class", "text-center text-normal");
        var el10 = dom.createTextNode("Everything you need to know about using Primo Composer.");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("br");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "divider");
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                  ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "row");
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "col-md-6 left-panel-text");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        Visarity Technologies GmbH, business registration no HRB 137473, with offices at Lerchenstraße 28 a, 22767 Hamburg, Germany (Visarity)\n                        has developed and is the owner of a cloud platform available on http://primo.design, which can be used for the creation\n                        and handling of on-line advertisements (the “Cloud Platform”). In sum, the Cloud Platform enables an individual user\n                        to create interactive on-line advertisements (i.e., Ad-Units) on the web site and has an advanced  3D editing feature\n                        that enables a quick creation of advertisements.\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        These terms of service (“");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("Terms");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("”) apply to the use of the Cloud Platform and the electronic service for the creation and handling of on-line\n                        advertisements available on the site (the “ ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("Service");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("”). The Terms are an agreement between you (“");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("You");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("” or the “");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("User");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("”) and Visarity.\n                        By entering into an agreement with Visarity with reference to these Terms, or otherwise by using, creating an account and/or\n                        by logging onto the Cloud Platform, You accept the Terms.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("h4");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        1. Provision of Services.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("1.1");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" Visarity hereby grants to the User – during the agreed term – a non-exclusive right to use the Cloud Platform and to\n                        use the Service for agreed number of accounts In addition, Visarity agrees to provide support services if so request by the User.\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("1.2");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" The Cloud Platform and the Service may only be used for the agreed permitted use, i.e., unless otherwise is agreed and\n                        in addition to what follows from the general specification of the Cloud Platform, the creation, hosting and publishing of advertisements in the form\n                        of so called Ad-Units with a specific text, 3D graphics, elements or any other sort of advertisement the User can create using the Cloud Platform and the Service and\n                        the associated HTML5 or BIN file. In addition, the Cloud Platform and the Service may not be used for illegal purposes or purposes that violate\n                        any applicable laws and regulations or for any other purposes that are or may be perceived as illegal, obscene, abusive, libellous, threatening,\n                        vulgar or otherwise can be regarded reprehensible.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("h4");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        2. Fees and service charges");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("2.1");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" Visarity is entitled to a set-up fee, a monthly license fees, a variable fee and, to the extent applicable,\n                        service charges, unless otherwise is agreed, according to Visarity's price list applicable from time to time. ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("2.2");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" The set-up fee is payable to Visarity on agreed delivery date or otherwise when the User started to use the Cloud Platform and\n                        the Service. The monthly license fee shall be paid annually in advance against invoice during the agreed term with effect from\n                        the agreed delivery date (or otherwise when the User started to use the Service) and shall be payable by the User within 20 days\n                        of receipt by the User of the  invoice. The variable fee and the service charges, if applicable, shall be invoiced by Visarity\n                        on a monthly basis the calendar month after the relevant advertisements have been published and/or the support services have been\n                        performed and shall be payable by the User within 20 days of receipt by the User of such invoice. Visarity reserves the right to\n                        charge the User a penalty interest of 12 per cent per annum or the highest rate permitted under applicable law (if higher) in respect\n                        of the late payment of any sum due from the due date until payment is made.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("2.3");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" Unless otherwise stated, all amounts stated are expressed exclusive of value added tax. Value added tax and other charges levied on the fees payable\n                        by the User to Bannerflow shall, if applicable, be paid by the User.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("h4");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        3. Service Levels");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        Visarity warrants that the Cloud Platform and the Services in all material respects will conform to the specifications and service\n                        levels from time to time undertaken by Visarity or as separately agreed upon.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("h4");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        4. Proprietary rights ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("4.1");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" Visarity warrants its title to and ownership of the Cloud Platform and that Bannerflow has the right, power and\n                        authority to assign or license the same upon agreed terms and conditions. Nothing in these Terms gives the User the right to use any of Visarity's\n                         trade names, trademarks, logos, domain names or other distinguishing marks.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("4.2");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" The User (or its licensors) owns all property rights to the advertisements uploaded to or created using\n                        the Cloud Platform and the Service. Visarity obtains no right, title or interest from the User (or its licensors) in or to any content\n                        the User submit, post, transmit or display on or through the Cloud Platform and the Service.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("h4");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        5. Infringement and indemnity");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("5.1");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" Visarity will indemnify the User for any damages, liabilities, losses, costs and expenses\n                        (including reasonable attorneys’ fees) that may be awarded to any third party based on the grounds that the Cloud Platform and the Service\n                        infringe or otherwise violate the intellectual property rights of a third party, except to the extent arising from the User’s use\n                        thereof in violation of agreed terms. Visarity shall have sole control over the defence and settlement of any indemnified claim and the\n                        User shall provide reasonable cooperation in connection therewith. The User may participate in the defence at its own expense,\n                        and all settlements require its consent (not to be unreasonably withheld).");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("5.2");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" It is the User’s responsibility to ensure that advertisements uploaded to the Cloud Platform are legal,\n                        virus free and that they are in agreed format in order to be able to use the Service.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("h4");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        6. Limitation of liability");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("6.1");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" The Parties’ liability to each other for any damage and/or cost resulting from breach of the contractual\n                        obligations and negligent actions or omissions shall be subject to the below limitations. ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("6.2");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" Each party’s entire liability shall in any case not exceed an amount equal to two times the\n                        aggregate of the total fees paid or payable by the User underthe agreed term. The Parties shall not be liable to each other hereunder ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment(" end of left panel");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                    ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "col-md-6 right-panel-text");
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        for any lost profits, loss of anticipated savings nor any other type of indirect or consequential loss of any act or omission of\n                        the other party regardless of whether such losses were foreseeable or not.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("6.3");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" The aforementioned limitations shall not apply in case of (i) fraudulent misrepresentation (ii) breaches of confidentiality obligations\n                        (Section 7) and (iii) breach of undertakings in Section 5, in which case the liability thus shall not be limited per above.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("6.4");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" The aforementioned limitations shall further not apply to the User with regard to unpaid fees.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("6.5");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" Except for the warranties expressly set forth in this agreement, Visarity expressly disclaims all other\n                        all warranties with respect to the services, including all statutory and implied warranties, all warranties of merchantability and\n                        fitness for a particular purpose or use (even if Bannerflow has been made aware of such purpose or use), all warranties that the\n                        services shall be uninterrupted or error free, all warranties of title and non-infringement, and all warranties arising from course\n                        of performance, course of dealing, or usage of trade, or their equivalents under the laws of any jurisdiction. Because some jurisdictions\n                        do not allow the exclusion of implied warranties, the above exclusion of implied warranties may not apply to you.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("h4");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        7. Confidentiality");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        Each of the Parties hereto undertakes to the other to keep confidential all information (written or oral), concerning the business and\n                        affairs of the other party that it has obtained or received as a result of the discussions leading up to, entrance into or performance\n                        of this agreement. The obligation to maintain the confidentiality of information obtained or received does not extend nor apply to\n                        information which at the time of the party’s disclosure is in the public domain or after such disclosure, becomes in the public\n                        domain otherwise than through an unauthorized disclosure by such party.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("h4");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        8. Term and termination");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("8.1");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" The right to use the Cloud Platform and the Service is – unless otherwise is agreed separately – valid for an\n                        initial period of one year from agreed delivery date or otherwise from the date the User started to use the Service. Unless\n                        terminated in writing at least three months prior to the expiry date, the agreement will thereafter be automatically extended\n                        for an indefinite period with a mutual right to terminate the agreement by giving six months’ prior notice in writing.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("8.2");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" In addition to the above, the agreement may immediately be terminated:");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        (a) by Visarity if the User fails to pay any sum due hereunder within 45 days of the due date of such payment;");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        (b) by either party if the other party commits a material breach, which (in the case of a breach capable of being remedied)\n                        shall not have been remedied within seven days following receipt of a written request to remedy the same from the non- breaching party; and");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        (c) by either party if the other party enters into liquidation or becomes bankrupt or insolvent or makes a composition\n                        with its creditors or generally fails to pay its debts as they become due.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("8.3");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" At the written request of the User, at any time up until three months after termination of the\n                        agreement, Visarity shall return to the User, without further charges and in the format reasonably stipulated by the User,\n                        a copy of the User’s content uploaded on the Cloud Platform.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("h4");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        9. Force majeure");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("9.1");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" If a Force Majeure Event occurs, the party that is prevented by that Force Majeure event from\n                        performing any one or more obligations under this agreement (the “Nonperforming Party”) will be excused from performing\n                        those obligations, on condition that it complies with its obligations under section 9.3.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("9.2");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" For purposes of this agreement, “Force Majeure Event” means, with respect to the Nonperforming Party,\n                        any event or circumstance, regardless of whether it was foreseeable or not, that was not caused by the Nonperforming Party and\n                        that prevents the Nonperforming Party from complying with any of its obligations under this agreement, provided that such Nonperforming\n                        Party uses reasonable efforts to do so.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("9.3");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" Upon occurrence of a Force Majeure Event, the Nonperforming Party shall promptly notify the other party\n                        of occurrence of that Force Majeure Event, its effect on performance, and how long that the Nonperforming Party expects it to last.\n                        Thereafter the Nonperforming Party shall update that information as reasonably necessary. During a Force Majeure Event,\n                        the Nonperforming Party shall use reasonable efforts to limit damages to the other party and to resume its performance under this agreement.");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("h4");
        dom.setAttribute(el10, "class", "bold");
        var el11 = dom.createTextNode("\n                        10. Applicable law and jurisdiction");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("10.1");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" These Terms shall be governed by and construed in accordance with German law.\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("span");
        dom.setAttribute(el11, "class", "bold");
        var el12 = dom.createTextNode("10.2");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode(" Any dispute, controversy or claim arising out of or in connection with this contract, or\n                        the breach, termination or invalidity thereof, shall be finally settled by arbitration administered by the German Institue Of Arbitration (DIS).");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("br");
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                      ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("p");
        var el11 = dom.createTextNode("\n                        The Rules for Expedited Arbitrations shall apply, unless the DIS in its discretion determines, taking into account the complexity of the case,\n                        the amount in dispute and other circumstances, that the Arbitration Rules shall apply. In the latter case, the DIS shall also decide whether\n                        the Arbitral Tribunal shall be composed of one or three arbitrators.  The seat of arbitration shall be Cologne. The language to be used in\n                        the arbitral proceedings shall be German.\n                      ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n\n                    ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                  ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n              ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n\n              ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n              ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment(" x-panel ");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n          ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n          ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" col-md-12 ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" row ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n\n\n\n\n\n\n\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" main container ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});