define('elbe-ember-frond-end/helpers/decrement', ['exports', 'ember'], function (exports, _ember) {
  exports.decrement = decrement;

  function decrement(params, hash) {
    var value = params[0];

    return (value | 0) - 1;
  }

  exports['default'] = _ember['default'].Helper.helper(decrement);
});