define('elbe-ember-frond-end/initializers/deprecations', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize(application) {
    _ember['default'].Debug.registerDeprecationHandler(function (message, options, next) {
      if (options.id === 'ember-application.injected-container') {
        return;
      }
      if (options.id === 'ember-views.dispatching-modify-property') {
        return;
      } //
      if (options.id === 'ember-debug.warn-options-missing') {
        return;
      }
      next(message, options);
    });
  }

  exports['default'] = {
    name: 'deprecations',
    initialize: initialize
  };
});