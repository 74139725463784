define('elbe-ember-frond-end/routes/adminpanel', ['exports', 'ember', 'elbe-ember-frond-end/utils/formatUtils', 'elbe-ember-frond-end/config/environment', 'underscore', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _elbeEmberFrondEndUtilsFormatUtils, _elbeEmberFrondEndConfigEnvironment, _underscore, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'primo admin panel',
    actions: {},
    model: function model(params) {
      return new _ember['default'].RSVP.hash({
        sysversion: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/version',
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            //console.log(response);
          },
          error: function error(_error) {}
        }), //ajax
        jsFiles: _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/aws/listjsFiles',
          type: 'GET',
          dataType: 'json',
          success: function success(response) {
            //console.log(response);
          },
          error: function error(_error2) {}
        })
      });
    },
    setupController: function setupController(controller, model) {
      var filteredFiles = _underscore['default'].reject(model.jsFiles, function (item) {
        return item.id == 0;
      });
      var sortList = _underscore['default'].sortBy(filteredFiles, function (item) {
        return item.lastModified;
      });
      controller.set('jsFiles_list', sortList.reverse());
      controller.set('primo_sys_info', model.sysversion);
    }
  });
});