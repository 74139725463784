define('elbe-ember-frond-end/components/co2-tc-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    //  blockAgreeButton: true,
    // showBlink: true,
    showForm: false,
    co2Tracking: false,

    init: function init() {
      this._super();
      if (this.orgData.co2) {
        this.set('co2Tracking', this.campaignData.co2Tracking ? true : false);
      } else {
        this.set('co2Tracking', false);
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super();
      /*
      let container = document.getElementById("tc-container");
      container.addEventListener('scroll', () => {
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;
        console.log(`${scrollTop} ${clientHeight} ${scrollHeight}`)
        if (scrollTop > 64) {
          _this.set('showBlink', false);
        }
        
        if (scrollTop + clientHeight >= scrollHeight) {                
            _this.set('blockAgreeButton', false);
        }
      });          
      */
    },

    apiCallCO2Tracking: function apiCallCO2Tracking(creativeID, d) {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        var url = _ember['default'].API_URL + "/campaigns/update/" + creativeID;
        var data = { co2Tracking: d };
        xhr.addEventListener("load", function () {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject();
          }
        });
        xhr.addEventListener('error', function () {
          reject();
        });
        xhr.responseType = "json";
        xhr.open("POST", url);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.send(JSON.stringify(data));
      });
    },

    apiCallCO2Org: function apiCallCO2Org(orgID, d) {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        var url = _ember['default'].API_URL + "/org/create";
        var data = {
          orgID: orgID,
          co2Org: d
        };
        xhr.addEventListener("load", function () {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject();
          }
        });
        xhr.addEventListener('error', function () {
          reject();
        });
        xhr.responseType = "json";
        xhr.open("POST", url);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.send(JSON.stringify(data));
      });
    },

    turnOn: function turnOn() {
      var _this = this;
      var d = moment().format("YYYY-MM-DD");
      this.apiCallCO2Tracking(this.campaignData.campaignID, d).then(function () {
        _this.set('co2Tracking', true);
        _this.notifications.success('CO2e Tracking enabled - saved', { autoClear: true, clearDuration: 2200 });
      })['catch'](function () {
        _this.set('co2Tracking', false);
        _this.notifications.error('Server error, retry after sometime', {
          autoClear: true,
          clearDuration: 2200
        });
      });
    },

    actions: {
      onSwitchChange: function onSwitchChange(x) {
        var _this = this;
        if (x) {
          if (this.orgData.co2) {
            this.turnOn();
          } else {
            this.set('showForm', true);
          }
        } else {
          this.apiCallCO2Tracking(this.campaignData.campaignID, null).then(function () {
            _this.set('co2Tracking', false);
            _this.notifications.success('CO2e Tracking disabled - saved', { autoClear: true, clearDuration: 2200 });
          })['catch'](function () {
            _this.set('co2Tracking', false);
            _this.notifications.error('Server error, retry after sometime', {
              autoClear: true,
              clearDuration: 2200
            });
          });
        }
      },
      confirmTC: function confirmTC() {
        var _this2 = this;

        var _this = this;
        this.set('showForm', false);
        this.apiCallCO2Org(this.orgData.orgID, 1).then(function () {
          _this.notifications.success('CO2e T&C confirmation - saved', { autoClear: true, clearDuration: 2200 });
          _this2.turnOn();
        })['catch'](function () {
          _this.set('co2Tracking', false);
          _this.notifications.error('Server error, retry after sometime', {
            autoClear: true,
            clearDuration: 2200
          });
        });
      },
      closeForm: function closeForm(x) {
        this.set('showForm', false);
        this.set('co2Tracking', false);
      }
    }
  });
});