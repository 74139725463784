define("elbe-ember-frond-end/templates/new-campaign", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 6
          }
        },
        "moduleName": "elbe-ember-frond-end/templates/new-campaign.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container body");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "main_container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    \n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["content", "top-navbar", ["loc", [null, [3, 4], [3, 18]]]], ["inline", "new-campaign-component", [], ["campaignID", ["subexpr", "@mut", [["get", "campaignID", ["loc", [null, [4, 40], [4, 50]]]]], [], []], "campaignName", ["subexpr", "@mut", [["get", "campaignName", ["loc", [null, [4, 64], [4, 76]]]]], [], []], "user_admin_role", ["subexpr", "@mut", [["get", "user_admin_role", ["loc", [null, [4, 93], [4, 108]]]]], [], []], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 115], [4, 120]]]]], [], []], "session", ["subexpr", "@mut", [["get", "session", ["loc", [null, [4, 129], [4, 136]]]]], [], []]], ["loc", [null, [4, 4], [4, 138]]]]],
      locals: [],
      templates: []
    };
  })());
});