define('elbe-ember-frond-end/routes/overview-zip', ['exports', 'elbe-ember-frond-end/config/environment', 'ember', 'ember-cli-pagination/remote/route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _elbeEmberFrondEndConfigEnvironment, _ember, _emberCliPaginationRemoteRouteMixin, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberCliPaginationRemoteRouteMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'Overview of ZIPs',

    model: function model() {
      return {
        token: this.get('session.data.authenticated.token'),
        userID: this.get('session.data.authenticated.user_ID'),
        admin: this.get('session.data.user_admin_role')
      };
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
    }
  });
});