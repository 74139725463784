define('elbe-ember-frond-end/controllers/overview-zip', ['exports', 'ember', 'elbe-ember-frond-end/config/environment', 'elbe-ember-frond-end/utils/timeZone', 'npm:XLSX'], function (exports, _ember, _elbeEmberFrondEndConfigEnvironment, _elbeEmberFrondEndUtilsTimeZone, _npmXLSX) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    remodal: _ember['default'].inject.service(),

    searchTerm: "",

    orgList: [{ name: "All", id: null }],
    org: { name: "All", id: null },

    startDate: null,
    endDate: null,

    baseList: [],

    minDate: moment().subtract(360, 'days').startOf('month').toISOString(),

    fetchZip: function fetchZip() {
      var _this = this;

      var controller = this;
      _ember['default'].$.ajax({
        url: _ember['default'].API_URL + '/extern/v3/zip/list/' + this.model.userID,
        type: 'POST',
        dataType: 'json',
        data: JSON.stringify({ startTS: this.startDate, endTS: this.endDate }),
        headers: {
          "Content-Type": "application/json",
          "Authorization": this.model.token
        },
        success: function success(response) {
          var ol = [{ name: "All", id: null }];
          response.result.forEach(function (e) {
            ol.push({ name: e.orgName, id: e.orgID });
          });

          controller.set('baseList', response.result);
          //controller.set('overallList', response.result);
          controller.set('orgList', ol);
        },
        error: function error(_error) {}
      }); //ajax
    },

    init: function init() {
      var s = moment().startOf('month').toDate();
      var e = moment().endOf('day').toDate();

      var startDate = _elbeEmberFrondEndUtilsTimeZone['default'].toISOString(s);
      var endDate = _elbeEmberFrondEndUtilsTimeZone['default'].toISOString(e);

      this.set('startDate', startDate);
      this.set('endDate', endDate);
    },

    modelObserver: (function () {
      this.fetchZip();
    }).observes('model'),

    overallList: (function () {
      var _this2 = this;

      var bl = this.get('baseList');
      var ol = [];
      var tol = undefined;
      var _this = this;

      function addOrgUpdate(org) {
        return function () {
          _ember['default'].set(org, 'totalZips', 0);
          _ember['default'].set(org, 'totalZipsOnInvoice', 0);
          _ember['default'].set(org, 'totalZipsNullInvoice', 0);
          org.campaigns.forEach(function (campaign) {
            _ember['default'].set(campaign, 'totalZips', 0);
            _ember['default'].set(campaign, 'totalZipsOnInvoice', 0);
            _ember['default'].set(campaign, 'totalZipsNullInvoice', 0);
            campaign.confs.forEach(function (conf) {
              _ember['default'].set(conf, 'totalZips', 0);
              _ember['default'].set(conf, 'totalZipsOnInvoice', 0);
              _ember['default'].set(conf, 'totalZipsNullInvoice', 0);
              conf.zips.forEach(function (zip) {
                _ember['default'].set(org, 'totalZips', org.totalZips + 1);
                _ember['default'].set(campaign, 'totalZips', campaign.totalZips + 1);
                _ember['default'].set(conf, 'totalZips', conf.totalZips + 1);
                if (zip.onInvoice === null) {
                  _ember['default'].set(org, 'totalZipsNullInvoice', org.totalZipsNullInvoice + 1);
                  _ember['default'].set(campaign, 'totalZipsNullInvoice', campaign.totalZipsNullInvoice + 1);
                  _ember['default'].set(conf, 'totalZipsNullInvoice', conf.totalZipsNullInvoice + 1);
                } else if (zip.onInvoice) {
                  _ember['default'].set(org, 'totalZipsOnInvoice', org.totalZipsOnInvoice + 1);
                  _ember['default'].set(campaign, 'totalZipsOnInvoice', campaign.totalZipsOnInvoice + 1);
                  _ember['default'].set(conf, 'totalZipsOnInvoice', conf.totalZipsOnInvoice + 1);
                }
              });
            });
          });
        };
      }

      function onInvoiceChange(org, campaign, conf, zip) {
        zip.onInvoiceChange = function () {

          console.log(zip);

          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/extern/v3/zip/update/' + _this.model.userID,
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({ zipID: zip.zipID, onInvoice: zip.onInvoice ? 1 : 0 }),
            headers: {
              "Content-Type": "application/json",
              "Authorization": _this.model.token
            },
            success: function success(response) {},
            error: function error(_error2) {}
          }); //ajax

          org.update();
        };
      }

      function checkTerm(c, sts) {
        var ret = true;
        function check(s, c) {
          s = s.toLowerCase();
          c = c.toLowerCase();
          return s.indexOf(c) !== -1;
        }
        sts.forEach(function (s) {
          var f = check(c.campaignID, s) || check(c.campaignName, s) || check(c.campaignUserName, s);

          ret = ret && f;
        });
        return ret;
      }

      if (bl) {
        (function () {
          var org = _this2.get('org');
          if (org.id === null) {
            tol = ol = bl;
          } else {
            bl.forEach(function (e) {
              if (e.orgID === org.id) {
                ol.push(e);
              }
            });
            tol = ol;
          }

          if (_this2.searchTerm && _this2.searchTerm.length > 2) {
            (function () {
              var sts = _this2.searchTerm.split(" ");
              ol = [];
              tol.forEach(function (org) {
                var newOrg = null;
                org.campaigns.forEach(function (c) {
                  if (checkTerm(c, sts)) {
                    if (newOrg === null) {
                      newOrg = {
                        orgName: org.orgName,
                        orgID: org.orgID,
                        campaigns: []
                      };
                      ol.push(newOrg);
                    }
                    newOrg.campaigns.push(c);
                  }
                });
              });
            })();
          }
        })();
      }

      ol = JSON.parse(JSON.stringify(ol));

      ol.forEach(function (org) {
        org.totalZips = 0;
        org.totalZipsOnInvoice = 0;
        org.totalZipsNullInvoice = 0;
        org.update = addOrgUpdate(org);
        org.campaigns.forEach(function (campaign) {
          campaign.totalZips = 0;
          campaign.totalZipsOnInvoice = 0;
          campaign.totalZipsNullInvoice = 0;
          campaign.confs.forEach(function (conf) {
            conf.totalZips = 0;
            conf.totalZipsOnInvoice = 0;
            conf.totalZipsNullInvoice = 0;
            conf.zips.forEach(function (zip) {
              org.totalZips++;
              campaign.totalZips++;
              conf.totalZips++;
              if (zip.onInvoice === null) {
                conf.totalZipsNullInvoice++;
                campaign.totalZipsNullInvoice++;
                org.totalZipsNullInvoice++;
              } else if (zip.onInvoice) {
                conf.totalZipsOnInvoice++;
                campaign.totalZipsOnInvoice++;
                org.totalZipsOnInvoice++;
              }
              zip.zipCreatedAt = new Date(zip.zipCreatedAt).toLocaleString();
              onInvoiceChange(org, campaign, conf, zip);
            });
          });
        });
      });

      return ol;
    }).property('baseList', 'org', 'searchTerm'),

    actions: {
      applyDates: function applyDates(startDate, endDate) {
        startDate = startDate + "T00:00:00.000"; // moment(startDate).startOf('day').toISOString(true);
        endDate = endDate + "T23:59:59.999"; //moment(endDate).endOf('day').toISOString(true);           
        this.set('startDate', startDate);
        this.set('endDate', endDate);
        this.fetchZip();
      },
      cancelDates: function cancelDates() {},
      changeOrg: function changeOrg(o) {
        this.set("org", o);
        this.set('user', { name: "All", id: null, email: "" });
      },
      getFullReport: function getFullReport() {
        var bookType = "xlsx";
        var wb = _npmXLSX['default'].utils.book_new();
        var ws_name = "ZIP-Report-" + this.get('startDate').split("T")[0] + '/ ' + this.get('endDate').split("T")[0];
        var ol = this.get('overallList');

        ol.forEach(function (org) {
          var ws_data = [["Pos", "ID", "Creative Name", "Creative ID", "Config", "User", "User-Email", "CreatedAt", "Url"]];
          var cnt = 0;
          var zips = 0;
          var a;

          org.campaigns.forEach(function (campaign) {
            campaign.confs.forEach(function (conf) {
              conf.zips.forEach(function (zip) {
                if (zip.onInvoice) {
                  a = [];
                  a.push(++cnt);
                  a.push(zip.zipID);
                  a.push(campaign.campaignName);
                  a.push(campaign.campaignID);
                  a.push(conf.campaignConf || "default");
                  a.push(zip.zipUserName);
                  a.push(zip.zipUserEmail);
                  a.push(zip.zipCreatedAt);
                  a.push(zip.zipURL);
                  zips++;
                  ws_data.push(a);
                }
              });
            });
          });

          a = [];
          ws_data.push(a);
          a = [];
          a.push(zips);
          a.push("Chargeable Zips");
          ws_data.push(a);

          var ws = _npmXLSX['default'].utils.aoa_to_sheet(ws_data);
          _npmXLSX['default'].utils.book_append_sheet(wb, ws, org.orgName);
        });

        var write_opts = { bookType: bookType };
        _npmXLSX['default'].writeFile(wb, ws_name + "." + bookType, write_opts);
      },
      searchFilterEnter: function searchFilterEnter(searchStr) {
        this.set("searchTerm", searchStr);
      }
    }
  });
});