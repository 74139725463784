define('elbe-ember-frond-end/router', ['exports', 'ember', 'elbe-ember-frond-end/config/environment'], function (exports, _ember, _elbeEmberFrondEndConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _elbeEmberFrondEndConfigEnvironment['default'].locationType
  });

  /************************************************************/

  // main function
  /*
  metrics: Ember.inject.service(),
  
  didTransition() {
    //this._super();
    this._trackPage();
  },
   _trackPage() {
    Ember.run.scheduleOnce('afterRender', this, () => {
      const page = this.get('url');
      const title = this.getWithDefault('currentRouteName', 'unknown');
       Ember.get(this, 'metrics').trackPage({
        page,
        title
      });
    });
  }
  */
  function scrollToY(scrollTargetY, speed, easing) {
    // scrollTargetY: the target scrollY property of the window
    // speed: time in pixels per second
    // easing: easing equation to use

    var scrollY = window.scrollY,
        scrollTargetY = scrollTargetY || 0,
        speed = speed || 2000,
        easing = easing || 'easeOutSine',
        currentTime = 0;

    // min time .1, max time .8 seconds
    var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

    // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
    var PI_D2 = Math.PI / 2,
        easingEquations = {
      easeOutSine: function easeOutSine(pos) {
        return Math.sin(pos * (Math.PI / 2));
      },
      easeInOutSine: function easeInOutSine(pos) {
        return -0.5 * (Math.cos(Math.PI * pos) - 1);
      },
      easeInOutQuint: function easeInOutQuint(pos) {
        if ((pos /= 0.5) < 1) {
          return 0.5 * Math.pow(pos, 5);
        }
        return 0.5 * (Math.pow(pos - 2, 5) + 2);
      }
    };

    // add animation loop
    function tick() {
      currentTime += 1 / 60;

      var p = currentTime / time;
      var t = easingEquations[easing](p);

      if (p < 1) {
        requestAnimFrame(tick);

        window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t);
      } else {
        window.scrollTo(0, scrollTargetY);
      }
    }

    // call it once to get started
    tick();
  }

  _ember['default'].Route.reopen({
    activate: function activate() {
      // first add raf shim
      // http://www.paulirish.com/2011/requestanimationframe-for-smart-animating/
      /*
      window.requestAnimFrame = (function () {
        return window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          function (callback) {
            window.setTimeout(callback, 1000 / 60);
          };
      })();
      */

      this._super();
      //scrollToY(0, 2500, 'easeInOutQuint');
    }
  });

  /************************************************************/

  Router.map(function () {

    this.route('verify-email', {
      path: '/verify'
    });
    this.route('login', {
      path: '/'
    });
    this.route('login', {
      path: '/login'
    });
    this.route('dashboard', {
      path: '/dashboard'
    });
    this.route('creative-template', {
      path: '/templates'
    });
    this.route('new-campaign', {
      path: '/new_campaign'
    });
    this.route('format-gallery', {
      path: '/from-gallery'
    });
    this.route('edit-proj-settings', {
      path: '/proj_settings/:id'
    });
    this.route('campaign-details', {
      path: '/campaign_details'
    });
    this.route('brands', {
      path: '/update_brand/:brandID'
    });
    this.route('brands', {
      path: '/new_brand'
    });
    this.route('formats', {
      path: '/update_format/:formatID'
    });
    this.route('formats', {
      path: '/new_format'
    });

    this.route('galleries', {
      path: '/update_gallery/:galleryID'
    });
    this.route('galleries', {
      path: '/new_gallery'
    });
    this.route('camapign-analytics', {
      path: '/analytics/:id/:code'
    });
    this.route('publishers', {
      path: '/publisher'
    });
    this.route('template', {
      path: '/templates/:id'
    });
    this.route('tagGen', {
      path: '/tagGen/:id'
    });
    this.route('zipGen', {
      path: '/zipGen/:id'
    });
    this.route('list-brands', {
      path: '/brands'
    });
    this.route('list-formats', {
      path: '/formats'
    });
    this.route('list-galleries', {
      path: '/galleries'
    });
    this.route('list-publisher', {
      path: '/publishers'
    });
    this.route('serverError', {
      path: '/error'
    });
    this.route('users', {
      path: '/users'
    });
    this.route('list-users', {
      path: '/list-users'
    });
    this.route('editUser', {
      path: '/edituser/:id'
    });
    this.route('forgotpassword', {
      path: '/lostpassword'
    });
    this.route('privacy', {
      path: '/privacy'
    });
    this.route('terms-service', {
      path: '/termsOfService'
    });
    this.route('cookie', {
      path: '/cookie'
    });
    this.route('changepsswd', {
      path: '/password'
    });
    this.route('analyticsError', {
      path: '/analyticserror'
    });
    this.route('template-list', {
      path: '/managetemplates'
    });
    this.route('impressum', {
      path: '/impressum'
    });
    this.route('template-settings', {
      path: '/template-settings/:id'
    });
    this.route('adminpanel', {
      path: '/admin'
    });
    this.route('editPublisher', {
      path: '/editpublisher'
    });
    this.route('org', {
      path: '/org'
    });
    this.route('list-org', {
      path: '/listorg'
    });
    this.route('forbiddenError', {
      path: '/forbidden'
    });
    this.route('editOrg', {
      path: '/orgdetails/:id'
    });
    this.route('overview-live', {
      path: '/overview-live'
    });
    this.route('overview-zip', {
      path: '/overview-zip'
    });
    this.route('company', {
      path: '/company'
    });
  });

  exports['default'] = Router;
});