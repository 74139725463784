define('elbe-ember-frond-end/controllers/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    init: function init() {
      //console.log('app.js');
    }
  });
});
// app/controllers/application.js
/*actions: {
logout: function() {
	localStorage.clear();
	this.transitionTo('login');
}
}*/