define("elbe-ember-frond-end/templates/template-list", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 67,
                "column": 46
              },
              "end": {
                "line": 71,
                "column": 46
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/template-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "preview_btn_column");
            var el2 = dom.createTextNode("\n                                                  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "title", "Preview Template");
            dom.setAttribute(el2, "type", "button");
            dom.setAttribute(el2, "data-original-title", "Preview");
            dom.setAttribute(el2, "class", "preview_name_column btn btn-dark btn-md");
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "fa fa-eye");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" Preview ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                                              ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element1);
            return morphs;
          },
          statements: [["element", "action", ["showPreview", ["get", "template", ["loc", [null, [69, 198], [69, 206]]]]], ["bubbles", false], ["loc", [null, [69, 175], [69, 222]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 79,
                "column": 52
              },
              "end": {
                "line": 81,
                "column": 52
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/template-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                                    Edit Template Settings\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 90,
                "column": 50
              },
              "end": {
                "line": 92,
                "column": 52
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/template-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "title", "Update Template");
            dom.setAttribute(el1, "type", "button");
            dom.setAttribute(el1, "data-original-title", "Update Template");
            dom.setAttribute(el1, "class", "btn btn-info btn-md  btn_dashboard");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-upload");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Update ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", ["updateTemplateJSON", ["get", "template", ["loc", [null, [91, 209], [91, 217]]]]], ["bubbles", false], ["loc", [null, [91, 179], [91, 233]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 92,
                "column": 52
              },
              "end": {
                "line": 94,
                "column": 50
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/template-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                                                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "title", "Update Template");
            dom.setAttribute(el1, "disabled", "true");
            dom.setAttribute(el1, "type", "button");
            dom.setAttribute(el1, "data-original-title", "Update Template");
            dom.setAttribute(el1, "class", "btn btn-dark btn-md  btn_dashboard");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "fa fa-upload");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode(" Update ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 36
            },
            "end": {
              "line": 104,
              "column": 36
            }
          },
          "moduleName": "elbe-ember-frond-end/templates/template-list.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                                        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln column_width_small");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("img");
          dom.setAttribute(el3, "height", "48");
          dom.setAttribute(el3, "width", "48");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln bold");
          var el3 = dom.createTextNode("\n                                                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "campaign_name_column");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                                                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "campaign_name_col_subtxt");
          var el4 = dom.createTextNode(" Created : ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                                            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln");
          var el3 = dom.createTextNode("\n                                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "campaign_name_column");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                                              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "campaign_name_col_subtxt");
          var el4 = dom.createTextNode(" Updated : ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                                            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln");
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment(" Preview ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln ");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                                            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment(" /Preview ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment(" Settings ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln ");
          var el3 = dom.createTextNode("\n                                                  ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "type", "button");
          dom.setAttribute(el3, "class", "btn btn-dark btn-md has-tooltip btn_dashboard_icon");
          dom.setAttribute(el3, "data-test", "on-element");
          dom.setAttribute(el3, "data-tooltip-content", "Change the Template settings");
          var el4 = dom.createElement("i");
          dom.setAttribute(el4, "class", "fa fa fa-wrench");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                                                  ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                                            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment(" /Settings ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment(" Update Template ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln column_width_small");
          var el3 = dom.createTextNode("\n\n                                                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "preview_btn_column");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                                                   ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          dom.setAttribute(el4, "class", "preview_name_col_subtxt");
          var el5 = dom.createTextNode(" last update on : ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                                                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n                                            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment(" /Update Template ");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "class", "span2 vertical_align_coln column_width_small");
          var el3 = dom.createTextNode("\n                                                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "type", "button");
          dom.setAttribute(el3, "class", "btn  btn-md btn_dashboard_icon");
          var el4 = dom.createElement("i");
          dom.setAttribute(el4, "class", "fa fa fa-trash");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                                            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                                        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(element2, [3, 1]);
          var element4 = dom.childAt(element2, [5]);
          var element5 = dom.childAt(element2, [7]);
          var element6 = dom.childAt(element2, [19, 1]);
          var element7 = dom.childAt(element2, [25, 1]);
          var element8 = dom.childAt(element2, [29, 1]);
          var morphs = new Array(14);
          morphs[0] = dom.createAttrMorph(element2, 'id');
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
          morphs[2] = dom.createAttrMorph(element3, 'src');
          morphs[3] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
          morphs[5] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
          morphs[6] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
          morphs[7] = dom.createMorphAt(dom.childAt(element2, [9]), 1, 1);
          morphs[8] = dom.createMorphAt(dom.childAt(element2, [13]), 1, 1);
          morphs[9] = dom.createElementMorph(element6);
          morphs[10] = dom.createMorphAt(element6, 2, 2);
          morphs[11] = dom.createMorphAt(element7, 1, 1);
          morphs[12] = dom.createMorphAt(dom.childAt(element7, [3]), 1, 1);
          morphs[13] = dom.createElementMorph(element8);
          return morphs;
        },
        statements: [["attribute", "id", ["get", "template.templateID", ["loc", [null, [52, 50], [52, 69]]]]], ["content", "index", ["loc", [null, [53, 101], [53, 110]]]], ["attribute", "src", ["get", "template.thumbnail", ["loc", [null, [54, 95], [54, 113]]]]], ["content", "template.name", ["loc", [null, [56, 83], [56, 100]]]], ["inline", "moment-from-now", [["get", "template.createdAt", ["loc", [null, [57, 116], [57, 134]]]]], [], ["loc", [null, [57, 98], [57, 136]]]], ["content", "template.AdName", ["loc", [null, [60, 81], [60, 100]]]], ["inline", "moment-from-now", [["get", "template.AdupdatedAt", ["loc", [null, [61, 114], [61, 134]]]]], [], ["loc", [null, [61, 96], [61, 136]]]], ["content", "template.format", ["loc", [null, [63, 83], [63, 102]]]], ["block", "if", [["get", "template.demo_previewURL", ["loc", [null, [67, 52], [67, 76]]]]], [], 0, null, ["loc", [null, [67, 46], [71, 53]]]], ["element", "action", ["editTemplateSettings", ["get", "template", ["loc", [null, [78, 238], [78, 246]]]]], ["bubbles", false], ["loc", [null, [78, 206], [78, 262]]]], ["block", "tooltip-on-element", [], ["side", "bottom"], 1, null, ["loc", [null, [79, 52], [81, 75]]]], ["block", "if", [["get", "template.isAdTemplate", ["loc", [null, [90, 56], [90, 77]]]]], [], 2, 3, ["loc", [null, [90, 50], [94, 57]]]], ["inline", "moment-from-now", [["get", "template.updatedAt", ["loc", [null, [95, 122], [95, 140]]]]], [], ["loc", [null, [95, 104], [95, 142]]]], ["element", "action", ["deleteTemplateFromDB", ["get", "template", ["loc", [null, [101, 141], [101, 149]]]]], ["bubbles", false], ["loc", [null, [101, 109], [101, 165]]]]],
        locals: ["template", "index"],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 123,
                "column": 12
              },
              "end": {
                "line": 130,
                "column": 12
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/template-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "preview_spinner");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [7]), 1, 1);
            return morphs;
          },
          statements: [["inline", "ember-spinner", [], ["lines", 9, "radius", 8, "width", 10, "rotate", 0, "speed", "2.0", "zIndex", "99999", "color", "#ffffff"], ["loc", [null, [128, 20], [128, 115]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 132,
                "column": 12
              },
              "end": {
                "line": 139,
                "column": 12
              }
            },
            "moduleName": "elbe-ember-frond-end/templates/template-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "embedded-preview");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "campaignPreview");
            var el3 = dom.createTextNode("\n                        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["content", "embedPreview", ["loc", [null, [135, 24], [135, 42]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 122,
              "column": 8
            },
            "end": {
              "line": 140,
              "column": 8
            }
          },
          "moduleName": "elbe-ember-frond-end/templates/template-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "getPreview", ["loc", [null, [123, 18], [123, 28]]]]], [], 0, null, ["loc", [null, [123, 12], [130, 19]]]], ["block", "if", [["get", "showPreview", ["loc", [null, [132, 18], [132, 29]]]]], [], 1, null, ["loc", [null, [132, 12], [139, 19]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 147,
            "column": 0
          }
        },
        "moduleName": "elbe-ember-frond-end/templates/template-list.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container body");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "main_container");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" page content ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "right_col");
        dom.setAttribute(el3, "role", "main");
        var el4 = dom.createTextNode("\n\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "clearfix");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-md-12 col-sm-12 col-xs-12");
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "x_panel");
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "x_title");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("h3");
        var el9 = dom.createTextNode("Manage Templates");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "clearfix");
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("div");
        dom.setAttribute(el7, "class", "x_content");
        var el8 = dom.createTextNode("\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("div");
        dom.setAttribute(el8, "class", "row tile_count");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "col-md-4");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "col-md-4");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("div");
        dom.setAttribute(el10, "id", "custom-search-input");
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("div");
        dom.setAttribute(el11, "class", "input-group search-box col-md-12");
        var el12 = dom.createTextNode("\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createComment("");
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                                            ");
        dom.appendChild(el11, el12);
        var el12 = dom.createElement("span");
        dom.setAttribute(el12, "class", "input-group-btn");
        var el13 = dom.createTextNode("\n                                  ");
        dom.appendChild(el12, el13);
        var el13 = dom.createElement("button");
        dom.setAttribute(el13, "class", "btn btn-info btn-md");
        dom.setAttribute(el13, "type", "button");
        var el14 = dom.createTextNode("\n                                    ");
        dom.appendChild(el13, el14);
        var el14 = dom.createElement("i");
        dom.setAttribute(el14, "class", " remove-search glyphicon glyphicon-remove-circle");
        dom.appendChild(el13, el14);
        var el14 = dom.createTextNode("\n                                  ");
        dom.appendChild(el13, el14);
        dom.appendChild(el12, el13);
        var el13 = dom.createTextNode("\n                                ");
        dom.appendChild(el12, el13);
        dom.appendChild(el11, el12);
        var el12 = dom.createTextNode("\n                                        ");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("div");
        dom.setAttribute(el9, "class", "col-md-4 pull-right");
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n                            ");
        dom.appendChild(el7, el8);
        var el8 = dom.createElement("table");
        dom.setAttribute(el8, "id", "campaigns_dash");
        dom.setAttribute(el8, "class", "table table-striped jambo_table");
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("thead");
        var el10 = dom.createTextNode("\n                                    ");
        dom.appendChild(el9, el10);
        var el10 = dom.createElement("tr");
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createTextNode("#");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createTextNode("Thumbnail");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createTextNode("Template Name");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createTextNode("Campaign Name");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createTextNode("Template Format");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createTextNode("Preview");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createTextNode("Settings");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createTextNode("Update");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                        ");
        dom.appendChild(el10, el11);
        var el11 = dom.createElement("th");
        dom.setAttribute(el11, "class", "vertical_align_coln");
        var el12 = dom.createTextNode("Delete");
        dom.appendChild(el11, el12);
        dom.appendChild(el10, el11);
        var el11 = dom.createTextNode("\n                                    ");
        dom.appendChild(el10, el11);
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("\n                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                                ");
        dom.appendChild(el8, el9);
        var el9 = dom.createElement("tbody");
        var el10 = dom.createTextNode("\n");
        dom.appendChild(el9, el10);
        var el10 = dom.createComment("");
        dom.appendChild(el9, el10);
        var el10 = dom.createTextNode("                                ");
        dom.appendChild(el9, el10);
        dom.appendChild(el8, el9);
        var el9 = dom.createTextNode("\n                            ");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        var el8 = dom.createTextNode("\n\n\n                        ");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                        ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment(" x-content ");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n                    ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                    ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment(" x-panel ");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n                ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment(" col-md-12 ");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" row ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" right-col ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" main container ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0, 1]);
        var element10 = dom.childAt(element9, [5, 3, 1, 1, 3]);
        var element11 = dom.childAt(element10, [1, 3, 1, 1]);
        var element12 = dom.childAt(element11, [3, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element9, 1, 1);
        morphs[1] = dom.createMorphAt(element11, 1, 1);
        morphs[2] = dom.createElementMorph(element12);
        morphs[3] = dom.createMorphAt(dom.childAt(element10, [3, 3]), 1, 1);
        morphs[4] = dom.createMorphAt(element9, 9, 9);
        morphs[5] = dom.createMorphAt(element9, 11, 11);
        return morphs;
      },
      statements: [["content", "top-navbar", ["loc", [null, [3, 8], [3, 22]]]], ["inline", "input", [], ["type", "text", "class", "form-control input-xs", "enter", "searchTemplate", "placeholder", "filter by template name", "required", "required", "value", ["subexpr", "@mut", [["get", "searchTerm", ["loc", [null, [23, 181], [23, 191]]]]], [], []]], ["loc", [null, [23, 44], [23, 193]]]], ["element", "action", ["clearSearch"], [], ["loc", [null, [25, 70], [25, 94]]]], ["block", "each", [["get", "template_list_data", ["loc", [null, [51, 44], [51, 62]]]]], [], 0, null, ["loc", [null, [51, 36], [104, 45]]]], ["block", "ember-remodal", [], ["forService", true, "name", "preview-template-modal"], 1, null, ["loc", [null, [122, 8], [140, 26]]]], ["inline", "notification-container", [], ["notifications", ["subexpr", "@mut", [["get", "notifications", ["loc", [null, [141, 47], [141, 60]]]]], [], []], "position", "top"], ["loc", [null, [141, 8], [141, 77]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});