define('elbe-ember-frond-end/components/user-select', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    limit: 26,
    actions: {
      changeUser: function changeUser(newUser) {
        console.log('New User ' + newUser.userID);
        var campaign = this.get('campaign');
        _ember['default'].set(campaign, "userID", newUser.userID);
        _ember['default'].set(campaign, "userName", newUser.fullName);
        _ember['default'].set(campaign, "userRef", newUser);

        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/campaigns/update/' + campaign.campaignID,
          type: 'POST',
          data: JSON.stringify({
            campaignID: campaign.campaignID,
            userID: newUser.userID
          }),
          contentType: 'application/json',
          dataType: 'json',
          success: function success(response) {
            console.log("updated user");
          },
          error: function error(_error) {
            console.log(_error);
          }
        });
      }
    }
  });
});