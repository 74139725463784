define('elbe-ember-frond-end/routes/galleries', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    title: 'Gallery',
    actions: {
      error: function error(_error, transition) {
        if (_error && _error.status === 400 || 401) {
          return this.transitionTo('serverError');
        }
      }
    },
    model: function model(params) {
      var ret = null;
      var gallery = _ember['default'].$.ajax({
        url: _ember['default'].API_URL + '/gallery/' + params.galleryID,
        type: 'GET',
        context: this,
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        success: function success() {},
        error: function error() {}
      });

      var creatives = _ember['default'].$.ajax({
        url: _ember['default'].API_URL + '/gallery/listcreatives/' + params.galleryID,
        type: 'GET',
        context: this,
        contentType: 'application/json;charset=utf-8',
        dataType: 'json',
        success: function success() {},
        error: function error() {}
      });

      ret = new _ember['default'].RSVP.hash({
        gallery: gallery,
        creatives: creatives
      });
      return ret;
    },
    setupController: function setupController(controller, model) {
      controller.set('gallery', model.gallery);
      controller.set('creatives', model.creatives.creatives);
      controller.set('brands', model.creatives.brands);
      controller.set('formats', model.creatives.formats);
    }
  });
});