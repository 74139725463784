define('elbe-ember-frond-end/controllers/format-gallery', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    remodal: _ember['default'].inject.service(),
    isShowingCopyModal: false,

    queryParams: ['galleryID'],
    galleryID: null,

    actions: {
      closeCopyCampaign: function closeCopyCampaign() {
        this.toggleProperty('isShowingCopyModal');
      }
    }
  });
});