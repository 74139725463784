define('elbe-ember-frond-end/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'elbe-ember-frond-end/config/environment'], function (exports, _ember, _emberResolver, _emberLoadInitializers, _elbeEmberFrondEndConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;
  //Ember.API_URL = 'http://192.168.2.103:8080';
  _ember['default'].ANALYTICS_URL = _elbeEmberFrondEndConfigEnvironment['default'].ANALYTICS_URL;
  _ember['default'].API_URL = _elbeEmberFrondEndConfigEnvironment['default'].API_URL;
  _ember['default'].EDITOR_URL = _elbeEmberFrondEndConfigEnvironment['default'].EDITOR_URL;
  _ember['default'].WIZARD_URL = _elbeEmberFrondEndConfigEnvironment['default'].WIZARD_URL;
  _ember['default'].PREVIEW_URL = _elbeEmberFrondEndConfigEnvironment['default'].PREVIEW_URL;
  _ember['default'].GALLERY_URL = _elbeEmberFrondEndConfigEnvironment['default'].GALLERY_URL;
  _ember['default'].COOKIE_NAME = _elbeEmberFrondEndConfigEnvironment['default'].COOKIE_NAME;

  _ember['default'].Environment = _elbeEmberFrondEndConfigEnvironment['default'].environment;
  _ember['default'].Viewer_Url = _elbeEmberFrondEndConfigEnvironment['default'].VIEWER_URL;
  _ember['default'].LoginGoogleUrl = _elbeEmberFrondEndConfigEnvironment['default'].LOGIN_GOOGLE_URL;
  _ember['default'].LoginSlackUrl = _elbeEmberFrondEndConfigEnvironment['default'].LOGIN_SLACK_URL;
  _ember['default'].LIVE_BASE_URL = _elbeEmberFrondEndConfigEnvironment['default'].LIVE_BASE_URL;
  _ember['default'].PREVIEW_BASE_URL = _elbeEmberFrondEndConfigEnvironment['default'].PREVIEW_BASE_URL;

  App = _ember['default'].Application.extend({
    modulePrefix: _elbeEmberFrondEndConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _elbeEmberFrondEndConfigEnvironment['default'].podModulePrefix,
    Resolver: _emberResolver['default']
  });

  window["wceGlue"] = new Module({ useWorker: false }).wceGlue;

  (0, _emberLoadInitializers['default'])(App, _elbeEmberFrondEndConfigEnvironment['default'].modulePrefix);

  if (_elbeEmberFrondEndConfigEnvironment['default'].environment !== "development") {
    Raven.config('https://c362187959ac484eaa889fa6160bdada@sentry.io/135381').addPlugin(Raven.Plugins.Ember).install();
  }

  exports['default'] = App;
});