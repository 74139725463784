define("elbe-ember-frond-end/components/creative-format", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    format_btn_save: "Save",
    isSaving: false,

    other_value0: null,
    other_value1: null,
    other_value2: null,
    other_value3: null,
    other_value4: null,
    other_value5: null,

    otherCategories: [{ name: "150KB", id: "skb" }, { name: "2.5D", id: "3db" }, { name: "Animation", id: "anim" }, { name: "Best Of", id: "boc" }, { name: "Branding", id: "bnd" }, { name: "Compare", id: "cmp" }, { name: "Countdown", id: "cnt" }, { name: "Custom", id: "cust" }, { name: "Desktop", id: "des" }, { name: "DOOH", id: "ooh" }, { name: "Dynamic", id: "dyn" }, { name: "eCommerce", id: "ecom" }, { name: "Gamification", id: "game" }, { name: "Hero", id: "hero" }, { name: "Gyro", id: "gyr" }, { name: "Mobile", id: "mob" }, { name: "Multivariant", id: "mul" }, { name: "Performance", id: "perf" },
    //{ name: "PrimoStudio", id: "std" },
    { name: "Real 3D", id: "msh" }, { name: "Retargeting", id: "rtg" }, { name: "Responsive", id: "resp" },
    //{ name: "SmartBuilder", id: "smt" },
    { name: "Special FX", id: "sfx" }, { name: "Special placement", id: "spec" }, { name: "Video", id: "vid" }, { name: "Xmas", id: "xmas" }],

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      var i = 0;
      var others = this.get('format.tags') || "";
      others = others.split(" ");
      others.forEach(function (o) {
        _this.set('other_value' + i++, o);
      });
    },

    observerOtherValues: (function () {
      var _this = this;
      var tags = "";
      function get(attr) {
        if (tags && _this.get(attr)) {
          tags += " ";
        }
        tags += _this.get(attr) || "";
      }
      get("other_value0");
      get("other_value1");
      get("other_value2");
      get("other_value3");
      get("other_value4");
      get("other_value5");
      console.log(tags);
      this.set('format.tags', tags);
    }).observes('other_value0', 'other_value1', 'other_value2', 'other_value3', 'other_value4', 'other_value5'),

    actions: {
      save_newFormat: function save_newFormat() {
        if (this.get('format.name') === '' || this.get('format.name') == null) {
          this.notifications.warning('Require a creative format name', {
            autoClear: true,
            clearDuration: 3400
          });
        } else {
          this.set('format_btn_save', 'Saving...');
          this.set('isSaving', true);
          var format = this.get('format');
          var controller = this;
          var data = {
            formatID: this.get('format.formatID'),
            name: this.get('format.name'),
            description: this.get('format.description'),
            tags: this.get('format.tags'),
            userID: this.get('format.userID'),
            orgID: this.get('format.orgID')
          };
          _ember["default"].$.ajax({
            url: _ember["default"].API_URL + '/formats',
            type: 'POST',
            data: JSON.stringify(data),
            context: this,
            contentType: 'application/json;charset=utf-8',
            dataType: 'json',
            success: function success() {
              //console.log(response);
              controller.notifications.success('Creative Format ' + controller.get('format-name') + ' Saved', {
                autoClear: true,
                clearDuration: 3200
              });
              controller.set('format_btn_save', 'Save Again');
              controller.set('isSaving', false);
            },
            error: function error() {
              controller.notifications.success('Error saving... retry again', {
                autoClear: true,
                clearDuration: 3200
              });
              controller.set('format_btn_save', 'Retry');
              controller.set('isSaving', false);
            }
          });
        }
      }
    }
  });
});