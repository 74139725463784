define('elbe-ember-frond-end/routes/template-settings', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        title: 'Edit Template',
        model: function model(params) {
            return _ember['default'].$.ajax({
                url: _ember['default'].API_URL + '/templates/' + params.id,
                type: 'GET',
                dataType: 'json',
                success: function success(response) {
                    //console.log(response);
                },
                error: function error(_error) {
                    console.log(_error);
                }
            }); //ajax
        },
        setupController: function setupController(controller, model) {
            controller.set('templateID', model.templateID);
            controller.set('campaignID', model.campaignID);
            controller.set('templateName', model.name);
            controller.set('format', model.format);
            controller.set('description', model.description);
            controller.set('templateTypeSelected', model.type);
            controller.set('thumbnailURL', model.thumbnail);
            controller.set('splashURL', model.splash);
        }
    });
});