define('elbe-ember-frond-end/routes/edit-user', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        title: 'Edit User',
        actions: {
            error: function error(_error, transition) {
                if (_error && _error.status === 400 || 401) {
                    return this.transitionTo('serverError');
                }
            }
        },
        model: function model(params) {
            var controller = this;
            return _ember['default'].$.ajax({
                url: _ember['default'].API_URL + '/users/' + controller.get('session.data.authenticated.user_ID') + '/' + params.id,
                type: 'GET',
                dataType: 'json',
                success: function success(response) {},
                error: function error(_error2) {
                    reject(_error2);
                }
            }); //ajax
        },
        setupController: function setupController(controller, model) {
            controller.set('user_data', model);
            controller.set('user_fullName', model[0].fullName);
            controller.set('userID', model[0].userID);
            controller.set('user_email', model[0].email);
            controller.set('user_org', model[0].company);
            controller.set('user_role', model[0].userRole);
        }
    });
});