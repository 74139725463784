define("elbe-ember-frond-end/components/manage-ui", ["exports"], function (exports) {
  exports["default"] = Ember.Component.extend({
    newName: "New Brand",
    pAttr: "brandID",
    api: "brands",
    editUrl: "update_brand",

    user_admin_role: null,
    actions: {
      saveName: function saveName(param) {
        var controller = this;
        var data = {
          name: param.name
        };
        data[this.pAttr] = param[this.pAttr];

        document.getElementById(param[this.pAttr]).blur();

        Ember.$.ajax({
          url: Ember.API_URL + "/" + this.api,
          type: 'POST',
          data: JSON.stringify(data),
          context: this,
          contentType: 'application/json;charset=utf-8',
          dataType: 'json',
          success: function success() {
            controller.notifications.success(param.name + " updated.", {
              autoClear: true,
              clearDuration: 3200
            });
          },
          error: function error() {
            controller.notifications.success('Error saving... retry again', {
              autoClear: true,
              clearDuration: 3200
            });
          }
        });
      },
      copyGalleryLink: function copyGalleryLink(param) {
        var url = Ember.GALLERY_URL + "/?galleryID=" + param.galleryID;
        navigator.clipboard.writeText(url);
        this.notifications.success(url + " - successfully copied to clipboard.", {
          autoClear: true,
          clearDuration: 3000
        });
      },
      show: function show(param) {
        window.open(Ember.GALLERY_URL + "/?galleryID=" + param.galleryID);
      },
      edit: function edit(param) {
        window.open(this.editUrl + "/" + param[this.pAttr], "_self");
      },
      createNew: function createNew() {
        var controller = this;
        var date = moment().format("YYYY-MM-DD");
        var id = new UUID(4).format();
        var param = {
          name: this.newName,
          userID: this.get('userID'),
          orgID: this.get('orgID'),
          createdAt: date,
          updatedAt: date
        };
        param[this.pAttr] = id;

        this.get('list_data').unshiftObject(param);
        var data = {
          name: param.name,
          userID: param.userID,
          orgID: param.orgID
        };
        data[this.pAttr] = id;

        Ember.$.ajax({
          url: Ember.API_URL + "/" + this.api,
          type: 'POST',
          data: JSON.stringify(data),
          context: this,
          contentType: 'application/json;charset=utf-8',
          dataType: 'json',
          success: function success() {
            controller.notifications.success(param.name + '" created', {
              autoClear: true,
              clearDuration: 3200
            });
          },
          error: function error() {
            controller.notifications.success('Error saving... retry again', {
              autoClear: true,
              clearDuration: 3200
            });
          }
        });

        setTimeout(function () {
          var de = document.getElementById(id);
          if (de) {
            de.focus();
          }
        }, 100);
      },
      deleteFromDB: function deleteFromDB(param) {
        var controller = this;
        this.get('list_data').removeObject(param);

        //delete does not care about return type JSON
        Ember.$.ajax({
          url: Ember.API_URL + "/" + this.api + "/" + param[this.pAttr],
          type: 'DELETE',
          contentType: 'application/json',
          success: function success(response) {
            controller.notifications.success(param.name + " removed", {
              autoClear: true,
              clearDuration: 3200
            });
          },
          error: function error(_error) {}
        }); //ajax
      }
    }
  });
});