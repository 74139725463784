define("elbe-ember-frond-end/components/group-section", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    _icon: "",

    didReceiveAttrs: function didReceiveAttrs() {
      this.set("_icon", this.collapsed ? "" : "expanded");
    },
    actions: {
      toggleCollapse: function toggleCollapse() {
        this.set('collapsed', !this.get('collapsed'));
        this.set("_icon", this.collapsed ? "" : "expanded");
      }
    }
  });
});