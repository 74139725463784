define('elbe-ember-frond-end/controllers/publishers', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    isSaving: false,
    publishers: [],

    init: function init() {
      this._super();
      this.set('pub_btn_save', 'Save Publisher');
    },
    actions: {
      save_newPublisher: function save_newPublisher() {
        if (this.get('pub-name') === '' || this.get('pub-name').length < 3) {
          this.notifications.warning('Name is required & should be atleast 3 characters', {
            autoClear: true,
            clearDuration: 3200
          });
        } else {
          /*
          var regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
          if (!regexp.test(this.get('pub-url'))) {
            this.notifications.warning('Not a valid URL format, URL should start with http://', {
                autoClear: true,
                clearDuration: 3200
            });
          } else {*/
          this.set('brand_btn_save', 'Saving...');
          this.set('isSaving', true);
          var controller = this;
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/publisher',
            type: 'POST',
            data: JSON.stringify({
              publisherID: this.get('pubID'),
              name: this.get('pub-name'),
              website: "", //this.get('pub-url'),
              userID: this.get('session.data.authenticated.user_ID'),
              description: this.get('pub_description')
            }),
            context: this,
            contentType: 'application/json;charset=utf-8',
            dataType: 'json',
            success: function success() /*response*/{
              //console.log(response);
              controller.set('pub_btn_save', 'Save Again');
              controller.set('isSaving', false);
              controller.notifications.success(controller.get('pub-name') + ' Publisher saved', {
                autoClear: true,
                clearDuration: 2400
              });
              controller.send("clear_publisherFields");
            },
            error: function error(_error) {
              console.log(_error);
              controller.set('pub_btn_save', 'Retry');
              controller.set('isSaving', false);
              controller.notifications.error('Server Error, retry later', {
                autoClear: true,
                clearDuration: 2400
              });
              controller.send("clear_publisherFields");
            }
          });
          //}//else regex
        } //else
      }, //save_newpublisher
      clear_publisherFields: function clear_publisherFields() {
        var pubUUID = new UUID(4).format();
        this.set('pub_btn_save', 'Save Publisher');
        this.set('isSaving', false);
        this.set('pubID', pubUUID);
        this.set('pub-name', '');
        this.set('pub-url', '');
        this.set('pub_description', '');
      }
    } });
});
//actions