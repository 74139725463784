define('elbe-ember-frond-end/components/image-display', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    actions: {
      uploadFile: function uploadFile(file) {
        if (this.get('uploadFile')) {
          this.get('uploadFile')(file, this.get('param'));
        }
      }
    }
  });
});