define('elbe-ember-frond-end/controllers/list-users', ['exports', 'ember', 'elbe-ember-frond-end/utils/formatUtils', 'underscore'], function (exports, _ember, _elbeEmberFrondEndUtilsFormatUtils, _underscore) {
    exports['default'] = _ember['default'].Controller.extend({
        session: _ember['default'].inject.service('session'),
        remodal: _ember['default'].inject.service(),
        checkedSuspendUser: false,
        current_userData: null,
        init: function init() {
            this.get('session').authorize('authorizer:custom', { admin: true });
        },
        searchValueObserver: (function () /*searchStr*/{
            var searchString = this.get('searchTerm').toLowerCase();
            var userList = this.get('user_list_data_orig');
            var filteredList = _underscore['default'].filter(userList, function (obj) {
                return (obj.fullName || " ").toLowerCase().indexOf(searchString) >= 0;
            });
            this.set('users_list_data', filteredList);
        }).observes('searchTerm'),

        actions: {
            searchUserName: function searchUserName(searchStr) {
                var userList = this.get('user_list_data_orig');
                var filteredList = _underscore['default'].filter(userList, function (obj) {
                    return (obj.fullName || " ").toLowerCase().indexOf(searchStr.toLowerCase());
                });
                this.set('users_list_data', filteredList);
            },
            clearSearch: function clearSearch() {
                var campaignList = this.get('user_list_data_orig');
                this.set('users_list_data', campaignList);
                this.set('searchTerm', '');
            },
            createNewUser: function createNewUser() {
                this.transitionToRoute('users');
            },
            fetchUsersWithCampaigns: function fetchUsersWithCampaigns() {
                var controller = this;
                _ember['default'].$.ajax({
                    url: _ember['default'].API_URL + '/usersWithCampaign/' + this.get('session.data.authenticated.user_ID'),
                    type: 'GET',
                    dataType: 'json',
                    success: function success(response) {
                        controller.set('users_list_data', response);
                        //console.log(response);
                    },
                    error: function error(_error) {
                        console.log(_error);
                    }
                }); //ajax
            },
            fetchAllUsers: function fetchAllUsers() {
                var controller = this;
                _ember['default'].$.ajax({
                    url: _ember['default'].API_URL + '/getnewusers/' + this.get('session.data.authenticated.user_ID'),
                    type: 'GET',
                    dataType: 'json',
                    success: function success(response) {
                        controller.set('new_users_list_data', response);
                        //console.log(response);
                    },
                    error: function error(_error2) {
                        console.log(_error2);
                    }
                }); //ajax
            },

            notifyDeleteConfirmed: function notifyDeleteConfirmed() {
                var controller = this;
                var u_data = this.get('current_userData');
                $('#' + u_data.userID).closest('tr').children('td').animate({
                    padding: 0
                }).wrapInner('<div />').children().slideUp(200, function () {
                    $(this).closest('tr').remove();
                });
                _ember['default'].run.later(function () {
                    _ember['default'].$.ajax({
                        url: _ember['default'].API_URL + '/deleteuser/' + controller.get('session.data.authenticated.user_ID') + '/' + u_data.userID,
                        type: 'DELETE',
                        contentType: 'application/json;charset=utf-8',
                        dataType: 'json',
                        success: function success() /*response*/{
                            controller.set('current_userData', '');
                            //console.log(data.length);
                            //console.log(response);
                        },
                        error: function error(_error3) {
                            console.log(_error3);
                        }
                    }); //ajax
                }, 200);
            }, //confirm deleteProject
            notifyCanceled: function notifyCanceled() {
                this.set('current_userData', '');
            },

            delete_NewUserFromDB: function delete_NewUserFromDB(userData) {
                //var controller = this;
                console.log(userData);
                this.set('current_userData', userData);
                this.get('remodal').open('delete-user-modal', {
                    title: 'Do you want to delete user ' + userData.fullName + ' ?',
                    onConfirm: 'notifyDeleteConfirmed',
                    onCancel: 'notifyCanceled',
                    innerButtonClasses: 'btn-delete-user'
                });
            }, //delete
            editUser: function editUser(user) {
                this.transitionToRoute('editUser', user.userID);
            },
            supendUser: function supendUser(user) {
                var controller = this;
                var userStatus = false;
                if (user.status != null || user.status != undefined || user.status == 'active') {
                    //this.set('checkedSuspendUser', false);
                    userStatus = 'inactive';
                }
                if (user.status === 'inactive') {
                    userStatus = 'active';
                }

                //var userData = this.get('users_list_data');
                //var controller = this;
                //console.log(user);
                //delete does not care about return type JSON
                _ember['default'].$.ajax({
                    url: _ember['default'].API_URL + '/userstatus/' + controller.get('session.data.authenticated.user_ID'),
                    type: 'POST',
                    data: JSON.stringify({
                        username: user.email,
                        userID: user.userID,
                        status: userStatus
                    }),
                    contentType: 'application/json',
                    dataType: 'json',
                    success: function success() /*response*/{
                        controller.send('fetchUsersWithCampaigns');
                        controller.send('fetchAllUsers');
                    },
                    error: function error(_error4) {
                        console.log(_error4);
                    }
                }); //ajax
            } }
    });
});
//suspendUser