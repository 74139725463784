define('elbe-ember-frond-end/components/top-navbar', ['exports', 'ember', 'elbe-ember-frond-end/config/environment'], function (exports, _ember, _elbeEmberFrondEndConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service('session'),
    userName: JSON.parse(localStorage.getItem('ember_simple_auth:session')).authenticated.username,
    org_name: JSON.parse(localStorage.getItem('ember_simple_auth:session')).authenticated.org_name,
    user_admin_role: JSON.parse(localStorage.getItem('ember_simple_auth:session')).user_admin_role,
    user_role: JSON.parse(localStorage.getItem('ember_simple_auth:session')).authenticated.user_role,
    isSubscription: JSON.parse(localStorage.getItem('ember_simple_auth:session')).authenticated.billingType === "Subscription",
    buildDate: _elbeEmberFrondEndConfigEnvironment['default'].APP.buildDate,
    version: _elbeEmberFrondEndConfigEnvironment['default'].APP.version + '+' + _elbeEmberFrondEndConfigEnvironment['default'].currentRevision,
    isSessionExpired: false,
    enableAuthorizedLinks: false,
    sessionCheckErrorCnt: 0,
    init: function init() {
      var controller = this;

      var checkLogin = function checkLogin() {
        var userParams = {
          userID: controller.get('session.data.authenticated.user_ID')
        };
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/updateLastLogin/' + controller.get('session.data.authenticated.token') + '/' + controller.get('session.data.authenticated.user_ID'),
          type: 'POST',
          dataType: 'json',
          data: userParams,
          success: function success() /*response*/{
            setTimeout(checkLogin, 1000 * 60 * 15);
            controller.set("sessionCheckErrorCnt", 0);
          },
          error: function error(_error) {
            console.log(_error);
            var cnt = controller.get('sessionCheckErrorCnt') + 1;
            if (_error.responseJSON && _error.responseJSON.invalid) {
              cnt = 100000;
            }
            controller.set("sessionCheckErrorCnt", cnt);
            if (cnt > 1) {
              controller.set("sessionCheckErrorCnt", 0);
              controller.set('isSessionExpired', true);
            }
          }
        }); //ajax    
      };

      this.set('primo_app_version', _elbeEmberFrondEndConfigEnvironment['default'].APP.version + '+' + _elbeEmberFrondEndConfigEnvironment['default'].currentRevision);
      var username = this.get('userName');
      if (username === undefined) {
        this.set('enableAuthorizedLinks', false);
      } else if (username.length > 3) {
        this.set('enableAuthorizedLinks', true);
        setTimeout(checkLogin, 100);
      }
      this._super();
    },

    role: (function () {
      var ret = "";
      switch (this.user_role) {
        case "admin":
          ret = "Admin";
          break;
        case "designer":
          ret = "Designer";
          break;
        case "demo":
          ret = "Demo Account";
          break;
      }
      return ret;
    }).property('user_role'),

    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
        //this.get('router').transitionTo('login');
      }
    }
  });
});
//app/componenets/top-navbar.js