define('elbe-ember-frond-end/controllers/edit-org', ['exports', 'ember', 'npm:validator'], function (exports, _ember, _npmValidator) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service('session'),
    editInitial: true,
    isVerifyBtnDiaabled: false,
    orgStatusOptions: ['active', 'inactive', 'suspended', 'trial'],
    statusSelected: 'active',
    billingTypeList: ['Pay Per Campaign', 'Subscription'],
    countryListOptions: [{
      "name": "Afghanistan",
      "code": "AF"
    }, {
      "name": "Åland Islands",
      "code": "AX"
    }, {
      "name": "Albania",
      "code": "AL"
    }, {
      "name": "Algeria",
      "code": "DZ"
    }, {
      "name": "American Samoa",
      "code": "AS"
    }, {
      "name": "AndorrA",
      "code": "AD"
    }, {
      "name": "Angola",
      "code": "AO"
    }, {
      "name": "Anguilla",
      "code": "AI"
    }, {
      "name": "Antarctica",
      "code": "AQ"
    }, {
      "name": "Antigua and Barbuda",
      "code": "AG"
    }, {
      "name": "Argentina",
      "code": "AR"
    }, {
      "name": "Armenia",
      "code": "AM"
    }, {
      "name": "Aruba",
      "code": "AW"
    }, {
      "name": "Australia",
      "code": "AU"
    }, {
      "name": "Austria",
      "code": "AT"
    }, {
      "name": "Azerbaijan",
      "code": "AZ"
    }, {
      "name": "Bahamas",
      "code": "BS"
    }, {
      "name": "Bahrain",
      "code": "BH"
    }, {
      "name": "Bangladesh",
      "code": "BD"
    }, {
      "name": "Barbados",
      "code": "BB"
    }, {
      "name": "Belarus",
      "code": "BY"
    }, {
      "name": "Belgium",
      "code": "BE"
    }, {
      "name": "Belize",
      "code": "BZ"
    }, {
      "name": "Benin",
      "code": "BJ"
    }, {
      "name": "Bermuda",
      "code": "BM"
    }, {
      "name": "Bhutan",
      "code": "BT"
    }, {
      "name": "Bolivia",
      "code": "BO"
    }, {
      "name": "Bosnia and Herzegovina",
      "code": "BA"
    }, {
      "name": "Botswana",
      "code": "BW"
    }, {
      "name": "Bouvet Island",
      "code": "BV"
    }, {
      "name": "Brazil",
      "code": "BR"
    }, {
      "name": "British Indian Ocean Territory",
      "code": "IO"
    }, {
      "name": "Brunei Darussalam",
      "code": "BN"
    }, {
      "name": "Bulgaria",
      "code": "BG"
    }, {
      "name": "Burkina Faso",
      "code": "BF"
    }, {
      "name": "Burundi",
      "code": "BI"
    }, {
      "name": "Cambodia",
      "code": "KH"
    }, {
      "name": "Cameroon",
      "code": "CM"
    }, {
      "name": "Canada",
      "code": "CA"
    }, {
      "name": "Cape Verde",
      "code": "CV"
    }, {
      "name": "Cayman Islands",
      "code": "KY"
    }, {
      "name": "Central African Republic",
      "code": "CF"
    }, {
      "name": "Chad",
      "code": "TD"
    }, {
      "name": "Chile",
      "code": "CL"
    }, {
      "name": "China",
      "code": "CN"
    }, {
      "name": "Christmas Island",
      "code": "CX"
    }, {
      "name": "Cocos (Keeling) Islands",
      "code": "CC"
    }, {
      "name": "Colombia",
      "code": "CO"
    }, {
      "name": "Comoros",
      "code": "KM"
    }, {
      "name": "Congo",
      "code": "CG"
    }, {
      "name": "Congo, The Democratic Republic of the",
      "code": "CD"
    }, {
      "name": "Cook Islands",
      "code": "CK"
    }, {
      "name": "Costa Rica",
      "code": "CR"
    }, {
      "name": "Cote D\"Ivoire",
      "code": "CI"
    }, {
      "name": "Croatia",
      "code": "HR"
    }, {
      "name": "Cuba",
      "code": "CU"
    }, {
      "name": "Cyprus",
      "code": "CY"
    }, {
      "name": "Czech Republic",
      "code": "CZ"
    }, {
      "name": "Denmark",
      "code": "DK"
    }, {
      "name": "Djibouti",
      "code": "DJ"
    }, {
      "name": "Dominica",
      "code": "DM"
    }, {
      "name": "Dominican Republic",
      "code": "DO"
    }, {
      "name": "Ecuador",
      "code": "EC"
    }, {
      "name": "Egypt",
      "code": "EG"
    }, {
      "name": "El Salvador",
      "code": "SV"
    }, {
      "name": "Equatorial Guinea",
      "code": "GQ"
    }, {
      "name": "Eritrea",
      "code": "ER"
    }, {
      "name": "Estonia",
      "code": "EE"
    }, {
      "name": "Ethiopia",
      "code": "ET"
    }, {
      "name": "Falkland Islands (Malvinas)",
      "code": "FK"
    }, {
      "name": "Faroe Islands",
      "code": "FO"
    }, {
      "name": "Fiji",
      "code": "FJ"
    }, {
      "name": "Finland",
      "code": "FI"
    }, {
      "name": "France",
      "code": "FR"
    }, {
      "name": "French Guiana",
      "code": "GF"
    }, {
      "name": "French Polynesia",
      "code": "PF"
    }, {
      "name": "French Southern Territories",
      "code": "TF"
    }, {
      "name": "Gabon",
      "code": "GA"
    }, {
      "name": "Gambia",
      "code": "GM"
    }, {
      "name": "Georgia",
      "code": "GE"
    }, {
      "name": "Germany",
      "code": "DE"
    }, {
      "name": "Ghana",
      "code": "GH"
    }, {
      "name": "Gibraltar",
      "code": "GI"
    }, {
      "name": "Greece",
      "code": "GR"
    }, {
      "name": "Greenland",
      "code": "GL"
    }, {
      "name": "Grenada",
      "code": "GD"
    }, {
      "name": "Guadeloupe",
      "code": "GP"
    }, {
      "name": "Guam",
      "code": "GU"
    }, {
      "name": "Guatemala",
      "code": "GT"
    }, {
      "name": "Guernsey",
      "code": "GG"
    }, {
      "name": "Guinea",
      "code": "GN"
    }, {
      "name": "Guinea-Bissau",
      "code": "GW"
    }, {
      "name": "Guyana",
      "code": "GY"
    }, {
      "name": "Haiti",
      "code": "HT"
    }, {
      "name": "Heard Island and Mcdonald Islands",
      "code": "HM"
    }, {
      "name": "Holy See (Vatican City State)",
      "code": "VA"
    }, {
      "name": "Honduras",
      "code": "HN"
    }, {
      "name": "Hong Kong",
      "code": "HK"
    }, {
      "name": "Hungary",
      "code": "HU"
    }, {
      "name": "Iceland",
      "code": "IS"
    }, {
      "name": "India",
      "code": "IN"
    }, {
      "name": "Indonesia",
      "code": "ID"
    }, {
      "name": "Iran, Islamic Republic Of",
      "code": "IR"
    }, {
      "name": "Iraq",
      "code": "IQ"
    }, {
      "name": "Ireland",
      "code": "IE"
    }, {
      "name": "Isle of Man",
      "code": "IM"
    }, {
      "name": "Israel",
      "code": "IL"
    }, {
      "name": "Italy",
      "code": "IT"
    }, {
      "name": "Jamaica",
      "code": "JM"
    }, {
      "name": "Japan",
      "code": "JP"
    }, {
      "name": "Jersey",
      "code": "JE"
    }, {
      "name": "Jordan",
      "code": "JO"
    }, {
      "name": "Kazakhstan",
      "code": "KZ"
    }, {
      "name": "Kenya",
      "code": "KE"
    }, {
      "name": "Kiribati",
      "code": "KI"
    }, {
      "name": "Korea, Democratic People\"S Republic of",
      "code": "KP"
    }, {
      "name": "Korea, Republic of",
      "code": "KR"
    }, {
      "name": "Kuwait",
      "code": "KW"
    }, {
      "name": "Kyrgyzstan",
      "code": "KG"
    }, {
      "name": "Lao People\"S Democratic Republic",
      "code": "LA"
    }, {
      "name": "Latvia",
      "code": "LV"
    }, {
      "name": "Lebanon",
      "code": "LB"
    }, {
      "name": "Lesotho",
      "code": "LS"
    }, {
      "name": "Liberia",
      "code": "LR"
    }, {
      "name": "Libyan Arab Jamahiriya",
      "code": "LY"
    }, {
      "name": "Liechtenstein",
      "code": "LI"
    }, {
      "name": "Lithuania",
      "code": "LT"
    }, {
      "name": "Luxembourg",
      "code": "LU"
    }, {
      "name": "Macao",
      "code": "MO"
    }, {
      "name": "Macedonia, The Former Yugoslav Republic of",
      "code": "MK"
    }, {
      "name": "Madagascar",
      "code": "MG"
    }, {
      "name": "Malawi",
      "code": "MW"
    }, {
      "name": "Malaysia",
      "code": "MY"
    }, {
      "name": "Maldives",
      "code": "MV"
    }, {
      "name": "Mali",
      "code": "ML"
    }, {
      "name": "Malta",
      "code": "MT"
    }, {
      "name": "Marshall Islands",
      "code": "MH"
    }, {
      "name": "Martinique",
      "code": "MQ"
    }, {
      "name": "Mauritania",
      "code": "MR"
    }, {
      "name": "Mauritius",
      "code": "MU"
    }, {
      "name": "Mayotte",
      "code": "YT"
    }, {
      "name": "Mexico",
      "code": "MX"
    }, {
      "name": "Micronesia, Federated States of",
      "code": "FM"
    }, {
      "name": "Moldova, Republic of",
      "code": "MD"
    }, {
      "name": "Monaco",
      "code": "MC"
    }, {
      "name": "Mongolia",
      "code": "MN"
    }, {
      "name": "Montserrat",
      "code": "MS"
    }, {
      "name": "Morocco",
      "code": "MA"
    }, {
      "name": "Mozambique",
      "code": "MZ"
    }, {
      "name": "Myanmar",
      "code": "MM"
    }, {
      "name": "Namibia",
      "code": "NA"
    }, {
      "name": "Nauru",
      "code": "NR"
    }, {
      "name": "Nepal",
      "code": "NP"
    }, {
      "name": "Netherlands",
      "code": "NL"
    }, {
      "name": "Netherlands Antilles",
      "code": "AN"
    }, {
      "name": "New Caledonia",
      "code": "NC"
    }, {
      "name": "New Zealand",
      "code": "NZ"
    }, {
      "name": "Nicaragua",
      "code": "NI"
    }, {
      "name": "Niger",
      "code": "NE"
    }, {
      "name": "Nigeria",
      "code": "NG"
    }, {
      "name": "Niue",
      "code": "NU"
    }, {
      "name": "Norfolk Island",
      "code": "NF"
    }, {
      "name": "Northern Mariana Islands",
      "code": "MP"
    }, {
      "name": "Norway",
      "code": "NO"
    }, {
      "name": "Oman",
      "code": "OM"
    }, {
      "name": "Pakistan",
      "code": "PK"
    }, {
      "name": "Palau",
      "code": "PW"
    }, {
      "name": "Palestinian Territory, Occupied",
      "code": "PS"
    }, {
      "name": "Panama",
      "code": "PA"
    }, {
      "name": "Papua New Guinea",
      "code": "PG"
    }, {
      "name": "Paraguay",
      "code": "PY"
    }, {
      "name": "Peru",
      "code": "PE"
    }, {
      "name": "Philippines",
      "code": "PH"
    }, {
      "name": "Pitcairn",
      "code": "PN"
    }, {
      "name": "Poland",
      "code": "PL"
    }, {
      "name": "Portugal",
      "code": "PT"
    }, {
      "name": "Puerto Rico",
      "code": "PR"
    }, {
      "name": "Qatar",
      "code": "QA"
    }, {
      "name": "Reunion",
      "code": "RE"
    }, {
      "name": "Romania",
      "code": "RO"
    }, {
      "name": "Russian Federation",
      "code": "RU"
    }, {
      "name": "RWANDA",
      "code": "RW"
    }, {
      "name": "Saint Helena",
      "code": "SH"
    }, {
      "name": "Saint Kitts and Nevis",
      "code": "KN"
    }, {
      "name": "Saint Lucia",
      "code": "LC"
    }, {
      "name": "Saint Pierre and Miquelon",
      "code": "PM"
    }, {
      "name": "Saint Vincent and the Grenadines",
      "code": "VC"
    }, {
      "name": "Samoa",
      "code": "WS"
    }, {
      "name": "San Marino",
      "code": "SM"
    }, {
      "name": "Sao Tome and Principe",
      "code": "ST"
    }, {
      "name": "Saudi Arabia",
      "code": "SA"
    }, {
      "name": "Senegal",
      "code": "SN"
    }, {
      "name": "Serbia and Montenegro",
      "code": "CS"
    }, {
      "name": "Seychelles",
      "code": "SC"
    }, {
      "name": "Sierra Leone",
      "code": "SL"
    }, {
      "name": "Singapore",
      "code": "SG"
    }, {
      "name": "Slovakia",
      "code": "SK"
    }, {
      "name": "Slovenia",
      "code": "SI"
    }, {
      "name": "Solomon Islands",
      "code": "SB"
    }, {
      "name": "Somalia",
      "code": "SO"
    }, {
      "name": "South Africa",
      "code": "ZA"
    }, {
      "name": "South Georgia and the South Sandwich Islands",
      "code": "GS"
    }, {
      "name": "Spain",
      "code": "ES"
    }, {
      "name": "Sri Lanka",
      "code": "LK"
    }, {
      "name": "Sudan",
      "code": "SD"
    }, {
      "name": "Suriname",
      "code": "SR"
    }, {
      "name": "Svalbard and Jan Mayen",
      "code": "SJ"
    }, {
      "name": "Swaziland",
      "code": "SZ"
    }, {
      "name": "Sweden",
      "code": "SE"
    }, {
      "name": "Switzerland",
      "code": "CH"
    }, {
      "name": "Syrian Arab Republic",
      "code": "SY"
    }, {
      "name": "Taiwan, Province of China",
      "code": "TW"
    }, {
      "name": "Tajikistan",
      "code": "TJ"
    }, {
      "name": "Tanzania, United Republic of",
      "code": "TZ"
    }, {
      "name": "Thailand",
      "code": "TH"
    }, {
      "name": "Timor-Leste",
      "code": "TL"
    }, {
      "name": "Togo",
      "code": "TG"
    }, {
      "name": "Tokelau",
      "code": "TK"
    }, {
      "name": "Tonga",
      "code": "TO"
    }, {
      "name": "Trinidad and Tobago",
      "code": "TT"
    }, {
      "name": "Tunisia",
      "code": "TN"
    }, {
      "name": "Turkey",
      "code": "TR"
    }, {
      "name": "Turkmenistan",
      "code": "TM"
    }, {
      "name": "Turks and Caicos Islands",
      "code": "TC"
    }, {
      "name": "Tuvalu",
      "code": "TV"
    }, {
      "name": "Uganda",
      "code": "UG"
    }, {
      "name": "Ukraine",
      "code": "UA"
    }, {
      "name": "United Arab Emirates",
      "code": "AE"
    }, {
      "name": "United Kingdom",
      "code": "GB"
    }, {
      "name": "United States",
      "code": "US"
    }, {
      "name": "United States Minor Outlying Islands",
      "code": "UM"
    }, {
      "name": "Uruguay",
      "code": "UY"
    }, {
      "name": "Uzbekistan",
      "code": "UZ"
    }, {
      "name": "Vanuatu",
      "code": "VU"
    }, {
      "name": "Venezuela",
      "code": "VE"
    }, {
      "name": "Viet Nam",
      "code": "VN"
    }, {
      "name": "Virgin Islands, British",
      "code": "VG"
    }, {
      "name": "Virgin Islands, U.S.",
      "code": "VI"
    }, {
      "name": "Wallis and Futuna",
      "code": "WF"
    }, {
      "name": "Western Sahara",
      "code": "EH"
    }, {
      "name": "Yemen",
      "code": "YE"
    }, {
      "name": "Zambia",
      "code": "ZM"
    }, {
      "name": "Zimbabwe",
      "code": "ZW"
    }],

    init: function init() {
      this._super();
      this.get('session').authorize('authorizer:custom', { admin: true });
      this.set('org_update_btn', 'Update Organization');
      this.set('org_btn_vat_verify', 'Verify Tax ID');
      this.set('org_domain_btn_verify', 'Verify Domain');
      this.set('org_btn_billing_update', 'Update Billing');
    },
    actions: {
      chooseBillingType: function chooseBillingType(params) {
        this.set('orgBillingTypeSelected', params);
      },
      openCountryList: function openCountryList() {
        this.set('editInitial', false);
      },
      updateBillingDetails: function updateBillingDetails() {
        var controller = this;
        var billingType = this.get('orgBillingTypeSelected');
        var richMediaCPM = Number(this.get('org-richmedia-CPM'));
        var videoCPM = Number(this.get('org-video-CPM'));
        var billingTypeValid,
            richMediaCPMValid,
            videoCPMValid,
            updateValid = false;

        richMediaCPM = isNaN(richMediaCPM) ? undefined : richMediaCPM;
        videoCPM = isNaN(videoCPM) ? undefined : videoCPM;

        if (billingType === undefined) {
          controller.notifications.error('Select a Billing Type', {
            autoClear: true,
            clearDuration: 2600
          });
        } else {
          if (!_npmValidator['default'].isEmpty(billingType + "")) {
            billingTypeValid = true;
          } else {
            controller.notifications.error('Select a Billing Type', {
              autoClear: true,
              clearDuration: 2600
            });
          }
        }

        if (billingTypeValid) {
          if (billingType !== 'Subscription') {
            if (richMediaCPM === undefined || richMediaCPM === null) {
              controller.notifications.error('RichMedia CPM is empty', {
                autoClear: true,
                clearDuration: 2600
              });
            } else {
              richMediaCPMValid = true;
            } //richmedia checker

            if (videoCPM === undefined || videoCPM === null) {
              controller.notifications.error('Video+Rich Media CPM is empty', {
                autoClear: true,
                clearDuration: 2600
              });
            } else {
              videoCPMValid = true;
            }
          }
        }

        var orgParams = {
          orgID: controller.get('orgID')
        };
        if (billingTypeValid) {
          if (billingType === 'Subscription') {
            orgParams.billingType = billingType;
            updateValid = true;
          } else {
            if (richMediaCPMValid && videoCPMValid) {
              orgParams.billingType = billingType;
              orgParams.richmediaCPM = richMediaCPM;
              orgParams.videoCPM = videoCPM;
              updateValid = true;
            }
          }
        }

        if (updateValid) {
          controller.set('org_btn_billing_update', 'Updating Org Billing..');
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/org/create',
            type: 'POST',
            data: orgParams,
            success: function success() /*response*/{
              controller.set('org_btn_billing_update', 'Saved Organization..');
              controller.notifications.success('Successfully Updated Org Billing', {
                autoClear: true,
                clearDuration: 3400
              });
            },
            error: function error(_error) {
              console.log(_error);
              controller.set('org_btn_billing_update', 'Create Organization');
              controller.notifications.error('Error updating Org Billing', {
                autoClear: true,
                clearDuration: 3400
              });
            }
          });
        }
      },
      updateOrganization: function updateOrganization() {
        var controller = this;
        var name = this.get('org-name');
        var city = this.get('org-city');
        var address = this.get('org-address');
        var country = this.get('countrySelected');
        var zipcode = this.get('org-pincode');
        var email = this.get('org-email');
        var nameValid,
            cityValid,
            addressValid,
            countryValid,
            zipValid,
            emailValid = false;

        if (name === undefined) {
          controller.notifications.error('Organization NAME is empty', {
            autoClear: true,
            clearDuration: 2600
          });
        } else {
          if (!_npmValidator['default'].isEmpty(name + "")) {
            if (name.length > 2) {
              nameValid = true;
            } else {
              controller.notifications.error('Organization NAME should be greater than 3 characters', {
                autoClear: true,
                clearDuration: 2600
              });
            }
          } else {
            controller.notifications.error('Organization name is empty', {
              autoClear: true,
              clearDuration: 2600
            });
          }
        }

        if (address !== undefined) {
          if (!_npmValidator['default'].isEmpty(address + "")) {
            if (address.length > 10) {
              addressValid = true;
            } else {
              controller.notifications.error('Kindly enter a valid address', {
                autoClear: true,
                clearDuration: 2600
              });
            }
          } else {
            controller.notifications.error('ADDRESSS is not a valid format', {
              autoClear: true,
              clearDuration: 2600
            });
          }
        } else {
          controller.notifications.error('Org ADDRESSS field is empty', {
            autoClear: true,
            clearDuration: 2600
          });
        }

        if (city === undefined) {
          controller.notifications.error('Org CITY is empty', {
            autoClear: true,
            clearDuration: 2600
          });
        } else {
          if (!_npmValidator['default'].isEmpty(city + "")) {
            cityValid = true;
          } else {
            controller.notifications.error('not a valid city', {
              autoClear: true,
              clearDuration: 2600
            });
          }
        }

        if (country === undefined) {
          controller.notifications.error('Org COUNTRY is empty', {
            autoClear: true,
            clearDuration: 2600
          });
        } else {
          if (!_npmValidator['default'].isEmpty(country + "")) {
            countryValid = true;
          } else {
            controller.notifications.error('Kindly select the Org COUNTRY', {
              autoClear: true,
              clearDuration: 2600
            });
          }
        }

        if (zipcode === undefined) {
          controller.notifications.error('Org ZipCode is empty', {
            autoClear: true,
            clearDuration: 2600
          });
        } else {
          if (!_npmValidator['default'].isEmpty(zipcode + "")) {
            zipValid = true;
          } else {
            controller.notifications.error('Kindly select the Org ZipCode', {
              autoClear: true,
              clearDuration: 2600
            });
          }
        }

        if (_npmValidator['default'].isEmail(email + "")) {
          emailValid = true;
        } else {
          controller.notifications.error('Org EMAIL is empty or not valid', {
            autoClear: true,
            clearDuration: 2600
          });
        }

        if (nameValid && cityValid && addressValid && countryValid && zipValid && emailValid) {
          //console.log(controller.get('orgID'));
          var orgParams = {
            name: name,
            orgID: controller.get('orgID'),
            pincode: zipcode,
            city: city,
            country: country.name,
            address: address,
            billingEmail: email
          };
          controller.set('isUpdateOrgBtnDiaabled', true);
          controller.set('org_update_btn', 'Updating Organization..');
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/org/create',
            type: 'POST',
            data: orgParams,
            success: function success() /*response*/{
              controller.set('isUpdateOrgBtnDiaabled', true);
              controller.set('org_update_btn', 'Updated Organization..');
              controller.notifications.success('Successfully Updated organization', {
                autoClear: true,
                clearDuration: 3400
              });
            },
            error: function error(_error2) {
              console.log(_error2);
              controller.set('isUpdateOrgBtnDiaabled', false);
              controller.set('org_update_btn', 'Update Organization');
              controller.notifications.error('Error Updating Organization', {
                autoClear: true,
                clearDuration: 3400
              });
            }
          });
        }
      }, //save_new org
      clear_OrgFields: function clear_OrgFields() {
        this.set('org-name', '');
        this.set('org-city', '');
        this.set('org-address', '');
        this.set('org-email', '');
      },
      verify_vat: function verify_vat() {
        var taxID = this.get('org-taxID');
        var orgID = this.get('orgID');
        var controller = this;
        var taxIDValid = false;
        if (taxID === undefined || taxID === null) {
          controller.notifications.error('Tax ID is empty', {
            autoClear: true,
            clearDuration: 2600
          });
        } else {
          if (!_npmValidator['default'].isEmpty(taxID + "")) {
            if (taxID.length > 5) {
              taxIDValid = true;
            } else {
              controller.notifications.error('Kindly enter a valid TaxID', {
                autoClear: true,
                clearDuration: 2600
              });
            }
          } else {
            controller.notifications.error('Kindly enter a valid TaxID', {
              autoClear: true,
              clearDuration: 2600
            });
          }
        }

        if (taxIDValid) {
          controller.set('isVerifyBtnDiaabled', true);
          controller.set('org_btn_vat_verify', 'Verifying Tax ID..');
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/org/verifyTaxID/' + orgID + '/' + taxID,
            type: 'GET',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json',
            success: function success(response) {
              if (response.valid) {
                controller.set('isVerifyBtnDiaabled', true);
                controller.set('org_btn_vat_verify', 'Verified Tax ID');
                controller.notifications.success('Successfully Verified Tax ID', {
                  autoClear: true,
                  clearDuration: 3400
                });
              } else {
                controller.set('isVerifyBtnDiaabled', false);
                controller.set('org_btn_vat_verify', 'Verify Tax ID');
                controller.notifications.error('Unable to validate Tax ID', {
                  autoClear: true,
                  clearDuration: 3400
                });
              }
            },
            error: function error(_error3) {
              console.log(_error3);
              controller.set('isVerifyBtnDiaabled', false);
              controller.set('org_btn_vat_verify', 'Verify Tax ID');
              controller.notifications.error('Unable to validate Tax ID', {
                autoClear: true,
                clearDuration: 3400
              });
            }
          });
        }
      },
      verify_domain: function verify_domain() {
        var domain = this.get('org-domain');
        var orgID = this.get('orgID');
        var controller = this;
        var domainValid = false;
        if (domain === undefined || domain === null) {
          controller.notifications.error('domian is empty', {
            autoClear: true,
            clearDuration: 2600
          });
        } else {
          if (!_npmValidator['default'].isFQDN(domain + "")) {
            if (domain.length > 3) {
              domainValid = true;
            } else {
              controller.notifications.error('Kindly enter a valid domain start with http://', {
                autoClear: true,
                clearDuration: 2600
              });
            }
          } else {
            controller.notifications.error('Kindly enter a valid domain start with http://', {
              autoClear: true,
              clearDuration: 2600
            });
          }
        }

        if (domainValid) {
          controller.set('isDomainVerifyBtnDiaabled', true);
          controller.set('org_domain_btn_verify', 'Verifying Domain..');
          _ember['default'].$.ajax({
            url: _ember['default'].API_URL + '/org/verifydomain/' + orgID + '/' + domain.replace(/(^\w+:|^)\/\//, ''),
            type: 'GET',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json',
            success: function success(response) {
              if (response.status) {
                controller.set('isDomainVerifyBtnDiaabled', true);
                controller.set('org_btn_vat_verify', 'Verified Tax ID');
                controller.set('regCompany', response);
                controller.notifications.success('Successfully Verified Tax ID', {
                  autoClear: true,
                  clearDuration: 3400
                });
              } else {
                controller.set('isDomainVerifyBtnDiaabled', false);
                controller.set('org_domain_btn_verify', 'Verify Tax ID');
                controller.notifications.error('Unable to verify domain', {
                  autoClear: true,
                  clearDuration: 3400
                });
              }
            },
            error: function error(_error4) {
              console.log(_error4);
              controller.set('isDomainVerifyBtnDiaabled', false);
              controller.set('org_domain_btn_verify', 'Verify Domain');
              controller.notifications.error('Unable to verify domain', {
                autoClear: true,
                clearDuration: 3400
              });
            }
          });
        }
      },
      statusSelected: function statusSelected() {},
      addSelectedUsers: function addSelectedUsers() {
        var controller = this;
        var userParams = {};
        var userList = _.uniq(this.get('selectedUsers'), 'userID');
        var selectedList = _.sortBy(userList, function (users) {
          return users.email;
        });
        this.set('selectedUsers', selectedList);
        userParams.id = userList;
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/org/userupdate/' + controller.get('orgID'),
          type: 'POST',
          data: userParams,
          success: function success() /*response*/{
            var domain = controller.get('org-email').lastIndexOf('@');
            _ember['default'].$.ajax({
              url: _ember['default'].API_URL + '/org/userstats/' + encodeURIComponent(controller.get('org-email').slice(domain + 1)),
              type: 'GET',
              dataType: 'json',
              success: function success(user_stats) {
                var filteredList = [];
                for (var i = 0; i < user_stats.length; i++) {
                  for (var j = 0; j < selectedList.length; j++) {
                    if (selectedList[j].email === user_stats[i].email) {
                      filteredList.push(user_stats[i]);
                    }
                  }
                }
                controller.set('users_list_data', _.sortBy(_.filter(filteredList, function (obj) {
                  return obj.email !== null;
                }), 'campaignCount').reverse());
              },
              error: function error(_error6) {
                console.log(_error6);
              }
            }); //ajax
          },
          error: function error(_error5) {
            console.log(_error5);
          }
        }); //ajax
      },
      deleteUser: function deleteUser(user) {
        var controller = this;
        var userParams = {};
        var userList = this.get('selectedUsers');
        var filteredList = _.without(userList, _.findWhere(userList, {
          userID: user.userID
        }));
        this.set('selectedUsers', _.sortBy(filteredList, filteredList.email));
        userParams.id = filteredList;
        _ember['default'].$.ajax({
          url: _ember['default'].API_URL + '/org/userupdate/' + controller.get('orgID'),
          type: 'POST',
          data: userParams,
          success: function success() /*response*/{
            $('#' + user.userID).closest('tr').children('td').animate({
              padding: 0
            }).wrapInner('<div />').children().slideUp(200, function () {
              $(controller).closest('tr').remove();
            });

            var domain = controller.get('org-email').lastIndexOf('@');
            _ember['default'].$.ajax({
              url: _ember['default'].API_URL + '/org/userstats/' + encodeURIComponent(controller.get('org-email').slice(domain + 1)),
              type: 'GET',
              dataType: 'json',
              success: function success(user_stats) {
                var filteredList_ = [];
                for (var i = 0; i < user_stats.length; i++) {
                  for (var j = 0; j < filteredList.length; j++) {
                    if (filteredList[j].email === user_stats[i].email) {
                      filteredList_.push(user_stats[i]);
                    }
                  }
                }
                controller.set('users_list_data', _.sortBy(_.filter(filteredList_, function (obj) {
                  return obj.email !== null;
                }), 'campaignCount').reverse());
              },
              error: function error(_error8) {
                console.log(_error8);
              }
            }); //ajax
          },
          error: function error(_error7) {
            console.log(_error7);
          }
        }); //ajax
      }
    } });
  //actions

  function isFloat(n) {
    return n.match(/^-?\d*(\.\d+)?$/) && !isNaN(parseFloat(n)) && n % 1 != 0;
  }
});