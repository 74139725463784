define('elbe-ember-frond-end/routes/edit-publisher', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        title: 'Edit Publisher',
        actions: {
            error: function error(_error, transition) {
                if (_error && _error.status === 400 || 401) {
                    return this.transitionTo('serverError');
                }
            }
        }
    });
});