define('elbe-ember-frond-end/components/selector-container', ['exports', 'ember', 'elbe-ember-frond-end/config/environment'], function (exports, _ember, _elbeEmberFrondEndConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({
        selectors: [],
        updated: null,
        width: 256,
        height: 256,
        selectConf: true,
        selectorsAvailable: true,

        didReceiveAttrs: function didReceiveAttrs() {
            this.updateSelectors();
            this.dimObserver();
        },

        srcUrl: (function () {
            var model = this.get('model');
            var ret = "https://ps.visarity.com/campaigns/" + model.campaign.campaignID + '/';
            if (_elbeEmberFrondEndConfigEnvironment['default'].environment === 'production') {
                if (model.campaign.status < 4) {
                    ret = "https://ps.visarity.com/campaigns/" + model.campaign.campaignID + '/';
                } else {
                    ret = "https://ls.visarity.com/campaigns/" + model.campaign.campaignID + '/';
                }
            } else {
                if (model.campaign.status < 4) {
                    ret = "https://dps.visarity.com/campaigns/" + model.campaign.campaignID + '/';
                } else {
                    ret = "https://dls.visarity.com/campaigns/" + model.campaign.campaignID + '/';
                }
            }
            ret = ret + "index.html?fullScreen=1&analytics=0&";

            var dyn = this.get('model.dynSelector');
            for (var attr in dyn) {
                for (var attr2 in dyn[attr]) {
                    //console.log(" " + attr + "." + attr2 + ":" + dyn[attr][attr2]);               
                    ret = ret + "&" + attr + "." + attr2 + "=" + dyn[attr][attr2];
                }
            }

            return ret;
        }).property('model', 'model.campaign.campaignID', 'model.dynSelector'),

        dimObserver: (function () {
            var project = this.get('model.project');
            var a = project.resolutionValueX / project.resolutionValueY;
            var h = Math.min(project.resolutionValueY, 400);
            var w = h * a;
            this.set('width', w);
            this.set('height', h);
        }).observes('model', 'model.project.resolutionY'),

        updateSelectors: (function () {
            var ret;
            var items = JSON.parse(JSON.stringify(this.get('dynItems')));
            //console.log("dynItems: " + JSON.stringify(this.get('dynItems')));
            ret = items.filter(function (i) {
                if (i.type === "selector") {
                    i.selected = true;
                    i.name = i.instanceName.replace(/\W/g, '_');
                    return true;
                }
                return false;
            });
            this.set('selectors', ret);
            this.observerUpdate();
        }).observes("dynItems"),

        observerUpdate: (function () {
            var dyn = {};
            var configurationSelected = "";
            this.get('selectors').forEach(function (s) {
                var split = s.dynId.split('.');
                dyn[split[0]] = {};
                dyn[split[0]][split[1]] = s.active;
            });
            this.set('model.dynSelector', dyn);
        }).observes('updated', 'selectors')

    });
});