define('elbe-ember-frond-end/controllers/verify-email', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['token', 'username'],
    token: null,
    username: null,
    emailVerified: false,
    init: function init() {
      //console.log(this.get('token'));
    }

  });
});