define("elbe-ember-frond-end/helpers/is-before", ["exports", "ember"], function (exports, _ember) {
    exports.isBefore = isBefore;

    function isBefore(p) {
        var s = p.toString().split(",");
        var a = s[0];
        var b = s[1];
        //  console.log("isBefore: " + a + " " + b);
        return moment(a).isBefore(b);
    }

    exports["default"] = _ember["default"].Helper.helper(isBefore);
});